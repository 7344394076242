<svelte:options tag="cdlc-navigation-button" />

<script>
  import { onMount } from 'svelte';

  export let dark_mode = false;
  export let is_from_cdlconnect = false;

  let show = false;

  onMount(() => {
    show = isFromCDLConnect();
  });

  function isFromCDLConnect() {
    try {
      const cpix = window['cpix'];
      if (cpix) {
        console.log('cpix', cpix);
        return (
          is_from_cdlconnect ||
          new cpix.Pixel().paramKeys.utm_medium === 'cdlconnect.io'
        );
      }
    } catch (e) {
      console.error('web component cpix error', e);
    }
  }
</script>

{#if show}
  <a class="NavigationButton" class:dark_mode href="https://cdlconnect.io">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
    >
      <path
        d="M3.28393 14.7478C4.04248 15.5064 5.26781 15.5064 5.99718 14.7478L17.4628 3.28215C18.2214 2.52361 18.2214 1.29827 17.4628 0.568907C16.7043 -0.189636 15.479 -0.189636 14.7496 0.568907L3.28393 12.0346C2.52539 12.7639 2.52539 13.9893 3.28393 14.7478Z"
        fill="#2E5FF6"
      />
      <path
        d="M0.568907 7.33841C1.32745 8.09696 2.55279 8.09696 3.28216 7.33841L7.10404 3.51653C7.86258 2.75799 7.86258 1.53265 7.10404 0.803282C6.3455 0.0447395 5.12016 0.0447395 4.3908 0.803282L0.568907 4.62517C-0.189636 5.38371 -0.189636 6.60905 0.568907 7.33841Z"
        fill="#278254"
      />
      <path
        d="M10.6334 17.4315C11.3919 18.1901 12.6172 18.1901 13.3466 17.4315L20.5819 10.1962C21.3405 9.43767 21.3405 8.21234 20.5819 7.48297C19.8234 6.72443 18.598 6.72443 17.8687 7.48297L10.6334 14.7183C9.87482 15.4477 9.87482 16.673 10.6334 17.4315Z"
        fill="#DF2C19"
      />
    </svg>
    <span>Back to CDLConnect</span>

    <svg
      width="7"
      height="11"
      viewBox="0 0 7 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.65204 10.75C1.4411 10.75 1.2536 10.6797 1.11298 10.5391C0.808289 10.2578 0.808289 9.76562 1.11298 9.48438L5.07391 5.5L1.11298 1.53906C0.808289 1.25781 0.808289 0.765625 1.11298 0.484375C1.39423 0.179688 1.88641 0.179688 2.16766 0.484375L6.66766 4.98438C6.97235 5.26562 6.97235 5.75781 6.66766 6.03906L2.16766 10.5391C2.02704 10.6797 1.83954 10.75 1.65204 10.75Z"
        fill={dark_mode ? '#F8F8F8' : '#000'}
      />
    </svg>
  </a>
{/if}

<style src="./NavigationButton.scss">
</style>
