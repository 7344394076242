<svelte:options tag="cdlc-indeed-job-ad" />

<script>
  import _ from 'lodash';
  export let ad_data;

  $: console.warn('ad_data', ad_data);
</script>

{#if ad_data && ad_data.headline}
  <div
    class="IndeedJobAd"
    on:click={() => {
      if (_.get(ad_data, 'permalink_url')) {
        window.open(_.get(ad_data, 'permalink_url'), '_blank');
      }
    }}
  >
    <div class="IndeedJobAd-jobTitle">
      <h2>{_.get(ad_data, 'headline')}</h2>
      <button
        ><svg
          width="24"
          height="24"
          role="presentation"
          aria-hidden="true"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          ><path
            d="M12 7C13.1 7 14 6.1 14 5C14 3.9 13.1 3 12 3C10.9 3 10 3.9 10 5C10 6.1 10.9 7 12 7ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 17C10.9 17 10 17.9 10 19C10 20.1 10.9 21 12 21C13.1 21 14 20.1 14 19C14 17.9 13.1 17 12 17Z"
            fill="#2d2d2d"
          /></svg
        ></button
      >
    </div>
    <div class="IndeedJobAd-subJobTitle">
      <h5>{_.get(ad_data, 'companyName')}</h5>
    </div>
    <div class="IndeedJobAd-Location">
      {#if _.get(ad_data, 'location')}
        <h5>{_.get(ad_data, 'location')}</h5>
      {/if}
      {#if _.get(ad_data, 'additionalLocations')}
        <span>+{_.size(ad_data.additionalLocations)} locations</span>
      {/if}
    </div>
    <div class="Indeed-tagWrapper">
      {#each _.get(ad_data, 'tags') as tag}
        <div class="Indeed-tag">
          {tag}
        </div>
      {/each}
    </div>
    <div class="Indeed-actionWrapper">
      {#each _.get(ad_data, 'actions') as action}
        <div class="Indeed-action">
          {#if action.icon}
            <img width="14" height="14" alt="action icon" src={action.icon} />
          {/if}
          {action.text}
        </div>
      {/each}
    </div>

    <div class="Indeed-descriptionWrapper">
      <ul class="Indeed-descriptionList">
        {#each _.get(ad_data, 'tags') as tag, i}
          {#if i < 3}
            <li>{tag}</li>
          {/if}
        {/each}
      </ul>
    </div>
    <div class="Indeed-activity">12 days ago</div>
  </div>
{/if}

<style src="./IndeedJobAd.scss">
</style>
