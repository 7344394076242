<svelte:options tag="cdlc-live-chat-typing-icon" />

<script>
  import debug from 'debug';
  import _ from 'lodash';

  const log = debug('cdlc:TypingIcon');
</script>

<div class="typingIcon">
  <svg width="50" height="25" xmlns="http://www.w3.org/2000/svg">
    <circle cx="17" cy="12.5" r="2.5" fill="#2E5FF6" />
    <circle cx="25" cy="12.5" r="2.5" fill="#2E5FF6" />
    <circle cx="33" cy="12.5" r="2.5" fill="#2E5FF6" />
  </svg>
</div>

<style src="./TypingIcon.scss">
</style>
