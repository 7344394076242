<svelte:options tag="cdlc-facebook-job-ad" />

<script>
  import _ from 'lodash';
  export let ad_data = {};
  let userInfo = {};

  $: {
    if (_.get(ad_data, 'from.name') === 'Livetrucking.com') {
      userInfo = {
        name: 'Live Trucking',
        logo: _.get(ad_data, 'from.picture'),
      };
    } else {
      userInfo = {
        name: 'CDLLife',
        logo: 'https://d2r0eic16r3uxv.cloudfront.net/1625075883441233.png.resize.jpg?width=600',
      };
    }
  }
</script>

{#if ad_data}
  <div
    class="FacebookJobAd"
    on:click={() => {
      if (ad_data.permalink_url) {
        window.open(ad_data.permalink_url, '_blank');
      }
    }}
  >
    <div class="FacebookJobAd-headerWrapper">
      <div class="FacebookJobAd-pageLogo">
        <img src={_.get(ad_data, 'from.picture')} alt="company picture" />
        <div class="FacebookJobAd-pageName">
          <p>{userInfo.name}</p>
          <span>a few seconds ago</span>
        </div>
      </div>
      <div class="FacebookJobAd-headerAction">
        <svg
          width="18"
          height="4"
          viewBox="0 0 18 4"
          fill="#989AA2"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 3C9.55228 3 10 2.55229 10 2C10 1.44772 9.55228 1 9 1C8.44772 1
            8 1.44772 8 2C8 2.55229 8.44772 3 9 3Z"
            fill="#989AA2"
          />
          <path
            d="M16 3C16.5523 3 17 2.55229 17 2C17 1.44772 16.5523 1 16 1C15.4477
            1 15 1.44772 15 2C15 2.55229 15.4477 3 16 3Z"
            fill="#989AA2"
          />
          <path
            d="M2 3C2.55228 3 3 2.55229 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1
            1.44772 1 2C1 2.55229 1.44772 3 2 3Z"
            fill="#989AA2"
          />
          <path
            d="M9 3C9.55228 3 10 2.55229 10 2C10 1.44772 9.55228 1 9 1C8.44772 1
            8 1.44772 8 2C8 2.55229 8.44772 3 9 3Z"
            stroke="#989AA2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16 3C16.5523 3 17 2.55229 17 2C17 1.44772 16.5523 1 16 1C15.4477
            1 15 1.44772 15 2C15 2.55229 15.4477 3 16 3Z"
            stroke="#989AA2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2 3C2.55228 3 3 2.55229 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1
            1.44772 1 2C1 2.55229 1.44772 3 2 3Z"
            stroke="#989AA2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <p class="FacebookJobAd-description">{_.get(ad_data, 'message')}</p>
    {#if _.get(ad_data, 'full_picture')}
      <div class="FacebookJobAd-mainAdImage">
        <img
          alt="Ad Image"
          src={_.get(ad_data, 'full_picture') || _.get(ad_data, 'image')}
        />
      </div>
    {/if}
    {#if _.get(ad_data, 'call_to_action')}
      <div class="FacebookJobAd-footer">
        <div class="FacebookJobAd-footer-left">
          <span>cdllife.com</span>
          <p>{_.get(ad_data, 'call_to_action.value.link_title')}</p>
          <span>{_.get(ad_data, 'call_to_action.value.link_description')}</span>
        </div>
        <div class="FacebookJobAd-footer-right">
          <button
            >{_.startCase(
              _.capitalize(_.get(ad_data, 'call_to_action.type'))
            ) || 'Apply Now'}</button
          >
        </div>
      </div>
    {/if}
  </div>
{/if}

<style src="./FacebookJobAd.scss">
</style>
