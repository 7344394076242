<svelte:options tag="cdlc-mobile-post-header" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import moment from 'moment';
  import { onMount, tick } from 'svelte';
  import { get_current_component } from 'svelte/internal';

  const log = debug('cdlc:MobilePostHeader');

  export let dispatch = () => {};
  export let post;
  export let options = {
    showEllipses: true,
    // hideAvatar: false,
    // askSensitive: true,
  };

  let isVIP = true;
  let companyNameOverride;
  let formatUserTags = '';
  let imageUrl;
  let postDateLabel = '';
  let verifiedStatus = '';

  $: sourceTitle = updateSourceTitle(post);

  onMount(async () => {
    await tick();
    isVIP = _.get(post, 'user.states.isVIP');
    verifiedStatus = _.get(post, 'user.states.verifiedStatus');
    updateCompanyNameOverride();
    updateFormatUserTags();
    updatePostDateLabel();
    updateImageUrl();
    log('post: ', post);
  });

  function updateSourceTitle(post) {
    const source = _.get(post, 'source');
    if (source === 'CDLLIFE') {
      return 'CDLLife';
    } else if (source === 'LIVETRUCKING') {
      return 'Live Trucking';
    } else if (source === 'TRUCKDRIVERSUSA') {
      return 'Truck Drivers U.S.A.';
    } else {
      return _.get(post, 'user.firstName');
    }
  }

  function updateFormatUserTags() {
    formatUserTags = '';
    const user = _.get(post, 'user');
    const userTag = _.get(post, 'user.userTag');

    let userTags = {
      cdlHolder: 'CDL Holder',
      ownerOperator: 'Owner Operator',
      ownAuthority: 'Own Authority',
      moderator: 'Moderator',
    };

    if (
      _.get(user, 'dates.created') &&
      new Date(user.dates.created) > moment().subtract(7, 'days').toDate()
    ) {
      return 'New User';
    }

    formatUserTags = userTags[userTag] || null;
  }

  function updatePostDateLabel() {
    const postType = _.get(post, 'postType');
    if (_.includes(['AD', 'JOB'], postType)) {
      postDateLabel = 'Sponsored';
    } else if (
      _.get(post, 'clid') &&
      !_.get(post, 'pollInformation.choices.length')
    ) {
      postDateLabel = 'Sponsored';
    } else {
      const date = _.get(post, 'dates.created');
      postDateLabel = moment(date).fromNow();
    }

    
  }

  function updateCompanyNameOverride() {
    companyNameOverride = '';
    if (post.postType === 'AD') {
      companyNameOverride = _.get(post, 'adInformation.companyNameOverride');
    } else if (post.postType === 'JOB') {
      companyNameOverride = _.get(post, 'jobInformation.poster.name');
    }
  }

  function updateImageUrl() {
    imageUrl = '';
    if (post.postType === 'AD') {
      imageUrl = _.get(post, 'adInformation.companyImageOverride');
    } else if (post.postType === 'JOB') {
      imageUrl = _.get(post, 'jobInformation.poster.companyLogo');
    } else if (
      post.postType === 'MESSAGE' &&
      _.get(post, 'pollInformation.sponsored')
    ) {
      imageUrl = _.get(post, 'pollInformation.companyInfo.userImage');
    }
    if (!imageUrl) {
      imageUrl = _.get(post, 'user.userImage');
    }
  }
</script>

<div class="MobilePostHeader">
  {#if post}
    {#if !options.hideAvatar}
      <div
        class="MobilePostHeader-avatarContainer"
        on:click={() => {
          dispatch('header-user');
        }}
      >
        {#if post.postType !== 'ARTICLE'}
          <img
            src={imageUrl ||
              'https://d2r0eic16r3uxv.cloudfront.net/1601471704432269.png'}
            alt="Avatar"
            class="MobilePostHeader-avatar"
          />
          {#if isVIP}
            <svg
              id="Layer_1"
              class="MobilePostHeader-isVIP"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 48.23"
            >
              <defs>
                <style>
                  .cls-1 {
                    fill: #ededee;
                  }
                  .cls-2 {
                    fill: #3a5aa7;
                  }
                  .cls-3 {
                    fill: #737374;
                  }
                  .cls-4 {
                    opacity: 0.6;
                  }
                  .cls-5 {
                    fill: #fff;
                  }
                </style>
              </defs>
              <title>VIP Flair</title>
              <path
                class="cls-1"
                d="M91,7.72H40.91a24.06,24.06,0,0,1,0,34.56H91a9,9,0,0,0,9-9V16.73A9,9,0,0,0,91,7.72Z"
                transform="translate(0 -0.89)"
              />
              <circle class="cls-2" cx="24.11" cy="24.11" r="24.11" />
              <path
                class="cls-3"
                d="M60.21,28.49l3.14-10.42h3.18L61.7,31.93h-3l-4.8-13.86h3.17Z"
                transform="translate(0 -0.89)"
              />
              <path
                class="cls-3"
                d="M72.82,31.93H70V18.07h2.86Z"
                transform="translate(0 -0.89)"
              />
              <path
                class="cls-3"
                d="M80.27,27.05v4.88H77.41V18.07h5.41a6.24,6.24,0,0,1,2.74.57,4.25,4.25,0,0,1,1.83,1.62,4.6,4.6,0,0,1,.63,2.4,4,4,0,0,1-1.39,3.21,5.84,5.84,0,0,1-3.86,1.18Zm0-2.32h2.55a2.51,2.51,0,0,0,1.73-.53,1.94,1.94,0,0,0,.59-1.52,2.29,2.29,0,0,0-.6-1.65,2.26,2.26,0,0,0-1.65-.65H80.27Z"
                transform="translate(0 -0.89)"
              />
              <g class="cls-4">
                <path
                  class="cls-5"
                  d="M13.57,38.25c.19-1.19.37-2.34.57-3.49.29-1.72.59-3.43.9-5.15.06-.31-.11-.48-.31-.67L9,23.44a2.88,2.88,0,0,1-.61-.85,1.94,1.94,0,0,1,1.75-2.65c2.12-.27,4.24-.61,6.36-.92.5-.07,1-.19,1.5-.21a.72.72,0,0,0,.72-.51q1.83-3.73,3.7-7.44a1.85,1.85,0,0,1,3.2-.17,3.45,3.45,0,0,1,.25.41q1.84,3.69,3.67,7.39c.07.15.13.27.32.3l4.45.63c1.29.18,2.59.35,3.88.55a1.89,1.89,0,0,1,1.11,3.31c-1.81,1.82-3.69,3.59-5.55,5.37A1.29,1.29,0,0,0,33.23,30c.5,2.6.89,5.22,1.35,7.82a1.88,1.88,0,0,1-.68,1.92,1.75,1.75,0,0,1-2,.2l-4.71-2.45c-.86-.46-1.74-.9-2.6-1.37a.89.89,0,0,0-.93,0c-2.4,1.27-4.8,2.53-7.22,3.77a1.85,1.85,0,0,1-2.77-1.19A2.65,2.65,0,0,1,13.57,38.25ZM33.16,23.63a.81.81,0,0,0-.79-.64c-1.61-.24-3.21-.5-4.82-.71a.7.7,0,0,1-.61-.46c-.69-1.44-1.4-2.86-2.1-4.29-.15-.32-.34-.61-.72-.61s-.59.29-.75.62c-.7,1.43-1.41,2.86-2.1,4.29a.74.74,0,0,1-.62.46c-1.42.18-2.84.4-4.25.6l-.68.11a.71.71,0,0,0-.62.49.74.74,0,0,0,.29.84c1.15,1.12,2.31,2.25,3.48,3.36a.71.71,0,0,1,.24.73c-.29,1.56-.53,3.13-.81,4.69-.06.35-.11.68.21.91s.68.12,1-.06c1.36-.73,2.73-1.42,4.09-2.16a.92.92,0,0,1,1,0c1.38.76,2.78,1.47,4.17,2.21a.81.81,0,0,0,.94,0,.72.72,0,0,0,.21-.8c-.27-1.57-.5-3.14-.8-4.7a.86.86,0,0,1,.3-.9c1.11-1.05,2.2-2.12,3.3-3.17A1.15,1.15,0,0,0,33.16,23.63Z"
                  transform="translate(0 -0.89)"
                />
              </g>
              <path
                class="cls-5"
                d="M33.17,23.63a1.19,1.19,0,0,1-.44.81c-1.1,1-2.19,2.12-3.3,3.17a.84.84,0,0,0-.3.9c.3,1.56.53,3.13.8,4.7a.73.73,0,0,1-.21.8.81.81,0,0,1-.94,0c-1.39-.74-2.79-1.45-4.18-2.21a.92.92,0,0,0-1,0c-1.35.74-2.72,1.43-4.09,2.16-.34.18-.68.32-1,.06s-.26-.56-.2-.91c.27-1.56.51-3.13.8-4.69a.67.67,0,0,0-.24-.73l-3.48-3.36a.76.76,0,0,1-.29-.84.74.74,0,0,1,.63-.49l.67-.11c1.42-.2,2.83-.42,4.25-.6a.71.71,0,0,0,.62-.46c.69-1.43,1.41-2.86,2.1-4.29.16-.33.35-.63.76-.62s.56.29.72.61c.69,1.43,1.41,2.85,2.1,4.29a.69.69,0,0,0,.61.46c1.61.21,3.21.47,4.82.71A.82.82,0,0,1,33.17,23.63Z"
                transform="translate(0 -0.89)"
              />
            </svg>
          {/if}
          <div></div>
        {/if}
        {#if post.postType === 'ARTICLE'}
          {#if post.source === 'CDLLIFE'}
            <!-- Generator: Adobe Illustrator 24.2.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
            <svg
              style="border: 1px solid #F4F5F8; border-radius: 50%;"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="40"
              height="40"
              viewBox="0 0 38 38"
              xml:space="preserve"
              ><style type="text/css">
                .st0 {
                  fill: #2c2d2d;
                }
                .st1 {
                  fill: #2e5ff6;
                }
              </style>
              <g>
                <g>
                  <g>
                    <path
                      class="st0"
                      d="M30.2,17.8c-0.4,0-0.7,0.1-0.9,0.3c0,0,0,0,0,0l-0.1,0l0,0c-0.2,0-0.4,0-0.6-0.1l-0.6,0
                      c-0.4,0-0.7,0-1.1-0.1l-1.1-0.1l-0.6,0l-0.3,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0v-1.4H23v5.9h3.9v-1.2h-2v-3.1
                      c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0l0.3,0l0.6,0l1.1,0.1c0.4,0,0.7,0.1,1.1,0.1l0.6,0c0.2,0,0.4,0,0.6,0l0,0c0,0,0,0,0,0
                      c0,0.1,0,0.2,0,0.3v2.5c0,0.2,0,0.3,0.1,0.4L26.6,23h-3.9v5.9h4.2v-1.2h-2.3v-1.4h2v-0.8l1.8-3.1l1-0.5c0.2,0.2,0.5,0.3,0.8,0.3
                      c0.6,0,1.1-0.3,1.1-1v-2.5C31.3,18.1,30.8,17.8,30.2,17.8z
                      M26.3,25.2h-1.7V24h2.3v-0.7l1.1-0.6L26.3,25.2z"
                    />
                    <path
                      class="st0"
                      d="M11.7,28.9h3.9v-1.2h-2V23h-1.9V23l-2.9-1.5c0.1-0.1,0.1-0.3,0.1-0.4v-2.5c0-0.1,0-0.2,0-0.3c0,0,0,0,0,0
                      l0,0c0.2,0,0.4,0,0.6,0l0.6,0c0.4,0,0.7-0.1,1.1-0.1l0.2,0c-0.1,0.4-0.2,0.8-0.2,1.3c0,0.5,0.1,0.9,0.3,1.3
                      c0.2,0.4,0.4,0.7,0.7,1c0.3,0.3,0.6,0.5,1,0.6c0.4,0.1,0.8,0.2,1.2,0.2c0.3,0,0.6,0,0.9-0.1c0.3-0.1,0.6-0.2,0.9-0.3l-0.3-1.3
                      c-0.3,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.2-0.5-0.3c-0.1-0.2-0.2-0.3-0.3-0.6
                      C13,19.9,13,19.7,13,19.4c0-0.3,0-0.5,0.1-0.7c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.1,0.6-0.1
                      c0.1,0,0.2,0,0.4,0c0.1,0,0.3,0,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1l0.3-1.3c-0.1,0-0.3-0.1-0.4-0.1
                      c-0.2,0-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.5-0.1c-0.2,0-0.3,0-0.4,0c-0.5,0-0.9,0.1-1.3,0.2c-0.4,0.1-0.7,0.3-1,0.6
                      c-0.2,0.2-0.4,0.5-0.6,0.8l-0.4,0c-0.4,0-0.7,0-1.1,0.1l-0.6,0c-0.2,0-0.4,0-0.6,0.1l0,0l-0.1,0c0,0,0,0,0,0
                      c-0.2-0.2-0.5-0.3-0.9-0.3c-0.6,0-1.1,0.2-1.1,0.9v2.5c0,0.6,0.5,1,1.1,1c0.3,0,0.6-0.1,0.8-0.3l1.3,0.7l1.8,3.1V28.9z
                      M10.3,22.8l1.4,0.8v1.4L10.3,22.8z"
                    />
                    <rect x="15.9" y="23" class="st0" width="1.9" height="5.9" />
                    <polygon
                      class="st0"
                      points="22.3,24 22.3,23 18.4,23 18.4,28.9 20.3,28.9
                      20.3,26.8 22,26.8 22,25.5 20.3,25.5 20.3,24 "
                    />
                    <path
                      class="st0"
                      d="M20.3,22.2c0.4-0.1,0.7-0.3,1-0.5c0.3-0.2,0.5-0.5,0.7-0.9c0.2-0.4,0.2-0.9,0.2-1.4c0-0.5-0.1-0.9-0.2-1.3
                      c-0.1-0.4-0.4-0.7-0.6-0.9c-0.3-0.2-0.6-0.4-1-0.5c-0.4-0.1-0.8-0.2-1.3-0.2H17v5.9h2C19.5,22.4,19.9,22.3,20.3,22.2z
                      M18.9,21
                      v-3.3H19c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.1,0.5,0.3c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.2,0.1,0.5,0.1,0.8c0,0.4,0,0.6-0.1,0.9
                      c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.3,0.2-0.5,0.2C19.4,21,19.2,21,19,21H18.9z"
                    />
                    <path
                      class="st0"
                      d="M10.9,15.5c0,0,0,0.1,0.2,0.1c0.1,0,7.9,0.3,7.9,0.3l0.1,0l0.1,0c0,0,7.9-0.3,7.9-0.3c0.1,0,0.2-0.1,0.2-0.1
                      s0.1-1,0-1.5V5h-1.6v6.8l0,0c0.2,0,0.3,0,0.3,0c0.7,0.2,0.9,0.7,0.9,0.8c0,0.1,0,0.6,0,0.9c-0.2-0.1-0.3-0.1-0.3-0.1l-7.3,0.2
                      l0,0l-0.2,0l-0.2,0l0,0l-7.3-0.2c0,0-0.1,0-0.2,0l0.1-0.8c0,0-0.1-0.8,1.2-1c0,0,0,0,0,0V5h-1.6v9.8c0,0,0,0.1,0,0.1
                      C10.9,15.2,10.9,15.5,10.9,15.5z"
                    />
                  </g>
                  <g>
                    <path
                      class="st1"
                      d="M26.8,12.7c0-0.1-0.2-0.7-0.9-0.8c-0.7-0.2-7-0.3-7-0.3s-0.6,0-1.1,0c-0.5,0-4.1,0.1-5.3,0.2
                      c-1.2,0.1-1.2,1-1.2,1l-0.1,0.8c0.1,0,0.2,0,0.2,0l7.3,0.2l0,0l0.2,0l0.2,0l0,0l7.3-0.2c0,0,0.1,0,0.3,0.1
                      C26.8,13.3,26.8,12.8,26.8,12.7z
                      M12.7,13.1l-0.3,0c-0.2,0-0.3,0.1-0.3-0.1l0-0.3c0-0.2,0.1-0.2,0.3-0.3l0.3,0
                      c0.2,0,0.3,0,0.3,0.2l0,0.3C13,13.1,12.9,13,12.7,13.1z
                      M17.1,13c-0.2,0-0.7,0-0.7,0c0,0,0,0,0,0v-0.6c0,0,0-0.1,0.1-0.1
                      c0.1,0,0.3-0.1,0.3-0.1c0.1,0,0.3,0,0.4,0c0,0,0.1,0,0.1,0.1C17.3,12.3,17.3,12.9,17.1,13z
                      M19.2,13L19,13c-0.2,0-0.3,0-0.3-0.2
                      l0-0.3c0-0.2,0.1-0.2,0.3-0.2l0.3,0c0.2,0,0.3,0,0.3,0.2l0,0.3C19.5,13,19.4,13,19.2,13z
                      M21.8,12.9
                      C21.8,12.9,21.8,12.9,21.8,12.9c0,0.1-0.6,0.1-0.8,0c-0.2,0-0.2-0.6-0.2-0.7c0-0.1,0.1-0.1,0.1-0.1c0,0,0.3,0,0.4,0
                      c0.1,0,0.3,0,0.3,0.1c0.1,0,0.1,0.1,0.1,0.1V12.9z
                      M26.2,12.6l0,0.3c0,0.2-0.1,0.2-0.3,0.2l-0.3,0c-0.2,0-0.3,0-0.3-0.2l0-0.3
                      c0-0.2,0.2-0.2,0.3-0.2l0.3,0C26,12.4,26.2,12.4,26.2,12.6z"
                    />
                  </g>
                </g>
                <path
                  class="st0"
                  d="M10.3,29.4v2.7h0.1v0.2c0,0.4,0.4,0.8,0.9,0.8h2c0.5,0,0.9-0.3,0.9-0.8v-0.2h9.6v0.2c0,0.4,0.4,0.8,0.9,0.8h2
                  c0.5,0,0.9-0.3,0.9-0.8v-0.2h0.2v-2.7H10.3z"
                />
              </g>
            </svg>
          {:else if post.source === 'LIVETRUCKING'}
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 300 300"
              style="enable-background:new 0 0 300 300;"
              xml:space="preserve"
              ><style type="text/css">
                .st0 {
                  fill: #222b59;
                }
                .st1 {
                  fill: #cd3741;
                }
                .st2 {
                  fill: #3a5aa7;
                }
                .st3 {
                  fill: #f6fafd;
                }
                .st4 {
                  fill: #892129;
                }
                .st5 {
                  fill-rule: evenodd;
                  clip-rule: evenodd;
                  fill: #f6fafd;
                }
                .st6 {
                  fill-rule: evenodd;
                  clip-rule: evenodd;
                  fill: #222b59;
                }
                .st7 {
                  fill: #ae2224;
                }
                .st8 {
                  fill: #ffffff;
                }
                .st9 {
                  fill: #2c2d2d;
                }
                .st10 {
                  fill: #085b99;
                }
              </style>
              <g>
                <rect x="-0.5" y="-0.5" class="st7" width="301" height="301" />
                <g id="epexb0_1_">
                  <g>
                    <path
                      class="st8"
                      d="M189.6,247.4c-18.9,0-37.5,0-56.5,0c10.4-48.8,20.7-97.3,31.1-146.3c-14.1,0-27.5,0-41.6,0
                      c1.6-6.9,3.1-13.3,4.6-19.7c46.7,0,93.2,0,140.2,0c-1.3,6.1-2.5,12.1-4,17.9c-0.2,0.9-2.3,1.7-3.6,1.7c-11.8,0.1-23.5,0.2-35.3,0
                      c-2.7,0-3.8,0.7-4.4,3.5c-9.8,46.3-19.7,92.5-29.5,138.8C190.2,244.6,189.9,245.8,189.6,247.4z"
                    />
                    <path
                      class="st8"
                      d="M106.5,55.9c-7.8,36.3-15.4,71.9-23.2,108.2c13.5,0,26.5,0,39.8,0c-1.2,5.7-2.2,10.8-3.3,15.9
                      c-27.5,0-54.6,0-82.3,0c1.5-7.3,3-14.3,4.5-21.4c7.1-33.3,14.1-66.6,21.3-99.8c0.2-1.1,1.7-2.9,2.7-2.9
                      C79.4,55.9,92.7,55.9,106.5,55.9z"
                    />
                  </g>
                </g>
              </g>
            </svg>
          {:else if post.source === 'TRUCKDRIVERSUSA'}
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 300 300"
              style="enable-background:new 0 0 300 300;"
              xml:space="preserve"
              ><style type="text/css">
                .st0 {
                  fill: #222b59;
                }
                .st1 {
                  fill: #cd3741;
                }
                .st2 {
                  fill: #3a5aa7;
                }
                .st3 {
                  fill: #f6fafd;
                }
                .st4 {
                  fill: #892129;
                }
                .st5 {
                  fill-rule: evenodd;
                  clip-rule: evenodd;
                  fill: #f6fafd;
                }
                .st6 {
                  fill-rule: evenodd;
                  clip-rule: evenodd;
                  fill: #222b59;
                }
                .st7 {
                  fill: #ae2224;
                }
                .st8 {
                  fill: #ffffff;
                }
                .st9 {
                  fill: #2c2d2d;
                }
                .st10 {
                  fill: #085b99;
                }
              </style>
              <g>
                <path
                  class="st0"
                  d="M149.8,300l12.4-13.2c11.5-12.3,26.1-20.4,43.5-24.1c16.9-3.6,34.9-8.1,51.6-16.2c13-6.3,21-13.3,26.1-22.9
                  c14.2-26.6,15.2-51.4,3.2-75.9c-1.8-3.7-3.7-7.2-5.7-11c-1.7-3.2-3.5-6.6-5.1-9.9c-10-20.2-8.3-38.9,4.9-55.6l8.5-10.7l-8.6-10.6
                  c-7-8.6-12.8-16-18.1-23.2l-7-9.4l-11.3,3.2c-10.6,3-20.9,4.5-30.7,4.5c-19.3,0-37.4-6-53.7-17.9l-10-7.3l-10,7.3
                  c-16.3,12-34.5,18-54,18c-9.7,0-19.9-1.5-30.5-4.4l-11.1-3.1L37.4,27C30.7,35.7,25,43,19.2,50.1l-8.8,10.6L19,71.5
                  c13,16.2,14.8,34.6,5.4,54.5c-2,4.2-4.2,8.4-6.4,12.5c-1.9,3.6-3.8,7.1-5.5,10.7c-5.4,11-7.8,21.6-7.3,32.1
                  c1.8,45.2,21.7,61.1,51,71c14.4,4.9,26.5,8.2,38.1,10.6c17.1,3.5,31.7,11.5,43.3,23.9L149.8,300"
                />
                <path
                  class="st0"
                  d="M277.1,220.2c-4.3,8.1-11.4,14.2-22.9,19.8c-16,7.7-33.5,12.1-50,15.6c-18.8,4-34.7,12.9-47.3,26.2l-7.1,7.5
                  l-7.1-7.5c-12.7-13.5-28.5-22.3-47.1-26c-11.3-2.3-23.1-5.6-37.2-10.3c-27.2-9.2-44.4-23.1-46-64.4c-0.5-9.4,1.7-18.8,6.5-28.7
                  c1.7-3.5,3.5-6.9,5.5-10.5c2.2-4.2,4.5-8.4,6.5-12.8c10.6-22.3,8.4-43.8-6.3-62.2l-4.9-6.2l5-6.1c6-7.2,11.7-14.6,18.4-23.4l4-5.2
                  l6.3,1.8c11.2,3.1,22.1,4.7,32.5,4.7c21.1,0,40.7-6.5,58.3-19.4l5.7-4.2l5.7,4.2c17.6,12.8,37.1,19.3,58,19.3
                  c10.4,0,21.4-1.6,32.7-4.8l6.4-1.8l4,5.4c5.4,7.3,11.2,14.7,18.3,23.4l4.9,6l-4.8,6.1c-14.9,18.8-16.9,40.7-5.7,63.3
                  c1.7,3.4,3.5,6.9,5.3,10.2c2,3.7,3.8,7.1,5.5,10.7C291.1,173.2,290.1,195.9,277.1,220.2z"
                />
                <g>
                  <path
                    class="st1"
                    d="M267.7,225.4c-0.3,0.3-0.6,0.6-0.9,0.9C267.1,225.9,267.4,225.7,267.7,225.4z"
                  />
                  <path
                    class="st1"
                    d="M267.9,225.2L267.9,225.2c-0.1,0.1-0.1,0.1-0.2,0.2C267.7,225.3,267.8,225.2,267.9,225.2z"
                  />
                  <path
                    class="st1"
                    d="M266.8,226.2c-0.2,0.1-0.3,0.3-0.5,0.4C266.5,226.5,266.6,226.4,266.8,226.2z"
                  />
                  <path
                    class="st1"
                    d="M266.3,226.7c-0.5,0.4-0.9,0.8-1.4,1.3C265.4,227.5,265.9,227.1,266.3,226.7z"
                  />
                  <path
                    class="st1"
                    d="M96.8,251.1c-2.7-0.5-5.4-1.1-8.2-1.8C91.4,249.9,94.1,250.5,96.8,251.1z"
                  />
                </g>
                <g>
                  <path
                    class="st2"
                    d="M281.2,195.8C281.2,195.8,281.2,195.8,281.2,195.8c0.4-1.7,0.7-3.4,0.9-5.1c0-0.2,0.1-0.4,0.1-0.6
                    c0.1-0.8,0.2-1.6,0.3-2.4c0-0.2,0-0.3,0-0.5c0.1-1.4,0.2-2.8,0.2-4.3c0-0.4,0-0.7,0-1.1c0-0.7,0-1.4,0-2.1c0-0.5,0-1-0.1-1.5
                    c-0.1-1-0.1-1.9-0.2-2.9c-0.1-0.6-0.1-1.2-0.2-1.8c-0.1-0.5-0.2-1.1-0.2-1.6c-0.1-0.8-0.3-1.6-0.5-2.5c-0.1-0.5-0.2-1-0.3-1.5
                    c-0.2-0.9-0.4-1.7-0.6-2.6c-0.1-0.4-0.2-0.8-0.3-1.2c-0.3-1.1-0.6-2.1-1-3.2c-0.1-0.1-0.1-0.3-0.2-0.4c-0.4-1.1-0.8-2.1-1.2-3.2
                    c-0.1-0.3-0.3-0.6-0.4-0.9c-0.5-1.2-1-2.3-1.6-3.5c-1.7-3.5-3.5-6.9-5.5-10.5c-1.8-3.3-3.6-6.8-5.4-10.3
                    c-12-24.4-9.9-48.1,6.3-68.4l2.4-3.1l-2.5-3c-7.2-8.8-13-16.3-18.5-23.6l-2-2.7l-3.2,0.9c-11.7,3.3-23.1,5-34,5l0,0h0
                    c-21.9,0-42.4-6.8-60.8-20.2l-2.9-2.1l-2.9,2.1c-18.5,13.5-39,20.4-61.1,20.4h0l0,0c-10.8,0-22.1-1.6-33.8-4.9L49,31.7l-2,2.6
                    c-6.7,8.9-12.5,16.2-18.5,23.5l-2.5,3l2.5,3.1c15.9,19.9,18.3,43.1,6.8,67.3c-2.1,4.4-4.4,8.7-6.6,13c-1.9,3.6-3.7,6.9-5.4,10.4
                    c-4.5,9.2-6.4,17.8-6,26.4c0.1,1.3,0.1,2.6,0.2,3.9c0,0.2,0,0.5,0.1,0.7c0.1,1.1,0.2,2.1,0.3,3.1c0,0.2,0,0.3,0,0.5
                    c0.1,1.1,0.2,2.2,0.4,3.2c0,0.1,0,0.1,0,0.2c0.2,1.1,0.3,2.3,0.5,3.4l118.7-63.8h2.3L22.3,209.4c1.2,3.2,2.7,6.1,4.3,8.7
                    c0,0,0,0,0,0.1c0.4,0.6,0.8,1.2,1.2,1.8c0,0,0.1,0.1,0.1,0.1c0.4,0.6,0.8,1.1,1.2,1.6c0.1,0.1,0.1,0.1,0.2,0.2
                    c0.4,0.5,0.8,1,1.3,1.5c0.1,0.1,0.1,0.1,0.2,0.2c0.5,0.6,1,1.1,1.5,1.6l109.8-93.2h2.3L54.7,239c0,0,0,0,0,0
                    c1.7,0.7,3.5,1.3,5.4,1.9c0,0,0,0,0,0c0,0,0,0,0,0c1.6,0.5,3.1,1,4.6,1.5c0.5,0.1,0.9,0.3,1.4,0.4c1.1,0.3,2.2,0.7,3.2,1
                    c0.5,0.1,0.9,0.3,1.4,0.4c1.3,0.4,2.6,0.8,3.9,1.2c0.1,0,0.3,0.1,0.4,0.1c1.4,0.4,2.7,0.8,4,1.2c0.5,0.1,0.9,0.2,1.4,0.4
                    c0.9,0.3,1.8,0.5,2.8,0.7c0.5,0.1,0.9,0.2,1.4,0.4c1.1,0.3,2.2,0.6,3.3,0.8c0.2,0.1,0.4,0.1,0.6,0.2c0,0,0,0,0,0l58.3-117.2h2.2
                    l-22,130.6c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0.1,0.3,0.2,0.4,0.3c0.8,0.5,1.6,1,2.4,1.5c0.3,0.2,0.7,0.5,1,0.7
                    c0.6,0.4,1.2,0.8,1.7,1.2c0.4,0.3,0.7,0.5,1.1,0.8c0.5,0.4,1.1,0.8,1.6,1.2c0.4,0.3,0.7,0.6,1.1,0.9c0.5,0.4,1,0.8,1.6,1.3
                    c0.4,0.3,0.7,0.6,1.1,0.9c0.5,0.5,1,0.9,1.6,1.4c0.3,0.3,0.7,0.6,1,0.9c0.6,0.5,1.1,1.1,1.7,1.6c0.3,0.3,0.5,0.5,0.8,0.8
                    c0.8,0.8,1.6,1.6,2.4,2.5l3.5,3.8l3.5-3.8c0,0,0,0,0,0c0.8-0.8,1.6-1.6,2.4-2.4c0.3-0.3,0.6-0.6,0.9-0.9c0.5-0.5,1-1,1.6-1.5
                    c0.4-0.3,0.8-0.7,1.1-1c0.5-0.4,0.9-0.8,1.4-1.2c0.4-0.4,0.9-0.7,1.3-1.1c0.4-0.3,0.8-0.7,1.3-1c0.5-0.4,1-0.8,1.4-1.1
                    c0.4-0.3,0.8-0.6,1.2-0.9c0.5-0.4,1-0.8,1.6-1.1c0.4-0.2,0.7-0.5,1.1-0.7c0.6-0.4,1.1-0.8,1.7-1.2c0.3-0.2,0.6-0.4,0.9-0.5
                    c0.7-0.4,1.3-0.8,2-1.2l-22-130.5h2.2L211.6,249c6-1.4,12-2.9,18-4.7c0,0,0,0,0,0c0.5-0.2,1-0.3,1.5-0.5c1.2-0.4,2.3-0.7,3.5-1.1
                    c0.5-0.2,1.1-0.4,1.6-0.5c-0.2,0.1-0.5,0.2-0.7,0.3l0,0c1.5-0.5,3-1.1,4.5-1.6c0.4-0.2,0.9-0.3,1.3-0.5c1.3-0.5,2.6-1,3.9-1.5
                    l-89.5-106.7h2.3l109.6,93.1c1.6-1.6,2.9-3.3,4-5.1c0.1-0.1,0.1-0.2,0.2-0.3c0.4-0.6,0.7-1.1,1-1.7c0,0,0,0,0,0
                    c0.6-1.1,1.2-2.3,1.7-3.4c0.5-1.1,1-2.2,1.5-3.3c0,0,0,0,0,0c0.3-0.7,0.6-1.5,0.9-2.2l-116.8-76.9h2.3L281.2,195.8z"
                  />
                  <path
                    class="st2"
                    d="M235.2,242.5L235.2,242.5c-0.2,0.1-0.5,0.1-0.7,0.2c-0.1,0-0.3,0.1-0.4,0.1
                    C234.5,242.7,234.8,242.6,235.2,242.5z"
                  />
                  <path
                    class="st2"
                    d="M229.6,244.3C229.6,244.3,229.6,244.3,229.6,244.3c0.1,0,0.1,0,0.2-0.1C229.7,244.2,229.6,244.3,229.6,244.3z
                    "
                  />
                </g>
                <path
                  class="st3"
                  d="M280.2,151c-1.7-3.6-3.6-7-5.5-10.7c-1.8-3.3-3.6-6.7-5.3-10.2c-11.2-22.6-9.2-44.5,5.7-63.3l4.8-6.1l-4.9-6
                  c-7.1-8.7-12.9-16.1-18.3-23.4l-4-5.4l-6.4,1.8c-11.3,3.2-22.3,4.8-32.7,4.8c-20.9,0-40.4-6.5-58-19.3L149.9,9l-5.7,4.2
                  C126.6,26.1,107,32.6,85.9,32.6c-10.3,0-21.3-1.6-32.5-4.7l-6.3-1.8l-4,5.2c-6.7,8.8-12.4,16.2-18.4,23.4l-5,6.1l4.9,6.2
                  c14.7,18.3,16.8,39.8,6.3,62.2c-2.1,4.3-4.3,8.6-6.5,12.8c-1.9,3.6-3.7,7-5.5,10.5c-4.8,9.9-7,19.3-6.5,28.7
                  c1.6,41.2,18.8,55.2,46,64.4c14.1,4.8,25.9,8,37.2,10.3c18.6,3.8,34.5,12.5,47.1,26l7.1,7.5l7.1-7.5c12.5-13.4,28.4-22.2,47.3-26.2
                  c16.5-3.5,34-7.9,50-15.6c11.5-5.5,18.5-11.6,22.9-19.8C290.1,195.9,291.1,173.2,280.2,151z
                  M18.7,195.8 M22.3,209.4 M162.5,132.1
                  h-2.3L277,209c-1.2,3-2.6,5.9-4.2,9c-1.4,2.6-3.1,5-5.3,7.2l-109.6-93.1h-2.3l89.5,106.7c-1.3,0.5-2.6,1.1-3.9,1.5
                  c-0.4,0.2-0.9,0.3-1.3,0.5c-1.5,0.6-3,1.1-4.5,1.6c0.2-0.1,0.5-0.2,0.7-0.3c-0.5,0.2-1.1,0.4-1.6,0.5c0.2-0.1,0.5-0.1,0.7-0.2
                  c-1.4,0.5-2.8,0.9-4.2,1.3c-0.5,0.2-1,0.3-1.5,0.5c-6,1.8-12,3.3-18,4.7l-58.2-116.9h-2.2l22,130.5c0,0,0,0,0,0c0,0,0,0,0,0
                  c-7.2,4.3-13.8,9.7-19.7,15.9l-3.5,3.8l-3.5-3.8c-5.9-6.2-12.4-11.5-19.5-15.8c0,0,0,0,0,0l22-130.6h-2.2L88.3,249.2
                  c-8.6-2.1-17.8-4.8-28.3-8.3c-6.2-2.1-5.3-1.9-5.3-1.9l89.7-106.9h-2.3L32.3,225.3c-4.1-4.2-7.5-9.4-9.9-15.9l117.5-77.3h-2.3
                  L18.7,195.8c-0.8-4.5-1.3-9.4-1.5-14.9c-0.4-8.6,1.6-17.2,6-26.4c1.7-3.5,3.5-6.8,5.4-10.4c2.3-4.2,4.6-8.6,6.6-13
                  c11.4-24.1,9-47.4-6.8-67.3L26,60.9l2.5-3c6-7.3,11.8-14.7,18.5-23.5l2-2.6l3.2,0.9c11.6,3.2,23,4.9,33.8,4.9
                  c22.1,0,42.7-6.8,61.1-20.4l2.9-2.1l2.9,2.1c18.4,13.4,38.9,20.2,60.8,20.2c10.9,0,22.3-1.7,34-5l3.2-0.9l2,2.7
                  c5.5,7.4,11.3,14.9,18.5,23.6l2.5,3l-2.4,3.1c-16.1,20.3-18.3,44-6.3,68.4c1.7,3.5,3.6,7,5.4,10.3c1.9,3.6,3.8,7,5.5,10.5
                  c6.8,13.9,8.6,28,5.4,42.7L162.5,132.1z"
                />
                <g>
                  <path
                    class="st4"
                    d="M160.2,132.1h-2L267.7,225c2.1-2.2,3.8-4.5,5.1-7c1.6-3,3-6,4.2-9L160.2,132.1z"
                  />
                  <path
                    class="st4"
                    d="M153.4,132.1L211.7,249c5.9-1.4,11.9-2.9,17.9-4.7c0.1,0,0.1,0,0.2-0.1c1.5-0.4,2.9-0.9,4.3-1.4
                    c0.4-0.1,0.8-0.2,1.1-0.4c-0.2,0.1-0.5,0.1-0.7,0.2c0.5-0.2,1.1-0.4,1.6-0.5c-0.2,0.1-0.5,0.2-0.7,0.3c1.5-0.5,3-1.1,4.5-1.6
                    c0.4-0.2,0.9-0.3,1.3-0.5c1.3-0.5,2.6-1,3.9-1.5l-89.5-106.7H153.4z"
                  />
                  <path
                    class="st4"
                    d="M173.1,262.6l-22-130.5h-2.3l-22,130.6c7.1,4.3,13.6,9.6,19.5,15.8l3.5,3.8l3.5-3.8
                    C159.3,272.3,165.9,266.9,173.1,262.6C173.1,262.6,173.1,262.6,173.1,262.6C173.1,262.6,173.1,262.6,173.1,262.6z"
                  />
                  <path
                    class="st4"
                    d="M144.5,132.1L54.8,239c1.7,0.7,3.5,1.3,5.3,1.9c10.4,3.5,19.6,6.2,28.2,8.3l58.3-117.1H144.5z"
                  />
                  <path
                    class="st4"
                    d="M140.1,132.1L22.6,209.4c2.4,6.4,5.7,11.5,9.8,15.7l109.6-93.1H140.1z"
                  />
                </g>
                <path
                  class="st5"
                  d="M234.9,167.7c-0.9-10.8-3.2-31.9-15.2-32.9c-1.4-0.1-2.7-0.2-4-0.3v-23.5c0-0.6-0.1-1.2-0.3-1.7
                  c3.3-0.1,5.9-2.7,5.9-5.9V85.2c0-3.3-2.8-5.9-6.2-5.9H212c-2.9,0-5.4,1.9-6.1,4.5h-1.5c-0.4-0.6-0.8-1.1-1.4-1.5l0-32.7
                  c0-0.7,1.5-2.4,2.1-3.1c0.3-0.4,0.6-0.8,0.9-1.1c2.1-2.8,2.1-13.1,0.9-15.7c-0.6-1.2-1.7-1.9-2.9-1.9c-0.5,0-1.8,0.2-2.8,1.6
                  l-7.2,11.3c-1.2,1.8-2.7,4.6-2.7,7.8v10.4c-4.7-2.3-15.8-4.5-41.4-4.5c-25.6,0-36.6,2.2-41.4,4.5V48.5c0-2.5-0.9-5.1-2.6-7.8
                  l-7.3-11.3c-0.9-1.4-2.2-1.6-2.7-1.6c-1.2,0-2.2,0.7-2.8,1.8c-1.2,2.3-1.2,12.8,0.8,15.7c0.2,0.3,0.5,0.7,0.8,1.1
                  c0.5,0.7,2,2.6,2.2,3.4v32.5c-0.5,0.5-1,1-1.4,1.5H94c-0.6-2.6-3.1-4.5-6.1-4.5h-3.2c-3.4,0-6.2,2.6-6.2,5.9v18.1
                  c0,3.2,2.6,5.7,5.9,5.9c-0.2,0.5-0.3,1.1-0.3,1.7v23.5c-1.3,0.1-2.6,0.2-4,0.3c-11.9,1-14.3,22.1-15.2,32.9h-3.3v29.2
                  c0,3.1,2.6,6.7,6,6.7h5.2c1.3,3.5,3.4,7.3,6.5,8.6l0.5,0.2h21.1c3.5,0,6.3-3,8.5-8.9h80.6c2.2,5.9,5.1,8.9,8.5,8.9H220l0.5-0.2
                  c3.1-1.3,5.2-5.1,6.5-8.6h5.2c3.4,0,6-3.6,6-6.7v-29.2H234.9z"
                />
                <g>
                  <path
                    class="st6"
                    d="M151.6,82.5v18.7l32.3,4.2c1,0.1,1.9,0.4,2.8,0.7l-0.6-21.9L151.6,82.5z"
                  />
                  <path
                    class="st6"
                    d="M148.4,101.2V82.5l-34.5,1.8l-0.6,21.9c0.9-0.3,1.8-0.6,2.8-0.7L148.4,101.2z"
                  />
                  <path
                    class="st6"
                    d="M100.2,149.9c-6.1-0.2-14.4-0.2-20.5,0c-1.1,0-2,0.9-2.1,2.1c-0.3,2.5-0.2,3,0,5.6c0.1,1.1,0.9,2,2.1,2.1
                    c6.1,0.2,14.4,0.2,20.5,0c1.2,0,1.9-1,2.1-2c0.3-2.6,0.3-3.1,0-5.6C102.1,150.8,101.3,149.9,100.2,149.9z
                    M89.4,158.9
                    c-3.4,0-6.8-0.1-9.7-0.2c-0.7,0-1.2-0.6-1.3-1.3c-0.2-2.5-0.3-3,0-5.4c0.1-0.7,0.6-1.3,1.3-1.3c2.9-0.1,6.3-0.2,9.7-0.2V158.9z
                    M101.4,157.4c-0.1,0.7-0.6,1.3-1.3,1.3c-2.9,0.1-6.2,0.2-9.6,0.2v-8.4c3.4,0,6.8,0.1,9.6,0.2c0.7,0,1.2,0.6,1.3,1.3
                    C101.8,154.5,101.8,154.9,101.4,157.4z"
                  />
                  <path
                    class="st6"
                    d="M220.3,149.9c-6.1-0.2-14.4-0.2-20.5,0c-1.1,0-1.9,1-2.1,2c-0.3,2.6-0.3,3.1,0,5.6c0.1,1.1,0.9,2,2.1,2
                    c6.1,0.2,14.4,0.2,20.5,0c1.1,0,2-0.9,2.1-2.1c0.2-2.5,0.3-3,0-5.6C222.3,150.8,221.5,149.9,220.3,149.9z
                    M209.5,158.9
                    c-3.4,0-6.8-0.1-9.6-0.2c-0.7,0-1.2-0.6-1.3-1.3c-0.3-2.5-0.3-2.9,0-5.4c0.1-0.7,0.6-1.3,1.3-1.3c2.9-0.1,6.2-0.2,9.6-0.2V158.9z
                    M221.6,157.5c-0.1,0.7-0.6,1.3-1.3,1.3c-2.9,0.1-6.3,0.2-9.7,0.2v-8.4c3.4,0,6.8,0.1,9.7,0.2c0.7,0,1.2,0.6,1.3,1.3
                    C221.8,154.5,221.8,155,221.6,157.5z"
                  />
                  <path
                    class="st6"
                    d="M125.4,74.5c0-2.8-2.3-5.2-5.2-5.2c-2.8,0-5.2,2.3-5.2,5.2c0,2.8,2.3,5.2,5.2,5.2
                    C123.1,79.6,125.4,77.3,125.4,74.5z
                    M115.8,74.5c0-2.5,2-4.5,4.5-4.5c2.5,0,4.5,2,4.5,4.5c0,2.5-2,4.5-4.5,4.5
                    C117.8,79,115.8,77,115.8,74.5z"
                  />
                  <path
                    class="st6"
                    d="M155.2,72.7c0-2.8-2.3-5.2-5.2-5.2c-2.8,0-5.2,2.3-5.2,5.2c0,2.8,2.3,5.2,5.2,5.2
                    C152.8,77.9,155.2,75.6,155.2,72.7z
                    M145.5,72.7c0-2.5,2-4.5,4.5-4.5c2.5,0,4.5,2,4.5,4.5c0,2.5-2,4.5-4.5,4.5
                    C147.5,77.2,145.5,75.2,145.5,72.7z"
                  />
                  <path
                    class="st6"
                    d="M184.9,74.5c0-2.8-2.3-5.2-5.2-5.2c-2.8,0-5.2,2.3-5.2,5.2c0,2.8,2.3,5.2,5.2,5.2
                    C182.6,79.6,184.9,77.3,184.9,74.5z
                    M175.2,74.5c0-2.5,2-4.5,4.5-4.5c2.5,0,4.5,2,4.5,4.5c0,2.5-2,4.5-4.5,4.5
                    C177.2,79,175.2,77,175.2,74.5z"
                  />
                  <rect
                    x="152.7"
                    y="150.5"
                    class="st6"
                    width="17.8"
                    height="2.9"
                  />
                  <rect
                    x="152.7"
                    y="144.7"
                    class="st6"
                    width="17.8"
                    height="2.9"
                  />
                  <path
                    class="st6"
                    d="M170.5,138.9h-8.4c-1.2,1.3-3.9,2.4-7.4,2.9h15.7V138.9z"
                  />
                  <path
                    class="st6"
                    d="M170.5,133.1h-15.8c3.5,0.5,6.2,1.6,7.4,2.9h8.4V133.1z"
                  />
                  <rect
                    x="152.7"
                    y="121.6"
                    class="st6"
                    width="17.8"
                    height="2.9"
                  />
                  <rect
                    x="152.7"
                    y="156.3"
                    class="st6"
                    width="17.8"
                    height="2.9"
                  />
                  <rect
                    x="152.7"
                    y="115.8"
                    class="st6"
                    width="17.8"
                    height="2.9"
                  />
                  <path
                    class="st6"
                    d="M129.5,141.8h15.7c-3.5-0.5-6.2-1.6-7.4-2.9h-8.4V141.8z"
                  />
                  <rect
                    x="152.7"
                    y="127.4"
                    class="st6"
                    width="17.8"
                    height="2.9"
                  />
                  <rect
                    x="129.5"
                    y="121.6"
                    class="st6"
                    width="17.8"
                    height="2.9"
                  />
                  <rect
                    x="129.5"
                    y="144.7"
                    class="st6"
                    width="17.8"
                    height="2.9"
                  />
                  <path
                    class="st6"
                    d="M129.5,133.1v2.9h8.4c1.2-1.3,3.9-2.4,7.4-2.9H129.5z"
                  />
                  <rect
                    x="129.5"
                    y="156.3"
                    class="st6"
                    width="17.8"
                    height="2.9"
                  />
                  <polygon
                    class="st6"
                    points="152.7,110.6 152.7,112.9 170.5,112.9 170.5,111.5
                    173.7,111.5 173.7,112.9 184.5,114.3 184.5,115.8 173.7,115.8
                    173.7,118.7 184.5,118.7 184.5,121.6 173.7,121.6 173.7,124.5
                    184.5,124.5 184.5,127.4 173.7,127.4 173.7,130.2 184.5,130.2
                    184.5,133.1 173.7,133.1 173.7,136 184.5,136 184.5,138.9
                    173.7,138.9 173.7,141.8 184.5,141.8 184.5,144.7 173.7,144.7
                    173.7,147.6 184.5,147.6 184.5,150.5 173.7,150.5 173.7,153.4
                    184.5,153.4 184.5,156.3 173.7,156.3 173.7,159.1 184.5,159.1
                    184.5,162 173.7,162 173.7,163.4 170.5,163.4 170.5,162
                    152.7,162 152.7,164.4 185.3,164.4 185.3,113.4 173.2,110.6 "
                  />
                  <rect
                    x="129.5"
                    y="150.5"
                    class="st6"
                    width="17.8"
                    height="2.9"
                  />
                  <rect
                    x="129.5"
                    y="115.8"
                    class="st6"
                    width="17.8"
                    height="2.9"
                  />
                  <rect
                    x="129.5"
                    y="127.4"
                    class="st6"
                    width="17.8"
                    height="2.9"
                  />
                  <polygon
                    class="st6"
                    points="129.5,163.4 126.4,163.4 126.4,162 115.5,162
                    115.5,159.1 126.4,159.1 126.4,156.3 115.5,156.3 115.5,153.4
                    126.4,153.4 126.4,150.5 115.5,150.5 115.5,147.6 126.4,147.6
                    126.4,144.7 115.5,144.7 115.5,141.8 126.4,141.8 126.4,138.9
                    115.5,138.9 115.5,136 126.4,136 126.4,133.1 115.5,133.1
                    115.5,130.2 126.4,130.2 126.4,127.4 115.5,127.4 115.5,124.5
                    126.4,124.5 126.4,121.6 115.5,121.6 115.5,118.7 126.4,118.7
                    126.4,115.8 115.5,115.8 115.5,114.3 126.4,112.9 126.4,111.5
                    129.5,111.5 129.5,112.9 147.3,112.9 147.3,110.6 126.8,110.6
                    114.7,113.4 114.7,164.4 147.3,164.4 147.3,162 129.5,162 "
                  />
                  <path
                    class="st6"
                    d="M159.7,137.5c0-0.3-0.8-0.7-2.2-1.2c-1.9-0.7-4.6-1.1-7.5-1.1c-3,0-5.6,0.4-7.5,1.1c-1.3,0.5-2.2,0.9-2.2,1.2
                    c0,0.3,0.8,0.7,2.2,1.2c1.9,0.7,4.6,1.1,7.5,1.1c3,0,5.6-0.4,7.5-1.1C158.9,138.2,159.7,137.8,159.7,137.5z"
                  />
                </g>
                <g>
                  <path
                    class="st6"
                    d="M232.3,170.6c-0.6-7.6-2.2-32.1-12.7-32.9c-7.8-0.7-12.3-1.2-20.4-1.6v-2.4c4.6,0.2,9.1,0.6,13.7,1v-23.7
                    c0-3.8-8.8-5.1-14.6-3.9l10.4-5.3c7.1-4.1,7.1-3,0,1.6c0,1.6,1.5,3,3.3,3h3.2c1.8,0,3.3-1.3,3.3-3V85.2c0-1.6-1.5-3-3.3-3H212
                    c-1.8,0-3.3,1.3-3.3,3v1.6h-6.2c-0.2-1.3-1.1-2.3-2.3-2.9V49.6c0-2.1,2.4-4.4,3.5-6c1.6-2.1,1.4-14.8,0-12.6l-7.2,11.3
                    c-1.2,1.9-2.2,4-2.2,6.2v35.3c-0.4,0.2-0.7,0.4-1,0.6l-1.2-21c0-8.2-84.1-8.2-84.1,0l-1.2,21c-0.3-0.2-0.6-0.4-1-0.6V48.5
                    c0-2.2-1-4.4-2.1-6.2L96.3,31c-1.4-2.2-1.5,10.5,0,12.6c0.9,1.3,3.5,4.4,3.5,6v34.3c-1.2,0.6-2.1,1.5-2.3,2.9h-6.2v-1.6
                    c0-1.6-1.5-3-3.3-3h-3.2c-1.8,0-3.3,1.3-3.3,3v18.1c0,1.6,1.5,3,3.3,3H88c1.8,0,3.3-1.3,3.3-3c-7.1-4.6-7.1-5.6,0-1.6l10.5,5.3
                    c-5.8-1.3-14.6,0-14.6,3.8v23.7c4.6-0.4,9.1-0.8,13.7-1v2.4c-8.1,0.4-12.6,1-20.4,1.6c-10.5,0.9-12.1,25.3-12.7,32.9h-3.1v26.3
                    c0,1.6,1.5,3.8,3.1,3.8H75c1.2,4,3.1,7.8,5.6,8.9h20.6c2.8,0,5-4.4,6.4-8.9h84.8c1.4,4.5,3.6,8.9,6.4,8.9h20.6
                    c2.4-1,4.4-4.9,5.6-8.9h7.3c1.6,0,3.1-2.1,3.1-3.8v-26.3H232.3z
                    M82.2,209.5c-1.7-2.3-3-5.6-3.8-8.9H80c0.9,3.4,2.3,6.6,4,8.9
                    H82.2z
                    M86.6,209.5c-1.7-2.3-3-5.6-3.8-8.9h1.6c0.9,3.4,2.3,6.6,4,8.9H86.6z
                    M91,209.5c-1.7-2.3-3-5.6-3.8-8.9h1.6
                    c0.9,3.4,2.3,6.6,4,8.9H91z
                    M99.5,165.5H79.3c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h20.2c0.6,0,1,0.5,1,1
                    C100.5,165.1,100.1,165.5,99.5,165.5z
                    M103.8,158.5c-0.2,1.2-1,2.1-2.2,2.2c-7.8,0.3-15.6,0.3-23.5,0c-1.2,0-2.1-1-2.2-2.2
                    c-0.3-3.2-0.3-4.4,0-7.6c0.1-1.2,1-2.1,2.2-2.2c7.8-0.3,15.6-0.3,23.5,0c1.2,0,2,1,2.2,2.2C104.2,154.1,104.2,155.3,103.8,158.5z
                    M106.4,89.8h-9v13.3l-6.2-3.1V90c-7.1-0.5-7.1-1,0-1.5h15.2L106.4,89.8z
                    M166.9,190.3c0,1.2-1,2.3-2.3,2.3h-29.8
                    c-1.2,0-2.3-1-2.3-2.3v-4.7c0-1.2,1-2.3,2.3-2.3h29.8c1.2,0,2.3,1,2.3,2.3V190.3z
                    M192.8,136v1.7v32.9h-85.6v-33V136v-21.6
                    c0-3,1.6-5.5,3.9-7.1l0.9-23.1c0-0.9,1-1.9,1.9-1.9l4.9-0.2v-1.4c-2.8-0.7-4.8-3.2-4.8-6.1c0-3.5,2.8-6.3,6.3-6.3
                    c3.5,0,6.3,2.8,6.3,6.3c0,3-2.1,5.5-4.8,6.1v1.3l9.6-0.5c0.1-1.5,1.3-2.6,2.8-2.6c1.4,0,2.6,1,2.8,2.4l11.6-0.6v-1.8
                    c-2.8-0.7-4.8-3.2-4.8-6.1c0-3.5,2.8-6.3,6.3-6.3c3.5,0,6.3,2.8,6.3,6.3c0,3-2.1,5.5-4.8,6.1v1.8l11.6,0.6
                    c0.2-1.3,1.4-2.4,2.8-2.4c1.5,0,2.7,1.2,2.8,2.6l9.6,0.5v-1.3c-2.8-0.7-4.8-3.2-4.8-6.1c0-3.5,2.8-6.3,6.3-6.3
                    c3.5,0,6.3,2.8,6.3,6.3c0,3-2.1,5.5-4.8,6.1v1.4l4.9,0.2c0.9,0,1.9,1,1.9,1.9l0.9,23.1c2.3,1.6,3.8,4,3.8,7V136z
                    M193.5,88.5h15.2
                    c7.1,0.5,7.1,1,0,1.5v10l-6.2,3.1V89.8h-9L193.5,88.5z
                    M207.2,209.5c1.7-2.2,3.2-5.5,4-8.9h1.6c-0.8,3.3-2.2,6.5-3.8,8.9H207.2z
                    M211.6,209.5c1.7-2.2,3.2-5.5,4-8.9h1.6c-0.8,3.3-2.2,6.5-3.8,8.9H211.6z
                    M217.8,209.5H216c1.7-2.2,3.2-5.5,4-8.9h1.6
                    C220.8,203.9,219.5,207.2,217.8,209.5z
                    M220.7,165.5h-20.2c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1h20.2c0.6,0,1,0.5,1,1
                    C221.7,165.1,221.2,165.5,220.7,165.5z
                    M224,158.5c-0.1,1.2-1,2.1-2.2,2.2c-7.8,0.3-15.6,0.3-23.5,0c-1.2,0-2-1-2.2-2.2
                    c-0.4-3.2-0.4-4.4,0-7.6c0.2-1.2,1-2.1,2.2-2.2c7.8-0.3,15.6-0.3,23.5,0c1.2,0,2,1,2.2,2.2C224.3,154.1,224.2,155.3,224,158.5z"
                  />
                </g>
              </g>
            </svg>
          {:else}
            <img
              src={imageUrl ||
                'https://d2r0eic16r3uxv.cloudfront.net/1601471704432269.png'}
              alt="Avatar"
              class="MobilePostHeader-avatar"
            />
          {/if}
        {/if}
      </div>
    {/if}
    <div style="width: 100%; display: flex;">
      <div>
        {#if _.get(post, 'videoInformation.youtubeId')}
          <div class="MobilePostHeader-username">
            {_.get(post, 'videoInformation.caption')}
          </div>
        {/if}
        {#if post.postType !== 'ARTICLE' && !companyNameOverride}
          <div class="MobilePostHeader-username" style={_.get(post, 'videoInformation.youtubeId') ? 'font-weight: normal; font-size: 12px; letter-spacing: 0.3px; color: inherit' : ''}>
            {#if _.get(post, 'pollInformation.companyInfo.name')}
              <span
                on:click={() => {
                  dispatch('header-user');
                }}
                >{post.pollInformation.companyInfo.name}
              </span>
            {:else}
              <span
                on:click={() => {
                  dispatch('header-user');
                }}
              >
                {post.user.firstName}
                {post.user.lastName}
              </span>
            {/if}
            {#if verifiedStatus === 'verified'}
              <span class="MobilePostHeader-verifiedBadge">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 8C16 6.84375 15.25 5.84375 14.1875 5.4375C14.6562 4.4375 14.4688 3.1875 13.6562 2.34375C12.8125 1.53125 11.5625 1.34375 10.5625 1.8125C10.1562 0.75 9.15625 0 8 0C6.8125 0 5.8125 0.75 5.40625 1.8125C4.40625 1.34375 3.15625 1.53125 2.34375 2.34375C1.5 3.1875 1.3125 4.4375 1.78125 5.4375C0.71875 5.84375 0 6.84375 0 8C0 9.1875 0.71875 10.1875 1.78125 10.5938C1.3125 11.5938 1.5 12.8438 2.34375 13.6562C3.15625 14.5 4.40625 14.6875 5.40625 14.2188C5.8125 15.2812 6.8125 16 8 16C9.15625 16 10.1562 15.2812 10.5625 14.2188C11.5938 14.6875 12.8125 14.5 13.6562 13.6562C14.4688 12.8438 14.6562 11.5938 14.1875 10.5938C15.25 10.1875 16 9.1875 16 8ZM11.4688 6.625L7.375 10.6875C7.21875 10.8438 7 10.8125 6.875 10.6875L4.5 8.3125C4.375 8.1875 4.375 7.96875 4.5 7.8125L5.3125 7C5.46875 6.875 5.6875 6.875 5.8125 7.03125L7.125 8.34375L10.1562 5.34375C10.3125 5.1875 10.5312 5.1875 10.6562 5.34375L11.4688 6.15625C11.5938 6.28125 11.5938 6.5 11.4688 6.625Z"
                    fill="#2E5FF6"
                  />
                </svg>
              </span>
            {/if}
            {#if post.postType == 'REVIEW'}
              <span style="font-weight: normal;">left a review</span>
            {/if}
            {#if post.postType !== 'REVIEW' && post.place}
              <span class="is-grey">{post.place.action}</span>
              <span
                on:click={e => {
                  dispatch('header-place', { place: post.place });
                  try {
                    e.preventDefault();
                    e.stopPropagation();
                  } catch (e) {}
                }}
              >
                {post.place.title}
              </span>
            {/if}
          </div>
        {/if}

        {#if post.postType === 'AD' && companyNameOverride}
          <span
            class="MobilePostHeader-username"
            on:click={() => {
              dispatch('header-user');
            }}
          >
            {companyNameOverride}
          </span>
        {/if}

        {#if post.postType === 'JOB' && companyNameOverride}
          <span
            class="MobilePostHeader-username"
            on:click={() => {
              dispatch('header-user');
            }}
          >
            {companyNameOverride}
          </span>
        {/if}

        {#if post.postType === 'ARTICLE'}
          <span
            class="MobilePostHeader-username"
            on:click={() => {
              dispatch('header-user');
            }}
          >
            {sourceTitle}
          </span>
        {/if}
        <p class="MobilePostUserTags-date">
          {#if formatUserTags}<span>{formatUserTags} &bull;</span>{/if}
          {postDateLabel}
          <!-- {#if post.postType === 'VIDEO' && post.videoInformation.youtubeId}
            <span>
              - via
            </span>
            <span>
              <svg height="1.25em" width="1.25em" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 461.001 461.001" xml:space="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path style="fill:#F61C0D;" d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728 c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137 C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607 c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"></path> </g> </g></svg>
            </span>
            <span> Youtube</span>
          {/if} -->
        </p>
      </div>
      {#if options.showEllipses && !(post && post.states && post.states.isArchived)}
        <div
          class="MobilePostEdit-dots"
          on:click={() => {
            dispatch('header-ellipses');
          }}
        >
          <svg
            width="18"
            height="4"
            viewBox="0 0 18 4"
            fill="#989AA2"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 3C9.55228 3 10 2.55229 10 2C10 1.44772 9.55228 1 9 1C8.44772 1
            8 1.44772 8 2C8 2.55229 8.44772 3 9 3Z"
              fill="#989AA2"
            />
            <path
              d="M16 3C16.5523 3 17 2.55229 17 2C17 1.44772 16.5523 1 16 1C15.4477
            1 15 1.44772 15 2C15 2.55229 15.4477 3 16 3Z"
              fill="#989AA2"
            />
            <path
              d="M2 3C2.55228 3 3 2.55229 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1
            1.44772 1 2C1 2.55229 1.44772 3 2 3Z"
              fill="#989AA2"
            />
            <path
              d="M9 3C9.55228 3 10 2.55229 10 2C10 1.44772 9.55228 1 9 1C8.44772 1
            8 1.44772 8 2C8 2.55229 8.44772 3 9 3Z"
              stroke="#989AA2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16 3C16.5523 3 17 2.55229 17 2C17 1.44772 16.5523 1 16 1C15.4477
            1 15 1.44772 15 2C15 2.55229 15.4477 3 16 3Z"
              stroke="#989AA2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2 3C2.55228 3 3 2.55229 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1
            1.44772 1 2C1 2.55229 1.44772 3 2 3Z"
              stroke="#989AA2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      {/if}
    </div>
  {/if}
</div>

<style src="./MobilePostHeader.scss">
</style>
