<svelte:options tag="cdlc-signup-form" />

<script>
  import '../LeadForm/LeadForm.svelte';
  import _ from 'lodash';
  import debug from 'debug';
  import presets from './SignUpForm.presets';
  import presetMappers from './SignUpForm.presetMappers';
  import { createEventDispatcher } from 'svelte';
  import { get_current_component } from 'svelte/internal';
  import backend from './SignUpForm.backend';

  const log = debug('cdlc:SignUpForm'),
    svelteDispatch = createEventDispatcher(),
    component = get_current_component(),
    dispatch = (name, detail) => {
      svelteDispatch(name, detail);
      component.dispatchEvent &&
        component.dispatchEvent(new CustomEvent(name, { detail }));
    };

  export let preset = 'diesel';

  let request = async function(data) {
    console.warn('STARTING REQUEST');
    console.log('BODY', data);
    return await backend.fetch('/submit', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  };
</script>

<cdlc-lead-form
  has_terms={false}
  sections={presets[preset] || []}
  {request}
  on:presubmit={d => {
    const fn = presetMappers[preset];
    log('presubmit', d, fn);
    if (fn) {
      fn(d.detail);
    }
  }}
  on:submit={d => {
    log('submitted', d && d.detail);
    dispatch('submitted', d && d.detail);
  }}
  on:progress={d => {
    log('progress', d && d.detail);
    dispatch('progress', d && d.detail);
  }}
/>

<style src="./SignUpForm.scss">
</style>
