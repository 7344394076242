<script>
  import debug from 'debug';
  import _ from 'lodash';
  import '../MobilePostText/MobilePostText.svelte';

  const log = debug('cdlc:MobilePostDeleted');

  export let dispatch = () => {};
  export let mode = 'embed';
  export let post;
</script>

<style src="./MobilePostDeleted.scss">
</style>

<svelte:options tag="cdlc-mobile-post-deleted" />
{#if post}
  <div class="MobilePostDeleted">
    <svg
      width="46"
      height="41"
      viewBox="0 0 46 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.875 17.75C21.7812 17.75 21 18.6094 21 19.625C21 20.7188 21.7812 21.5 22.875 21.5C23.8906 21.5 24.75 20.7188 24.75 19.625C24.75 18.6094 23.8906 17.75 22.875 17.75ZM9.75 15.25C8.65625 15.25 7.875 16.1094 7.875 17.125C7.875 18.2188 8.65625 19 9.75 19C10.7656 19 11.625 18.2188 11.625 17.125C11.625 16.1094 10.7656 15.25 9.75 15.25ZM36 15.25C34.9062 15.25 34.125 16.1094 34.125 17.125C34.125 18.2188 34.9062 19 36 19C37.0156 19 37.875 18.2188 37.875 17.125C37.875 16.1094 37.0156 15.25 36 15.25ZM45.375 17.125C45.375 14 40.9219 11.1875 34.2812 9.70312C32.9531 4.625 28.2656 0.875 22.875 0.875C17.4062 0.875 12.7188 4.625 11.3906 9.70312C4.75 11.1875 0.375 14 0.375 17.125C0.375 21.9688 10.375 25.875 22.875 25.875C35.2969 25.875 45.375 21.9688 45.375 17.125ZM22.875 3.375C27.6406 3.375 31.8594 7.28125 32.1719 12.0469C29.4375 12.9062 26.2344 13.375 22.875 13.375C19.4375 13.375 16.2344 12.9062 13.5 12.0469C13.8125 7.28125 18.0312 3.375 22.7969 3.375H22.875ZM22.875 23.375C9.90625 23.375 2.875 19.3125 2.875 17.125C2.875 15.7969 5.6875 13.6875 11 12.2812C11.0781 13.2969 11.7812 14.1562 12.7188 14.4688C15.9219 15.4062 19.2812 15.875 22.875 15.875C26.3906 15.875 29.75 15.4062 32.9531 14.4688C33.8906 14.1562 34.5938 13.2969 34.6719 12.2812C39.9844 13.6875 42.875 15.7969 42.875 17.125C42.875 19.3125 35.7656 23.375 22.875 23.375ZM38.5 26.1094C37.7188 26.3438 36.9375 26.5781 36.0781 26.8125L43.0312 40.5625C43.1094 40.7969 43.3438 40.875 43.5781 40.875C43.6562 40.875 43.8125 40.875 43.8906 40.875L44.9844 40.25C45.2188 40.1719 45.2969 39.9375 45.2969 39.7031C45.2969 39.625 45.2969 39.5469 45.2969 39.4688L38.5 26.1094ZM0.375 39.4688C0.375 39.5469 0.375 39.625 0.375 39.7031C0.375 39.9375 0.453125 40.1719 0.6875 40.25L1.78125 40.875C1.85938 40.875 2.01562 40.875 2.09375 40.875C2.32812 40.875 2.5625 40.7969 2.64062 40.5625L9.59375 26.8125C8.73438 26.5781 7.95312 26.3438 7.17188 26.1094L0.375 39.4688Z"
        fill="url(#paint0_linear)" />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="22.875"
          y1="0.875"
          x2="22.875"
          y2="40.875"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#2E9DF6" />
          <stop offset="1" stop-color="#DC39C8" />
        </linearGradient>
      </defs>
    </svg>

    <div class="MobilePostDeleted-title">There’s Nothing Here</div>
    <p class="MobilePostDeleted-text">This post has been removed.</p>
  </div>
{/if}
