<svelte:options tag="cdlc-lead-matching" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import { createEventDispatcher, onMount } from 'svelte';
  import { get_current_component } from 'svelte/internal';
  import { defaultQuestions } from './defaultQuestions';
  import { t } from '../../tools/translate';

  const log = debug('cdlc:LeadMatching'),
    svelteDispatch = createEventDispatcher(),
    component = get_current_component(),
    dispatch = (name, detail) => {
      svelteDispatch(name, detail);
      component.dispatchEvent &&
        component.dispatchEvent(new CustomEvent(name, { detail }));
    };

  export let data = {
    hiringAreas: [],
    hiringStates: [],
    hiringZips: [],
  };
  export let opts = {
    // see mergedOpts below for defaults
  };
  export let saving = false;
  export let area_title = 'operating';

  // Initials/Internals --------------

  let ready = false,
    hiringMap = {},
    readOnly = false,
    hasUnsavedChanges = false,
    lastSave,
    showHint,
    modifiedResult = {};

  // Computed values --------------------

  $: organizedQuestions = organizeQuestions(
    defaultQuestions(mergedOpts),
    mergedOpts
  );

  $: open = false;

  $: readOnly = _.get(opts, 'readOnly');

  $: mergedOpts = _.merge(
    {
      saveImmediately: false,
      mainHeader: true,
      readOnly: false,
      descriptionParagraph: false,
      clientPortalHints: false,
      activeQuestions: {
        benefitsQuestion: false,
        driverTypes: true,
        driverAndJobType: false,
        trailerTypes: true,
        experience: true,
        cdlRequirement: false,
        endorsementTypes: true,
        violations: true,
        accidents: true,
        cdlClassA: true,
        militaryExperience: false,
        hiringAreaMapSection: true,
        jobTypes: false,
        showQuestionNotes: true,
      },
    },
    opts
  );

  // Computed Logic --------------

  $: log('modifiedResult', modifiedResult);

  onMount(() => {
    setTimeout(() => {
      modifiedResult = data || {};
      ready = true;
      lastSave = _.cloneDeep(modifiedResult);
    });
  });

  // Checking if unsaved changes
  $: {
    let wasUnsaved = hasUnsavedChanges;
    hasUnsavedChanges =
      JSON.stringify(lastSave) !== JSON.stringify(modifiedResult);

    if (!wasUnsaved && hasUnsavedChanges) {
      dispatch('unsaved-changes');
      if (mergedOpts.saveImmediately) {
        hasUnsavedChanges = false;
        saveHiringCriteria();
      }
    }
  }

  function saveHiringCriteria() {
    lastSave = _.cloneDeep(modifiedResult);
    dispatch('hiring-criteria-updated', modifiedResult);
  }

  function organizeQuestions(questions, opts) {
    const result = [];
    _.each(questions, q => {
      result.push({
        id: _.uniqueId('cdlc-' + q.key),
        title: q.title,
        note: q.note,
        showError: q.showError,
        errorMsg: q.errorMsg,
        key: q.key,
        clientPortalHint: q.clientPortalHint,
        multi: !!q.multi,
        category: q.category,
        config: _.get(q, 'config', {}),
        store: q.store || (q.multi ? 'array' : 'value'),
        options: _.map(q.options, o => {
          return typeof o === 'object'
            ? o
            : {
                title: o,
                value: o,
              };
        }),
      });
    });
    return result;
  }

  function isActiveOption(result, question, option) {
    if (question.store === 'array') {
      return _.includes(_.get(result, question.key), option.value);
    } else {
      if (question.multi) {
        return _.get(result, `${question.key}.${option.value}`); // true/value
      } else {
        return _.get(result, question.key) === option.value;
      }
    }
  }

  function toggleActivity(result, question, option) {
    handleToggle(result, question, option);

    if (_.get(question, 'config.selectAllAfter')) {
      const index = _.findIndex(question.options, { value: option.value }),
        minIndex = _.min(
          // first get all active
          _.filter(question.options, o => isActiveOption(result, question, o))
            // then create an array of the indices
            .map(o => {
              return _.findIndex(question.options, { value: o.value });
            })
        );
      if (index === minIndex) {
        // all afterward
        _.each(question.options.slice(minIndex), option => {
          if (!isActiveOption(result, question, option)) {
            handleToggle(result, question, option);
          }
        });
      }
    }

    modifiedResult = result;

    function handleToggle(result, question, option) {
      if (question.store === 'array') {
        if (isActiveOption(result, question, option)) {
          _.pull(_.get(result, question.key), option.value);
        } else {
          result[question.key] = result[question.key] || [];
          result[question.key].push(option.value);
        }
      } else {
        if (question.multi) {
          _.set(
            result,
            `${question.key}.${option.value}`,
            !_.get(result, `${question.key}.${option.value}`)
          ); // true/value
        } else {
          _.set(result, question.key, option.value);
        }
      }
    }
  }
</script>

<div class="LeadMatching">
  {#if ready}
    {#if mergedOpts.activeQuestions.hiringAreaMapSection}
      <div class="LeadMatching-section">
        <div class="LeadMatching-sectionHeader">
          {#if mergedOpts.mainHeader}
            <h2>Hiring Area</h2>
          {/if}
          {#if mergedOpts.showError && mergedOpts.errorMsg}
            <div class="LeadMatching-validationError">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="AccordionWarning"
                x="0px"
                y="0px"
                width="24"
                height="24"
                viewBox="0 0 172 172"
                style=" fill:#000000;"
                ><g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="1"
                  stroke-linecap="butt"
                  stroke-linejoin="miter"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                  ><path d="M0,172v-172h172v172z" fill="none" /><g
                    fill="#ea4335"
                    ><path
                      d="M157.66667,86c0,39.517 -32.14967,71.66667 -71.66667,71.66667c-39.517,0 -71.66667,-32.14967 -71.66667,-71.66667c0,-39.517 32.14967,-71.66667 71.66667,-71.66667c39.517,0 71.66667,32.14967 71.66667,71.66667zM86,107.5c-3.95958,0 -7.16667,3.20708 -7.16667,7.16667c0,3.95958 3.20708,7.16667 7.16667,7.16667c3.95958,0 7.16667,-3.20708 7.16667,-7.16667c0,-3.95958 -3.20708,-7.16667 -7.16667,-7.16667zM91.375,91.375v-35.83333c0,-2.967 -2.40442,-5.375 -5.375,-5.375c-2.97058,0 -5.375,2.408 -5.375,5.375v35.83333c0,2.967 2.40442,5.375 5.375,5.375c2.97058,0 5.375,-2.408 5.375,-5.375z"
                    /></g
                  ></g
                ></svg
              >
              <p>{mergedOpts.errorMsg}</p>
            </div>
          {/if}
          {#if mergedOpts.descriptionParagraph}
            <p class="LeadMatching-descriptionParagraph">
              The hiring area will define what driver locations you wish to
              receive applications from. Drivers will search for trucking jobs
              by location and the hiring area determines which jobs match their
              search criteria.
            </p>
          {/if}
        </div>
        <cdlc-hiring-map
          {area_title}
          on:map-updated={updatedMap => {
            _.set(
              modifiedResult,
              'hiringStates',
              _.sortBy(_.get(updatedMap, 'detail.hiringStates'))
            );
            _.set(
              modifiedResult,
              'hiringAreas',
              _.sortBy(_.get(updatedMap, 'detail.hiringAreas'))
            );
            _.set(
              modifiedResult,
              'freeform',
              _.get(updatedMap, 'detail.freeform')
            );

            modifiedResult = modifiedResult;
          }}
          map_data={{
            hiringStates: _.get(data, 'hiringStates', []),
            hiringAreas: _.get(data, 'hiringAreas', []),
            freeform: _.get(data, 'freeform', {}),
          }}
          {opts}
        />
      </div>
    {/if}
    {#if !readOnly && hasUnsavedChanges}
      <button
        on:click={saveHiringCriteria}
        disabled={saving}
        class="LeadMatching-saveButton LeadMatching-saveButton--top"
      >
        {#if saving}
          <div class="loader" />
        {:else}Update{/if}
      </button>
    {/if}

    {#each organizedQuestions as question (question.key)}
      {#if mergedOpts.activeQuestions[question.key] && !question.category}
        <div class="LeadMatching-section">
          <div class="LeadMatching-sectionHeader">
            <h4>
              {t(question.title)}
              {#if question.multi}
                <span
                  style=" font-size: 0.75em; line-height: 1em; vertical-align:
                  middle; display: inline-block; opacity: 0.7; transform:
                  translateY(-1px); "
                >
                  ({t('Select All Applicable')})
                </span>
              {/if}

              {#if question.showError && question.errorMsg}
                <div class="LeadMatching-validationError">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="AccordionWarning"
                    x="0px"
                    y="0px"
                    width="24"
                    height="24"
                    viewBox="0 0 172 172"
                    style=" fill:#000000;"
                    ><g
                      fill="none"
                      fill-rule="nonzero"
                      stroke="none"
                      stroke-width="1"
                      stroke-linecap="butt"
                      stroke-linejoin="miter"
                      stroke-miterlimit="10"
                      stroke-dasharray=""
                      stroke-dashoffset="0"
                      font-family="none"
                      font-weight="none"
                      font-size="none"
                      text-anchor="none"
                      style="mix-blend-mode: normal"
                      ><path d="M0,172v-172h172v172z" fill="none" /><g
                        fill="#ea4335"
                        ><path
                          d="M157.66667,86c0,39.517 -32.14967,71.66667 -71.66667,71.66667c-39.517,0 -71.66667,-32.14967 -71.66667,-71.66667c0,-39.517 32.14967,-71.66667 71.66667,-71.66667c39.517,0 71.66667,32.14967 71.66667,71.66667zM86,107.5c-3.95958,0 -7.16667,3.20708 -7.16667,7.16667c0,3.95958 3.20708,7.16667 7.16667,7.16667c3.95958,0 7.16667,-3.20708 7.16667,-7.16667c0,-3.95958 -3.20708,-7.16667 -7.16667,-7.16667zM91.375,91.375v-35.83333c0,-2.967 -2.40442,-5.375 -5.375,-5.375c-2.97058,0 -5.375,2.408 -5.375,5.375v35.83333c0,2.967 2.40442,5.375 5.375,5.375c2.97058,0 5.375,-2.408 5.375,-5.375z"
                        /></g
                      ></g
                    ></svg
                  >
                  <p>{question.errorMsg}</p>
                </div>
              {/if}

              {#if mergedOpts.clientPortalHints}
                <div style="display: inline-flex;">
                  <div
                    on:mouseenter={e => {
                      showHint = question.key;
                    }}
                    on:mouseleave={() => {
                      showHint = '';
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      width="16"
                      height="16"
                      viewBox="0 0 172 172"
                      style=" fill:#000000;"
                      ><g
                        fill="none"
                        fill-rule="nonzero"
                        stroke="none"
                        stroke-width="1"
                        stroke-linecap="butt"
                        stroke-linejoin="miter"
                        stroke-miterlimit="10"
                        stroke-dasharray=""
                        stroke-dashoffset="0"
                        font-family="none"
                        font-weight="none"
                        font-size="none"
                        text-anchor="none"
                        style="mix-blend-mode: normal"
                        ><path d="M0,172v-172h172v172z" fill="none" /><g
                          fill="#73767f"
                          ><path
                            d="M86,6.88c-43.65603,0 -79.12,35.46397 -79.12,79.12c0,43.65603 35.46397,79.12 79.12,79.12c43.65603,0 79.12,-35.46397 79.12,-79.12c0,-43.65603 -35.46397,-79.12 -79.12,-79.12zM86,13.76c39.93779,0 72.24,32.30221 72.24,72.24c0,39.93779 -32.30221,72.24 -72.24,72.24c-39.93779,0 -72.24,-32.30221 -72.24,-72.24c0,-39.93779 32.30221,-72.24 72.24,-72.24zM86,37.84c-5.69958,0 -10.32,4.62042 -10.32,10.32c0,5.69958 4.62042,10.32 10.32,10.32c5.69958,0 10.32,-4.62042 10.32,-10.32c0,-5.69958 -4.62042,-10.32 -10.32,-10.32zM72.24,72.24v6.88h3.44h3.44v44.72h-3.44h-3.44v6.88h3.44h3.44h13.76h3.44h3.44v-6.88h-3.44h-3.44v-51.6h-3.44h-13.76z"
                          /></g
                        ></g
                      ></svg
                    >
                  </div>

                  {#if showHint === question.key}
                    <div
                      style="position: absolute; font-size: 12px; background: #FFFFFF; padding: 16px; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25); border-radius: 8px; bottom: 40px; "
                    >
                      <p style="font-size: 12px;">
                        {question.clientPortalHint}
                      </p>
                    </div>
                  {/if}
                </div>
              {/if}
            </h4>
            {#if question.note && mergedOpts.activeQuestions.showQuestionNotes}
              <p>
                {@html question.note}
              </p>
            {/if}
          </div>

          <div class="LeadMatching-selector">
            {#each question.options as option}
              <div class="LeadMatching-selectorButton">
                <button
                  for={question.id}
                  disabled={readOnly}
                  on:click={() => {
                    toggleActivity(modifiedResult, question, option);
                  }}
                  style="text-transform: capitalize"
                  class:is-active={isActiveOption(
                    modifiedResult,
                    question,
                    option
                  )}
                >
                  {t(option.title)}
                </button>
              </div>
            {/each}
          </div>
        </div>
      {/if}
    {/each}

    {#if mergedOpts.activeQuestions.benefitsQuestion}
      <h1 class="LeadMatching-sectionMainTitle">Benefits</h1>
      <div class="LeadMatching-sectionGrid">
        {#each organizedQuestions as question}
          {#if question.category === 'benefits'}
            <div class="LeadMatching-section">
              <div class="LeadMatching-sectionHeader">
                <h2>
                  {t(question.title)}
                </h2>
              </div>
              <div class="LeadMatching-selector">
                {#each question.options as option}
                  <div class="LeadMatching-selectorButton">
                    <button
                      for={question.id}
                      disabled={readOnly}
                      on:click={() => {
                        toggleActivity(modifiedResult, question, option);
                      }}
                      style="text-transform: capitalize"
                      class:is-active={isActiveOption(
                        modifiedResult,
                        question,
                        option
                      )}
                    >
                      {t(option.title)}
                    </button>
                  </div>
                {/each}
              </div>
            </div>
          {/if}
        {/each}
      </div>
    {/if}

    {#if !readOnly && hasUnsavedChanges}
      <br />
      <br />
      <button
        on:click={saveHiringCriteria}
        disabled={saving}
        class="LeadMatching-saveButton"
      >
        {#if saving}
          <div class="loader" />
        {:else}Update{/if}
      </button>
    {/if}
  {/if}
</div>

<style src="./LeadMatching.scss">
</style>
