<svelte:options tag="cdlc-multiad" />

<script>
  import { onMount } from 'svelte';
  import '../Ad/Ad.svelte';

  export let site = '1120901';
  export let fill_rate = 1.0;
  export let zone = '';
  export let num_ads = 1;
  export let network = '10445';
  export let size; // optional size
  export let keywords; // optional array of keywords

  let numAds;
  let ready = false;
  onMount(() => {
    setTimeout(() => {
      ready = true;
      numAds = new Array(num_ads);
    });
  });
</script>

{#if ready}
  <div class="MultiAd">
    {#each numAds as ad}
      <cdlc-ad {site} {fill_rate} {zone} {network} {size} {keywords} />
    {/each}
  </div>
{/if}

<style src="./MultiAd.scss"></style>
