<svelte:options tag="cdlc-job-search" />

<script>
  import { onMount, afterUpdate } from 'svelte';
  // import Waypoint from 'svelte-waypoint';
  import _ from 'lodash';
  import debug from 'debug';
  export let state = '';
  export let company_id = '';
  export let view_all_button_style = '';
  
  const log = debug('cdlc:JobSearch');
  let search = '';
  const open = true;
  let tab = 'about',
    nav,
    loadingJobs,
    showAllJobs = false,
    applyJob = {},
    prevJobs = [],
    zip,
    lastState,
    lastRun = {},
    jobsSchema,
    jobs;

  $: if (zip) {
    console.log('search');
    loadJobs();
  }

  onMount(async () => {
    loadJobs();
  });
  
  const loadJobs = _.debounce(async () => {
    log('jobs', company_id, loadingJobs);
    loadingJobs = true;
    if (!company_id) {
      return loadingJobs = false;
    }
    jobs = [];
    const queryOne =
      `company=${company_id}` +
      `&limit=100` +
      `&archived=false` +
      (zip
        ? `&hiringZips=${zip}`
        : state
        ? `&state=${state}`
        : '');

    const queryOneJobs = await fetch(
      'https://dedicatedjobs.cdllife.com/api/jobs?' + queryOne
    );
    const jbs = await queryOneJobs.json();

    lastState = state;
    jobs = [...jbs.data];
    loadingJobs = false;
  }, 500);

  function handleAnchorClick(event) {
    try {
      event.preventDefault();
    } catch (e) {}
    const link = event.currentTarget;
    const anchorId = new URL(link.href).hash.replace('#', '');
    const anchor = document.getElementById(anchorId);
    window.scrollTo({
      top: _.get(anchor, 'offsetTop', 0),
      behavior: 'smooth',
    });
  }
</script>

<div class="CDLCJobSearch">
  <input
    name={'search'}
    type="text"
    on:change={e => {}}
    placeholder={'Search by city or zipcode'}
    bind:value={search}
  />
</div>

<style src="./JobSearch.scss">
</style>
