<script>
  import debug from 'debug';
  import { createEventDispatcher } from 'svelte';
  import { get_current_component } from 'svelte/internal';
  import backend from './JobSelector.backend';
  import _ from 'lodash';

  const log = debug('cdlc:JobSelector'),
    svelteDispatch = createEventDispatcher(),
    component = get_current_component(),
    dispatch = (name, detail) => {
      svelteDispatch(name, detail);
      component.dispatchEvent &&
        component.dispatchEvent(new CustomEvent(name, { detail }));
    };

  export let sections = [];
  export let activeSection = 0;
  export let max = 9999;
  export let internal_title = false;

  let containerWidth = 999;

  $: selectedJobs = getSelectedJobs(sections);
  $: allActiveSelected = !_.find(_.get(sections[activeSection], 'jobs'), j => {
    return !j.selected;
  });

  function createJobSettings(d) {
    return {
      actionButtons: [
        {
          event: 'check',
          html: `
          <svg viewBox="0 0 512 512">
            <path 
            ${
              d.selected
                ? 'style="fill:var(--cdlc-color-accent, #ff7134) !important"'
                : ''
            }
            d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
          </svg>
          `,
        },
      ],
    };
  }

  function getSelectedJobs(sections) {
    const jobs = [];
    _.each(sections, s => {
      _.each(s.jobs, d => {
        if (d.selected) {
          jobs.push(d);
        }
      });
    });
    return jobs;
  }
</script>

<style src="./JobSelector.scss">

</style>

<svelte:options tag="cdlc-job-selector" />

<div class="JobSelector" bind:clientWidth={containerWidth}>
  <div class="JobSelector-nav uk-flex uk-flex-between">
    {#if sections.length > 1}
      <ul class="uk-tab">
        {#each sections as section, index}
          <li class:uk-active={activeSection === index}>
            <a
              href="javascript:;"
              on:click={() => {
                activeSection = index;
              }}>
              {section.title}
            </a>
          </li>
        {/each}
      </ul>
    {/if}
    <div class="Actions">
      {#if max === 9999}
        {#if allActiveSelected}
          <button
            class="uk-button uk-button-default JobSelector-button"
            on:click={() => {
              _.each(sections[activeSection].jobs, j => {
                j.selected = false;
              });
              sections[activeSection] = sections[activeSection];
            }}>
            Deselect All
          </button>
        {:else}
          <button
            class="uk-button uk-button-default JobSelector-button"
            on:click={() => {
              _.each(sections[activeSection].jobs, j => {
                j.selected = true;
              });
              sections[activeSection] = sections[activeSection];
            }}>
            Select All
          </button>
        {/if}
      {/if}

      {#if selectedJobs && selectedJobs.length}
        <button
          class="uk-button uk-button-primary JobSelector-button-active"
          on:click={() => {
            dispatch('selected', selectedJobs);
          }}>
          Select Job{selectedJobs.length !== 1 ? 's' : ''}
          {#if max > 1}
            {#if selectedJobs.length}({selectedJobs.length}){/if}
          {/if}
        </button>
      {:else}
        <button
          class="uk-button uk-button-default JobSelector-button-inactive"
          on:click={() => {
            dispatch('selected', selectedJobs);
          }}>
          Cancel
        </button>
      {/if}
    </div>
  </div>

  {#if sections[activeSection] && sections[activeSection].jobs}
    {#each _.chunk(sections[activeSection].jobs, 3) as chunk}
      <div
        class:uk-flex={(containerWidth || 999) > 900}
        style="margin-bottom: 15px">
        {#each chunk as d}
          <cdlc-job-card
            class:uk-width-1-3={(containerWidth || 999) > 900}
            class:uk-width-1-1={(containerWidth || 999) <= 900}
            job_id={d.job_id}
            job={d.job}
            {internal_title}
            on:loaded={event => {
              d.job = event.detail;
            }}
            on:check={() => {
              let s = !d.selected;
              if (max === 1) {
                _.each(_.get(sections[activeSection], 'jobs'), j => {
                  j.selected = false;
                });
              }
              d.selected = s;
            }}
            settings={createJobSettings(d)} />
        {/each}
      </div>
    {/each}
  {/if}
</div>
