<svelte:options tag="cdlc-job-card" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import { createEventDispatcher, onMount, tick } from 'svelte';
  import { get_current_component } from 'svelte/internal';
  import backend from './JobCard.backend';
  import './JobCardComponents/JobCardAction/JobCardAction.svelte';
  import './JobCardComponents/JobCardTitle/JobCardTitle.svelte';
  import './JobCardComponents/JobCardButtons/JobCardButtons.svelte';
  import './JobCardComponents/JobCardMap/JobCardMap.svelte';
  import './JobCardComponents/JobCardDetails/JobCardDetails.svelte';
  const log = debug('cdlc:JobCard'),
    svelteDispatch = createEventDispatcher(),
    component = get_current_component(),
    dispatch = (name, detail) => {
      svelteDispatch(name, detail);
      component.dispatchEvent &&
        component.dispatchEvent(new CustomEvent(name, { detail }));
    };

  let componentName = 'JobCard';
  let api = 'https://dedicatedjobs.cdllife.com';

  export let job_id = null;
  export let job = null;
  export let internal_title = false;
  export let settings = {
    useBranding: false,
    fullNote: false,
    hidePhone: false,
    showJobId: false,
    viewJobButton: true,
    companyLogo: true,
    showJobInfoItems: true,
    hideActions: false,
  };

  $: mergedSettings = _.merge(
    {
      useBranding: false,
      fullNote: false,
      hidePhone: false,
      showJobId: false,
      viewJobButton: true,
      companyLogo: true,
      showJobInfoItems: true,
    hideActions: false,
    },
    settings
  );
  let loading = true,
  loadedJob;

  $: loadJob(job, job_id).then(job => {
    loadedJob = job;
  }).catch(e => {
    console.error('error loading job', e)
  })

  async function loadJob(job, job_id) {
    if (job) {
      return job;
    } else if (job_id) {
      const jobUrl = api + '/api/jobs/' + job_id,
        res = await fetch(jobUrl),
        json = await res.json(),
        job = json.data;
      log('job', job);
      dispatch('loaded', job);
      return job;
    } else {
      return;
    }
  }
</script>

{#if loadedJob}
  <div class="JobCard">
    {#if internal_title}
      <div
        style="color: #0c5899; font-weight: 700; font-size: 1.75em; text-align:
        center;"
      >
        {loadedJob.internalTitle}
      </div>
    {/if}
    <div class="JobCard-wrap">
      <cdlc-job-card-buttons
        job={loadedJob}
        settings={mergedSettings}
        {dispatch}
      />
      <cdlc-job-card-map job={loadedJob} settings={mergedSettings} />
      <cdlc-job-card-title job={loadedJob} settings={mergedSettings} />
      {#if !settings.hideActions}
        <cdlc-job-card-action
          job={loadedJob}
          settings={mergedSettings}
          {dispatch}
        />
      {/if}
      <cdlc-job-card-details
        job={loadedJob}
        settings={mergedSettings}
        {dispatch}
      />
      {#if loadedJob && loadedJob.companyLogo && settings.companyLogo}
        <div class="JobCard-footer">
          <img alt="companyLogo" src={loadedJob.companyLogo} />
        </div>
      {/if}
    </div>
  </div>
{/if}

<style src="./JobCard.scss">
</style>
