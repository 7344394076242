<svelte:options tag="cdlc-matched-form" />

<script>
  import '../LeadForm/LeadForm.svelte';
  import _ from 'lodash';
  import debug from 'debug';
  import presets from './MatchedForm.presets';
  import presetMappers from './MatchedForm.presetMappers';
  import { createEventDispatcher, tick } from 'svelte';
  import { get_current_component } from 'svelte/internal';

  const log = debug('cdlc:MatchedForm'),
    svelteDispatch = createEventDispatcher(),
    component = get_current_component(),
    dispatch = (name, detail) => {
      svelteDispatch(name, detail);
      component.dispatchEvent &&
        component.dispatchEvent(new CustomEvent(name, { detail }));
    };

  export let url = '';
  export let preset = 'diesel';
  export let referral_type = 'micrositeDieselHire';
  export let filtered_companies = [];
  export let section_method = 'singular'; // or multi-step
  export let plugins = ['cpix'];
  export let plugin_settings;
  export let has_terms = true;
  export let custom_terms_checkbox = '';
  export let custom_terms = '';
  export let leadFormMulti;
  export let leadForm;
  export let query_param_mapper = d => d;

  $: request = {
    url: url,
  };

  function progress(d) {
    log('progress', d && d.detail);
    dispatch('progress', d && d.detail);
  }

  function submit(d) {
    log('submitted', d && d.detail);
    dispatch('submitted', d && d.detail);
  }

  function presubmit(d) {
    const fn = presetMappers[preset];
    log('presubmit', d, fn);
    if (fn) {
      fn(d.detail);
    }
    d.detail.referralType = referral_type;
    d.detail.filteredCompanies = filtered_companies;
  }

  function activesection(d) {
    log('activesection', d && d.detail);
    dispatch('activesection', d && d.detail);
  }
</script>

{#await tick() then ready}
  <!-- NOTE: we need a duplicate of everything inside each for the if here
  ... there isn't really a way to dynamically choose which element
-->
  {#if section_method === 'multi-step'}
    <cdlc-lead-form-multi
      store_results_key="cdlc-matched-form"
      sections={presets[preset] || []}
      {plugins}
      {request}
      {has_terms}
      {custom_terms_checkbox}
      {custom_terms}
      default_query_param_key="ai"
      {query_param_mapper}
      bind:this={leadFormMulti}
      on:activesection={activesection}
      on:presubmit={presubmit}
      on:submit={submit}
      on:progress={progress}
    />
  {:else}
    <cdlc-lead-form
      store_results_key="cdlc-matched-form"
      default_query_param_key="ai"
      {has_terms}
      {custom_terms_checkbox}
      {custom_terms}
      sections={presets[preset] || []}
      bind:this={leadForm}
      {query_param_mapper}
      {plugins}
      {plugin_settings}
      {request}
      on:presubmit={presubmit}
      on:submit={submit}
      on:progress={progress}
    />
  {/if}
{/await}

<style src="./MatchedForm.scss">
</style>
