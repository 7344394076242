<svelte:options tag="cdlc-mobile-post-article" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import { onMount, tick } from 'svelte';
  import '../MobilePostText/MobilePostText.svelte';

  const log = debug('cdlc:MobilePostArticle');

  export let dispatch = () => {};
  export let post;
  let photoUrl;
  let articleDomainLink;
  onMount(async () => {
    await tick();
    if (post) {
      photoUrl = _.get(post, 'articleInformation.photoUrl');
      setArticleDomainLink();
    }
    log('post: ', post);
  });
  function setArticleDomainLink() {
    if (post.postType === 'ARTICLE') {
      const link = _.lowerCase(_.get(post, 'articleInformation.sourceId'));
      if (_.includes(link, 'cdllife')) {
        articleDomainLink = 'cdllife.com';
      } else if (_.includes(link, 'livetrucking')) {
        articleDomainLink = 'livetrucking.com';
      } else if (_.includes(link, 'driveusatruck')) {
        articleDomainLink = 'driveusatruck.com';
      } else {
        articleDomainLink = _.get(
          post,
          'articleInformation.metadata.domainLabel',
          ''
        );
      }
    }
  }
</script>

{#if post}
  <div class="MobilePostArticle">
    {#if post.postType === 'ARTICLE'}
      <cdlc-mobile-post-text
        {dispatch}
        {post}
        text={post.articleInformation.description}
        max_length="275"
      />
      <div class="MobilePost-postSection" style="padding: 0px 0px;">
        <div
          class="MobilePost-postSection-linkContent"
          on:click={() => {
            dispatch('body-link', { link: post.articleInformation.linkUrl });
          }}
        >
          {#if photoUrl}
            <img
              src={photoUrl}
              class="MobilePost-postSection-linkContent-image"
              style="width: 100%; margin-bottom: 10px;"
            />
          {/if}
          <!-- TITLE -->
          <div class="MobilePost-postSection-title" style="">
            {post.articleInformation.title}
          </div>
          <!-- LINK -->
          <div class="MobilePostArticle-url">{articleDomainLink}</div>
        </div>
      </div>
    {/if}
  </div>
{/if}

<style src="./MobilePostArticle.scss">
</style>
