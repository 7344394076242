<svelte:options tag="cdlc-live-chat-popup" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import { onMount } from 'svelte';

  const log = debug('cdlc:LiveChatPopup');

  export let click;

  export let done_click;
</script>

<div class="cdlc-LiveChatPopup">
  <div class="popup-overlay" />
  <div class="popup">
    <h1>Are you still chatting?</h1>
    <div class="buttonContainer">
      <button class="doneClick" on:click={done_click}>No, I'm not</button>
      <button class="continueClick" on:click={click}
        >Yes, I'm still chatting</button>
    </div>
  </div>
</div>

<style src="./LiveChatPopup.scss">
</style>
