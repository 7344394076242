<svelte:options tag="cdlc-job-card-v2-title" />

<script>
  import _ from 'lodash';
  export let job = {};
  export let text = '';
</script>

{#if job}
  <div class="JobCardV2-title">
    <div class="JobCardV2-title-badgeWrapper">
      {#if job._id}
        <span class="JobCardV2-title-badge">
          {job._id.slice(-6).toUpperCase()}</span
        >
      {/if}

      <span class="JobCardV2-title-badge solidBg">
        {#if job.priorityType === 'High'}
          <svg
            style="fill: #ffa123; width: 12px;"
            class="fas fa-flag smallPriorityIcon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            ><path
              d="M349.565 98.783C295.978 98.783 251.721 64 184.348 64c-24.955 0-47.309 4.384-68.045 12.013a55.947 55.947 0 0 0 3.586-23.562C118.117 24.015 94.806 1.206 66.338.048 34.345-1.254 8 24.296 8 56c0 19.026 9.497 35.825 24 45.945V488c0 13.255 10.745 24 24 24h16c13.255 0 24-10.745 24-24v-94.4c28.311-12.064 63.582-22.122 114.435-22.122 53.588 0 97.844 34.783 165.217 34.783 48.169 0 86.667-16.294 122.505-40.858C506.84 359.452 512 349.571 512 339.045v-243.1c0-23.393-24.269-38.87-45.485-29.016-34.338 15.948-76.454 31.854-116.95 31.854z"
            /></svg
          >
        {:else if job.priorityType === 'Urgent'}
          <svg
            style="fill: #ea4335; width: 12px;"
            class="fas fa-exclamation-triangle smallPriorityIcon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            ><path
              d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
            /></svg
          >
        {:else if job.priorityType === 'Not Funded'}
          <svg
            style="fill: #c2c2c2; width: 12px;"
            class="fas fa-ban smallPriorityIcon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z"
            /></svg
          >
        {/if}

        {job.priorityType || 'Standard'}
      </span>
    </div>
    <h1 class="JobCardV2-title-internalTitle">
      {@html job.internalTitle}
    </h1>
    <p class="JobCardV2-title-details">
      {@html text}
    </p>
  </div>
{/if}

<style src="./JobCardV2Title.scss">
</style>
