<svelte:options tag="cdlc-short-form" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import { createEventDispatcher } from 'svelte';
  import { get_current_component } from 'svelte/internal';
  import { LiveChatEventEmitter } from '../LiveChat/LiveChat.svelte';
  import backend from './ShortForm.backend';

  const log = debug('cdlc:ShortForm'),
    svelteDispatch = createEventDispatcher(),
    component = get_current_component(),
    dispatch = (name, detail) => {
      svelteDispatch(name, detail);
      component.dispatchEvent &&
        component.dispatchEvent(new CustomEvent(name, { detail }));
    };

  export let url = 'https://dedicatedjobs.cdllife.com/api/leads';
  export let referral_type = 'jobBoard';
  export let job;
  export let client;
  export let dob = true;
  export let merge_details = {};
  export let query_param_mapper = d => d;
  // when skip_lead_submit is true, dispatches lead data but doesn not apply lead to job
  export let skip_lead_submit = false;

  $: parsedJob = tryParse(job);
  $: parsedCompany = tryParse(client);
  $: jobTypeSelect = parsedJob && parsedJob.jobTypeSelect;
  $: cdlRequirement = parsedJob && parsedJob.cdlRequirement;
  $: nonUSZipCode =
    parsedJob && parsedJob.landingPage && parsedJob.landingPage.nonUSZipCode;
  $: removeCertifications =
    parsedJob &&
    parsedJob.landingPage &&
    parsedJob.landingPage.removeCertifications;
  $: removeQualifications =
    parsedJob &&
    parsedJob.landingPage &&
    parsedJob.landingPage.removeQualifications;

  LiveChatEventEmitter.on('started', convoId => {
    merge_details = merge_details || {};
    merge_details.info = merge_details.info || {};
    merge_details.info.liveChatConvoId = convoId;
  });

  setTimeout(() => {
    log('job', parsedJob);
    log('client', parsedCompany);
    log('referral', referral_type);
  });

  const dieselQualifications = {
    title: 'Qualifications',
    questions: [
      {
        label: 'Experience as Diesel Mechanic',
        type: 'select',
        key: 'info.years',
        validator: 'required',
        options: [
          {
            value: 'needtraining',
            title: 'Need Training',
          },
          {
            value: 'intraining',
            title: 'In School Now',
          },
          {
            value: 'graduatedtraining',
            title: 'School Grad, No Experience Yet',
          },
          {
            value: '1-5_months',
            title: '1-5 Months',
          },
          {
            value: '6-11_months',
            title: '6-11 Months',
          },
          {
            value: '12-23_months',
            title: '12-23 Months',
          },
          {
            value: '2_years',
            title: '2 Years',
          },
          {
            value: '3_years',
            title: '3 Years',
          },
          {
            value: '4_years',
            title: '4 Years',
          },
          {
            value: '5+_years',
            title: '5+ Years',
          },
        ],
      },
    ],
  };

  $: sections = [
    {
      title: 'Personal Info',
      questions: [
        {
          key: 'info.firstName',
          label: 'First Name',
          type: 'input',
          inputType: 'text',
          validator: 'required',
        },
        {
          key: 'info.lastName',
          label: 'Last Name',
          type: 'input',
          inputType: 'text',
          validator: 'required',
        },
        {
          key: 'info.email',
          label: 'Email Address',
          type: 'input',
          inputType: 'email',
          validator: 'email',
        },
        {
          key: 'info.phone',
          label: 'Phone',
          type: 'input',
          inputType: 'tel',
          validator: 'required',
        },
        {
          key: 'info.zip',
          label: 'Zip Code',
          type: 'input',
          inputType: nonUSZipCode === true ? 'text' : 'tel',
          validator: 'required',
        },
      ].filter(d => d),
    },
    removeQualifications
      ? null
      : jobTypeSelect === 'diesel-mechanic'
        ? dieselQualifications
        : {
            title: 'Qualifications',
            questions: [
              {
                label: 'Experience',
                type: 'select',
                key: 'info.years',
                validator: 'required',
                options: [
                  {
                    value: 'needtraining',
                    title: 'Need CDL Training',
                  },
                  {
                    value: 'intraining',
                    title: 'In CDL School Now',
                  },
                  {
                    value: 'graduatedtraining',
                    title: 'CDL Grad, No Experience Yet',
                  },
                  {
                    value: '1-5_months',
                    title: '1-5 Months',
                  },
                  {
                    value: '6-11_months',
                    title: '6-11 Months',
                  },
                  {
                    value: '12-23_months',
                    title: '12-23 Months',
                  },
                  {
                    value: '2_years',
                    title: '2 Years',
                  },
                  {
                    value: '3_years',
                    title: '3 Years',
                  },
                  {
                    value: '4_years',
                    title: '4 Years',
                  },
                  {
                    value: '5+_years',
                    title: '5+ Years',
                  },
                ],
              },

              cdlRequirement === 'cdl-b'
                ? {
                    label: 'What type of CDL License do you have?',
                    type: 'select',
                    key: 'info.cdlType',
                    validator: 'required',
                    certification: true,
                    options: [
                      {
                        value: 'cdl-a',
                        title: 'Class A',
                      },
                      {
                        value: 'cdl-b',
                        title: 'Class B',
                      },
                      {
                        value: 'cdl-c',
                        title: 'Class C',
                      },
                      {
                        value: 'none',
                        title: 'None',
                      },
                    ],
                  }
                : {
                    label: 'Do you have a Class A CDL?',
                    type: 'select',
                    key: 'info.cdl',
                    validator: 'required',
                    certification: true,
                    options: [
                      {
                        value: 'yes',
                        title: 'Yes',
                      },
                      {
                        value: 'no',
                        title: 'No',
                      },
                    ],
                  },
              cdlRequirement === 'cdl-b'
                ? null
                : {
                    label: 'Do you have a Class B CDL?',
                    type: 'select',
                    key: 'info.cdlb',
                    certification: true,
                    // validator: 'required',
                    logic: d => {
                      return d && d.info && d.info.cdl === 'no';
                    },
                    options: [
                      {
                        value: 'yes',
                        title: 'Yes',
                      },
                      {
                        value: 'no',
                        title: 'No',
                      },
                    ],
                  },
              {
                label: 'Are you an Owner Operator?',
                type: 'select',
                key: 'info.ownerOperator',
                validator: 'required',
                options: [
                  {
                    value: 'yes',
                    title: 'Yes',
                  },
                  {
                    value: 'no',
                    title: 'No',
                  },
                ],
              },
              {
                label: 'Are you Solo or Team?',
                type: 'select',
                key: 'info.teamDriver',
                validator: 'required',
                options: [
                  {
                    value: 'no',
                    title: 'Solo',
                  },
                  {
                    value: 'yes',
                    title: 'Team',
                  },
                ],
              },
              {
                label: 'Select all your Endorsements',
                type: 'multiSelect',
                key: 'endorsements',
                certification: true,
                options: [
                  {
                    value: 'H',
                    title: 'H - Hazardous Materials',
                  },
                  {
                    value: 'N',
                    title: 'N - Tanker Vehicles',
                  },
                  {
                    value: 'P',
                    title: 'P - Passengers',
                  },
                  {
                    value: 'S',
                    title: 'S - School Bus',
                  },
                  {
                    value: 'T',
                    title: 'T - Doubles or Triples',
                  },
                  {
                    value: 'X',
                    title: 'X - Combo Hazmat and Tanker',
                  },
                  {
                    value: 'TWIC',
                    title: 'TWIC',
                  },
                ],
              },
            ].filter(d => {
              if (!d) return false;
              if (!removeCertifications) return true;

              return !d.certification;
            }),
          },
    customQuestionSection(parsedCompany, parsedJob),
  ].filter(d => d);

  $: request = {
    url: url,
  };

  function customQuestionSection(client, job) {
    const section = {
      title: 'Other Questions',
      questions: [],
    };

    _.each((client && client.applicationCustomQuestions) || {}, (q, k) => {
      appendQuestion(client, '', q, k);
    });

    _.each((job && job.applicationCustomQuestions) || {}, (q, k) => {
      appendQuestion(job, 'job', q, k);
    });

    log('job questions', job && job.applicationCustomQuestions);
    log('client questions', client && client.applicationCustomQuestions);
    log('set questions', section.questions);
    log({ job, client });

    if (section.questions.length) return section;

    function appendQuestion(obj, path, q, key) {
      if (q) {
        let options = _.get(
          obj,
          `applicationCustomQuestionConfig.${key}.options`
        );

        if (!options || !options.length) {
          options = [
            {
              value: 'yes',
              title: 'Yes',
            },
            {
              value: 'no',
              title: 'No',
            },
          ];
        }

        section.questions.push({
          label: q,
          type:
            _.get(obj, `applicationCustomQuestionConfig.${key}.questionType`) ||
            'select',
          key: 'info.' + path + key,
          validator: 'required',
          options: options.map(o => {
            return {
              title: o.title || o.value,
              value: o.value || o.title,
            };
          }),
        });
      }
    }
  }

  function tryParse(d) {
    try {
      return JSON.parse(d);
    } catch (e) {
      return d || {};
    }
  }

  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  }

  function handleCustomQuestionsOnLead(client, job, lead) {
    const questions = (client && client.applicationCustomQuestions) || {};

    log('handleCustomQuestionsOnLead', { questions, lead });

    _.each((client && client.applicationCustomQuestions) || {}, (q, k) => {
      appendQuestion(client, '', q, k);
    });

    _.each((job && job.applicationCustomQuestions) || {}, (q, k) => {
      appendQuestion(job, 'job', q, k);
    });

    _.each(questions, (question, k) => {});

    function appendQuestion(obj, path, question, k) {
      if (!question) return;
      if (!String(question || '').trim()) return;

      const answer = _.get(lead, `info.${path}${k}`);

      if (question && answer) {
        lead.info.customQuestions = lead.info.customQuestions || [];
        lead.info.customQuestions.push({
          answer,
          question,
        });
        delete lead.info[k];
      }
    }
  }
</script>

<cdlc-lead-form
  store_results_key="cdlc-lead-form"
  default_query_param_key="ai"
  {sections}
  {request}
  {query_param_mapper}
  {skip_lead_submit}
  on:presubmit={d => {
    if (_.get(parsedJob, '_id')) {
      _.set(d.detail, 'job', _.get(parsedJob, '_id'));
    }
    if (parsedJob.company) {
      d.detail.company = parsedJob.company;
    }
    d.detail.jobOwner = parsedJob.user;
    d.detail.type = 'application';
    d.detail.referralType = referral_type;
    d.detail.info.confirmedEndorsements = _.map(d.detail.endorsements);
    d.detail.info.confirmedNonEndorsements = _.filter(
      ['H', 'N', 'P', 'S', 'T', 'X', 'TWIC'],
      e => {
        return !_.includes(d.detail.info.confirmedEndorsements, e);
      }
    );
    d.detail.info.qualifications = {
      ownerOperator: _.get(d.detail, 'info.ownerOperator') === 'yes',
      teamDriver: _.get(d.detail, 'info.teamDriver') === 'yes',
    };
    d.detail.info.zip = String(d.detail.info.zip || '')
      .trim()
      .slice(0, 5);
    if (d.detail.tr) {
      d.detail.info.tr = d.detail.tr;
      delete d.detail.tr;
    }
    let lrec = parseFloat(getQueryVariable('lrec'));
    if (lrec) {
      _.set(d.detail, 'info.tr.lrec', parseFloat(lrec));
    }
    d.detail.sendImmediately = true;
    _.merge(d.detail, tryParse(merge_details));

    // CDL-A/B/C stuff
    try {
      // different set of questions asked
      if (cdlRequirement === 'cdl-b') {
        // CDL-A
        _.set(
          d.detail,
          'info.cdl',
          _.get(d, 'detail.info.cdlType') === 'cdl-a' ? 'yes' : 'no'
        );

        // CDL-B
        _.set(
          d.detail,
          'info.cdlb',
          _.get(d, 'detail.info.cdlType') === 'cdl-b' ? 'yes' : 'no'
        );

        // CDL-C
        _.set(
          d.detail,
          'info.cdlc',
          _.get(d, 'detail.info.cdlType') === 'cdl-c' ? 'yes' : 'no'
        );
      }
    } catch (e) {
      console.error('error', e);
    }

    handleCustomQuestionsOnLead(parsedCompany, parsedJob, d.detail);
    log('lead', d.detail);
  }}
  on:submit={d => {
    log('submitted', d && d.detail);
    dispatch('submitted', d && d.detail);
    LiveChatEventEmitter.emit('hide');
  }}
  on:progress={d => {
    log('progress', d && d.detail);
    dispatch('progress', d && d.detail);
  }}
/>

<style src="./ShortForm.scss">
</style>
