<svelte:options tag="cdlc-job-card-v2-actions" />

<script>
  import _ from 'lodash';
  import { icons } from '../../JobCardV2Tools/index';
  export let dispatch = () => {};
  export let job;
  export let settings = {};

  let showActionDropdown = false,
    showNestedDropdown = false,
    showPriority = false;
</script>

{#if job}
  <div class="JobCardV2-actions">
    <div class="JobCardV2-actionsWrapper">
      {#if job._leadData && job._leadData.leadType}
        <span class="JobCardV2-leadType {job._leadData.leadType}">
          {@html icons[job._leadData.leadType.toLowerCase()]}
          {job._leadData.leadType}</span
        >
      {/if}
      {#if settings.actionDropdownOptions && settings.actionDropdownOptions.length}
        <div
          class="JobCardV2-action-ellipsis"
          on:click={() => {
            showActionDropdown = !showActionDropdown;
            showNestedDropdown = false;
            showPriority = false;
          }}
        >
          <svg
            width="16"
            height="3"
            viewBox="0 0 16 3"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3333 1.5C13.3333 0.835938 13.8802 0.25 14.5833 0.25C15.2474 0.25 15.8333 0.835938 15.8333 1.5C15.8333 2.20312 15.2474 2.75 14.5833 2.75C13.8802 2.75 13.3333 2.20312 13.3333 1.5ZM7.08334 1.5C7.08334 0.835938 7.63022 0.25 8.33334 0.25C8.99741 0.25 9.58334 0.835938 9.58334 1.5C9.58334 2.20312 8.99741 2.75 8.33334 2.75C7.63022 2.75 7.08334 2.20312 7.08334 1.5ZM3.33334 1.5C3.33334 2.20312 2.74741 2.75 2.08334 2.75C1.38022 2.75 0.833344 2.20312 0.833344 1.5C0.833344 0.835938 1.38022 0.25 2.08334 0.25C2.74741 0.25 3.33334 0.835938 3.33334 1.5Z"
              fill="#262626"
            />
          </svg>
        </div>

        {#if showActionDropdown}
          <div
            style={showActionDropdown
              ? 'min-width: calc(100% + 400px); padding-right: 200px; height: 250px; position: absolute;'
              : ''}
            on:mouseenter={() => {
              showActionDropdown = true;
            }}
            on:mouseleave={() => {
              showActionDropdown = false;
            }}
          >
            <div class="JobCardV2-actionDropdown">
              {#if settings.actionDropdownOptions.length}
                {#each settings.actionDropdownOptions as a, i}
                  <a
                    on:mouseenter={e => {
                      if (a.nested) {
                        showNestedDropdown = !showNestedDropdown;
                        showPriority = false;
                        showPriority = showPriority;
                      } else if (a.priorityNested) {
                        showPriority = !showPriority;
                        showNestedDropdown = false;
                        showNestedDropdown = showNestedDropdown;
                      }
                    }}
                    style="color: {a.color}"
                    class="JobCardV2-actionOption"
                    href={'javascript:;'}
                    on:click={() => {
                      if (a.nested) {
                        showNestedDropdown = !showNestedDropdown;
                        showPriority = false;
                        showPriority = showPriority;
                      } else if (a.priorityNested) {
                        showPriority = !showPriority;
                        showNestedDropdown = false;
                        showNestedDropdown = showNestedDropdown;
                      } else {
                        showActionDropdown = !showActionDropdown;
                        showNestedDropdown = false;
                        showPriority = false;
                        dispatch(`${a.handler}`);
                      }
                    }}
                  >
                    {#if a.icon || icons[a.key]}
                      <span class="JobCardV2-listIcon">
                        {@html a.icon || icons[a.key]}
                      </span>
                    {/if}
                    {a.title}
                    {#if a.nested || a.priorityNested}
                      <svg
                        class="JobCardV2-nestedDropdown-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                        ><path
                          d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                          fill="#262626"
                        /></svg
                      >
                    {/if}
                  </a>

                  {#if showNestedDropdown && a.nested}
                    <div class="JobCardV2-actionDropdown nestedActions">
                      {#each a.nested as n}
                        <a
                          class="JobCardV2-actionOption"
                          href={'javascript:;'}
                          on:click={() => {
                            showNestedDropdown = !showNestedDropdown;
                            showActionDropdown = !showActionDropdown;
                            dispatch(`${n.handler}`);
                          }}
                        >
                          {n.title}
                        </a>
                      {/each}
                    </div>
                  {:else if showPriority && a.priorityNested}
                    <div class="JobCardV2-actionDropdown priorityNestedActions">
                      {#each a.priorityNested as n}
                        <a
                          class="JobCardV2-actionOption"
                          href={'javascript:;'}
                          on:click={() => {
                            showPriority = !showPriority;
                            showActionDropdown = !showActionDropdown;
                            dispatch(`${n.handler}`, n.title);
                          }}
                        >
                          {#if n.title === 'High'}
                            <svg
                              style="fill: #ffa123; width: 12px;"
                              class="fas fa-flag smallPriorityIcon"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              ><path
                                d="M349.565 98.783C295.978 98.783 251.721 64 184.348 64c-24.955 0-47.309 4.384-68.045 12.013a55.947 55.947 0 0 0 3.586-23.562C118.117 24.015 94.806 1.206 66.338.048 34.345-1.254 8 24.296 8 56c0 19.026 9.497 35.825 24 45.945V488c0 13.255 10.745 24 24 24h16c13.255 0 24-10.745 24-24v-94.4c28.311-12.064 63.582-22.122 114.435-22.122 53.588 0 97.844 34.783 165.217 34.783 48.169 0 86.667-16.294 122.505-40.858C506.84 359.452 512 349.571 512 339.045v-243.1c0-23.393-24.269-38.87-45.485-29.016-34.338 15.948-76.454 31.854-116.95 31.854z"
                              /></svg
                            >
                          {:else if n.title === 'Urgent'}
                            <svg
                              style="fill: #ea4335; width: 12px;"
                              class="fas fa-exclamation-triangle smallPriorityIcon"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                              ><path
                                d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                              /></svg
                            >
                          {:else if n.title === 'Not Funded'}
                            <svg
                              style="fill: #c2c2c2; width: 12px;"
                              class="fas fa-ban smallPriorityIcon"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z"
                              /></svg
                            >
                          {/if}
                          {n.title}
                        </a>
                      {/each}
                    </div>
                  {/if}
                {/each}
              {/if}
            </div>
          </div>
        {/if}
      {/if}
    </div>
  </div>
{/if}

<style src="./JobCardV2Actions.scss">
</style>
