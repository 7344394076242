import en from './en';

const tLookup = {
    en: en,
  },
  language = 'en';

export function t(s) {
  const languageLookup = tLookup[language] || tLookup.en;

  return languageLookup[s] || s;
}
