<svelte:options tag="cdlc-job-ad" />

<script>
  import './FacebookJobAd/FacebookJobAd.svelte';
  import './HiredCDLAd/HiredCDLAd.svelte';
  import './IndeedJobAd/IndeedJobAd.svelte';
  import './ZiprecruiterJobAd/ZiprecruiterJobAd.svelte';
  import './TalrooJobAd/TalrooJobAd.svelte';
  import './TextBlastJobAd/TextBlastJobAd.svelte';
  import './UpwardJobAd/UpwardJobAd.svelte';
  import debug from 'debug';
  import backend from './JobAd.backend';
  import { createEventDispatcher, onMount, tick } from 'svelte';
  import { get_current_component } from 'svelte/internal';
  import '../MobilePost/MobilePostImageSlider/MobilePostImageSlider.svelte';
  import datatypes from '../../tools/jobTools/datatypes.js';
  import _ from 'lodash';

  const log = debug('cdlc:JobAd'),
    svelteDispatch = createEventDispatcher(),
    component = get_current_component(),
    dispatch = (name, detail) => {
      svelteDispatch(name, detail);
      component.dispatchEvent &&
        component.dispatchEvent(new CustomEvent(name, { detail }));
    };

  export let job_id;
  export let ad_type;
  export let ad_data = {};
  export let job;
  export let audience_count;
  export let saving = {};
  export let job_adset;
  export let settings = {
    showFullDetails: false,
    shuffleJobData: true,
    jobCardFormat: false,
    tab: '',
    actionDropdownOptions: {},
  };

  let api = 'https://dedicatedjobs.cdllife.com',
    showActionDropdown = false,
    showNestedDropdown = false,
    showPriority = false,
    imagesArray,
    loaded = false;

  $: shuffledJob = job && createdShuffledJob(job);

  $: image =
    resizeImage(_.get(shuffledJob, 'computedAds.media.0.url'), 'width=300') ||
    '';

  $: {
    imagesArray =
      resizeImage(_.get(shuffledJob, 'computedAds.media'), 'width=300') || '';
  }

  $: {
    let mapshuffledJob = _.get(shuffledJob, 'computedAds.media');

    imagesArray = _.map(mapshuffledJob, i => {
      i.url = resizeImage(i.url, 'width=300');
      return i;
    });
  }

  $: {
    if (settings.jobCardFormat) {
      image = `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyCp4aj43tx9151AKGc2ZV8GNKfSbCgq3NE&size=300x300&scale=2&${job.imgSrc}`;
    }
  }

  $: headline = _.get(shuffledJob, 'computedAds.headlines.0') || '';
  $: text = _.get(shuffledJob, 'computedAds.text.0') || '';
  $: loadJob(job, job_id);

  async function loadJob(currentJob, job_id) {
    if (currentJob) {
      log('already have job', currentJob);
      loaded = true;
      return currentJob;
    } else if (job_id) {
      const jobUrl = api + '/api/job-ads?_id=' + job_id,
        res = await fetch(jobUrl),
        json = await res.json();
      job = json && json.data && json.data[0];
      log('job', job);
      dispatch('loaded', job);
      loaded = true;
      return job;
    } else {
      return;
    }
  }

  function createdShuffledJob(job) {
    if (!job) return;
    if (typeof job !== 'object') return;
    if (job._shuffled) return job;

    if (settings.shuffleJobData) {
      job.computedAds = job.computedAds || {};
      job.computedAds.media = _.filter(
        _.shuffle(_.get(job, 'computedAds.media')),
        d => {
          return d.mediaType === 'IMAGE';
        }
      );
      job.computedAds.headlines = _.shuffle(job.computedAds.headlines);
      job.computedAds.text = _.shuffle(job.computedAds.text);
      job._shuffled = true;
      return job;
    } else {
      return job;
    }
  }

  function resizeImage(s, opts) {
    if (/d2r0eic16r3uxv/.test(s)) {
      return s + '.resize.png?' + (opts || '');
    }
    return s;
  }

  function getTrailerType(job) {
    let trailerTypes;

    if (_.isEmpty(job.computedAds.tags)) {
      trailerTypes = _.get(job, 'trailerType');
      trailerTypes = trailerTypes;
    } else {
      trailerTypes = _.get(job, 'computedAds.tags');
      trailerTypes = trailerTypes;
    }

    if (trailerTypes && trailerTypes.length > 1) {
      return _.truncate(_.join(_.drop(trailerTypes), '/'), {
        length: 25,
        separator: '...',
      });
    } else if (trailerTypes && trailerTypes.length) {
      return _.startCase(trailerTypes[0]);
    } else {
      return;
    }
  }
  function getDriverType(job) {
    if (_.isEmpty(job.computedAds.tags)) {
      return datatypes.driverType[job.driverType];
    } else {
      return _.get(job, 'computedAds.tags[0]');
    }
  }
</script>

{#if loaded && shuffledJob}
  {#if ad_type === 'facebook'}
    <cdlc-facebook-job-ad {ad_data} />
  {:else if ad_type === 'hiredcdl'}
    <cdlc-hired-cdl-job-ad {ad_data} />
  {:else if ad_type === 'indeed'}
    <cdlc-indeed-job-ad {ad_data} />
  {:else if ad_type === 'ziprecruiter'}
    <cdlc-ziprecruiter-job-ad {ad_data} />
  {:else if ad_type === 'talroo'}
    <cdlc-talroo-job-ad {ad_data} />
  {:else if ad_type === 'blasts'}
    <cdlc-text-blast-job-ad {ad_data} />
  {:else if ad_type === 'upward'}
    <cdlc-upward-job-ad {ad_data} />
  {:else}
    <div class="JobAd-card">
      {#if settings.jobCardFormat}
        <div class="JobAd-title-section JobAd-jobFormat">
          <div class="JobAd-title-wrapper">
            <div class="JobAd-title-text">
              <div>
                <h2 class:priorityLable={job.priorityType !== 'Standard'}>
                  {job.internalTitle}
                </h2>
                {#if job.priorityType === 'High'}
                  <svg
                    class="fas fa-flag largePriorityIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    ><path
                      d="M349.565 98.783C295.978 98.783 251.721 64 184.348 64c-24.955 0-47.309 4.384-68.045 12.013a55.947 55.947 0 0 0 3.586-23.562C118.117 24.015 94.806 1.206 66.338.048 34.345-1.254 8 24.296 8 56c0 19.026 9.497 35.825 24 45.945V488c0 13.255 10.745 24 24 24h16c13.255 0 24-10.745 24-24v-94.4c28.311-12.064 63.582-22.122 114.435-22.122 53.588 0 97.844 34.783 165.217 34.783 48.169 0 86.667-16.294 122.505-40.858C506.84 359.452 512 349.571 512 339.045v-243.1c0-23.393-24.269-38.87-45.485-29.016-34.338 15.948-76.454 31.854-116.95 31.854z"
                    /></svg
                  >
                {:else if job.priorityType === 'Urgent'}
                  <svg
                    class="fas fa-exclamation-triangle largePriorityIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    ><path
                      d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                    /></svg
                  >
                {:else if job.priorityType === 'Not Funded'}
                  <svg
                    class="fas fa-ban largePriorityIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z"
                    /></svg
                  >
                {/if}
              </div>

              <p class="JobAd-internal-ID">
                JOB ID <span
                  >{job._id.slice(job._id.length - 6).toUpperCase()}</span
                >
              </p>
            </div>
          </div>
        </div>
      {:else}
        <div class="JobAd-title-section">
          <img
            alt="company logo"
            src={resizeImage(
              job.computedAds.companyLogoSquare,
              'width=200&jpg=false'
            )}
          />
          <h2>
            {job.computedAds.companyName}
          </h2>
        </div>
      {/if}
      <div
        class="JobAd-description-section"
        class:jobFormat={settings.jobCardFormat}
      >
        {#if settings.jobCardFormat}
          {#if job.curatedContent && job.cardType === 'xml'}
            <p>
              {@html job.curatedContent.substring(0, 120) + '...'}
              <a
                href="/landing/job/{job._id}?clearCache=true"
                target="_blank"
                class="JobAd-jobFormat-description"
              >
                See full details
              </a>
            </p>
          {:else}
            <p style="min-height: 53px;">
              {@html text.substring(0, 120) + '...'}
              <a
                href="/landing/job/{job._id}?clearCache=true"
                target="_blank"
                class="JobAd-jobFormat-description"
              >
                See full details
              </a>
            </p>
          {/if}
        {:else}
          <p>
            {text}
          </p>
        {/if}
      </div>
      <div class="JobAd-label-section">
        <span class="JobAd-labels">{getDriverType(job)} </span>
        <span class="JobAd-labels">{getTrailerType(job)} </span>
      </div>
      <div class="JobAd-map-section">
        <div class="JobAd-map-image">
          {#if _.size(job.computedAds.media) > 0 && !settings.jobCardFormat}
            <cdlc-mobile-post-image-slider images={imagesArray} />
          {:else}
            <img alt="map image" src={image} />
          {/if}

          {#if settings.jobCardFormat}
            <button
              class="JobAd-jobFormat-checkAudienceBtn"
              on:click={() => {
                dispatch('audience');
              }}
            >
              {#if saving}
                <div class="LoadingSpinner" />
              {:else if audience_count}
                Drivers: {audience_count}
              {:else}
                Check Audience
              {/if}
            </button>
          {/if}
        </div>
      </div>

      {#if settings.jobCardFormat}
        <div class="JobAd-jobFormat-viewDetailsSection">
          <button
            class="JobAd-jobFormat-viewAds"
            on:click={() => {
              showActionDropdown = !showActionDropdown;
              showNestedDropdown = false;
              showPriority = false;
            }}>Manage Job</button
          >
          {#if showActionDropdown}
            <div
              style={showActionDropdown
                ? 'min-width: calc(100% + 400px); padding-right: 200px; height: 250px; position: absolute;'
                : ''}
              on:mouseenter={e => {
                showActionDropdown = true;
              }}
              on:mouseleave={() => {
                showActionDropdown = false;
              }}
            >
              <div class="JobAd-jobFormat-actionDropdown">
                {#each settings.actionDropdownOptions as a, i}
                  <a
                    on:mouseenter={e => {
                      if (a.nested) {
                        showNestedDropdown = !showNestedDropdown;
                        showPriority = false;
                        showPriority = showPriority;
                      } else if (a.priorityNested) {
                        showPriority = !showPriority;
                        showNestedDropdown = false;
                        showNestedDropdown = showNestedDropdown;
                      }
                    }}
                    style="color: {a.color}"
                    class="JobAd-jobFormat-actionOption"
                    href={'javascript:;'}
                    on:click={() => {
                      if (a.nested) {
                        showNestedDropdown = !showNestedDropdown;
                        showPriority = false;
                        showPriority = showPriority;
                      } else if (a.priorityNested) {
                        showPriority = !showPriority;
                        showNestedDropdown = false;
                        showNestedDropdown = showNestedDropdown;
                      } else {
                        showActionDropdown = !showActionDropdown;
                        showNestedDropdown = false;
                        showPriority = false;
                        dispatch(`${a.handler}`);
                      }
                    }}
                  >
                    {a.title}
                    {#if a.nested || a.priorityNested}
                      <svg
                        class="JobAd-nestedDropdown-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                        ><path
                          d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"
                        /></svg
                      >
                    {/if}
                  </a>

                  {#if showNestedDropdown && a.nested}
                    <div class="JobAd-jobFormat-actionDropdown nestedActions">
                      {#each a.nested as n}
                        <a
                          class="JobAd-jobFormat-actionOption"
                          href={'javascript:;'}
                          on:click={() => {
                            showNestedDropdown = !showNestedDropdown;
                            showActionDropdown = !showActionDropdown;
                            dispatch(`${n.handler}`);
                          }}
                        >
                          {n.title}
                        </a>
                      {/each}
                    </div>
                  {:else if showPriority && a.priorityNested}
                    <div
                      class="JobAd-jobFormat-actionDropdown priorityNestedActions"
                    >
                      {#each a.priorityNested as n}
                        <a
                          class="JobAd-jobFormat-actionOption"
                          href={'javascript:;'}
                          on:click={() => {
                            showPriority = !showPriority;
                            showActionDropdown = !showActionDropdown;
                            dispatch(`${n.handler}`, n.title);
                          }}
                        >
                          {#if n.title === 'High'}
                            <svg
                              style="fill: #ffa123; width: 12px;"
                              class="fas fa-flag smallPriorityIcon"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              ><path
                                d="M349.565 98.783C295.978 98.783 251.721 64 184.348 64c-24.955 0-47.309 4.384-68.045 12.013a55.947 55.947 0 0 0 3.586-23.562C118.117 24.015 94.806 1.206 66.338.048 34.345-1.254 8 24.296 8 56c0 19.026 9.497 35.825 24 45.945V488c0 13.255 10.745 24 24 24h16c13.255 0 24-10.745 24-24v-94.4c28.311-12.064 63.582-22.122 114.435-22.122 53.588 0 97.844 34.783 165.217 34.783 48.169 0 86.667-16.294 122.505-40.858C506.84 359.452 512 349.571 512 339.045v-243.1c0-23.393-24.269-38.87-45.485-29.016-34.338 15.948-76.454 31.854-116.95 31.854z"
                              /></svg
                            >
                          {:else if n.title === 'Urgent'}
                            <svg
                              style="fill: #ea4335; width: 12px;"
                              class="fas fa-exclamation-triangle smallPriorityIcon"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                              ><path
                                d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                              /></svg
                            >
                          {:else if n.title === 'Not Funded'}
                            <svg
                              style="fill: #c2c2c2; width: 12px;"
                              class="fas fa-ban smallPriorityIcon"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z"
                              /></svg
                            >
                          {/if}
                          {n.title}
                        </a>
                      {/each}
                    </div>
                  {/if}
                {/each}
              </div>
            </div>
          {/if}
        </div>
      {:else}
        <div class="JobAd-apply-section">
          {#if settings.showFullDetails}
            <button
              on:click={e => {
                dispatch('applyNow', 'application');
              }}>Apply Now</button
            >
            <a
              href="javascript:;"
              on:click={e => {
                dispatch('jobOverview', 'jobDetails');
              }}>See full details</a
            >
          {:else}
            <p>{headline}</p>
            <button class="rightApplyNowBtn">Apply Now</button>
          {/if}
        </div>
      {/if}
    </div>
  {/if}
{/if}

<style src="./JobAd.scss">
</style>
