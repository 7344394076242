<svelte:options tag="cdlc-job-card-map" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import moment from 'moment';
  import { createEventDispatcher, onMount, tick } from 'svelte';
  import { get_current_component } from 'svelte/internal';
  import jobTools from '../../../../tools/jobTools/tools.js';
  import datatypes from '../../../../tools/jobTools/datatypes.js';

  const log = debug('cdlc:JobCardMap'),
    svelteDispatch = createEventDispatcher(),
    component = get_current_component(),
    dispatch = (name, detail) => {
      svelteDispatch(name, detail);
      component.dispatchEvent &&
        component.dispatchEvent(new CustomEvent(name, { detail }));
    };

  export let job;
  let mapAPI,
  lastSrc;

  $: {
    if (job && job.imgSrc && job.imgSrc !== lastSrc) {
      mapAPI = `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyCp4aj43tx9151AKGc2ZV8GNKfSbCgq3NE&size=373x186&scale=2&${job.imgSrc}`;
      lastSrc = job.imgSrc
    }
  }

</script>

{#if job}
  <div class="JobCard-imgWrap">
    <div class="JobCard-map">
      {#if mapAPI}
        <img alt="map" src={mapAPI} />
      {/if}
    </div>
  </div>
{/if}

<style src="./JobCardMap.scss">
</style>
