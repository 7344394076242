<svelte:options tag="cdlc-mobile-post-photo" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import { onMount, tick } from 'svelte';
  import { get_current_component } from 'svelte/internal';
  import '../MobilePostText/MobilePostText.svelte';

  const log = debug('cdlc:MobilePostPhoto');

  export let dispatch = () => {};
  export let post;
  export let thread;

  $: message = _.get(post, 'photoInformation.caption');

  $: imageInfo = (() => {
    if (_.get(post, 'photoInformation.imageUrl')) {
      return _.get(post, 'photoInformation');
    }
    if (_.get(post, 'photoInformation.imageArray.0')) {
      return _.get(post, 'photoInformation.imageArray.0');
    }
    if (thread && _.get(post, 'commentInformation.imageUrl')) {
      return _.get(post, 'commentInformation');
    }
    if (thread && _.get(post, 'commentInformation.giphyUrl')) {
      return _.get(post, 'commentInformation');
    }
    return null;
  })();

  $: numImages = _.size(_.get(post, 'photoInformation.imageArray'));
</script>

{#if post && imageInfo}
  <div class="MobilePostPhoto">
    <div class="Post-postSection">
      <div>
        <cdlc-mobile-post-text
          {dispatch}
          {post}
          text={message}
          max_length="275"
        />
        <div class="MobilePostPhoto-container">
          {#if numImages > 1}
            <cdlc-mobile-post-image-slider
              style="border: 16px solid white;"
              {thread}
              rounded={true}
              {dispatch}
              images={post.photoInformation.imageArray}
            />
          {:else}
          <cdlc-img
              style="border: 16px solid white;"
              on:click={() => {
                dispatch('body-image-click', { image: imageInfo });
              }}
              min_ratio={1 / 1.91}
              max_ratio={5 / 4}
              metadata={imageInfo.imageMetadata}
              rounded={true}
              src={imageInfo.imageUrl || imageInfo.giphyUrl}
            />
          {/if}
        </div>
      </div>
    </div>
  </div>
{/if}

<style src="./MobilePostPhoto.scss">
</style>
