<svelte:options tag="cdlc-mobile-post-message" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import { onMount } from 'svelte';
  import '../MobilePostText/MobilePostText.svelte';

  const log = debug('cdlc:MobilePostMessage');

  export let dispatch = () => {};
  export let post;

  $: message = _.get(post, 'messageInformation.message');
  
</script>

<!-- {dispatch} -->
<div class="MobilePostMessage">
    <cdlc-mobile-post-text
      {dispatch}
      {post}
      text={message}
      max_length="275"
      growable={true}
    />
</div>

<style src="./MobilePostMessage.scss">
</style>
