<svelte:options tag="cdlc-job-card-action" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import moment from 'moment';
  import { createEventDispatcher, onMount, tick } from 'svelte';
  import { get_current_component } from 'svelte/internal';

  const log = debug('cdlc:JobCardAction');

  export let dispatch = () => {};
  export let job;
  export let settings = {};

  onMount(async () => {
    await tick();
    log('job', job);
  });
</script>

{#if job}
  <div class="JobCard-action">
    <a
      class="JobCard-actionApply"
      href="javascript:;"
      on:click={() => {
        dispatch('apply');
      }}
      style={settings.useBranding
        ? `background-color: ${job.primaryColor}`
        : ''}
    >
      <span>
        <svg
          class="fa"
          width="20"
          height="20"
          style="transform: translateY(2px);"
          viewBox="0 0 1792 1792"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M532 1108l152 152-52 52h-56v-96h-96v-56zm414-390q14 13-3 30l-291
            291q-17 17-30 3-14-13 3-30l291-291q17-17 30-3zm-274
            690l544-544-288-288-544 544v288h288zm608-608l92-92q28-28
            28-68t-28-68l-152-152q-28-28-68-28t-68 28l-92 92zm384-384v960q0
            119-84.5 203.5t-203.5 84.5h-960q-119
            0-203.5-84.5t-84.5-203.5v-960q0-119 84.5-203.5t203.5-84.5h960q119 0
            203.5 84.5t84.5 203.5z"
            fill="#fff"
          />
        </svg>
        Apply
      </span>
    </a>
    {#if job.cdlPhone && !settings.hidePhone}
      <a
        class="JobCard-actionCall"
        href="tel:{job.cdlPhone}"
        style={settings.useBranding
          ? `background-color: ${job.secondaryColor}`
          : ''}
      >
        <span>
          <svg
            class="fa"
            width="20"
            height="20"
            viewBox="0 0 1792 1792"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1408
              1193q0-11-2-16t-18-16.5-40.5-25-47.5-26.5-45.5-25-28.5-15q-5-3-19-13t-25-15-21-5q-15
              0-36.5 20.5t-39.5 45-38.5 45-33.5 20.5q-7
              0-16.5-3.5t-15.5-6.5-17-9.5-14-8.5q-99-55-170-126.5t-127-170.5q-2-3-8.5-14t-9.5-17-6.5-15.5-3.5-16.5q0-13
              20.5-33.5t45-38.5 45-39.5
              20.5-36.5q0-10-5-21t-15-25-13-19q-3-6-15-28.5t-25-45.5-26.5-47.5-25-40.5-16.5-18-16-2q-48
              0-101 22-46 21-80 94.5t-34 130.5q0 16 2.5 34t5 30.5 9 33 10 29.5
              12.5 33 11 30q60 164 216.5 320.5t320.5 216.5q6 2 30 11t33 12.5
              29.5 10 33 9 30.5 5 34 2.5q57 0 130.5-34t94.5-80q22-53
              22-101zm256-777v960q0 119-84.5 203.5t-203.5 84.5h-960q-119
              0-203.5-84.5t-84.5-203.5v-960q0-119 84.5-203.5t203.5-84.5h960q119
              0 203.5 84.5t84.5 203.5z"
              fill="#fff"
            />
          </svg>
          Call
        </span>
      </a>
    {/if}
  </div>
{/if}

<style src="./JobCardAction.scss">
</style>
