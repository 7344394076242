import _ from 'lodash';

const tools = {};

export default tools;

export const loadScript = (tools.loadScript = function(src, keyOnWindow) {
  const self = loadScript;

  self.promises = self.promises || {};

  if (self.promises[src]) return self.promises[src];

  if (keyOnWindow && window[keyOnWindow]) {
    return Promise.resolve(window[keyOnWindow]);
  }

  return (self.promises[src] = new Promise((resolve, reject) => {
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = src;
    s.onload = () => {
      if (!keyOnWindow) {
        return resolve();
      }

      let count;

      const int = setInterval(() => {
        if (window[keyOnWindow]) {
          resolve(window[keyOnWindow]);
          clearInterval(int);
        } else if (++count > 10) {
          clearInterval(int);
          reject();
        }
      }, 100);
    };

    s.addEventListener('error', () => {
      reject();
    });

    document.querySelector('head').appendChild(s);
  }));
});

export const loadStyle = (tools.loadStyle = function(src) {
  const self = loadStyle;

  self.promises = self.promises || {};

  if (self.promises[src]) return self.promises[src];

  return (self.promises[src] = new Promise((resolve, reject) => {
    fetch(src)
      .then(res => res.text())
      .then(
        s => {
          resolve(s);
        },
        e => {
          reject(e);
        }
      );
  }));
});

export const formatComma = (tools.formatComma = function(n, r) {
  if (r || r === 0) {
    n = _.round(parseFloat(n), r);
  }
  var str = String(n).replace(/[^0-9.]/g, ''),
    d = Number(str) || 0;

  if (n < 0) d *= -1;

  return String(d).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
});
