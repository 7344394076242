<svelte:options tag="cdlc-jobboard" />

<script>
  import { onMount, afterUpdate } from 'svelte';
  // import Waypoint from 'svelte-waypoint';
  import _ from 'lodash';
  import debug from 'debug';
  import { t } from '../../tools/translate';
  export let state;
  export let company_id = '';
  export let view_all_button_style = '';
  export let nav_to_intelli = false;
  export let default_intelli_app = '';

  const log = debug('cdlc:Jobboard');
  const open = true;
  let tab = 'about',
    nav,
    loadingJobs,
    showAllJobs = false,
    applyJob = {},
    prevJobs = [],
    zip,
    lastState,
    lastRun = {},
    jobsSchema,
    jobs;

  $: if (zip) {
    console.log('search');
    loadJobs();
  }

  onMount(async () => {
    loadJobs();
  });
  
  const loadJobs = _.debounce(async () => {
    log('jobs', company_id, loadingJobs);
    loadingJobs = true;
    if (!company_id) {
      return loadingJobs = false;
    }
    jobs = [];

    const queryOne =
      `company=${company_id}` +
      `&limit=100` +
      `&archived=false` +
      (zip
        ? `&hiringZips=${zip}`
        : state
        ? `&state=${state}`
        : '');

    const queryOneJobs = await fetch(
      'https://dedicatedjobs.cdllife.com/api/jobs?' + queryOne
    );
    const jbs = await queryOneJobs.json();

    lastState = state;
    jobs = [...jbs.data];
    loadingJobs = false;
  }, 500);

  function handleAnchorClick(event) {
    try {
      event.preventDefault();
    } catch (e) {}
    
    if (nav_to_intelli) {
      if (_.get(event, 'applicationUrl')) {
        window.open(event.applicationUrl, '_blank');  
      } else if (default_intelli_app) {
        window.open(default_intelli_app, '_blank');
      }
    }
    
    const link = event.currentTarget;
    const anchorId = new URL(link.href).hash.replace('#', '');
    const anchor = document.getElementById(anchorId);
    window.scrollTo({
      top: _.get(anchor, 'offsetTop', 0),
      behavior: 'smooth',
    });
  }
</script>



{#if open}

<!-- <SchemaJson json={jobsSchema} /> -->
<div class="CDLCJobboard" id="jobs">
  <div class="CDLCJobboard-search">
    <form
      class="CDLCJobboard-search-form"
      action="javascript:;"
    >
      <div class="CDLCJobboard-search-icon">
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="search-icon"><circle fill="none" stroke="#000" stroke-width="1.1" cx="9" cy="9" r="7"></circle><path fill="none" stroke="#000" stroke-width="1.1" d="M14,14 L18,18 L14,14 Z"></path></svg>
      </div>
      <input
        class="CDLCJobboard-search-input"
        type="search"
        bind:value={zip}
        placeholder="Zip Code"
      />
    </form>
  </div>

  <div id="jobs" class="CDLCJobboard-jobs">
    {#if loadingJobs}
      <div class="CDLCJobboard-jobs-loading">
        <svg width="24" height="24" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><style>.spinner_V8m1{transform-origin:center;animation:spinner_zKoa 2s linear infinite}.spinner_V8m1 circle{stroke-linecap:round;animation:spinner_YpZS 1.5s ease-in-out infinite}@keyframes spinner_zKoa{100%{transform:rotate(360deg)}}@keyframes spinner_YpZS{0%{stroke-dasharray:0 150;stroke-dashoffset:0}47.5%{stroke-dasharray:42 150;stroke-dashoffset:-16}95%,100%{stroke-dasharray:42 150;stroke-dashoffset:-59}}</style><g class="spinner_V8m1"><circle cx="12" cy="12" r="9.5" fill="none" stroke-width="3"></circle></g></svg>
      </div>
    {:else}
      {#if jobs && jobs.length}
        <div class="CDLCJobboard-jobs-jobs">
          {#each jobs as job, index}
            {#if index < 3}
              <div class="CDLCJobboard-jobs-job">
                <cdlc-job-card
                  
                  on:apply={() => {
                    handleAnchorClick({
                      currentTarget: {
                        href: location.origin + '/jobs#applyForm',
                      },
                      applicationUrl: _.get(job, 'applicationUrl')                    
                    });
                  }}
                  {job}
                  settings={{
                    viewJobButtons: false,
                    useBranding: true,
                    companyLogo: false,
                    showJobInfoItems: false,
                  }}
                />
              </div>
            {:else if showAllJobs}
              <div class="CDLCJobboard-jobs-job">
                <cdlc-job-card
                  on:apply={() => {
                    handleAnchorClick({
                      currentTarget: {
                        href: location.origin + '/jobs#applyForm',
                      },
                      applicationUrl: _.get(job, 'applicationUrl')
                    });
                  }}
                  {job}
                  settings={{
                    viewJobButtons: false,
                    useBranding: true,
                    companyLogo: false,
                    showJobInfoItems: false,
                  }}
                />
              </div>
            {/if}
          {/each}
        </div>
        {#if jobs.length > 3 && !showAllJobs}
          <div class="CDLCJobboard-jobs-viewMore">
            <button
              style={view_all_button_style}
              on:click={() => {
                showAllJobs = true;
              }}
            >
              View All Jobs ({jobs.length})
            </button>
          </div>
          <br />
          <br />
        {/if}
      {:else}
        <p class="CDLCJobboard-jobs-noJobs">No jobs found</p>
      {/if}
    {/if}
  </div>
  <br /> 
</div>
{:else}
  <div>NOT OPEN</div>
{/if}

<style src="./Jobboard.scss">
</style>
