import _ from 'lodash';

let presets = {};

presets.diesel = d => {
  const endorsements = [];

  _.each(d, (v, k) => {
    if (v === undefined) {
      delete d[k];
      return;
    }

    if (/certification/.test(k) && v instanceof Array) {
      _.each(v, x => {
        endorsements.push(x);
      });
      delete d[k];
    }
  });

  d.endorsements = endorsements;

  return d;
};

presets.cdl = d => {
  d.trailerTypes = d.trailerTypes || [];
  if (d.mostCommonFreight) {
    d.trailerTypes.push(d.mostCommonFreight);
    d.trailerTypes = _.uniq(d.trailerTypes);
  }
};

presets.liveTruckingLong = d => {
  d.trailerTypes = d.trailerTypes || [];
  if (d.mostCommonFreight) {
    d.trailerTypes.push(d.mostCommonFreight);
    d.trailerTypes = _.uniq(d.trailerTypes);
  }
};

presets.liveTruckingMulti = d => {
  d.trailerTypes = d.trailerTypes || [];
  if (d.mostCommonFreight) {
    d.trailerTypes.push(d.mostCommonFreight);
    d.trailerTypes = _.uniq(d.trailerTypes);
  }
};

presets.liveTruckingMobile = d => {
  d.trailerTypes = d.trailerTypes || [];
  if (d.mostCommonFreight) {
    d.trailerTypes.push(d.mostCommonFreight);
    d.trailerTypes = _.uniq(d.trailerTypes);
  }
};

export default presets;
