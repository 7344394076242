<svelte:options tag="cdlc-short-form-custom-questions" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import { createEventDispatcher } from 'svelte';
  import { get_current_component } from 'svelte/internal';
  import { LiveChatEventEmitter } from '../LiveChat/LiveChat.svelte';
  import backend from './ShortFormCustomQuestions.backend';

  const log = debug('cdlc:ShortFormCustomQuestions'),
    svelteDispatch = createEventDispatcher(),
    component = get_current_component(),
    dispatch = (name, detail) => {
      svelteDispatch(name, detail);
      component.dispatchEvent &&
        component.dispatchEvent(new CustomEvent(name, { detail }));
    };

  export let url = '';
  export let referral_type = 'jobBoard';
  export let job;
  export let client;
  export let dob = true;
  export let merge_details = {};
  export let query_param_mapper = d => d;

  $: parsedJob = tryParse(job);
  $: parsedCompany = tryParse(client);

  setTimeout(() => {
    log('job', parsedJob);
    log('client', parsedCompany);
    log('referral', referral_type);
  });

  $: sections = [customQuestionSection(parsedCompany, parsedJob)].filter(
    d => d
  );

  function customQuestionSection(client, job) {
    const section = {
      title: '',
      questions: [],
    };

    _.each((client && client.applicationCustomQuestions) || {}, (q, k) => {
      appendQuestion(client, '', q, k);
    });

    _.each((job && job.applicationCustomQuestions) || {}, (q, k) => {
      appendQuestion(job, 'job', q, k);
    });

    log('job questions', job && job.applicationCustomQuestions);
    log('client questions', client && client.applicationCustomQuestions);
    log('set questions', section.questions);
    log({ job, client });

    if (section.questions.length) return section;

    function appendQuestion(obj, path, q, key) {
      if (q) {
        let options = _.get(
          obj,
          `applicationCustomQuestionConfig.${key}.options`
        );

        if (!options || !options.length) {
          options = [
            {
              value: 'yes',
              title: 'Yes',
            },
            {
              value: 'no',
              title: 'No',
            },
          ];
        }

        section.questions.push({
          label: q,
          type:
            _.get(obj, `applicationCustomQuestionConfig.${key}.questionType`) ||
            'select',
          key: 'info.' + path + key,
          validator: 'required',
          options: options.map(o => {
            return {
              title: o.title || o.value,
              value: o.value || o.title,
            };
          }),
        });
      }
    }
  }

  function tryParse(d) {
    try {
      return JSON.parse(d);
    } catch (e) {
      return d || {};
    }
  }

  function handleCustomQuestionsOnLead(client, job, lead) {
    const questions = (client && client.applicationCustomQuestions) || {};

    log('handleCustomQuestionsOnLead', { questions, lead });

    _.each((client && client.applicationCustomQuestions) || {}, (q, k) => {
      appendQuestion(client, '', q, k);
    });

    _.each((job && job.applicationCustomQuestions) || {}, (q, k) => {
      appendQuestion(job, 'job', q, k);
    });

    _.each(questions, (question, k) => {});

    function appendQuestion(obj, path, question, k) {
      if (!question) return;
      if (!String(question || '').trim()) return;

      const answer = _.get(lead, `info.${path}${k}`);

      if (question && answer) {
        lead.info.customQuestions = lead.info.customQuestions || [];
        lead.info.customQuestions.push({
          answer,
          question,
        });
        delete lead.info[k];
      }
    }
  }
</script>

<cdlc-lead-form
  has_terms={false}
  store_results_key="cdlc-lead-form-custom-questions"
  default_query_param_key="ai"
  {sections}
  request={() => {
    return new Promise((resolve, reject) => {
      resolve();
    });
  }}
  {query_param_mapper}
  on:presubmit={d => {
    log('lead', d.detail);
    handleCustomQuestionsOnLead(parsedCompany, parsedJob, d.detail);
  }}
  on:submit={d => {
    log('submitted', d && d.detail);
    dispatch('submitted', d && d.detail);
  }}
  on:progress={d => {
    log('progress', d && d.detail);
    dispatch('progress', d && d.detail);
  }}
/>

<style src="./ShortFormCustomQuestions.scss">
</style>
