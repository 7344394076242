<script>
  import debug from 'debug';
  import _ from 'lodash';
  import moment from 'moment';
  import { createEventDispatcher, onMount, tick } from 'svelte';
  import { get_current_component } from 'svelte/internal';
  import jobTools from '../../../../tools/jobTools/tools.js';
  import datatypes from '../../../../tools/jobTools/datatypes.js';

  const log = debug('cdlc:JobCardMap');

  export let dispatch = () => {};
  export let job;
  export let settings = {};

  const buttonPresets = {
    pencil: `
      <svg
        height="16"
        tooltip="Edit Job"
        viewBox="0 0 1792 1792"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M491 1536l91-91-235-235-91
          91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7
          17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832
          832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38
          90-38 53 0 91 38l235 234q37 39 37 91z" />
      </svg>
    `,
    open: `
          <svg
            height="16"
            viewBox="0 0 1792 1792"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1792 640q0 26-19 45l-512 512q-19 19-45
              19t-45-19-19-45v-256h-224q-98 0-175.5 6t-154 21.5-133 42.5-105.5
              69.5-80 101-48.5 138.5-17.5 181q0 55 5 123 0 6 2.5 23.5t2.5 26.5q0
              15-8.5 25t-23.5 10q-16
              0-28-17-7-9-13-22t-13.5-30-10.5-24q-127-285-127-451 0-199 53-333
              162-403 875-403h224v-256q0-26 19-45t45-19 45 19l512 512q19 19 19
              45z" />
          </svg>
      `,
  };

  onMount(async () => {
    await tick();
    log('job', job);
  });

  function htmlDecode(input) {
    var e = document.createElement('textarea');
    e.innerHTML = input;
    // handle case of empty input
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  }
</script>

<style src="./JobCardButtons.scss">

</style>

<svelte:options tag="cdlc-job-card-buttons" />
{#if job}
  <div class="JobCard-imgWrap">
    {#if settings.showXmlButtons && job.cardType === 'xml'}
      {#if jobTools.hasXMLMetadata}
        <div
          class="XMLBadge XMLBadge--clickable"
          on:click={() => {
            dispatch('xmlMetadata');
          }}>
          XML
          <span class="XMLBadge-divider">|</span>
          <span
            class="XMLBadge-metadata"
            on:click={() => {
              dispatch('xmlMetadata');
            }}>
            Metadata
          </span>
          {#if job.metadata.landingPageURL}
            <span class="XMLBadge-divider">|</span>
            <span class="XMLBadge-link">Landing Page</span>
          {/if}
        </div>
      {:else}
        <div class="XMLBadge">XML</div>
      {/if}
    {/if}

    {#if settings.actionButtons && settings.actionButtons.length}
      <div class="JobCard-buttonsWrap">
        {#each settings.actionButtons.reverse() as button}
          <a
            href="javascript:;"
            on:click={() => {
              dispatch(button.event, job);
            }}>
            {#if button.icon}
              {@html buttonPresets[button.icon] || ''}
            {:else if button.html}
              {@html htmlDecode(button.html)}
            {:else}
              <svg>
                <path />
              </svg>
            {/if}
          </a>
        {/each}
      </div>
    {/if}
  </div>
{/if}
