<svelte:options tag="cdlc-mobile-post-footer" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import { onMount, tick } from 'svelte';
  import { get_current_component } from 'svelte/internal';

  const log = debug('cdlc:MobilePostFooter');

  export let dispatch = () => {};
  export let post;
  export let user_engagements = {
    liked: false,
  };

  $: likes = _.get(post, 'stats.likes', 0);
  $: comments = _.get(post, 'stats.comments', 0);
  $: shares = _.get(post, 'stats.shares', 0);
</script>

{#if post}
  <div class="MobilePostFooter">
    <div
      class="MobilePostFooter-item MobilePostFooter-item--likes"
      on:click={() => {
        dispatch('footer-like');
      }}
    >
      {#if user_engagements.liked}
        <div class="MobilePostFooter-item-icon">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="#2E5FF6"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_4909_1527)">
              <path
                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                fill="url(#paint0_linear_4909_1527)"
              />
              <path
                d="M5.34002 6.63997H3.94002C3.69503 6.63997 3.52002 6.83248 3.52002 7.05997V11.26C3.52002 11.505 3.69503 11.68 3.94002 11.68H5.34002C5.56751 11.68 5.76002 11.505 5.76002 11.26V7.05997C5.76002 6.83248 5.56751 6.63997 5.34002 6.63997ZM4.64002 10.98C4.39503 10.98 4.22002 10.805 4.22002 10.56C4.22002 10.3325 4.39503 10.14 4.64002 10.14C4.86751 10.14 5.06002 10.3325 5.06002 10.56C5.06002 10.805 4.86751 10.98 4.64002 10.98ZM10.24 4.15498C10.24 2.85997 9.40002 2.71997 8.98002 2.71997C8.61253 2.71997 8.45503 3.41997 8.38501 3.73498C8.28002 4.11997 8.19253 4.50496 7.93 4.76746C7.37 5.34496 7.07253 6.06247 6.37253 6.74496C6.33752 6.79747 6.32002 6.84995 6.32002 6.90247V10.6475C6.32002 10.7525 6.40751 10.84 6.51253 10.8575C6.79253 10.8575 7.16002 11.015 7.44002 11.1375C8.00002 11.3825 8.68252 11.68 9.52252 11.68H9.57503C10.3275 11.68 11.22 11.68 11.57 11.1725C11.7275 10.9625 11.7625 10.7 11.675 10.385C11.9725 10.0875 12.1125 9.52746 11.9725 9.07248C12.27 8.66999 12.305 8.09248 12.13 7.68995C12.34 7.47997 12.48 7.14746 12.4625 6.83248C12.4625 6.28995 12.0075 5.79997 11.43 5.79997H9.64501C9.78501 5.30999 10.24 4.88995 10.24 4.15498Z"
                fill="white"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_4909_1527"
                x1="8"
                y1="0"
                x2="8"
                y2="16"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#3880FF" />
                <stop offset="1" stop-color="#2E5FF6" />
              </linearGradient>
              <clipPath id="clip0_4909_1527">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      {:else}
        <div class="MobilePostFooter-item-icon">
          <svg
            width="15"
            height="17"
            viewBox="0 0 15 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.5625 9.46875C14.6875 10.1875 14.5938 10.875 14.2812 11.4375C14.375 12.1875 14.1562 12.9688 13.7188 13.5312C13.6875 15.2812 12.625 16.5 10.2188 16.5C10 16.5 9.75 16.5 9.5 16.5C6.3125 16.5 5.34375 15.3125 3.9375 15.2812C3.84375 15.6875 3.4375 16 3 16H1C0.4375 16 0 15.5625 0 15V7.5C0 6.96875 0.4375 6.5 1 6.5H4.0625C4.65625 6 5.5 4.625 6.21875 3.90625C6.65625 3.46875 6.53125 0.5 8.46875 0.5C10.25 0.5 11.4375 1.5 11.4375 3.78125C11.4375 4.375 11.3125 4.84375 11.1562 5.25H12.3125C13.8125 5.25 15 6.53125 15 7.90625C15 8.5 14.8438 9 14.5625 9.46875ZM12.625 11.1562C13.3125 10.5312 13.2188 9.5625 12.7812 9.09375C13.0938 9.09375 13.5 8.5 13.5 7.9375C13.4688 7.34375 12.9688 6.75 12.3125 6.75H9.0625C9.0625 5.5625 9.9375 5 9.9375 3.78125C9.9375 3.03125 9.9375 2 8.46875 2C7.875 2.59375 8.15625 4.09375 7.28125 4.96875C6.4375 5.8125 5.21875 8 4.3125 8H4V13.8438C5.65625 13.8438 7.125 15 9.34375 15H10.5312C11.625 15 12.4375 14.4688 12.1875 12.9688C12.6562 12.6875 13.0312 11.8125 12.625 11.1562ZM2.75 14C2.75 13.5938 2.40625 13.25 2 13.25C1.5625 13.25 1.25 13.5938 1.25 14C1.25 14.4375 1.5625 14.75 2 14.75C2.40625 14.75 2.75 14.4375 2.75 14Z"
              fill="#73767F"
            />
          </svg>
        </div>
      {/if}

      {#if likes > 0}
        <div class="MobilePostFooter-item-text" class:MobilePostFooter-liked-color={user_engagements.liked}>
          {likes}
        </div>
      {:else}
        <div class="MobilePostFooter-item-text">Like</div>
      {/if}
    </div>

    <div
      class="MobilePostFooter-item MobilePostFooter-item--comments"
      on:click={() => {
        dispatch('footer-comments');
      }}
    >
      <div class="MobilePostFooter-item-icon">
        <svg
          width="17"
          height="15"
          viewBox="0 0 17 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 0.5C13.4062 0.5 17 3.4375 17 7C17 10.5938 13.4062 13.5 9 13.5C7.96875 13.5 7 13.3438 6.09375 13.0625C5.1875 13.7188 3.65625 14.5 1.75 14.5C1.4375 14.5 1.15625 14.3438 1.03125 14.0625C0.9375 13.7812 0.96875 13.4688 1.1875 13.25C1.21875 13.25 2.1875 12.1875 2.625 10.9688C1.59375 9.875 1 8.5 1 7C1 3.4375 4.5625 0.5 9 0.5ZM9 12C12.5625 12 15.5 9.78125 15.5 7C15.5 4.25 12.5625 2 9 2C5.40625 2 2.5 4.25 2.5 7C2.5 8.34375 3.15625 9.34375 3.71875 9.9375L4.375 10.625L4.03125 11.5C3.875 11.9375 3.65625 12.375 3.40625 12.75C4.15625 12.5 4.78125 12.1562 5.21875 11.8438L5.8125 11.4062L6.53125 11.625C7.3125 11.875 8.15625 12 9 12Z"
            fill="#73767F"
          />
        </svg>
      </div>

      {#if comments > 0}
        <div class="MobilePostFooter-item-text">{comments}</div>
      {:else}
        <div class="MobilePostFooter-item-text">Comment</div>
      {/if}
    </div>

    <div
      class="MobilePostFooter-item MobilePostFooter-item--share"
      on:click={() => {
        dispatch('footer-share');
      }}
    >
      <div class="MobilePostFooter-item-icon">  
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.4688 9.25H14.9062C14.7305 9.25 14.625 9.39062 14.625
          9.53125V16.5625C14.625 16.8789 14.3438 17.125 14.0625
          17.125H1.6875C1.37109 17.125 1.125 16.8789 1.125
          16.5625V4.1875C1.125 3.90625 1.37109 3.625 1.6875
          3.625H8.71875C8.85938 3.625 9 3.51953 9 3.34375V2.78125C9 2.64062
          8.85938 2.5 8.71875 2.5H1.6875C0.738281 2.5 0 3.27344 0
          4.1875V16.5625C0 17.5117 0.738281 18.25 1.6875 18.25H14.0625C14.9766
          18.25 15.75 17.5117 15.75 16.5625V9.53125C15.75 9.39062 15.6094 9.25
          15.4688 9.25ZM17.5781 0.25L12.7969 0.285156C12.5508 0.285156 12.375
          0.460938 12.375 0.707031V1.05859C12.375 1.26953 12.5508 1.48047
          12.7969 1.48047L15.9609 1.44531V1.48047L4.60547 12.8359C4.53516
          12.9062 4.46484 13.0469 4.46484 13.1523C4.46484 13.2578 4.53516
          13.3633 4.60547 13.4336L4.81641 13.6445C4.88672 13.7148 4.99219
          13.7852 5.09766 13.7852C5.20312 13.7852 5.34375 13.7148 5.41406
          13.6445L16.7695 2.28906L16.8047 2.32422L16.7695 5.48828C16.7695
          5.69922 16.9805 5.91016 17.1914 5.91016H17.543C17.7891 5.91016
          17.9648 5.69922 17.9648 5.48828L18 0.671875C18 0.460938 17.7891 0.25
          17.5781 0.25Z"
            fill="#73767F"
            stroke="#73767F90"
            stroke-width="1"
          />
        </svg>
      </div>
      <div class="MobilePostFooter-text">Share</div>
    </div>
  </div>

  <!-- <div class="MobilePostFooter"> -->
  <!-- COMMENT COUNT ////////////////////////////////////////////////////// -->
  <!-- <div class="FooterRow-button">
      <span
        on:click={() => {
          dispatch('footer-like');
        }}
      >
        {#if user_engagements.liked}
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="#2E5FF6"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_4301_12283)">
              <path
                d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
                fill="url(#paint0_linear_4301_12283)"
              />
              <path
                d="M6.6749 8.30039H4.9249C4.61866 8.30039 4.3999 8.54103 4.3999 8.82539V14.0754C4.3999 14.3816 4.61866 14.6004 4.9249 14.6004H6.6749C6.95926 14.6004 7.1999 14.3816 7.1999 14.0754V8.82539C7.1999 8.54103 6.95926 8.30039 6.6749 8.30039ZM5.7999 13.7254C5.49366 13.7254 5.2749 13.5066 5.2749 13.2004C5.2749 12.916 5.49366 12.6754 5.7999 12.6754C6.08426 12.6754 6.3249 12.916 6.3249 13.2004C6.3249 13.5066 6.08426 13.7254 5.7999 13.7254ZM12.7999 5.19415C12.7999 3.57539 11.7499 3.40039 11.2249 3.40039C10.7655 3.40039 10.5687 4.27539 10.4811 4.66915C10.3499 5.15039 10.2405 5.63163 9.91238 5.95975C9.21238 6.68163 8.84054 7.57851 7.96554 8.43163C7.92178 8.49727 7.8999 8.56287 7.8999 8.62851V13.3098C7.8999 13.441 8.00926 13.5504 8.14054 13.5723C8.49054 13.5723 8.9499 13.7692 9.2999 13.9223C9.9999 14.2285 10.853 14.6004 11.903 14.6004H11.9687C12.9093 14.6004 14.0249 14.6004 14.4624 13.966C14.6593 13.7035 14.703 13.3754 14.5937 12.9816C14.9655 12.6098 15.1405 11.9098 14.9655 11.341C15.3374 10.8379 15.3811 10.116 15.1624 9.61287C15.4249 9.35039 15.5999 8.93475 15.578 8.54103C15.578 7.86287 15.0093 7.25039 14.2874 7.25039H12.0561C12.2311 6.63791 12.7999 6.11287 12.7999 5.19415Z"
                fill="white"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_4301_12283"
                x1="10"
                y1="0"
                x2="10"
                y2="20"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#3880FF" />
                <stop offset="1" stop-color="#2E5FF6" />
              </linearGradient>
              <clipPath id="clip0_4301_12283">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        {:else}
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            style="transform:translateY(-1px)"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.4375 10.3047C17.7891 9.84766 18 9.25 18 8.54688C17.9648
              7.21094 16.8398 5.98047 15.4336 5.98047H12.9727C13.2539 5.38281
              13.6758 4.60938 13.6758 3.55469C13.6758 1.48047 12.8672 0.25
              10.7578 0.25C9.70312 0.25 9.31641 1.58594 9.14062 2.64062C9
              3.23828 8.89453 3.83594 8.54297 4.1875C7.69922 4.99609 6.46875 7
              5.69531 7.42188C5.625 7.45703 5.51953 7.49219 5.41406
              7.49219C5.20312 7.21094 4.85156 7 4.5 7H1.125C0.492188 7 0 7.52734
              0 8.125V17.125C0 17.7578 0.492188 18.25 1.125 18.25H4.5C5.09766
              18.25 5.625 17.7578 5.625 17.125V16.8438C6.75 16.8438 9.14062
              18.2852 11.8477 18.25C12.0234 18.25 13.1836 18.2852 13.2891
              18.25C15.3633 18.25 16.5234 17.0195 16.4531 15.1211C16.9805
              14.4883 17.2617 13.5742 17.1211 12.7656C17.543 12.0625 17.6484
              11.1133 17.4375 10.3047ZM1.125
              17.125V8.125H4.5V17.125H1.125ZM16.0312 9.98828C16.5938 10.375
              16.5938 12.0625 15.8203 12.4844C16.2773 13.2578 15.8555 14.3477
              15.293 14.6641C15.5742 16.4922 14.625 17.125 13.2891
              17.125C13.1484 17.1602 11.9531 17.125 11.8477 17.125C9.28125
              17.125 7.10156 15.7188 5.625 15.7188V8.54688C6.92578 8.54688
              8.15625 6.15625 9.35156 4.96094C10.4062 3.90625 10.0547 2.11328
              10.7578 1.375C12.5508 1.375 12.5508 2.64062 12.5508
              3.55469C12.5508 5.03125 11.4961 5.69922 11.4961
              7.10547H15.4336C16.2422 7.10547 16.8398 7.84375 16.875
              8.54688C16.875 9.25 16.5938 9.8125 16.0312 9.98828ZM3.65625
              15.4375C3.65625 14.9805 3.26953 14.5938 2.8125 14.5938C2.32031
              14.5938 1.96875 14.9805 1.96875 15.4375C1.96875 15.9297 2.32031
              16.2812 2.8125 16.2812C3.26953 16.2812 3.65625 15.9297 3.65625
              15.4375Z"
              fill="#73767F"
              stroke="#73767F90"
              stroke-width="1"
            />
          </svg>
        {/if}
        <span
          class="MobilePostFooter-text {user_engagements.liked
            ? 'MobilePostLiked'
            : 'MobilePostLike'}"
          >{user_engagements.liked ? 'Liked' : 'Like'}</span
        >
      </span>
    </div> -->

  <!-- <div class="FooterRow-button">
      <span
        on:click={() => {
          dispatch('footer-comments');
        }}
      >
        <svg
          width="19"
          height="17"
          viewBox="0 0 19 17"
          fill="none"
          style="transform:translateY(1px)"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 1.5C14.3242 1.5 17.875 4.27734 17.875 7.6875C17.875 11.0977
            14.3242 13.875 10 13.875C8.98047 13.875 7.99609 13.7344 7.04688
            13.4531L6.51953 13.2773L6.0625 13.5938C5.25391 14.1562 4.02344
            14.8242 2.47656 15C2.89844 14.4727 3.49609 13.5586 3.88281
            12.5391L4.12891 11.8711L3.67188 11.3789C2.65234 10.2891 2.125
            9.02344 2.125 7.6875C2.125 4.27734 5.64062 1.5 10 1.5ZM10
            0.375C5.00781 0.375 1 3.67969 1 7.6875C1 9.375 1.66797 10.9219
            2.82812 12.1523C2.33594 13.5234 1.24609 14.7188 1.21094
            14.7188C0.964844 14.9648 0.929688 15.3164 1.03516 15.6328C1.17578
            15.9492 1.49219 16.125 1.80859 16.125C3.98828 16.125 5.67578 15.2461
            6.73047 14.5078C7.75 14.8242 8.83984 15 10 15C14.957 15 19 11.7305
            19 7.6875C19 3.67969 14.957 0.375 10 0.375Z"
            fill="#73767F"
            stroke="#73767F90"
            stroke-width="1"
          />
        </svg>
        <span class="MobilePostFooter-text">Comment</span>
      </span>
    </div> -->

  <!-- LIKE COMMUNITY AND JOB////////////////////////////////////////////////////// -->
  <!-- <div
      class="FooterRow-button"
      on:click={() => {
        dispatch('footer-share');
      }}
    >
      <span>
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.4688 9.25H14.9062C14.7305 9.25 14.625 9.39062 14.625
            9.53125V16.5625C14.625 16.8789 14.3438 17.125 14.0625
            17.125H1.6875C1.37109 17.125 1.125 16.8789 1.125
            16.5625V4.1875C1.125 3.90625 1.37109 3.625 1.6875
            3.625H8.71875C8.85938 3.625 9 3.51953 9 3.34375V2.78125C9 2.64062
            8.85938 2.5 8.71875 2.5H1.6875C0.738281 2.5 0 3.27344 0
            4.1875V16.5625C0 17.5117 0.738281 18.25 1.6875 18.25H14.0625C14.9766
            18.25 15.75 17.5117 15.75 16.5625V9.53125C15.75 9.39062 15.6094 9.25
            15.4688 9.25ZM17.5781 0.25L12.7969 0.285156C12.5508 0.285156 12.375
            0.460938 12.375 0.707031V1.05859C12.375 1.26953 12.5508 1.48047
            12.7969 1.48047L15.9609 1.44531V1.48047L4.60547 12.8359C4.53516
            12.9062 4.46484 13.0469 4.46484 13.1523C4.46484 13.2578 4.53516
            13.3633 4.60547 13.4336L4.81641 13.6445C4.88672 13.7148 4.99219
            13.7852 5.09766 13.7852C5.20312 13.7852 5.34375 13.7148 5.41406
            13.6445L16.7695 2.28906L16.8047 2.32422L16.7695 5.48828C16.7695
            5.69922 16.9805 5.91016 17.1914 5.91016H17.543C17.7891 5.91016
            17.9648 5.69922 17.9648 5.48828L18 0.671875C18 0.460938 17.7891 0.25
            17.5781 0.25Z"
            fill="#73767F"
            stroke="#73767F90"
            stroke-width="1"
          />
        </svg>
        <span class="MobilePostFooter-text">Share</span>
      </span>
    </div> -->

  <!-- </div> -->
{/if}

<style src="./MobilePostFooter.scss">
</style>
