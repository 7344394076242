import diesel from './presets/diesel';
// allowing for multiple updates
import { cdl } from './presets/cdl';
import {
  liveTruckingMulti,
  liveTruckingLong,
  liveTruckingMobile,
} from './presets/liveTrucking';

export default {
  diesel,
  cdl,
  liveTruckingLong,
  liveTruckingMulti,
  liveTruckingMobile,
};
