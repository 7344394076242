import _ from 'lodash';

export default [
  {
    title: 'Personal Info',
    questions: [
      {
        key: 'firstName',
        label: 'First Name',
        type: 'input',
        inputType: 'text',
        validator: 'required',
      },
      {
        key: 'lastName',
        label: 'Last Name',
        type: 'input',
        inputType: 'text',
        validator: 'required',
      },
      {
        key: 'dob',
        label: 'Date of Birth',
        type: 'input',
        inputType: 'date',
        validator: 'required',
      },
      {
        key: 'email',
        label: 'Email Address',
        type: 'input',
        inputType: 'email',
        validator: 'email',
      },
      {
        key: 'phone',
        label: 'Phone',
        type: 'input',
        inputType: 'tel',
        validator: 'required',
      },
      {
        key: 'zip',
        label: 'Zip Code',
        type: 'input',
        inputType: 'tel',
        validator: 'zipcode',
      },
    ],
  },
  {
    title: 'Qualifications',
    questions: [
      {
        label:
          'Have you obtained a certification of completion for diesel mechanic training from any of the following sources: community colleges, technical institutes, automotive schools or on-the-job training which has led to a successful certification of completion?',
        type: 'select',
        key: 'dieselCertification',
        validator: 'required',
        options: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
      },
      {
        label:
          'How much verifiable experience do you have as a Diesel Technician?',
        type: 'select',
        key: 'experience',
        validator: 'required',
        options: [
          {
            value: 'needtraining',
            title: 'Need Diesel Training',
          },
          {
            value: 'intraining',
            title: 'In Training Now',
          },
          {
            value: 'graduatedtraining',
            title: 'Graduated Training, No Experience Yet',
          },
          {
            value: '1-5_months',
            title: '1-5 Months',
          },
          {
            value: '6-11_months',
            title: '6-11 Months',
          },
          {
            value: '12-23_months',
            title: '12-23 Months',
          },
          {
            value: '2_years',
            title: '2 Years',
          },
          {
            value: '3_years',
            title: '3 Years',
          },
          {
            value: '4_years',
            title: '4 Years',
          },
          {
            value: '5+_years',
            title: '5+ Years',
          },
        ],
      },
      {
        label: 'Have you been professionally certified by the ASE?',
        type: 'select',
        key: 'dieselASECertification',
        validator: 'required',
        options: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
      },
      {
        label:
          'Have you obtained any of the following ASE certifications for Medium/Heavy Duty Trucks?',
        type: 'multiSelect',
        key: 'dieselASECertificationHeavy',
        //#toggle
        logic: d => {
          return d.dieselASECertification === 'yes';
        },
        options: _.map(
          [
            'T1 – Gasoline Engines',
            'T2 – Diesel Engines',
            'T3 – Drive Train',
            'T4 – Brakes',
            'T5 – Suspension & Steering',
            'T6 – Electrical/ Electronic Systems',
            'T7 – Heating, Ventilation & Air Conditioning (HVAC)',
            'T8 – Preventive Maintenance Inspection',
          ],
          d => {
            return {
              title: d,
              value: d.slice(0, 2).toLowerCase(),
            };
          }
        ),
        fullWidth: true,
      },
      {
        label:
          'Have you obtained any of the following ASE certifications for Automobile & Light Trucks?',
        type: 'multiSelect',
        key: 'dieselASECertificationLight',
        logic: d => {
          return d.dieselASECertification === 'yes';
        },
        options: _.map(
          [
            'A1 – Engine Repair',
            'A2 – Automatic Transmission/Transaxle',
            'A3 – Manual Drive Train & Axles',
            'A4 – Suspension & Steering',
            'A5 – Brakes',
            'A6 – Electrical/Electronic Systems',
            'A7 – Heating & Air Conditioning',
            'A8 – Engine Performance',
            'A9 – Light Vehicle Diesel Engines',
          ],
          d => {
            return {
              title: d,
              value: d.slice(0, 2).toLowerCase(),
            };
          }
        ),
        fullWidth: true,
      },
      {
        label:
          'Have you obtained any of the following ASE certifications for School Bus',
        type: 'multiSelect',
        key: 'dieselASECertificationSchoolBus',
        logic: d => {
          return d.dieselASECertification === 'yes';
        },
        options: _.map(
          [
            'S1 – Body Systems & Special Equipment',
            'S2 – Diesel Engines',
            'S3 – Drive Train',
            'S4 – Brakes',
            'S5 – Suspension & Steering',
            'S6 – Electrical/Electronic Systems ',
            'S7 – Air Conditioning Systems & Controls',
          ],
          d => {
            return {
              title: d,
              value: d.slice(0, 2).toLowerCase(),
            };
          }
        ),
        fullWidth: true,
      },
      {
        label:
          'Have you obtained any of the following ASE certifications for Transit Bus',
        type: 'multiSelect',
        key: 'dieselASECertificationTransitBus',
        logic: d => {
          return d.dieselASECertification === 'yes';
        },
        options: _.map(
          [
            'H1 – Compressed Natural Gas (CNG) Engines',
            'H2 – Diesel Engines',
            'H3 – Drive Train',
            'H4 – Brakes',
            'H5 – Suspension & Steering',
            'H6 – Electrical/Electronic Systems',
            'H7 – Heating Ventilation & Air Conditioning (HVAC)',
            'H8 – Preventive Maintenance & Inspection (PMI)',
          ],
          d => {
            return {
              title: d,
              value: d.slice(0, 2).toLowerCase(),
            };
          }
        ),
        fullWidth: true,
      },
      {
        label: 'Which manufacturers do you have certifications for?',
        type: 'multiSelect',
        key: 'dieselOEMCertificationsManufacturers',
        options: _.map(
          [
            'Kenworth',
            'Peterbilt',
            'Volvo',
            'International',
            'Mack',
            'Freightliner',
            'Western Star',
          ],
          d => {
            return {
              title: d,
              value: d.toLowerCase(),
            };
          }
        ),
      },
      {
        label: 'Are you able to supply your own tools?',
        type: 'select',
        key: 'dieselOwnTools',
        validator: 'required',
        options: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
      },
      {
        label: 'Do you have Welding Experience',
        type: 'select',
        key: 'dieselWelding',
        options: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
      },
      {
        label: 'Are you willing to relocate for job offers?',
        type: 'select',
        key: 'dieselRelocate',
        options: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
      },
      {
        label: 'Do you have a valid drivers license?',
        type: 'select',
        key: 'validDriversLicense',
        validator: 'required',
        options: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
      },
      {
        label: 'Do you have a Class A CDL?',
        type: 'select',
        key: 'cdlLicense',
        validator: 'required',
        options: [
          {
            value: true,
            title: 'Yes',
          },
          {
            value: false,
            title: 'No',
          },
        ],
      },
      {
        label: 'Have you received your GED or High School Diploma?',
        type: 'select',
        key: 'completedSecondaryEducation',
        validator: 'required',
        options: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
      },
    ],
  },
  {
    title: 'Additional Questions (optional)',
    questions: [
      {
        label: 'Do you have any military experience?',
        type: 'select',
        key: 'militaryExperience',
        options: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
      },
      {
        label: 'What is the best time to contact you?',
        type: 'select',
        key: 'bestContactTime',
        options: [
          {
            value: 'anytime',
            title: 'Anytime',
          },
          {
            value: 'morning',
            title: 'Morning',
          },
          {
            value: 'afternoon',
            title: 'Afternoon',
          },
          {
            value: 'evening',
            title: 'Evening',
          },
        ],
      },
    ],
  },
];
