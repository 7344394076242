<svelte:options tag="cdlc-hiring-map" />

<script>
  import debug from 'debug';
  import { onMount, createEventDispatcher, setContext } from 'svelte';
  import turf from '@turf/turf';
  import { token, contextKey } from './mapbox.js';
  import tools from '../../tools/index.js';
  import stateAbbreviations from '../../tools/stateAbbreviations.json';
  import _ from 'lodash';
  import * as usaborder from './usaborder.json';
  import axios from 'axios';
  import { get_current_component } from 'svelte/internal';

  const geocoderClass = _.get(window, 'google.maps.Geocoder');
  let geocoder = geocoderClass && new geocoderClass();

  setContext(contextKey, {
    getMap: () => map,
    getMapbox: () => mapbox,
  });

  export let map_data = { hiringStates: [], hiringAreas: [], freeform: {} };
  export let mode = 'otr'; // otr, otr-freeform
  export let opts = {};
  export let center = [-98.5795, 39.8282];
  export let zoom = 3;
  export let area_title = 'operating';

  $: mode = opts.mode || 'otr';
  $: mergedOpts = _.merge({}, opts);

  const log = debug('cdlc:HiringMap'),
    svelteDispatch = createEventDispatcher(),
    component = get_current_component(),
    dispatch = (name, detail) => {
      svelteDispatch(name, detail);
      component.dispatchEvent &&
        component.dispatchEvent(new CustomEvent(name, { detail }));
    };

  let readOnly = false;
  let hideLocationList = false;
  let container;
  let map;
  let Draw;
  let mapData;
  let mapboxStyle;
  let pinMarkers = [];
  let usaFeature = usaborder;
  let mapboxTheme = 'mapbox://styles/mapbox/streets-v11';
  let mapboxOptions;
  let statesGeoJSONURL =
    'https://d2ad6b4ur7yvpq.cloudfront.net/' +
    'naturalearth-3.3.0/ne_110m_admin_1_states_provinces' +
    '.geojson';
  let statesGeoJSON;
  let savingArea = false;
  let minRadius = 10;
  let defaultRadius = 50;
  let selectedRadius = defaultRadius;
  let hoverValue = '';
  let maxRadius = 300;
  let addAreaMethod = 'single';
  let newAreaMulti = {
    text: '',
    radius: defaultRadius,
  };
  let newArea = {
    text: '',
    radius: defaultRadius,
  };
  let hintHover = [
    'How many miles would you like the circle to cover?',
    'Where would you like to place the circle?',
  ];

  onMount(() => {
    setTimeout(() => {
      mapData = map_data;
      opts = opts;

      readOnly = _.get(opts, 'readOnly');
      hideLocationList = _.get(opts, 'hideLocationList', false);
      mapboxTheme = _.get(opts, 'mapboxTheme') || mapboxTheme;
      mapboxOptions = _.get(opts, 'mapboxOptions');
      loadMapbox();
    }, 750);
  });

  $: {
    mapData = map_data;

    try {
      updateStops();
      updateStates();
      updatePins();

      dispatch('map-updated', map_data);
    } catch (e) {}
  }
  $: hoverValue;
  function loadMapbox() {
    // LOAD MAPBOX SCRIPT AND CSS
    Promise.all([
      tools.loadScript(
        'https://api.mapbox.com/mapbox-gl-js/v1.9.1/mapbox-gl.js',
        'mapboxgl'
      ),
      tools.loadStyle(
        'https://api.mapbox.com/mapbox-gl-js/v1.9.1/mapbox-gl.css'
      ),
      tools.loadScript(
        'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.2.2/mapbox-gl-draw.js'
      ),
      geocoder
        ? Promise.resolve()
        : tools.loadScript(
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyCp4aj43tx9151AKGc2ZV8GNKfSbCgq3NE&libraries=geometry,places',
            'google'
          ),
    ]).then(([mapboxgl, css]) => {
      geocoder = geocoder || new google.maps.Geocoder();

      // FORCE MAPBOX CSS
      mapboxStyle.innerHTML = css;

      // ADJUST MAPDATA DEFAULTS
      mapData = _.merge(
        {
          type: 'states',
          updateSrc: true,
          states: [],
          stopsKey: 'stops',
          radiusKey: 'radius',
          job: null,
        },
        mapData
      );

      initMapbox();

      map.on('load', () => {
        createControls();
        setUpMapLayers();
        updateStops();
        updateStates();
        updatePins();

        dispatch('ready');
      });
      return () => {
        map.remove();
      };
    });
  }

  class MapboxGLButtonControl {
    constructor({
      className = '',
      title = '',
      icon = '',
      eventHandler = evtHndlr,
    }) {
      this.className = className;
      this.title = title;
      this.icon = icon;
      this.eventHandler = eventHandler;
    }

    onAdd(map) {
      this.btn = document.createElement('button');
      this.btn.id = 'select-all-button';
      this.btn.className = 'mapboxgl-ctrl-icon' + ' ' + this.className;
      this.btn.style.backgroundImage = this.icon;
      this.btn.style.backgroundRepeat = 'no-repeat';
      this.btn.style.backgroundPosition = 'center';
      this.btn.type = 'button';
      this.btn.textContent = this.title;
      this.btn.onclick = this.eventHandler;

      this.container = document.createElement('div');
      this.container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
      this.container.appendChild(this.btn);

      return this.container;
    }

    onRemove() {
      this._container.parentNode.removeChild(this._container);
      this._map = undefined;
    }
  }

  function initMapbox() {
    mapboxgl.accessToken = token;

    const optionsWithDefaults = Object.assign(
      {
        container,
        style: mapboxTheme,
        center: center,
        bearing: 0,
        pitch: 0,
        zoom: zoom,
      },
      mapboxOptions
    );

    map = new mapboxgl.Map(optionsWithDefaults);
  }

  function createControls() {
    if (mode === 'otr-freeform') {
      Draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: false,
        },
        defaultMode: 'draw_polygon',
      });

      const clearButton = new MapboxGLButtonControl({
        className: 'MapControls-clearButton',
        title: 'REDRAW',
        eventHandler: resetCustomArea,
      });

      map.addControl(new mapboxgl.NavigationControl(), 'bottom-left');
      map.addControl(Draw, 'top-right');
      map.addControl(clearButton, 'top-right');
      map.addControl(new mapboxgl.FullscreenControl(), 'bottom-right');
      container
        .getElementsByClassName('MapControls-clearButton')[0]
        .classList.add('hidden');

      map.getCanvas().style.cursor = 'crosshair';

      if (mapData.freeform.type) {
        mapData.freeform = _.merge(
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
            },
            properties: {},
          },
          mapData.freeform
        );
        Draw.add(mapData.freeform);
        Draw.changeMode('simple_select');
        map.getCanvas().style.cursor = '';
        container
          .getElementsByClassName('MapControls-clearButton')[0]
          .classList.remove('hidden');
      }

      map.on('draw.modechange', function (event) {
        if (event.mode == 'simple_select') {
          createCustomArea();
        } else {
          container
            .getElementsByClassName('MapControls-clearButton')[0]
            .classList.add('hidden');
        }
      });

      map.on('draw.update', updateCustomArea);
      map.on('draw.delete', resetCustomArea);
    } else {
      // CREATE BUTTONS
      const addPinButton = new MapboxGLButtonControl({
        className: 'MapControls-addPin',
        title: 'Add Pin',
        eventHandler: addPin,
      });
      const selectAllButton = new MapboxGLButtonControl({
        className: 'MapControls-selectAll',
        title:
          _.size(mapData.hiringStates) === 49
            ? 'Remove All States'
            : 'Select All States',
        eventHandler: selectAll,
      });

      // ADD BUTTONS TO MAP
      map.addControl(new mapboxgl.NavigationControl(), 'bottom-left');
      map.addControl(addPinButton, 'top-left');
      map.addControl(selectAllButton, 'top-right');
      map.addControl(new mapboxgl.FullscreenControl(), 'bottom-right');

      // MOUSE LISTENERS
      map.on('click', toggleState);

      map.on('mousemove', function (e) {
        var state = activeState(e);

        if (!readOnly) {
          if (state) {
            map.setFilter('state-fills-hover', ['==', 'postal', state]);
            map.getCanvas().style.cursor = 'pointer';
          } else {
            map.setFilter('state-fills-hover', ['any']);
            map.getCanvas().style.cursor = '';
          }
        }
      });
    }
  }

  function setUpMapLayers() {
    map.addSource('states', {
      type: 'geojson',
      data: statesGeoJSONURL,
    });

    if (!statesGeoJSON) {
      _.get(statesGeoJSONURL, function (d) {
        statesGeoJSON = _.indexBy(_.get(d, 'features'), 'properties.postal');
      });
    }

    map.addSource('stops', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });

    map.addLayer({
      id: 'state-fills',
      type: 'fill',
      source: 'states',
      layout: {},
      filter: ['all', ['!=', 'postal', 'AK'], ['!=', 'postal', 'HI']],
      paint: {
        // jscs:disable
        'fill-color': '#2e5ff6',
        'fill-opacity': readOnly ? 0 : 0.3,
        // jscs:enable
      },
    });

    map.addLayer(
      {
        id: 'state-fills-active',
        type: 'fill',
        source: 'states',
        layout: {},
        filter: ['all', ['!=', 'postal', 'AK'], ['!=', 'postal', 'HI']],
        paint: {
          // jscs:disable
          'fill-color': readOnly ? '#4B71E5' : '#2e5ff6',
          'fill-opacity': readOnly ? 1 : 0.7,
          // jscs:enable
        },
        filter: stateFilter(mapData.hiringStates),
      },
      readOnly ? 'state-label' : null
    );

    map.addLayer(
      {
        id: 'state-borders',
        type: 'line',
        source: 'states',
        layout: {},
        filter: ['all', ['!=', 'postal', 'AK'], ['!=', 'postal', 'HI']],
        paint: {
          // jscs:disable
          'line-color': readOnly ? '#999' : '#2e5ff6',
          'line-width': 1,
          // jscs:enable
        },
      },
      readOnly ? 'state-label' : null
    );

    map.addLayer({
      id: 'state-fills-hover',
      type: 'line',
      source: 'states',
      layout: {},
      filter: ['all', ['!=', 'postal', 'AK'], ['!=', 'postal', 'HI']],
      paint: {
        // jscs:disable
        'line-color': '#02A161',
        'line-width': 3,
        // jscs:enable
      },
      filter: ['any'],
    });

    map.setPaintProperty('state-label', 'text-halo-color', 'rgba(0, 0, 0, 0)');

    map.addLayer(
      {
        id: 'stops',
        source: 'stops',
        type: 'fill',
        paint: {
          'fill-opacity': readOnly ? 1 : 0.4,
          'fill-color': readOnly ? '#4B71E5' : '#8b51f5',
          'fill-outline-color': readOnly ? '#4B71E5' : '#8b51f5',
        },
      },
      readOnly ? 'state-borders' : null
    );

    map.addLayer(
      {
        id: 'stops-line',
        source: 'stops',
        type: 'line',
        paint: {
          'line-color': readOnly ? '#4B71E5' : '#8b51f5',
          'line-width': 2,
        },
      },
      readOnly ? 'state-borders' : null
    );
  }

  function stateFilter(s) {
    var x = ['any'];

    _.each(s, function (d) {
      x.push(['==', 'postal', d]);
    });

    return ['all', ['!=', 'postal', 'HI'], ['!=', 'postal', 'AK'], x];
  }

  function activeState(e) {
    var features = map.queryRenderedFeatures(e.point, {
      layers: ['state-fills'],
    });

    return _.get(features, '0.properties.postal');
  }

  function selectAll(e) {
    let currentStates = [];

    if (this.textContent === 'Select All States') {
      currentStates = [
        'AL',
        'AR',
        'AZ',
        'CA',
        'CO',
        'CT',
        'DC',
        'DE',
        'FL',
        'GA',
        'IA',
        'ID',
        'IL',
        'IN',
        'KS',
        'KY',
        'LA',
        'MA',
        'MD',
        'ME',
        'MI',
        'MN',
        'MO',
        'MS',
        'MT',
        'NC',
        'ND',
        'NE',
        'NH',
        'NJ',
        'NM',
        'NV',
        'NY',
        'OH',
        'OK',
        'OR',
        'PA',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VA',
        'VT',
        'WA',
        'WI',
        'WV',
        'WY',
      ];

      this.textContent = 'Remove All States';
    } else {
      currentStates = [];
      this.textContent = 'Select All States';
    }

    map.setFilter('state-fills-active', stateFilter(currentStates));
    mapData.hiringStates = currentStates;

    dispatch('map-updated', map_data);

    // Center map
    map.setZoom(zoom);
    map.flyTo({ center: center });
  }

  function toggleState(e) {
    let state = activeState(e);
    let currentStates = mapData.hiringStates;

    if (!state || readOnly) return;

    if (_.includes(currentStates, state)) {
      _.pull(currentStates, state);
    } else {
      currentStates = [...currentStates, state];
    }

    mapData.hiringStates = currentStates;

    dispatch('map-updated', map_data);

    updateStates();
  }

  function updateStates() {
    map.setFilter('state-fills-active', stateFilter(mapData.hiringStates));
  }

  function updateStops() {
    const source = map.getSource('stops');

    if (!source) {
      return;
    }

    source.setData({
      type: 'FeatureCollection',
      features: _.map(_.get(mapData, 'hiringAreas'), s => {
        return {
          type: 'Feature',
          properties: null,
          geometry: {
            type: 'Polygon',
            coordinates: [circleGeoJson(s.latitude, s.longitude, s.radius)],
          },
        };
      }),
    });

    mapData = mapData;
  }

  function updatePins() {
    const pins = getPins();

    // remove all markers so duplicates aren't created on the other points
    _.each(pinMarkers, marker => {
      marker.remove();
    });

    _.each(pins, pin => {
      const markerId = _.uniqueId('pin');
      let el = document.createElement('div');

      el.innerHTML = `
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	        width="36px" height="36px" viewBox="0 0 425.963 425.963" style="enable-background:new 0 0 425.963 425.963;" xml:space="preserve">
          <g>
	          <path style="fill: #f6852e" d="M213.285,0h-0.608C139.114,0,79.268,59.826,79.268,133.361c0,48.202,21.952,111.817,65.246,189.081
		          c32.098,57.281,64.646,101.152,64.972,101.588c0.906,1.217,2.334,1.934,3.847,1.934c0.043,0,0.087,0,0.13-0.002
		          c1.561-0.043,3.002-0.842,3.868-2.143c0.321-0.486,32.637-49.287,64.517-108.976c43.03-80.563,64.848-141.624,64.848-181.482
		          C346.693,59.825,286.846,0,213.285,0z M274.865,136.62c0,34.124-27.761,61.884-61.885,61.884
		          c-34.123,0-61.884-27.761-61.884-61.884s27.761-61.884,61.884-61.884C247.104,74.736,274.865,102.497,274.865,136.62z"/>
          </g>
        </svg>
      `;

      el.className = `PinMarker ${self.pinMarkerUUID}`;
      el.setAttribute('id', markerId);

      Object.defineProperty(pin, 'markerId', {
        enumerable: false,
        value: markerId,
        configurable: true,
      });

      const marker = new mapboxgl.Marker(el, {
        draggable: true,
        anchor: 'bottom',
      })
        .setLngLat([pin.longitude, pin.latitude])
        .addTo(map);

      let loading = false;

      marker.on('dragend', onDragEnd);

      pinMarkers.push(marker);

      updateStops();

      function onDragEnd(event) {
        const lng = _.get(event, 'target._lngLat.lng'),
          lat = _.get(event, 'target._lngLat.lat'),
          eventId = _.get(event, 'target._element.id');

        reverseGeocode(lat, lng).then(cityState => {
          const pinIndex = _.findIndex(mapData.hiringAreas, pin => {
            return pin.markerId === eventId;
          });

          const oldHiringArea = _.get(mapData.hiringAreas, pinIndex);

          oldHiringArea.latitude = lat;
          oldHiringArea.longitude = lng;
          oldHiringArea.cityState = cityState;
          oldHiringArea.text = cityState;
          oldHiringArea.isPin = true;

          updateStops();
        });
      }
    });
  }

  function getPins() {
    return _.filter(_.get(mapData, 'hiringAreas'), stop => {
      return !!_.get(stop, 'isPin');
    });
  }

  function addArea(e, info) {
    savingArea = true;
    getLatLong(info.text, (lat, lng, res) => {
      mapData.hiringAreas.push({
        latitude: lat,
        longitude: lng,
        cityState: res ? cityState(res.address_components) : '',
        text: _.get(info, 'text'),
        radius: _.max([_.min([_.get(info, 'radius'), maxRadius]), minRadius]),
      });
      mapData.hiringAreas = mapData.hiringAreas;

      _.set(newArea, 'text', '');
      _.set(newArea, 'radius', defaultRadius);

      newArea = newArea;

      updateStops();

      savingArea = false;
    });
  }

  function addAreaMulti(e, info) {
    const text = (info.text || '')
      .trim()
      .split('\n')
      .map(d => {
        return d.trim();
      });

    const fetchData = {
      method: 'POST',
      body: JSON.stringify({ locations: text }),
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    savingArea = true;
    fetch(
      'https://dedicatedjobs.cdllife.com/api/hiringZips/batchGeocode',
      fetchData
    )
      .then(res => {
        return res.json();
      })
      .then(
        json => {
          if (_.get(json, 'error')) {
            console.error(json.error);
          }

          _.each(_.get(json, 'data'), area => {
            if (!area.latitude || !area.longitude) {
              return;
            }

            let getRadius = String(area.text || '')
              .trim()
              .match(/\s+([0-9]+)$/);

            if (getRadius && getRadius[1]) {
              area.text = area.text.replace(getRadius[0], '');
              getRadius = parseFloat(getRadius[1]);
            }

            mapData.hiringAreas.push(
              _.merge(area, {
                radius:
                  getRadius ||
                  _.max([_.min([_.get(info, 'radius'), maxRadius]), minRadius]),
              })
            );
          });

          _.set(newAreaMulti, 'text', '');
          _.set(newAreaMulti, 'radius', defaultRadius);

          newAreaMulti = newAreaMulti;

          updateStops();

          savingArea = false;
        },
        e => {
          alert('An unknown error occured');
        }
      );
  }

  function removeArea(i) {
    mapData.hiringAreas.splice(i, 1);
    mapData.hiringAreas = mapData.hiringAreas;
    updateStops();
  }

  function clearAll() {
    mapData.hiringAreas = [];
    mapData.hiringAreas = mapData.hiringAreas;
    updateStops();
  }

  function addPin(event) {
    let location;
    let center = map.getCenter();

    event.stopImmediatePropagation();

    map.getCanvas().style.cursor = 'pointer'; // change Mapbox cursor

    reverseGeocode(center.lat, center.lng)
      .then(cityState => {
        mapData.hiringAreas = [
          ...mapData.hiringAreas,
          {
            cityState: cityState,
            isPin: true,
            showPin: true,
            longitude: center.lng,
            latitude: center.lat,
            radius: defaultRadius,
            text: cityState,
          },
        ];

        // clean up
        map.getCanvas().style.cursor = '';

        updatePins();
      })
      .catch(err => {
        log(err);
      });
  }
  function createCustomArea(e) {
    map.getCanvas().style.cursor = '';

    let feature = _.get(Draw.getAll(), 'features.0');
    let featureId = feature && feature.id;

    container
      .getElementsByClassName('MapControls-clearButton')[0]
      .classList.remove('hidden');

    try {
      feature = turf.intersect(
        turf.unkinkPolygon(feature).features[0],
        usaFeature
      );
    } catch (e) {
      console.log('caught intersect error', e);
      feature = _.get(Draw.getAll(), 'features.0');
    }

    if (feature) {
      try {
        let coordinates = _.get(feature, 'geometry.coordinates'),
          featureType = _.get(feature, 'geometry.type'),
          numCoordinates = 0;

        if (featureType === 'MultiPolygon') {
          _.each(coordinates, c => {
            numCoordinates += _.size(_.first(c));
          });
        } else {
          numCoordinates = _.size(_.first(coordinates));
        }

        if (numCoordinates > 30) {
          const options = { tolerance: 0.3, highQuality: true };
          feature = turf.simplify(turf.feature(feature.geometry), options);
        }
      } catch (e) {
        console.log('caught intersect error', e);
        feature = _.get(Draw.getAll(), 'features.0');
      }

      mapData.freeform = feature;
      mapData = mapData;
      dispatch('map-updated', map_data);

      Draw.delete(featureId);
      Draw.add(feature);
    } else {
      alert('At least one point must be within the USA.');
      Draw.deleteAll();
      Draw.changeMode('draw_polygon');
      container
        .getElementsByClassName('mapbox-gl-draw_trash')[0]
        .classList.add('hidden');
    }
  }

  function updateCustomArea(e) {
    createCustomArea();
  }

  function resetCustomArea(e) {
    map.getCanvas().style.cursor = 'crosshair';
    dispatch('map-updated', map_data);
    Draw.deleteAll();
    Draw.changeMode('draw_polygon');
    container
      .getElementsByClassName('MapControls-clearButton')[0]
      .classList.add('hidden');
  }

  // MAP FNS ------------------------
  const latLongCache = {};

  function getLatLong(s, cb) {
    if (!s) return cb(null, null);

    if (latLongCache[s]) {
      return cb(
        latLongCache[s].lat,
        latLongCache[s].lng,
        latLongCache[s].results
      );
    }

    geocoder.geocode({ address: s }, function (results, status) {
      var lat, lng;
      if (status == google.maps.GeocoderStatus.OK && results && results[0]) {
        results = results[0];
        lat = results.geometry.location.lat();
        lng = results.geometry.location.lng();
        latLongCache[s] = {
          lat: lat,
          lng: lng,
          results: results,
        };
        cb(lat, lng, results);
      } else cb(null, null);
    });
  }

  function reverseGeocode(lat, lng) {
    return new Promise((resolve, reject) => {
      geocoder.geocode(
        { latLng: new google.maps.LatLng(lat, lng) },
        function (results, status) {
          let r;

          if (
            status == google.maps.GeocoderStatus.OK &&
            results &&
            results[0]
          ) {
            resolve(cityState(_.get(results[0], 'address_components')));
          } else {
            reject('an error occured getting location');
          }
        }
      );
    });
  }

  function cityState(comps) {
    let city, state, fullState;

    comps.forEach(function (c) {
      if (~c.types.indexOf('administrative_area_level_1')) {
        state = c.short_name;
        fullState = c.long_name;
      } else if (~c.types.indexOf('locality')) {
        city = c.long_name;
      } else if (~c.types.indexOf('establishment')) {
        city = c.long_name;
      }
    });

    if (city) return `${city}${state ? ', ' + state : ''}`;
    return fullState;
  }

  function circleGeoJson(lat, lng, rad = 75, detail) {
    var detail = detail || 8,
      rad = rad * 1.60934, // miles to km
      R = 6371,
      pi = Math.PI,
      olat = lat,
      olng = lng,
      lat = (lat * pi) / 180,
      lng = (lng * pi) / 180,
      d = rad / R,
      features = [],
      i,
      brng,
      pLat,
      pLng,
      enc;

    for (i = 0; i < 360; i += detail) addPoint(i);
    addPoint(0); // complete circle

    return features;

    function addPoint(i) {
      brng = (i * pi) / 180;
      pLat = Math.asin(
        Math.sin(lat) * Math.cos(d) +
          Math.cos(lat) * Math.sin(d) * Math.cos(brng)
      );

      pLng =
        ((lng +
          Math.atan2(
            Math.sin(brng) * Math.sin(d) * Math.cos(lat),
            Math.cos(d) - Math.sin(lat) * Math.sin(pLat)
          )) *
          180) /
        pi;
      pLat = (pLat * 180) / pi;
      features.push([pLng, pLat]);
    }
  }

  // INPUT EVENTS --------------------
  function handleInput(e, stop) {
    if (e.target.value > maxRadius) {
      e.target.value = maxRadius;
    }

    stop.radius = parseInt(e.target.value);

    updateStops();
  }

  function handleFocusOut(e, stop) {
    if (e.target.value > maxRadius) {
      e.target.value = maxRadius;
    }
    if (e.target.value < minRadius) {
      e.target.value = minRadius;
    }

    stop.radius = parseInt(e.target.value);

    updateStops();
  }

  function handleRadiusChange(e, stop) {
    updateStops();
  }

  let infoIcon = `<svg
  width="16"
  height="16"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M11 10.9794C11 10.4271 11.4477 9.97937 12 9.97937C12.5523 9.97937 13 10.4271 13 10.9794V16.9794C13 17.5317 12.5523 17.9794 12 17.9794C11.4477 17.9794 11 17.5317 11 16.9794V10.9794Z"
    fill="#73767F"
  />
  <path
    d="M12 6.05115C11.4477 6.05115 11 6.49886 11 7.05115C11 7.60343 11.4477 8.05115 12 8.05115C12.5523 8.05115 13 7.60343 13 7.05115C13 6.49886 12.5523 6.05115 12 6.05115Z"
    fill="#73767F"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
    fill="#73767F"
  />`;
</script>

<div
  class="Container"
  on:mouseleave={() => {
    if (map) {
      map.scrollZoom.disable();
    }
  }}
  on:click={() => {
    if (map) {
      map.scrollZoom.enable();
    }
  }}
>
  <style bind:this={mapboxStyle}>
  </style>
  <style>
    .MapControls-addPin,
    .MapControls-selectAll {
      width: auto !important;
      font-weight: bold !important;
      padding: 0px 8px !important;
    }

    .MapControls-clearButton {
      width: fit-content !important;
      padding: 0px 6px !important;
      border-radius: 4px;
      background: #fff;
      box-shadow: 0px 0px 0px 1px rgb(0 0 0 / 10%);
      border: 1px solid #ddd;
      font-weight: bold;
      font-size: 11px;
      height: 30px;
      background-repeat: no-repeat;
      background-position: center;
    }

    .MapControls-clearButton.hidden {
      display: none;
    }
  </style>
  {#if readOnly && mode !== 'otr-freeform'}
    <style>
      .mapboxgl-ctrl-top-left,
      .mapboxgl-ctrl-top-right {
        display: none;
      }
    </style>
  {/if}
  <div id="Map" bind:this={container}>
    {#if map}
      <slot />
    {/if}
  </div>
</div>

{#if mode !== 'otr-freeform'}
  {#if !hideLocationList}
    <div class="Locations">
      <p class="Locations-mapDescription">
        Click on the map to select/deselect states. Add circles regions under
        the map:
      </p>
      {#if mapData.hiringAreas.length >= 1}
        <div class="Locations-listHeader">
          <label>Radius Center: </label>
        </div>
      {/if}
      <div class="Locations-list">
        {#if mapData && mapData.hiringAreas}
          {#each mapData.hiringAreas as stop, i}
            <div class="Locations-itemWrapper">
              <div class="Locations-item">
                <div
                  class="Locations-icon {stop.isPin
                    ? ''
                    : 'Locations-icon--crosshair'}"
                >
                  {#if stop.isPin}
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="14px"
                      height="14px"
                      viewBox="0 0 425.963 425.963"
                      style="enable-background:new 0 0 425.963 425.963;"
                      xml:space="preserve"
                    >
                      <g>
                        <path
                          style="fill: #262626;"
                          d="M213.285,0h-0.608C139.114,0,79.268,59.826,79.268,133.361c0,48.202,21.952,111.817,65.246,189.081
                    c32.098,57.281,64.646,101.152,64.972,101.588c0.906,1.217,2.334,1.934,3.847,1.934c0.043,0,0.087,0,0.13-0.002
                    c1.561-0.043,3.002-0.842,3.868-2.143c0.321-0.486,32.637-49.287,64.517-108.976c43.03-80.563,64.848-141.624,64.848-181.482
                    C346.693,59.825,286.846,0,213.285,0z
                    M274.865,136.62c0,34.124-27.761,61.884-61.885,61.884
                    c-34.123,0-61.884-27.761-61.884-61.884s27.761-61.884,61.884-61.884C247.104,74.736,274.865,102.497,274.865,136.62z"
                        />
                      </g>
                    </svg>
                  {:else}
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="14px"
                      height="14px"
                      viewBox="0 0 497.867 497.867"
                      style="enable-background:new 0 0 497.867 497.867;"
                      xml:space="preserve"
                    >
                      <g>
                        <path
                          style="fill: #262626;"
                          d="M477.546,228.616h-53.567c-9.827-80.034-74.019-143.608-154.719-153.134V20.321
                    C269.259,9.096,260.155,0,248.938,0c-11.226,0-20.321,9.096-20.321,20.321v54.974c-81.375,8.941-146.257,72.808-156.15,153.313
                    H20.321C9.096,228.608,0,237.704,0,248.929s9.096,20.321,20.321,20.321H72.19c8.99,81.513,74.328,146.428,156.426,155.451v52.844
                    c0,11.226,9.096,20.321,20.321,20.321c11.217,0,20.321-9.096,20.321-20.321v-53.023c81.416-9.608,146.054-74.222,154.996-155.264
                    h53.291c11.226,0,20.321-9.096,20.321-20.321S488.771,228.616,477.546,228.616z
                    M269.259,383.392v-67.028
                    c0-11.226-9.104-20.321-20.321-20.321c-11.226,0-20.321,9.096-20.321,20.321v67.24c-59.607-8.551-106.753-55.299-115.312-114.345
                    h68.207c11.226,0,20.321-9.096,20.321-20.321s-9.096-20.321-20.321-20.321h-67.882c9.38-58.046,56.103-103.761,114.987-112.215
                    v65.11c0,11.226,9.096,20.321,20.321,20.321c11.217,0,20.321-9.096,20.321-20.321v-64.899
                    c58.209,8.982,104.249,54.421,113.556,112.004h-66.459c-11.226,0-20.321,9.096-20.321,20.321s9.096,20.321,20.321,20.321h66.793
                    C374.646,327.842,328.191,374.297,269.259,383.392z"
                        />
                      </g>
                    </svg>
                  {/if}
                </div>
                <div class="Locations-name">
                  {stop.cityState ||
                    tools.formatComma(stop.latitude, 2) +
                      ', ' +
                      tools.formatComma(stop.longitude, 2)}
                </div>
                {#if !readOnly}
                  <div class="Locations-radius">
                    {#if opts.radiusInputType === 'select'}
                      <select
                        bind:value={stop.radius}
                        on:change={e => {
                          handleRadiusChange(e, stop);
                        }}
                      >
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={75}>75</option>
                        <option value={100}>100</option>
                        <option value={150}>150</option>
                        <option value={200}>200</option>
                        <option value={300}>300</option>
                      </select>
                    {:else}
                      <input
                        type="number"
                        min={minRadius}
                        max={maxRadius}
                        value={stop.radius}
                        on:input={e => handleInput(e, stop)}
                        on:focusout={e => handleFocusOut(e, stop)}
                      />
                    {/if}
                    <span>miles</span>
                  </div>
                {/if}
                {#if readOnly}
                  <span class="Locations-readOnlyMiles"
                    >{stop.radius} miles</span
                  >
                {/if}
              </div>
              <span class="Locations-remove" on:click={e => removeArea(i)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17 5V4C17 2.89543 16.1046 2 15 2H9C7.89543 2 7 2.89543 7 4V5H4C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H5V18C5 19.6569 6.34315 21 8 21H16C17.6569 21 19 19.6569 19 18V7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H17ZM15 4H9V5H15V4ZM17 7H7V18C7 18.5523 7.44772 19 8 19H16C16.5523 19 17 18.5523 17 18V7Z"
                    fill="#989AA2"
                  />
                  <path d="M9 9H11V17H9V9Z" fill="#989AA2" />
                  <path d="M13 9H15V17H13V9Z" fill="#989AA2" />
                </svg>
              </span>
            </div>
          {/each}
        {/if}
      </div>
    </div>
  {/if}
{/if}

{#if !readOnly && mode !== 'otr-freeform'}
  <div class="AddStop">
    <div class="AddStop-section">
      <h4>Add additional {area_title} areas:</h4>
      <div class="AddStop-typeSelect">
        <label>
          <input type="radio" value={'single'} bind:group={addAreaMethod} />
          Single
        </label>
        <label>
          <input type="radio" value={'multi'} bind:group={addAreaMethod} />
          Multiple
        </label>
      </div>
      <div class="AddStop-note">
        <span>Note:</span>
        If you are using a large list of cities, select
        <span>Multiple</span>
        above to input more than one at a time.
      </div>
    </div>

    <div class="AddStop-section">
      <div class="AddStop-inputs">
        {#if addAreaMethod === 'single'}
          <div class="AddStop-inputsSection">
            <label
              >Radius Center:
              {#if hoverValue === hintHover[1]}
                <div style="position: relative; display: inline-block;">
                  <div class="HintWrapper">
                    <div class="HintBubble">
                      <p>{hintHover[1]}</p>
                    </div>
                  </div>
                </div>
              {/if}
              <svg
                on:focus={() => {
                  hoverValue = hintHover[1];
                }}
                on:mouseover={() => {
                  hoverValue = hintHover[1];
                }}
                on:mouseout={() => {
                  hoverValue = '';
                }}
                on:blur={() => {
                  hoverValue = '';
                }}
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 10.9794C11 10.4271 11.4477 9.97937 12 9.97937C12.5523 9.97937 13 10.4271 13 10.9794V16.9794C13 17.5317 12.5523 17.9794 12 17.9794C11.4477 17.9794 11 17.5317 11 16.9794V10.9794Z"
                  fill="#73767F"
                />
                <path
                  d="M12 6.05115C11.4477 6.05115 11 6.49886 11 7.05115C11 7.60343 11.4477 8.05115 12 8.05115C12.5523 8.05115 13 7.60343 13 7.05115C13 6.49886 12.5523 6.05115 12 6.05115Z"
                  fill="#73767F"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
                  fill="#73767F"
                />
              </svg></label
            >
            <input
              type="text"
              placeholder="Enter city, state or zipcode"
              bind:value={newArea.text}
            />
          </div>
          <div class="AddStop-inputsSection AddStop-inputsSection--radius">
            <label
              >Route Radius
              {#if hoverValue === hintHover[0]}
                <div style="position: relative; display: inline-block;">
                  <div class="HintWrapper">
                    <div class="HintBubble">
                      <p>{hintHover[0]}</p>
                    </div>
                  </div>
                </div>
              {/if}
              <svg
                on:focus={() => {
                  hoverValue = hintHover[0];
                }}
                on:mouseover={() => {
                  hoverValue = hintHover[0];
                }}
                on:mouseout={() => {
                  hoverValue = '';
                }}
                on:blur={() => {
                  hoverValue = '';
                }}
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 10.9794C11 10.4271 11.4477 9.97937 12 9.97937C12.5523 9.97937 13 10.4271 13 10.9794V16.9794C13 17.5317 12.5523 17.9794 12 17.9794C11.4477 17.9794 11 17.5317 11 16.9794V10.9794Z"
                  fill="#73767F"
                />
                <path
                  d="M12 6.05115C11.4477 6.05115 11 6.49886 11 7.05115C11 7.60343 11.4477 8.05115 12 8.05115C12.5523 8.05115 13 7.60343 13 7.05115C13 6.49886 12.5523 6.05115 12 6.05115Z"
                  fill="#73767F"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12Z"
                  fill="#73767F"
                />
              </svg>
            </label>

            {#if opts.radiusInputType === 'select'}
              <select bind:value={newArea.radius}>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={75}>75</option>
                <option value={100}>100</option>
                <option value={150}>150</option>
                <option value={200}>200</option>
                <option value={300}>300</option>
              </select>
            {:else}
              <input
                type="number"
                min={minRadius}
                max={maxRadius}
                placeholder="25 mi"
                bind:value={newArea.radius}
              />
            {/if}
            <button
              on:click={e => {
                addArea(e, newArea);
              }}
              disabled={savingArea || !newArea.text || !newArea.radius}
            >
              Add Location
            </button>
            {#if !_.isEmpty(mapData.hiringAreas)}
              <button
                class="AddStop-clearAll"
                on:click={() => {
                  clearAll();
                }}
              >
                Clear All
              </button>
            {/if}
          </div>
        {:else}
          <div class="AddStop-inputsSection">
            <label>Location Center:</label>
            <textarea
              bind:value={newAreaMulti.text}
              placeholder="Enter city, state or zipcode (1 per line)"
            />
          </div>
          <div class="AddStop-inputsSection AddStop-inputsSection--radius">
            <label>Radius (miles):</label>
            {#if opts.radiusInputType === 'select'}
              <select bind:value={newAreaMulti.radius}>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={75}>75</option>
                <option value={100}>100</option>
                <option value={150}>150</option>
                <option value={200}>200</option>
                <option value={300}>300</option>
              </select>
            {:else}
              <input
                type="number"
                min={minRadius}
                max={maxRadius}
                bind:value={newAreaMulti.radius}
              />
            {/if}
            <button
              on:click={e => {
                addAreaMulti(e, newAreaMulti);
              }}
              disabled={savingArea ||
                !newAreaMulti.text ||
                !newAreaMulti.radius}
            >
              Add Location
            </button>

            {#if !_.isEmpty(mapData.hiringAreas)}
              <button
                class="AddStop-clearAll"
                on:click={() => {
                  clearAll();
                }}
              >
                Clear All
              </button>
            {/if}
          </div>
        {/if}
      </div>
    </div>
  </div>
{/if}

<style src="./HiringMap.scss">
</style>
