<svelte:options tag="cdlc-mobile-post-link" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import { onMount, tick } from 'svelte';
  import { get_current_component } from 'svelte/internal';
  import '../MobilePostText/MobilePostText.svelte';

  const log = debug('cdlc:MobilePostLink');

  export let dispatch = () => {};
  export let post;
  export let thread = false;

  $: message = _.get(post, 'linkInformation.caption');
  $: title =
    _.get(post, 'linkInformation.title') ||
    _.get(post, 'linkInformation.linkUrl') ||
    _.get(post, 'commentInformation.text');

  function handleLinkUrl(e) {
    if (!thread) {
      dispatch('body-link', {
        link: post.linkInformation.linkUrl,
      });
    } else {
      dispatch('body-link', {
        link: post.commentInformation.text,
      });
    }
  }
</script>

{#if post}
  <div class="MobilePostLink">
    <cdlc-mobile-post-text {dispatch} {post} text={message} max_length="275" />
    <div class="MobilePost-postSection" style="padding: 0px 15px;">
      <div
        class="MobilePost-postSection-linkContent
        MobilePost-postSection-linkContent--leftImage"
        on:click={handleLinkUrl}
      >
        {#if !thread}
          {#if post.linkInformation.image}
            <div class="MobilePost-postSection-linkContent-leftImageWrapper">
              <img
                alt="image"
                src={post.linkInformation.image}
                on:error={() => {
                  post.linkInformation.image = '';
                }}
              />
            </div>
          {/if}
        {/if}

        <div>
          <!-- ARTICLE TITLE -->
          {#if title}
            <div
              class="MobilePost-postSection-title"
              style="white-space: nowrap; overflow: hidden; text-overflow:
              ellipsis; background:none;"
            >
              {title}
            </div>
          {/if}
          <!-- DESCRIPTION -->
          {#if !thread}
            {#if post.linkInformation.description}
              <div class="MobilePostLink-description">
                <cdlc-mobile-post-text
                  {dispatch}
                  {post}
                  text={post.linkInformation.description}
                  styles="
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                "
                  classes="MobilePost-postSection-linkDescription"
                />
              </div>
            {/if}
          {/if}
          <!-- LINK -->
          <div class="MobilePostLink-link">
            {#if !thread}
              {post.linkInformation.linkUrl}
            {:else}
              {post.commentInformation.text}
            {/if}
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}

<style src="./MobilePostLink.scss">
</style>
