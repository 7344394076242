import _ from 'lodash';

let presets = {};

presets.diesel = d => {
  const endorsements = [];

  _.each(d, (v, k) => {
    if (v === undefined) {
      delete d[k];
      return;
    }

    if (/certification/.test(k) && v instanceof Array) {
      _.each(v, x => {
        endorsements.push(x);
      });
      delete d[k];
    }
  });

  d.endorsements = endorsements;

  return d;
};

export default presets;
