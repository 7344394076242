<svelte:options tag="cdlc-input-range" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import { createEventDispatcher, onMount, tick, afterUpdate } from 'svelte';
  import { get_current_component } from 'svelte/internal';

  const log = debug('cdlc:InputRange'),
    svelteDispatch = createEventDispatcher(),
    component = get_current_component(),
    dispatch = (name, detail) => {
      svelteDispatch(name, detail);
      component.dispatchEvent &&
        component.dispatchEvent(new CustomEvent(name, { detail }));
    };

  export let change_min = false;
  export let change_max = false;
  export let changeable_max = false;
  export let changeable_min = 0;
  export let name = 'inputRange';
  export let format = 'number';
  export let id = 'range';
  export let value = 5;
  export let max = '10';
  export let min = '0';
  export let step = 1;
  export let target = null;
  export let disabled = false;

  let mounted = false,
    modifyingMin = false,
    modifyingMax = false;

  $: leftValuePosition = getLeftValuePosition(value, max, min);
  $: targetLeftValuePosition = getLeftValuePosition(target, max, min);
  $: formatedMax = formatValue(max, format);
  $: formatedMin = formatValue(min, format);
  $: formatedValue = formatValue(value, format);
  $: formatedTarget = formatValue(target, format);

  // this is goofy due to trying to figure out how to put below the dragger
  $: leftValuePositionAdjusted = `calc(${leftValuePosition}% + (${15 -
    leftValuePosition * 0.28}px))`;

  $: targetLeftValuePositionAdjusted = `calc(${targetLeftValuePosition}% + (${15 -
    targetLeftValuePosition * 0.28}px))`;

  onMount(async () => {
    await tick();
    mounted = true;
    formatedMax = formatValue(max, format);
    formatedMin = formatValue(min, format);
    formatedValue = formatValue(value, format);
    formatedTarget = formatValue(target, format);

    leftValuePosition = getLeftValuePosition(value, max, min);
  });

  function getLeftValuePosition(value, max, min) {
    return Math.min(((value - min) / (max - min)) * 100, 100);
  }

  function valueChanged(e) {
    const u = {
      min: parseFloat(min),
      max: parseFloat(max),
      value: e ? parseFloat(e.target.value) : value,
    };
    dispatch('change', u);
    dispatch('changed', u);
  }

  function formatValue(n, format) {
    const r = 2;
    if (format === 'price') {
      return formatPrice(n);
    }

    if (r || r === 0) {
      n = _.round(parseFloat(n), r);
    }

    var str = String(n).replace(/[^0-9.]/g, ''),
      d = Number(str) || 0;

    if (n < 0) d *= -1;

    let s = String(d).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return s;
  }

  function formatPrice(d) {
    var orig = d,
      str = String(Math.abs(d)).replace(/[^0-9.]/g, ''),
      d = Number(str) || 0,
      b = orig < 0 ? '-$' : '$';

    var res = b + d.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return res.replace(/\.00$/, '');
  }
</script>

<div class="SliderContainer">
  {#if mounted}
    {#if modifyingMin}
      <span class="ModifyingLabel">Modifying Min</span>

      <div class="SliderAnimation">
        <div class="SliderAnimation-right">
          <cdlc-input-range
            min={changeable_min}
            max={value}
            value={min}
            target={min}
            {step}
            on:mouseup={event => {
              modifyingMin = false;
            }}
            on:change={event => {
              modifyingMin = false;
              min = event.detail.value;
              valueChanged();
            }}
          />
        </div>
      </div>
    {:else if modifyingMax}
      <span class="ModifyingLabel">Modifying Max</span>
      <div class="SliderAnimation">
        <div class="SliderAnimation-left">
          <cdlc-input-range
            min={value}
            max={changeable_max || max}
            value={max}
            target={max}
            {step}
            on:mouseup={event => {
              modifyingMax = false;
            }}
            on:change={event => {
              modifyingMax = false;
              max = event.detail.value;
              valueChanged();
            }}
          />
        </div>
      </div>
    {:else}
      <div class="Slider" class:is-disabled={disabled}>
        <div
          class="Slider-bookend"
          on:click={() => {
            if (change_min && !(change_min === 'false')) {
              modifyingMin = true;
            }
          }}
        >
          {formatedMin}
        </div>
        <div class="Slider-inputContainer">
          <input
            class="Slider-input"
            type="range"
            {disabled}
            bind:value
            on:change={valueChanged}
            {id}
            {name}
            {min}
            {max}
            {step}
          />
          <div
            class="Slider-activeBackground"
            style="width: {leftValuePositionAdjusted}"
          />
          <div class="Slider-thumb" style="left:{leftValuePositionAdjusted}">
            {formatedValue}
          </div>
          {#if disabled}
            <div
              class="Slider-targetLine"
              style="left:{leftValuePositionAdjusted}"
            />
          {:else if target}
            <div
              class="Slider-targetLine"
              style="left:{targetLeftValuePositionAdjusted}"
            />
            <div
              class="Slider-thumb is-target"
              style="left:{targetLeftValuePositionAdjusted}"
            >
              {formatedTarget}
            </div>
          {/if}
        </div>
        <div
          class="Slider-bookend"
          on:click={() => {
            if (change_max && !(change_max === 'false')) {
              modifyingMax = true;
            }
          }}
        >
          {formatedMax}
        </div>
      </div>
    {/if}
  {/if}
</div>

<style src="./InputRange.scss">
</style>
