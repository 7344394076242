<svelte:options tag="cdlc-mobile-post-ad" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import '../MobilePostText/MobilePostText.svelte';

  const log = debug('cdlc:MobilePostAd');

  export let dispatch = () => {};
  export let mode = 'embed';
  export let post;
  function click(event) {
    event.stopPropagation();
    if (mode === 'embed') {
      if (_.get(post, 'adInformation.fullLinkUrl')) {
        window.open(post.adInformation.fullLinkUrl, '_blank');
      } else if (_.get(post, 'adInformation.linkUrl')) {
        window.open(post.adInformation.linkUrl, '_blank');
      }
    } else {
      dispatch('body-link', {
        link:
          _.get(post, 'adInformation.linkUrl') ||
          _.get(post, 'adInformation.fullLinkUrl'),
      });
    }
  }
</script>

{#if post}
  <div class="MobilePostAd">
    <cdlc-mobile-post-text {post} text={post.adInformation.text} max_length="275" />
    <div class="MobilePost-postSection" style="padding: 0px;" on:click={click}>
      <div
        class="MobilePost-postSection-linkContent MobilePost-postSection-linkContent--ad"
      >
        {#if post.adInformation.videoUrl}
          <div>
            <video
              controls
              preload="metadata"
              src={post.adInformation.videoUrl}
              poster={post.adInformation.imageUrl}
              playsinline
              webkit-playsinline
            >
              <source
                style="object-fit: contain;"
                type="video/mp4"
                src={post.adInformation.videoUrl}
              />
            </video>
          </div>
        {/if}
        {#if !post.adInformation.videoUrl}
          <img
            src={post.adInformation.imageUrl}
            class="MobilePost-postSection-linkContent-image"
          />
        {/if}
        {#if post.adInformation.openType !== 'none'}
          <div
            class="MobilePost-postSection-title MobilePost-postSection-title--ad"
            style="display: flex; align-items: center;"
          >
            <div style="flex-grow: 1;">{post.adInformation.title}</div>
          </div>
          {#if post.adInformation.linkUrl}
            <div
              class="MobilePost-postSection-adCTA"
              style="padding-bottom: 12px;"
            >
              <a>
                {post.adInformation.ctaText}
                <svg
                  width="7"
                  height="13"
                  viewBox="0 0 7 13"
                  style=" height: 14px; vertical-align: middle; transform:
                  translateY(-1px); "
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.65625 0.816406L0.109375 1.33594C0 1.47266 0 1.69141
                    0.109375 1.80078L5.05859 6.75L0.109375 11.7266C0 11.8359 0
                    12.0547 0.109375 12.1914L0.65625 12.7109C0.792969 12.8477
                    0.984375 12.8477 1.12109 12.7109L6.86328 6.99609C6.97266
                    6.85938 6.97266 6.66797 6.86328 6.53125L1.12109
                    0.816406C0.984375 0.679688 0.792969 0.679688 0.65625
                    0.816406Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </div>
          {/if}
        {/if}
      </div>
    </div>
  </div>
{/if}

<style src="./MobilePostAd.scss">
</style>
