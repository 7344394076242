<svelte:options tag="cdlc-ziprecruiter-job-ad" />

<script>
  export let ad_data;
</script>

{#if ad_data}
  <div
    class="ZipRecruiterJobAd"
    on:click={() => {
      if (ad_data.permalink_url) {
        window.open(ad_data.permalink_url, '_blank');
      }
    }}
  >
    <div class="ZipRecruiterJobAd-headerActions">
      <button class="applyBtn"
        >Apply
        <img
          alt="apply icon"
          width="18"
          height="18"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABmJLR0QA/wD/AP+gvaeTAAABmklEQVQ4ja2Tu0pcURSGv3UcHIUoGhQ1leBlZjBY+QJpfAQLbaxERwuFgCCi2xRBY+FYDAOCpBILsbfwEWy84RzBkNYLMUbR0XH2spAzjnKOHoh/+a+1v/2vzV7wTpKwjYmJgaZCeVllqRfNcb47mzkPDUqYwc/WOjvIi37hNn9z3/Brbuki4nkdJtmYV9IC3cCHp2Y9Otg/i8UT9X2IVgEo8hWlDSVaXunUAk+ge5UVQbsQlkFyJbNnWVsrZGEVIDY9tAC0AVkg7vVFAJrNaA327gvIuDuTng8a8REio6ApEdlW5adXcwAqyNUgiDh6Gggxw7MexJ3JjL2sR/wO+SZRfQZR5BpVjeSjN8VEr6ndDE35JXH3T9Ydx3bufV88DpVIcP6p2OlDk/nmeXGT7Fd08sBkWkOP5pp0ysduRqWl1HhztLB6X5DFXgFgH39uCFUjXJYaxd2JmeQW8Ankh2CvgwgW+SgwAbLhmnSP5xcfW5BeVV0BXdBXdlkUVYfNAoUR30Se2s1AHZRVB4HuiP75bVJ/A2/6Xz0ALAyR9wnVy8sAAAAASUVORK5CYII="
        />
      </button>
      <button class="saveBtn"
        ><img
          alt="save icon"
          width="16"
          height="16"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAACLklEQVRIiWNkwAJ6Jk40fv7smc2fP/+YsMmjAxYWpn+SUlJHSvLzz6LLMSJzpk+fLn34+Klj33/8kCPGYHTAxcn+yMbC3CozM/Mp3HJkBUdPndn6/ccPOR4errMsbOwXSTH876/f+p+/fDE+evLsFgYGBkOsFvz48VOLnY392eJ5c03I8UFEbPyzHz9/aCOLwcN4//79LP/+/mVhYmZ6S47hDAwMDExMzG/+/f3Lsn//frjDiYpESsCoBaMWjFowagERgIWwEgiYNGkS+5Xrt/sZGBgYdDRVC/Py8n4So4+gD/7//8+Ulpndevj46fev377JfP32TebBY6c+pmTnzGtoaGAj24JVq1YxZ+TkTQiLjvv8+u27qv8M/1iEhYSWCQsJLWNk/M/09vXbxKu37r7PyMmbsGrVKmZc5sCrzP3797NMnj7rFycX9xUBXt5Vb96+Lf315zcfIxPjX15u3s0aKgpJlZWV7xkYGBh27tzJvWL9xkmfPn6M/ff3HyszM9NXUVGxxZ8+frL5/v2bdm5mGpujo+MfBgYscfDt21edb9++6jIxMv4REBRab2tunJ6UlPQaWY27u/tXBgaG5Hnz5lUcPnl25qcP731fvHiRwcDA8J8RzTw4/////0whkTG/GP4zMPDz8+ww1tdLyc7OfkEgiBkYGBgYpk6dKnH24qU5Hz9+8WBgZGBYs3wJGyMj4z8MhU1NTbadnZ1SxBiKDXR2dko1NTXZIosBACFv1BWT2vRmAAAAAElFTkSuQmCC"
        /></button
      >
    </div>
    <div class="ZipRecruiterJobAd-companyImg">
      {#if ad_data.companyLogo}
        <img src={ad_data.companyLogo} alt="company logo" />
      {:else}
        <img
          alt="company logo"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABmJLR0QA/wD/AP+gvaeTAAADA0lEQVRIicWXMXPbRhCFv8VFmiHZOm0yHgNDwOlMtf4RkUrJpcU6dVQ6vVvQZaRS1n+gW9EtyQEmHqmVWpIzydxtigMokJQYgKOMXwUs7t27273bXcB3gqwbptNvsQb2oib7cxyGf+wi/MO6wRnbEeWNCv3tmpyo8vMuogXfYzr9660zLhHHTwi/x1G44Y0qxlk+ECFWOAdIwnDQRHi5Yxe4d6L8inALfP3PFQs3KD1BfwOJgUbCS4yzfDDO8sbkcZ73JlmuTXkbMS4xyXJV4QAtYi2kolzHUSiTLB+pkDZ1by1hFQ5m7fa4tVh8AFi0Wved+fwAQJw5Fv6+31V0q3AShqPi8bZiHgF0uy8n6+PrhEnQYRxF51uFn9vVgr5Vf4m2CzdFEoWn0+m3mGUI9l90uy8n4zzvzdrtcWc2/1gdvy3G/cDaG9j701v+uXHG9AFE+CDWjDc4gb1QMSmAqO0DPVGuy7NRS3gXxFHYq7wOCpsAjLO8nrAoqTNmhLp33mBSUVJgoMqZGpsCK4dskmXnInIJoKpHcRSdTLJ8JM4cO2w94V0gIpcUIRBj/QKE1F89U0+4kqtPq3MX33qbjGbYdp1G4syxGnsEINZcamAv4ijslS7thuFVlaOqR8udqh4BV6L0YX9IA1e/ccZ2KEqfFuXSf5LEwY/rhDiKTiqvV97mvVP7cCl8ciJ3Rt0QwEpwF6h+8rp8DmywcZ3KxAIgSt97Z5mIau5YSBet1n1rsRjCMlf7Sa255JFcvZrD94fwkPM7s/nK2OBJXX/xk8DpWeD0rDOfJ6Jcg08UzpjDdY4/D3sJ7CXl2UDptxaLF+tjnxT+v/GsZbEaArH7d97oQ1bb1aWLfnn16jZwetaZzT8GNmhD1aVrlCIEzpjDslMtQ1Z7xwLvA9UUuBV4D+CMuwa+oBw64+6AL1XOs+TqXfAsufqhLG7iqbLYJFcve+f1PnllkK9KgJyr6Ip7yz685g/AJInCU6js+KFPfnSCos/W16K8fmTpX0W3Cy81vjf+BYhOo0d5FISRAAAAAElFTkSuQmCC"
        />
      {/if}
    </div>
    <div class="ZipRecruiterJobAd-jobTitle">
      <h2>{ad_data.headline}</h2>
      <span>NEW!</span>
    </div>
    <div class="ZipRecruiterJobAd-subJobTitle">
      <h5>{ad_data.companyName}</h5>
      {#if ad_data.location}
        <h5>{ad_data.location}</h5>
      {/if}
    </div>

    <div class="ZipRecruiterJobAd-tagWrapper">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="12"
        height="12"
        viewBox="0 0 172 172"
        style=" fill:#000000;"
        ><g
          fill="none"
          fill-rule="nonzero"
          stroke="none"
          stroke-width="1"
          stroke-linecap="butt"
          stroke-linejoin="miter"
          stroke-miterlimit="10"
          stroke-dasharray=""
          stroke-dashoffset="0"
          font-family="none"
          font-weight="none"
          font-size="none"
          text-anchor="none"
          style="mix-blend-mode: normal"
          ><path d="M0,172v-172h172v172z" fill="none" /><g fill="#989ea0"
            ><path
              d="M71.66667,21.5c-1.91758,-0.02603 -3.76561,0.7175 -5.13084,2.06433c-1.36523,1.34682 -2.13379,3.18458 -2.1338,5.10234h-35.73535c-7.91917,0 -14.33333,6.41417 -14.33333,14.33333v43c0,7.90483 6.4285,14.33333 14.33333,14.33333h114.66667c7.90483,0 14.33333,-6.4285 14.33333,-14.33333v-43c0,-7.91917 -6.41417,-14.33333 -14.33333,-14.33333h-35.73535c-0.00002,-1.91776 -0.76857,-3.75552 -2.1338,-5.10234c-1.36523,-1.34682 -3.21326,-2.09035 -5.13085,-2.06433zM86,71.66667c3.956,0 7.16667,3.21067 7.16667,7.16667c0,3.956 -3.21067,7.16667 -7.16667,7.16667c-3.956,0 -7.16667,-3.21067 -7.16667,-7.16667c0,-3.956 3.21067,-7.16667 7.16667,-7.16667zM14.33333,110.67741v18.32259c0,7.91917 6.41417,14.33333 14.33333,14.33333h114.66667c7.91917,0 14.33333,-6.41417 14.33333,-14.33333v-18.32259c-4.2355,2.46533 -9.08733,3.98926 -14.33333,3.98926h-114.66667c-5.246,0 -10.09783,-1.52393 -14.33333,-3.98926z"
            /></g
          ></g
        ></svg
      >
      <p class="ZipRecruiterJobAd-tag">Full Time</p>
    </div>

    <div class="ZipRecruiterJobAd-descriptionWrapper">
      <p class="ZipRecruiterJobAd-description">
        {(ad_data.message || '').slice(0, 200)}
      </p>
    </div>
  </div>
{/if}

<style src="./ZiprecruiterJobAd.scss">
</style>
