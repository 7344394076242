<svelte:options tag="cdlc-lead-form-multi" />

<script>
  import debug from 'debug';
  import { createEventDispatcher } from 'svelte';
  import { current_component, get_current_component } from 'svelte/internal';
  import backend from './LeadFormMulti.backend';
  import _ from 'lodash';

  const log = debug('cdlc:LeadFormMulti'),
    svelteDispatch = createEventDispatcher(),
    component = get_current_component(),
    dispatch = (name, detail) => {
      svelteDispatch(name, detail);
      component.dispatchEvent &&
        component.dispatchEvent(new CustomEvent(name, { detail }));
    };

  export let sections = [];
  export let plugins = [];
  export let request;
  export let store_results_key = null;
  export let default_query_param_key = null;
  export let leadForm;
  export let has_terms = true;
  export let custom_terms_checkbox = '';
  export let custom_terms = '';

  export function changeFormSection(i) {
    sectionIndex = i;
  }
  let sectionIndex = 0,
    showForm = true,
    storedResponses = {};

  $: activeQuestions = sections[sectionIndex];
  $: lastSection = sectionIndex === sections.length - 1;
  $: dispatch('activesection', sectionIndex);
  $: filteredPlugins = lastSection
    ? plugins
    : plugins.filter(d => d !== 'recaptcha');
</script>

{#if showForm && activeQuestions}
  <cdlc-lead-form
    bind:this={leadForm}
    {store_results_key}
    plugins={filteredPlugins}
    {default_query_param_key}
    sections={[activeQuestions]}
    request={lastSection
      ? request
      : answers => {
          _.merge(storedResponses, answers);
          showForm = false;
          sectionIndex++;
          setTimeout(() => {
            showForm = true;
            setTimeout(() => {
              leadForm.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 10);
          });
          return Promise.resolve();
        }}
    has_terms={lastSection ? has_terms : false}
    {custom_terms_checkbox}
    {custom_terms}
    button={lastSection ? 'Submit' : 'Continue'}
    on:storeResults={e => {
      const { current, updated } = e.detail;
      _.extend(updated, current, updated, storedResponses, updated);
    }}
    on:presubmit={d => {
      dispatch('presubmit', _.merge(d && d.detail, storedResponses));
    }}
    on:submit={d => {
      log('submitted', d && d.detail);
      dispatch('submit', d && d.detail);

      // -- deprecated --
      dispatch('submitted', d && d.detail);
    }}
    on:progress={d => {
      log('progress', d && d.detail);
      dispatch('progress', d && d.detail);
    }}
  />
{/if}

<style src="./LeadFormMulti.scss">
</style>
