<svelte:options tag="cdlc-mobile-post-poll" />

<script>
  import debug from 'debug';
  import _ from 'lodash';

  const log = debug('cdlc:MobilePostPoll');

  export let dispatch = () => {};
  export let post;
  export let user_engagements;
  export let is_admin = false;

  // TODO
  let userAnswer;

  $: pollInfo =
    _.get(post, 'pollInformation.choices.length') &&
    _.get(post, 'pollInformation');
  $: hasAnswered = user_engagements && user_engagements.poll_vote;

  function computeRate(total, choice) {
    if (choice && choice.votes) {
      return _.round((choice.votes / total) * 100);
    } else {
      return 0;
    }
  }

  function submit() {
    hasAnswered = true;
    dispatch('body-poll-vote', {
      choice: _.get(pollInfo.choices, userAnswer),
    });
  }
</script>

{#if pollInfo}
  {#if hasAnswered || is_admin}
    {#if pollInfo.sponsored}
      <div class="MobilePostMessage-pollChoicesContainerSponsored">
        {#each pollInfo.choices as choice, i}
          <div class="MobilePostMessage-pollChoicesContainerChoiceSponsored">
            <div
              class="MobilePostMessage-pollChoicesContainerChoiceBarContainerSponsored {userAnswer ===
              i
                ? 'MobilePostMessage-sponsoredPollUserAnswer'
                : ''}"
            >
              <div
                class="MobilePostMessage-pollChoicesContainerChoiceBarSponsoredWrapper"
                style="width:{computeRate(
                  pollInfo.totalVotes,
                  choice
                )}%; {computeRate(pollInfo.totalVotes, choice) > 98
                  ? 'border-radius: 9px;'
                  : ''}"
              >
                <div
                  class="MobilePostMessage-pollChoicesContainerChoiceBarSponsored {userAnswer ===
                  i
                    ? 'userAnswer'
                    : ''}"
                  style={computeRate(pollInfo.totalVotes, choice) > 98
                    ? 'border-radius: 9px;'
                    : ''}
                />
              </div>
            </div>
            <div
              class="MobilePostMessage-pollChoicesContainerChoiceSponsored sub"
            >
              <div
                class="MobilePostMessage-pollChoicesContainerChoiceDisplaySponsored"
              >
                <div
                  class="MobilePostMessage-pollChoicesRateSponsored"
                  style="color: black;"
                >
                  <div class="MobilePostMessage-pollChoiceRateSponsored">
                    {choice.text}
                  </div>
                  <div class="MobilePostMessage-pollChoiceTextSponsored">
                    <span style="color: #262626;"
                      >{computeRate(pollInfo.totalVotes, choice)}%</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/each}
      </div>
    {:else}
      <div class="MobilePostMessage-poll">
        <div class="MobilePostMessage-pollChoicesContainer">
          <div class="MobilePostMessage-pollvotes">
            {pollInfo.totalVotes || 0}
            Votes
          </div>
          {#each pollInfo.choices as choice}
            <div class="MobilePostMessage-pollChoicesContainerChoice">
              <div class="MobilePostMessage-pollChoicesContainerChoice sub">
                <div
                  class="MobilePostMessage-pollChoicesContainerChoiceDisplay"
                >
                  <div class="MobilePostMessage-pollChoicesRate">
                    <div style="margin: 0 5px 0 0 !important;">
                      <strong
                        >{computeRate(pollInfo.totalVotes, choice)}%</strong
                      >
                    </div>
                    <div style="text-align: center;">
                      <span style="color: #262626;">{choice.text}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="MobilePostMessage-pollChoicesContainerChoiceBarContainer"
                >
                  <div
                    class="MobilePostMessage-pollChoicesContainerChoiceBar"
                    style="width:{computeRate(pollInfo.totalVotes, choice)}%"
                  />
                </div>
              </div>
            </div>
          {/each}
        </div>
      </div>
    {/if}
  {:else}
    <span />
    {#if pollInfo.sponsored}
      <div class="MobilePostMessage-pollChoicesContainerSponsored">
        {#each pollInfo.choices as choice, i}
          <div
            class="MobilePostMessage-pollChoicesContainerChoiceSponsored MobilePostMessage-pollChoicesUnanswered {userAnswer ===
            i
              ? 'selected'
              : ''}"
            on:click={event => {
              userAnswer = i;
              try {
                event.preventDefault();
                event.stopPropagation();
              } catch (e) {}
            }}
          >
            <div
              class="MobilePostMessage-pollChoicesContainerChoiceSponsored sub"
            >
              <div
                class="MobilePostMessage-pollChoicesContainerChoiceDisplaySponsored"
              >
                <div class="MobilePostMessage-pollChoiceRateSponsored">
                  <div class="MobilePostMessage-inputsSponsored">
                    <label class="container">
                      <input type="radio" value={i} bind:group={userAnswer} />
                      <span class="checkmark" />
                    </label>
                  </div>
                  <div style="text-align: center;">
                    <span style="color: #2e5ff6;">{choice.text}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/each}
        {#if userAnswer !== undefined}
          <div class="MobilePostMessage-buttonBorder">
            <button on:click={submit} class="MobilePostMessage-pollVoteButton">
              Vote
            </button>
          </div>
        {/if}
      </div>
    {:else}
      <div class="MobilePostMessage-poll">
        <div class="MobilePostMessage-pollChoicesContainer">
          <div class="MobilePostMessage-pollvotes">
            {pollInfo.totalVotes || 0}
            Votes
          </div>
          <div>
            {#each pollInfo.choices as choice, i}
              <div
                class="MobilePostMessage-pollChoicesContainerChoice"
                on:click={event => {
                  userAnswer = i;
                  try {
                    event.preventDefault();
                    event.stopPropagation();
                  } catch (e) {}
                }}
              >
                <div class="MobilePostMessage-pollChoicesContainerChoice sub">
                  <div
                    class="MobilePostMessage-pollChoicesContainerChoiceDisplay"
                  >
                    <div class="MobilePostMessage-pollChoicesRate">
                      <div class="MobilePostMessage-inputs">
                        <label class="container">
                          <input
                            type="radio"
                            value={i}
                            bind:group={userAnswer}
                          />
                          <span class="checkmark" />
                        </label>
                      </div>
                      <div style="text-align: center;">
                        <span style="color: #262626;">{choice.text}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {/each}
            {#if userAnswer !== undefined}
              <div class="MobilePostMessage-buttonBorder">
                <button
                  on:click={submit}
                  class="MobilePostMessage-pollVoteButton"
                >
                  Vote
                </button>
              </div>
            {/if}
          </div>
        </div>
      </div>
    {/if}
  {/if}
{/if}

<style src="./MobilePostPoll.scss">
</style>
