<svelte:options tag="cdlc-job-card-details" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import moment from 'moment';
  import { t } from '../../../../tools/translate/index.js';
  import { createEventDispatcher, onMount, tick } from 'svelte';
  import { get_current_component } from 'svelte/internal';
  import jobTools from '../../../../tools/jobTools/tools.js';
  import datatypes from '../../../../tools/jobTools/datatypes.js';

  const log = debug('cdlc:JobCardDetails');

  export let dispatch = () => {};
  export let job;
  export let settings = {};

  let jobid;

  onMount(async () => {
    await tick();
    log('job', job);

    if (job._id !== 0) {
      jobid = _.toUpper(_.get(job, '_id', '').substr(-6));
    }
  });
</script>

{#if job}
  {#if !job.jobVersion || job.jobVersion !== 'v2'}
    <div class="JobCard-details">
      {#if job.cardType === 'description'}
        <div>
          {#if settings.showJobInfoItems}
            <div class="JobCard-info">
              <div class="JobCard-showInfo">
                {#if job.companyName}
                  <p><b>Company:</b> {job.companyName}</p>
                {/if}
                <div class="clearfix" />
                {#if job.driverType}
                  {#if job.jobTypeSelect != 'non-driving'}
                    <p>
                      <b>Driver Type:</b>
                      {datatypes.driverType[job.driverType]}
                    </p>
                  {/if}
                {/if}
                <div class="clearfix" />
                {#if job.jobTypeSelect || job.jobTypeCustom}
                  {#if job.jobTypeSelect == 'custom'}
                    <p><b>Job Type:</b> {job.jobTypeCustom}</p>
                    <div class="clearfix" />
                  {:else}
                    <p>
                      <b>Job Type:</b>
                      {datatypes.jobTypeSelect[job.jobTypeSelect]}
                    </p>
                    <div class="clearfix" />
                  {/if}
                {/if}

                {#if job.jobTypeSelect != 'non-driving'}
                  {#if job.trailerType && job.trailerType.length > 0}
                    <p>
                      <b>Trailer Type:</b>
                      <span
                        style=" display: inline-block; max-width: calc(100% -
                      76px); line-height: 14em; vertical-align: text-top; "
                      >
                        {jobTools.formatTrailerTypes(
                          datatypes.trailerType,
                          job.trailerType
                        )}
                      </span>
                    </p>
                    <div class="clearfix" />
                  {/if}
                {/if}
              </div>
              {#if settings.viewJobDetails}
                <div
                  class="JobCard-viewJob"
                  on:click={() => dispatch('openJob')}
                >
                  View Job Details
                </div>
              {/if}
            </div>
          {/if}
          <div class="JobCard-info">
            {#if !job.curatedContent && jobTools.admin}
              <p class="JobCard-faceUpDetails u-color--red">
                Please provide content
              </p>
            {:else}
              <p class="JobCard-faceUpDetails">{job.curatedContent}</p>
            {/if}
          </div>
        </div>
      {:else if job.cardType === 'xml'}
        <div>
          {#if settings.showJobInfoItems}
            <div class="JobCard-info">
              <div class="JobCard-showInfo">
                {#if job.companyName}
                  <p><b>Company:</b> {job.companyName}</p>
                {/if}
                <div class="clearfix" />
                {#if job.jobTypeSelect != 'non-driving'}
                  {#if job.driverType}
                    <p>
                      <b>Driver Type:</b>
                      {datatypes.driverType[job.driverType]}
                    </p>
                  {/if}
                {/if}
                <div class="clearfix" />
                {#if job.jobTypeCustom || job.jobTypeSelect}
                  {#if job.jobTypeSelect == 'custom'}
                    <p><b>Job Type:</b> {job.jobTypeCustom}</p>
                    <div class="clearfix" />
                  {:else}
                    <p>
                      <b>Job Type:</b>
                      {datatypes.jobTypeSelect[job.jobTypeSelect]}
                    </p>
                    <div class="clearfix" />
                  {/if}
                {/if}
                {#if job.jobTypeSelect != 'non-driving'}
                  {#if job.trailerType && job.trailerType.length > 0}
                    <p>
                      <b>Trailer Type:</b>
                      <span
                        style=" display: inline-block; max-width: calc(100% -
                      76px); line-height: 1em; vertical-align: text-top; "
                      >
                        {jobTools.formatTrailerTypes(
                          datatypes.trailerType,
                          job.trailerType
                        )}
                      </span>
                    </p>
                    <div class="clearfix" />
                  {/if}
                {/if}
              </div>
              {#if settings.viewJobDetails}
                <div
                  class="JobCard-viewJob"
                  on:click={() => dispatch('openJob')}
                >
                  View Job Details
                </div>
              {/if}
            </div>
          {/if}
          {#if job.curatedContent}
            <div class="JobCard-info">
              <p class="JobCard-faceUpDetails">
                {@html job.curatedContent.substring(0, 200) + '...'}
              </p>
            </div>
          {/if}
          {#if settings.showJobId}
            <div class="JobCard-info">
              <p><b>Job ID:</b> {jobid}</p>
            </div>
          {/if}
        </div>
      {:else if job.cardType === 'hybrid'}
        <div>
          {#if settings.showJobInfoItems}
            <div class="JobCard-info">
              <div class="JobCard-showInfo">
                {#if job.companyName}
                  <p><b>Company:</b> {job.companyName}</p>
                {/if}
                <div class="clearfix" />

                {#if job.jobTypeSelect != 'non-driving'}
                  {#if job.driverType}
                    <p>
                      <b>Driver Type:</b>
                      {datatypes.driverType[job.driverType]}
                    </p>
                  {/if}
                {/if}

                <div class="clearfix" />
                {#if job.jobTypeSelect || job.jobTypeCustom}
                  {#if job.jobTypeSelect == 'custom'}
                    <p><b>Job Type:</b> {job.jobTypeCustom}</p>
                    <div class="clearfix" />
                  {:else}
                    <p>
                      <b>Job Type:</b>
                      {datatypes.jobTypeSelect[job.jobTypeSelect]}
                    </p>
                    <div class="clearfix" />
                  {/if}
                {/if}
                {#if job.jobTypeSelect != 'non-driving'}
                  {#if job.trailerType && job.trailerType.length > 0}
                    <p>
                      <b>Trailer Type:</b>
                      <span
                        style=" display: inline-block; max-width: calc(100% -
                      76px); line-height: 1em; vertical-align: text-top; "
                      >
                        {jobTools.formatTrailerTypes(
                          datatypes.trailerType,
                          job.trailerType
                        )}
                      </span>
                    </p>
                    <div class="clearfix" />
                  {/if}
                {/if}
                {#if settings.showJobId}
                  <p><b>Job ID:</b> {jobid}</p>
                {/if}

                {#if settings.viewJobDetails}
                  <div
                    class="JobCard-viewJob"
                    on:click={() => dispatch('openJob')}
                  >
                    View Job Details
                  </div>
                {/if}
              </div>
            </div>
          {/if}
          <div class="JobCard-info">
            {#if !job.curatedContent && jobTools.admin}
              <p class="JobCard-faceUpDetails u-color--red">
                Please provide content
              </p>
            {:else}
              <p class="JobCard-faceUpDetails">{job.curatedContent}</p>
            {/if}
          </div>
        </div>
      {:else}
        <!-- job.cardType === 'default'> -->

        {#if settings.showJobInfoItems}
          <div class="JobCard-info">
            <div class="JobCard-showInfo">
              {#if job.companyName}
                <p><b>Company:</b> {job.companyName}</p>
              {/if}
              <div class="clearfix" />
              {#if job.jobTypeSelect != 'non-driving'}
                {#if job.driverType}
                  <p>
                    <b>Driver Type:</b>
                    {datatypes.driverType[job.driverType]}
                  </p>
                {/if}
              {/if}
              <div class="clearfix" />
              {#if job.jobTypeSelect == 'custom'}
                {#if job.jobTypeCustom || job.jobTypeSelect}
                  <p><b>Job Type:</b> {job.jobTypeCustom}</p>
                  <div class="clearfix" />
                {:else}
                  <p>
                    <b>Job Type:</b>
                    {datatypes.jobTypeSelect[job.jobTypeSelect]}
                  </p>
                {/if}
                <div class="clearfix" />
              {/if}
              {#if job.jobTypeSelect != 'non-driving'}
                {#if job.trailerType && job.trailerType.length > 0}
                  <p>
                    <b>Trailer Type:</b>
                    <span
                      style=" display: inline-block; max-width: calc(100% - 76px);
                    line-height: 1em; vertical-align: text-top; "
                    >
                      {jobTools.formatTrailerTypes(
                        datatypes.trailerType,
                        job.trailerType
                      )}
                    </span>
                  </p>
                  <div class="clearfix" />
                {/if}
              {/if}
              {#if job.jobTypeSelect != 'non-driving'}
                {#if job.milesType}
                  {#if job.milesType == 'milesperweek'}
                    <p>
                      <b>Miles Per Week:</b>
                      {datatypes.milesTypeFns.describe(job)}
                    </p>
                    <div class="clearfix" />
                  {:else if job.milesType == 'milespermonth'}
                    <p>
                      <b>Miles Per Month:</b>
                      {datatypes.milesTypeFns.describe(job)}
                    </p>
                    <div class="clearfix" />
                  {:else if job.milesType == 'calltodiscuss'}
                    <p><b>Miles Per Week/Month:</b> Call to Discuss</p>
                    <div class="clearfix" />
                  {/if}
                {/if}
              {/if}
              {#if job.payRateRange || job.paymentType || job.payRate}
                {#if job.payRateRange && (job.paymentType == 'paypermile' || job.paymentType == 'payperhour')}
                  <p>
                    <b>Compensation:</b>
                    {datatypes.paymentTypeFns.range(
                      job.paymentType,
                      job.payRateMin,
                      job.payRateMax
                    )}
                  </p>
                  <div class="clearfix" />
                {:else if jobTools.isNumber(job.paymentType, job.payRate)}
                  <p>
                    <b>Compensation:</b>
                    {datatypes.paymentTypeFns.describe(job)}
                  </p>
                  <div class="clearfix" />
                {:else}
                  <p>
                    <b>Compensation:</b>
                    {job.payRate}
                    {#if job.paymentType == 'payperhour'}
                      per hour
                    {:else}per mile{/if}
                  </p>
                  <div class="clearfix" />
                {/if}
              {/if}
              {#if job.bonusOffer}
                <p><b>Bonus:</b> {datatypes.bonusTypeFns.describe(job)}</p>
              {/if}
              <div class="clearfix" />
              {#if job.jobTypeSelect != 'non-driving'}
                {#if job.homeTime || job.homeTimeCustom || job.numDaysHome}
                  <p>
                    <b>Home Time:</b>
                    {#if datatypes.homeTime != 'xDays'}
                      {datatypes.homeTime[job.homeTime]}
                    {:else if job.homeTimeCustom}
                      {job.homeTimeCustom}
                    {:else}{job.numDaysHome} days out before home time{/if}
                  </p>
                  <div class="clearfix" />
                {/if}
              {/if}
              {#if settings.showJobId}
                <p><b>Job ID:</b> {jobid}</p>
              {/if}

              {#if settings.viewJobDetails}
                <div
                  class="JobCard-viewJob"
                  on:click={() => dispatch('openJob')}
                >
                  View Job Details
                </div>
              {/if}
            </div>
          </div>
        {/if}
      {/if}
    </div>
  {:else}
    <div class="JobCard-details">
      <div class="JobCard-info">
        <div class="JobCard-showInfo">
          {#if job.companyName}
            <p><b>Company:</b> {job.companyName}</p>
          {/if}
          <div class="clearfix" />
          {#if job.jobTypeSelect != 'non-driving'}
            {#if job.driverType}
              <p>
                <b>Driver Type:</b>
                {datatypes.driverType[job.driverType]}
              </p>
            {/if}
          {/if}
          <div class="clearfix" />

          {#if job.jobTypeCustom && job.jobTypeCustom !== '' && job.jobTypeSelect}
            <p><b>Job Type:</b> {job.jobTypeCustom}</p>
            <div class="clearfix" />
          {:else}
            <p>
              <b>Job Type:</b>
              {datatypes.jobTypeSelect[job.jobTypeSelect]}
            </p>
          {/if}
          <div class="clearfix" />

          {#if job.jobTypeSelect != 'non-driving'}
            {#if job.trailerType && job.trailerType.length > 0}
              <p>
                <b>Trailer Type:</b>
                <span
                  style=" display: inline-block; max-width: calc(100% - 95px);
                    line-height: 14px; vertical-align: text-top; "
                >
                  {jobTools.formatTrailerTypes(
                    datatypes.trailerType,
                    job.trailerType
                  )}
                </span>
              </p>
              <div class="clearfix" />
            {/if}
          {/if}

          {#if job.jobTypeSelect != 'non-driving'}
            {#if job.milesType && job.milesType !== 'none'}
              {#if job.milesType == 'milesperweek'}
                <p>
                  <b>Miles Per Week:</b>
                  {datatypes.milesTypeFns.describe(job)}
                </p>
                <div class="clearfix" />
              {:else if job.milesType == 'milespermonth'}
                <p>
                  <b>Miles Per Month:</b>
                  {datatypes.milesTypeFns.describe(job)}
                </p>
                <div class="clearfix" />
              {:else if job.milesType == 'calltodiscuss'}
                <p><b>Miles Per Week/Month:</b> Call to Discuss</p>
                <div class="clearfix" />
              {/if}
            {/if}
          {/if}

          {#if (job.payRateRange || job.paymentType || job.payRate) && job.paymentType !== 'none'}
            {#if job.payRateRange && (job.paymentType == 'paypermile' || job.paymentType == 'payperhour')}
              <p>
                <b>Compensation:</b>
                {datatypes.paymentTypeFns.range(
                  job.paymentType,
                  job.payRateMin,
                  job.payRateMax
                )}
              </p>
              <div class="clearfix" />
            {:else if jobTools.isNumber(job.paymentType, job.payRate)}
              <p>
                <b>Compensation:</b>
                {datatypes.paymentTypeFns.describe(job)}
              </p>
              <div class="clearfix" />
            {:else}
              <p>
                <b>Compensation:</b>
                {job.payRate}
                {#if job.paymentType == 'payperhour'}
                  Per Hour
                {:else}Per Mile{/if}
              </p>
              <div class="clearfix" />
            {/if}
          {/if}

          {#if job.jobTypeSelect != 'non-driving'}
            {#if (job.homeTime || job.homeTimeCustom || job.numDaysHome) && job.homeTime !== 'none'}
              <p>
                <b>Home Time:</b>
                {#if job.homeTime !== 'xDays'}
                  {datatypes.homeTime[job.homeTime]}
                {:else if job.homeTimeCustom}
                  {job.homeTimeCustom}
                {:else}{job.numDaysHome} days out before home time{/if}
              </p>
              <div class="clearfix" />
            {/if}
          {/if}

          {#if job.bonusOffer && job.bonusOffer !== 'none'}
            <p><b>Bonus:</b> {datatypes.bonusTypeFns.describe(job)}</p>
          {/if}
          <div class="clearfix" />

          {#if job.payType && job.payType !== 'none'}
            {#if job.payType && (job.payType === 'range' || job.payType === 'monthlyRange' || job.payType === 'annuallyRange')}
              <p>
                <b>Estimated Pay:</b>
                {datatypes.payTypeFns.range(
                  job.payType,
                  job.payMin,
                  job.payMax
                )}
              </p>
              <div class="clearfix" />
            {:else}
              <p>
                <b>Estimated Pay:</b>
                {datatypes.payTypeFns.describe(job)}
              </p>
              <div class="clearfix" />
            {/if}
          {/if}

          <div class="clearfix" />
          {#if job.customAttributes && job.customAttributes !== []}
            {#each job.customAttributes as customAttributes}
              <div class="clearfix" />
              <p class:spacer={!_.isEmpty(_.trim(customAttributes.title))}>
                <b>
                  {#if !_.isEmpty(_.trim(customAttributes.title))}
                    {_.upperFirst(customAttributes.title)}:
                  {/if}
                </b>
                {_.upperFirst(customAttributes.value)}
              </p>
              <div class="clearfix" />
            {/each}
          {/if}

          {#if settings.showJobId}
            <p><b>Job ID:</b> {jobid}</p>
          {/if}

          {#if settings.viewJobDetails}
            <div class="JobCard-viewJob" on:click={() => dispatch('openJob')}>
              View Job Details
            </div>
          {/if}
        </div>
      </div>
    </div>
  {/if}
{/if}

<style src="./JobCardDetails.scss">
</style>
