import _ from 'lodash';

export const questions = {
  firstName: {
    key: 'firstName',
    label: 'First Name',
    type: 'input',
    inputType: 'text',
    validator: 'required',
  },
  lastName: {
    key: 'lastName',
    label: 'Last Name',
    type: 'input',
    inputType: 'text',
    validator: 'required',
  },
  dob: {
    key: 'dob',
    label: 'Date of Birth',
    type: 'input',
    inputType: 'date',
    validator: 'required',
    dob: true,
  },
  email: {
    key: 'email',
    label: 'Email Address',
    type: 'input',
    inputType: 'email',
    validator: 'email',
  },
  phone: {
    key: 'phone',
    label: 'Phone',
    type: 'input',
    inputType: 'tel',
    validator: 'required',
  },
  zip: {
    key: 'zip',
    label: 'Zip Code',
    type: 'input',
    inputType: 'tel',
    validator: 'zipcode',
  },
  cdlLicense: {
    label: 'Do you have a Class A CDL?',
    type: 'select',
    key: 'cdlLicense',
    validator: 'required',
    options: [
      {
        value: true,
        title: 'Yes',
      },
      {
        value: false,
        title: 'No',
      },
    ],
  },
  experience: {
    label: 'How much verifiable experience do you have using your CDL?',
    type: 'select',
    key: 'experience',
    validator: 'required',
    options: [
      {
        value: 'needtraining',
        title: 'Need CDL Training',
      },
      {
        value: 'intraining',
        title: 'In CDL School Now',
      },
      {
        value: 'graduatedtraining',
        title: 'CDL Grad, No Experience Yet',
      },
      {
        value: '1-5_months',
        title: '1-5 Months',
      },
      {
        value: '6-11_months',
        title: '6-11 Months',
      },
      {
        value: '12-23_months',
        title: '12-23 Months',
      },
      {
        value: '2_years',
        title: '2 Years',
      },
      {
        value: '3_years',
        title: '3 Years',
      },
      {
        value: '4_years',
        title: '4 Years',
      },
      {
        value: '5+_years',
        title: '5+ Years',
      },
    ],
  },
  militaryExperience: {
    label: 'Do you have any military experience?',
    type: 'select',
    key: 'militaryExperience',
    options: [
      {
        value: 'yes',
        title: 'Yes',
      },
      {
        value: 'no',
        title: 'No',
      },
    ],
  },
  endorsements: {
    label: 'Do you have any endorsements?',
    type: 'multiSelect',
    key: 'endorsements',
    logic: d => {
      return d.cdlLicense === true;
    },
    options: [
      {
        value: 'hEndorsement',
        title: 'H - Hazardous Materials',
      },
      {
        value: 'nEndorsement',
        title: 'N - Tanker Vehicles',
      },
      {
        title: 'T - Doubles or Triples',
        value: 'tEndorsement',
      },
    ],
  },
  violations: {
    label: 'Number of moving violations in the last 3 years:',
    type: 'select',
    key: 'violations',
    validator: 'required',
    options: [
      {
        value: '0',
        title: '0',
      },
      {
        value: '1',
        title: '1',
      },
      {
        value: '2',
        title: '2',
      },
      {
        value: '3',
        title: '3',
      },
      {
        value: '4',
        title: '4',
      },
      {
        value: '5+',
        title: '5+',
      },
    ],
  },
  accidents: {
    label: 'Number of preventable accidents in the last 3 years:',
    type: 'select',
    key: 'accidents',
    validator: 'required',
    options: [
      {
        value: '0',
        title: '0',
      },
      {
        value: '1',
        title: '1',
      },
      {
        value: '2',
        title: '2',
      },
      {
        value: '3',
        title: '3',
      },
      {
        value: '4',
        title: '4',
      },
      {
        value: '5+',
        title: '5+',
      },
    ],
  },
  companyOrOwner: {
    label:
      'Are you a Company Driver, Lease Purchase Driver, or an Owner Operator?',
    type: 'select',
    key: 'companyOrOwner',
    validator: 'required',
    options: [
      {
        value: 'company',
        title: 'Company Driver',
      },
      {
        value: 'leasePurchase',
        title: 'Lease Purchase',
      },
      {
        value: 'owner',
        title: 'Owner Operator',
      },
    ],
  },
  interestOOorLP: {
    label:
      'Are you interested in becoming an Owner Operator or hearing about Lease Purchase opportunities?',
    type: 'select',
    key: 'interestOOorLP',
    logic: d => {
      return d.companyOrOwner === 'company';
    },
    options: [
      {
        value: 'yes',
        title: 'Yes',
      },
      {
        value: 'no',
        title: 'No',
      },
    ],
  },
  powerUnitOrOwnAuthority: {
    label:
      'Are you a Power Unit Only Owner Operator or do you have your Own Authority?',
    type: 'select',
    key: 'powerUnitOrOwnAuthority',
    logic: d => {
      return d.companyOrOwner === 'owner';
    },
    options: [
      {
        value: 'power-unit',
        title: 'Power Unit Only',
      },
      {
        value: 'own-authority',
        title: 'Own Authority',
      },
    ],
  },
  soloOrTeam: {
    label: 'Are you a Solo Driver or a Team Driver?',
    validator: 'required',
    type: 'select',
    key: 'soloOrTeam',
    options: [
      {
        value: 'solo',
        title: 'Solo Driver',
      },
      {
        value: 'team',
        title: 'Team Driver',
      },
    ],
  },
  interestTeamDriver: {
    label:
      'Would you be interested in becoming a team driver or matching up with another solo driver if it meant you would make more money and run in nicer equipment?',
    validator: 'required',
    type: 'select',
    key: 'interestTeamDriver',
    logic: d => {
      return d.soloOrTeam === 'solo';
    },
    options: [
      {
        value: 'yes',
        title: 'Yes',
      },
      {
        value: 'no',
        title: 'No',
      },
    ],
  },
  mostCommonFreight: {
    label: 'What type of freight do you most commonly haul?',
    validator: 'required',
    type: 'select',
    key: 'mostCommonFreight',
    options: _.map(
      {
        dryVan: 'Dry Van',
        reefer: 'Refrigerated (Reefer)',
        flatbed: 'Flatbed',
        tanker: 'Tanker',
        doubtrip: 'Doubles / Triples',
        intermodal: 'Intermodal',
        hhg: 'Household Goods',
        hazmat: 'Hazmat', // direct leads only
        specialized: 'Specialized',
        hauler: 'Auto Hauler',
        doublepups: 'Double Pups', // direct leads only
        dryBulk: 'Dry Bulk & Pneumatic',
        // other:        'Other'  -- deprecated
      },
      (title, value) => {
        return { value, title };
      }
    ),
  },
  trailerTypes: {
    label: 'What other freight types would you be interested in hauling?',
    validator: 'required',
    type: 'multiSelect',
    key: 'trailerTypes',
    options: _.map(
      {
        dryVan: 'Dry Van',
        reefer: 'Refrigerated (Reefer)',
        flatbed: 'Flatbed',
        tanker: 'Tanker',
        doubtrip: 'Doubles / Triples',
        intermodal: 'Intermodal',
        hhg: 'Household Goods',
        hazmat: 'Hazmat', // direct leads only
        specialized: 'Specialized',
        hauler: 'Auto Hauler',
        doublepups: 'Double Pups', // direct leads only
        dryBulk: 'Dry Bulk & Pneumatic',
        // other:        'Other'  -- deprecated
      },
      (title, value) => {
        return { value, title };
      }
    ),
  },
  bestContactTime: {
    label: 'What is the best time to contact you?',
    type: 'select',
    key: 'bestContactTime',
    options: [
      {
        value: 'anytime',
        title: 'Anytime',
      },
      {
        value: 'morning',
        title: 'Morning',
      },
      {
        value: 'afternoon',
        title: 'Afternoon',
      },
      {
        value: 'evening',
        title: 'Evening',
      },
    ],
  },
};

export const cdl = [
  {
    title: 'Personal Info',
    questions: [
      questions.firstName,
      questions.lastName,
      questions.dob,
      questions.email,
      questions.phone,
      questions.zip,
    ],
  },
  {
    title: 'Tell us about your experience',
    questions: [
      questions.cdlLicense,
      questions.experience,
      questions.endorsements,
      questions.militaryExperience,
      questions.violations,
      questions.accidents,
      questions.companyOrOwner,
      questions.interestOOorLP,
      questions.powerUnitOrOwnAuthority,
      questions.soloOrTeam,
      questions.interestTeamDriver,
      questions.mostCommonFreight,
      questions.trailerTypes,
      questions.bestContactTime,
    ],
  },
];
