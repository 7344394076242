<svelte:options tag="cdlc-live-chat" />

<script context="module">
  /*
LiveChatEventEmitter will provide module-level events that can be listened
too for when conversations start.

Example:
LiveChatEventEmitter.on('started', (convoId) => {
  // do what I want
})
*/

  class EventEmitterClass {
    constructor() {
      this._listeners = {};
    }

    on(key, fn) {
      this._listeners[key] = this._listeners[key] || [];
      this._listeners[key].push(fn);
    }

    off(key, fn) {
      this._listeners[key] = this._listeners[key] || [];
      this._listeners[key] = this._listeners[key].filter(d => {
        return d !== fn;
      });
    }

    emit(key, ...args) {
      (this._listeners[key] || []).forEach(fn => {
        fn.apply(this, args);
      });
    }
  }

  export const LiveChatEventEmitter = new EventEmitterClass();
</script>

<script>
  import moment from 'moment';
  import debug from 'debug';
  import './LiveChatIcon/LiveChatIcon.svelte';
  import './LiveChatPopup/LiveChatPopup.svelte';
  import './TypingIcon/TypingIcon.svelte';
  import { createEventDispatcher } from 'svelte';
  import {
    get_current_component,
    onDestroy,
    onMount,
    tick,
  } from 'svelte/internal';
  import io from 'socket.io-client';

  const log = debug('cdlc:LiveChat'),
    svelteDispatch = createEventDispatcher(),
    component = get_current_component(),
    dispatch = (name, detail) => {
      svelteDispatch(name, detail);
      component.dispatchEvent &&
        component.dispatchEvent(new CustomEvent(name, { detail }));
    },
    API =
      localStorage.getItem('JOBBOARD_API') ||
      'https://dedicatedjobs.cdllife.com';

  export let company_id;
  export let user_id;
  export let job_id;
  export let click;
  export let client_name;
  export let client_logo;

  let messageInput = '',
    phoneInput = '',
    existingPhone,
    leadFormPhone,
    convoId = null,
    messageArray = [],
    convo = null,
    chatOpen = false,
    messageCount = 0,
    onFocus = true,
    clientTyping = false,
    liveChatOn = false,
    firstTimeout = false,
    popupTimeout,
    popup,
    initialMessage,
    conversationSocket,
    defaultMessageExists = false,
    messageContainer,
    messageClickedArray = [],
    dateHeaderArray = [],
    today = moment().startOf('day'),
    connected = false,
    setDefaultMessage;

  conversationSocket = io.connect(`${API}/chat-conversations`, {
    transports: ['websocket'],
  });

  $: convoLocalStorageID = `CDL_LIVE_CHAT_CONVO_${company_id}`;

  $: if (convo) {
    liveChatOn = true;
    clearTimeout(setDefaultMessage);
    conversationSocket.emit('driver-live', convo);
  }

  $: if (convoId) {
    LiveChatEventEmitter.emit('started', convoId);
  }

  $: messageArray &&
    messageArray.length &&
    messageContainer &&
    scrollToBottom();

  LiveChatEventEmitter.on('hide', () => {
    if (!convoId) {
      liveChatOn = false;
    }
  });

  onMount(async () => {
    setTimeout(async () => {
      await tick();
      await connectSocket();
      windowEventListeners();
      setInterval(checkPageFocus, 30000);
      initializeCPIX();
      checkForExistinConvo();
      checkForExistingPhone();
      checkForLeadFormPhone();
      conversationSocket.emit('driver-available', {
        convoId: convoId ? convoId : null,
        companyId: company_id || user_id,
        jobId: job_id,
      });
    }, 0);
  });

  //using invtervals to update whether a driver is still on the chat
  function checkPageFocus() {
    if (document.hasFocus() && convo) {
      fetch(
        `${API}/api/topics/chatSystem/resources/chat-conversations/${convoId}`,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            receivedSettings: {
              liveChat: {
                lastSeen: new Date(),
              },
            },
          }),
        }
      );
    } else {
      log('either this page is unfocused or there is not convo');
    }
  }

  async function checkForExistinConvo() {
    const existingId = localStorage.getItem(convoLocalStorageID);

    if (existingId) {
      logEvent('existing-convo');
      log('Exising convo', existingId);

      // TODO ... sort out later
      convo = await fetch(
        `${API}/api/topics/chatSystem/resources/chat-conversations/${existingId}`
      ).then(res => res.json());
      // convo = res && res.data;

      log('got existing convo');
      if (convo && convo.data._id) {
        convoId = convo.data._id;
        conversationSocket.emit('user-join-room', convoId);
        messageArray = convo.data.messages;
      }
    }
  }

  async function scrollToBottom() {
    try {
      if (messageContainer) {
        await tick();
        messageContainer.scrollTo(0, messageContainer.scrollHeight);
      }
    } catch (e) {}
  }

  function checkForExistingPhone() {
    if (localStorage.getItem('CDL_LIVE_CHAT_PHONE')) {
      existingPhone = localStorage.getItem('CDL_LIVE_CHAT_PHONE');
      logEvent('existing-phone');
    }
  }

  function checkForLeadFormPhone() {
    if (
      localStorage.getItem('LEADFORM|cdlc-lead-form') &&
      localStorage.getItem('LEADFORM|cdlc-lead-form').info &&
      localStorage.getItem('LEADFORM|cdlc-lead-form').info.phone
    ) {
      try {
        leadFormPhone = JSON.parse(
          localStorage.getItem('LEADFORM|cdlc-lead-form')
        ).info.phone;
        phoneInput = leadFormPhone;
        logEvent('leadform-found');
      } catch (e) {
        console.error(e);
      }
    }
  }

  function validatePhoneNumbers(phone) {
    const reg = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/g;
    return reg.test(phone);
  }

  function initializeCPIX() {
    if (window['cpix']) return; // already set

    //CPIX STUFF FOR DEV - uncomment if needed
    !(function (e, t, c, n, i, p, s, a, o) {
      e[i] ||
        (((s = e[i] =
          function () {
            s.process ? s.process.apply(s, arguments) : s.queue.push(arguments);
          }).queue = []),
        (s.t = 1 * new Date()),
        ((a = t.createElement(c)).async = 1),
        (a.src =
          'https://cdllife.com/tr/pixel.js?t=' +
          864e5 * Math.ceil(new Date() / 864e5)),
        (o = t.getElementsByTagName(c)[0]).parentNode.insertBefore(a, o));
    })(window, document, 'script', 0, 'cpix'),
      cpix('init', 'ID-876628911');
    //END OF CPIX STUFF FOR DEV
  }

  function logEvent(name, metadata) {
    try {
      cpix('event', 'live-chat-' + name, metadata || {});
    } catch (e) {}
  }

  function connectSocket() {
    //connecting to conversation namespace
    // conversationSocket = io.connect(`${API}/chat-conversations`, {
    //   transports: ['websocket'],
    // });

    //setting up connection and joining room
    conversationSocket.on('connect', () => {
      log('establishing a chat connection');
      connected = true;
      // conversationSocket.emit('driver-available', {
      //   companyId: company_id,
      //   jobId: job_id,
      // });
    });

    conversationSocket.on('client-typing', () => {
      clientTyping = true;
    });

    conversationSocket.on('client-not-typing', () => {
      clientTyping = false;
    });

    //handling new messages
    conversationSocket.on('new-message', data => {
      if (data.sender === 'client') {
        messageCount++;
      }
      data.dates = {
        created: data['dates.created'],
        updated: data['dates.updated'],
      };
      dateHeaderArray = [];
      messageArray.push(data);
      messageArray = messageArray;
      logEvent('message-received');
    });

    conversationSocket.once('company-available', info => {
      console.log('company is now available', info);
      liveChatOn = true;

      if (convo) {
        conversationSocket.emit('driver-live', convo);
      }

      if (info.chatSettings) {
        const { defaultMessage, messageDelay } = info.chatSettings;

        if (defaultMessage) {
          initialMessage = {
            sender: 'client',
            message: defaultMessage,
            messageType: 'MESSAGE',
            metadata: {
              specialQuestion: 'DEFAULT_MESSAGE',
            },
            dates: {
              created: new Date(),
              updated: new Date(),
            },
          };
        }

        if (messageDelay) {
          setDefaultMessage = setTimeout(() => {
            messageArray.push(initialMessage);
            messageArray = messageArray;
            defaultMessageExists = true;
            messageCount = 1;
          }, messageDelay * 1000);
          logEvent('default-message-had-delay');
        }
      }
    });
  }

  function windowEventListeners() {
    //checking if window is focused - update last seen
    window.addEventListener('focus', () => {
      if (convo) {
        fetch(
          `${API}/api/topics/chatSystem/resources/chat-conversations/${convoId}`,
          {
            method: 'PUT',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              receivedSettings: {
                liveChat: {
                  lastSeen: new Date(),
                },
              },
            }),
          }
        );
        if (popup) {
          onFocus = false;
        } else {
          onFocus = true;
          clearTimeout(popupTimeout);
        }
        log('last seen updated', convo);
        logEvent('window-focused');
      }
    });

    window.addEventListener('beforeunload', function (e) {
      e.preventDefault();
      logEvent('component-unloaded');
      if (convo) {
        conversationSocket.emit('driver-not-live', convo);
      }
    });
  }

  function openChatWidget() {
    logEvent('chat-opened');
    dateHeaderArray = [];
    chatOpen = true;
    messageCount = 0;
  }

  async function createMessage(message, company_id) {
    if (message === '') {
      return;
    }
    if (!convo) {
      //create a conversation if none previously exist
      logEvent('convo-created');
      convo = await fetch(
        `${API}/api/topics/chatSystem/resources/chat-conversations`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user: user_id,
            company: company_id,
            job: job_id,
            convoStatus: 'active',
            receivedMethod: 'liveChat',
            receivedSettings: {
              liveChat: {
                lastSeen: new Date(),
                tr: (() => {
                  let tr = {};
                  try {
                    const pixel = new cpix.Pixel();
                    tr = pixel.paramKeys;
                  } catch (e) {
                    log(e);
                  }
                  return tr;
                })(),
              },
              sms: {
                to: existingPhone,
              },
            },
          }),
        }
      ).then(res => {
        return res.json();
      });
      convoId = convo.data._id;
      conversationSocket.emit('user-join-room', convoId);
      localStorage.setItem(convoLocalStorageID, convoId);
      if (defaultMessageExists) {
        console.log(initialMessage);
        await fetch(
          `${API}/api/topics/chatSystem/resources/chat-conversations/${convoId}/messages`,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(initialMessage),
          }
        ).then(res => {
          return res.json();
        });
      }
    }

    logEvent('new-message');

    await fetch(
      `${API}/api/topics/chatSystem/resources/chat-conversations/${convoId}/messages`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sender: 'applicant',
          message: message,
          messageType: 'MESSAGE',
          readStatus: 'UNREAD',
          dates: {
            created: new Date(),
            updated: new Date(),
          },
        }),
      }
    ).then(res => {
      return res.json();
    });
    messageInput = '';
  }

  //if popup is clicked - close it and update last seen
  async function continueClick() {
    logEvent('continue-click');
    await fetch(
      `${API}/api/topics/chatSystem/resources/chat-conversations/${convoId}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          receivedSettings: {
            liveChat: {
              lastSeen: new Date(),
            },
          },
        }),
      }
    );
    onFocus = true;
  }

  //this button does nothing really currently
  async function doneClick() {
    logEvent('done-click');
    onFocus = true;
  }

  //if focus is false - display popup after 30 sec
  //if user returns and focus goes false again - popup after 5 minutes
  window.addEventListener('blur', () => {
    if (convo && !firstTimeout) {
      popupTimeout = setTimeout(() => {
        firstTimeout = true;
        onFocus = false;
      }, 30000);
    } else if (convo && firstTimeout) {
      popupTimeout = setTimeout(() => {
        onFocus = false;
      }, 500000);
    }
    logEvent('window-lost-focus');
  });

  function phoneSubmit(phoneNumber) {
    if (validatePhoneNumbers(phoneNumber)) {
      localStorage.setItem('CDL_LIVE_CHAT_PHONE', phoneNumber);
      logEvent('phone-submit');
      fetch(
        `${API}/api/topics/chatSystem/resources/chat-conversations/${convoId}`,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            receivedSettings: {
              liveChat: {
                lastSeen: new Date(),
              },
              sms: {
                to: phoneNumber,
              },
            },
          }),
        }
      ).then(res => {
        return res.json();
      });
    } else {
      log('phone is not valid');
    }
    phoneInput = '';
  }

  function replaceURLString(message, fullMessage) {
    let safeResults = safe_tags_replace(message);
    let result = safeResults.replace(
      /(\b(https?|ftp|file):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;]*[\-A-Z09+&@#\/%=~_|])/gim,
      `<a style="color: ${
        fullMessage.sender === 'applicant' ? '#e0e0e0' : '#2e5ff6'
      }" target="_blank" href="$1">$1</a>`
    );
    return result;
  }

  var tagsToReplace = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
  };

  function replaceTag(tag) {
    return tagsToReplace[tag] || tag;
  }

  function safe_tags_replace(str) {
    return str.replace(/[&<>]/g, replaceTag);
  }

  function messageClick(message) {
    if (messageClickedArray.includes(message._id)) {
      let idx = messageClickedArray.findIndex(id => id === message._id);
      messageClickedArray.splice(idx, 1);
      messageClickedArray = messageClickedArray;
    } else {
      messageClickedArray.push(message._id);
      messageClickedArray = messageClickedArray;
    }
    logEvent('message-clicked');
  }

  function getStartDates(messageDate) {
    let startOfDate = moment(messageDate).startOf('day').format('LL');
    if (dateHeaderArray.includes(startOfDate)) {
      return false;
    } else {
      dateHeaderArray.push(startOfDate);
      dateHeaderArray = dateHeaderArray;
      return true;
    }
  }
</script>

{#if !onFocus}
  <cdlc-live-chat-popup
    bind:this={popup}
    done_click={doneClick}
    click={continueClick}
  />
{/if}

{#if liveChatOn}
  {#if !chatOpen}
    <cdlc-live-chat-icon
      client_typing={clientTyping}
      message_total={messageCount}
      on:click={openChatWidget}
    />
  {:else}
    <div class="cdlc-LiveChat">
      <div class="container">
        <div class="header">
          <div class="headerContent">
            <img class="companyIcon" src={client_logo} alt="Logo" />

            <div class="headerText">
              <p class="smallText">YOU ARE SPEAKING TO</p>
              <p class="bigText">{client_name}</p>
            </div>
          </div>
          <button
            class="exitButton"
            on:click={() => {
              chatOpen = false;
            }}
          >
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
              />
            </svg>
          </button>
        </div>
        <div class="conversationContainer" bind:this={messageContainer}>
          {#each messageArray as message}
            {#if getStartDates(message.dates && message.dates.created) && message.dates && message.dates.created}
              <div class="dateHeader">
                {#if moment(message.dates.created).isSame(today, 'd')}
                  <p>Today</p>
                {:else}
                  <p>
                    {moment(message.dates.created)
                      .startOf('day')
                      .format('LL') ||
                      moment(message['dates.created'])
                        .startOf('day')
                        .format('LL')}
                  </p>
                {/if}
              </div>
            {/if}
            <div class="messageContainer" on:click={messageClick(message)}>
              <div
                class="{message.sender === 'applicant'
                  ? 'applicant'
                  : 'client'} messageDisplay"
              >
                <p>{@html replaceURLString(message.message, message)}</p>
              </div>
              {#if message && message.metadata && message.metadata.specialQuestion === 'PHONE_NUMBER'}
                <div class="messageDisplay client phoneInput">
                  <form>
                    <input
                      bind:value={phoneInput}
                      placeholder="Phone number..."
                      type="tel"
                      name="phone"
                    />
                    <button
                      on:click|preventDefault={() => phoneSubmit(phoneInput)}
                      >Send</button
                    >
                  </form>
                </div>
              {/if}
            </div>
            {#if messageClickedArray.filter(id => id === message._id).length > 0}
              <div
                class={message.sender === 'applicant'
                  ? 'timeApplicant'
                  : 'timeClient'}
              >
                <p>
                  {moment(message.dates && message.dates.created).format('LT')}
                </p>
              </div>
            {/if}
          {/each}
          {#if clientTyping}
            <div class="messageDisplay client">
              <cdlc-live-chat-typing-icon />
            </div>
          {/if}
        </div>
        <div class="footer">
          <form>
            <input
              type="text"
              class="messageInput"
              bind:value={messageInput}
              placeholder="Type message here"
            />
            <button
              class="sendButton"
              on:click|preventDefault={() =>
                createMessage(messageInput, company_id)}
            >
              Send</button
            >
          </form>
        </div>
      </div>
    </div>
  {/if}
{/if}

<style src="./LiveChat.scss">
</style>
