<svelte:options tag="cdlc-lead-form" />

<script>
  import '../Calendar/Calendar.svelte';
  import '../FileUpload/FileUpload.svelte';
  import _ from 'lodash';
  import debug from 'debug';
  import { createEventDispatcher } from 'svelte';
  import { get_current_component } from 'svelte/internal';
  import validators from './LeadForm.validators.js';

  const log = debug('cdlc:LeadForm'),
    svelteDispatch = createEventDispatcher(),
    component = get_current_component(),
    dispatch = (name, detail) => {
      svelteDispatch(name, detail);
      component.dispatchEvent &&
        component.dispatchEvent(new CustomEvent(name, { detail }));
    };
  export let sections = [];
  export let button = 'Submit';
  export let request = {
    url: '/',
  };
  export let has_terms = true;
  export let custom_terms = '';
  export let custom_terms_checkbox = '';

  // if provided, will store results under this key
  export let store_results_key = null;
  export let default_query_param_key = null;
  export let query_param_mapper = d => d;
  // when skip_lead_submit is true, dispatches lead data but doesn not apply lead to job
  export let skip_lead_submit = false;

  // We can provide a list of plugin functionality
  // Options:
  //   - recaptcha
  //   - cpix
  export let plugins = ['cpix'];
  export let plugin_settings = {};

  const defaultPluginSettings = {
    cpix: {
      // no settings for now
    },
    recaptcha: {
      server: null, // required value or skipped
      minScore: 0.5,
      // product: 'cdllife'
    },
  };

  let storedDefaults = {};

  setTimeout(() => {
    log('form');
    log('  request', request);
    log('  sections', sections);
  });

  let mappedAnswers = {},
    mappedSections = [],
    hasAttemptedSubmit = false,
    submitting = false,
    submitted = false,
    errors = {},
    leadForm;

  $: isValid = !_.size(errors);

  $: if (store_results_key || default_query_param_key) {
    try {
      const parsedUrl = new URL(window.location.href);
      let value = parsedUrl.searchParams.get(default_query_param_key);
      let queryValues = JSON.parse(atob(value));
      storedDefaults = _.merge(storedDefaults, query_param_mapper(queryValues));
    } catch (e) {}
    try {
      storedDefaults = _.merge(
        storedDefaults,
        JSON.parse(localStorage.getItem('LEADFORM|' + store_results_key))
      );

      // don't prefill autogenerated emails
      if (storedDefaults.info) {
        if (_.includes(storedDefaults.info.email, 'user.cdllife.com')) {
          storedDefaults.info.email = '';
        }
      }
    } catch (e) {}
  }

  $: {
    const terms = _.get(_.last(mappedSections), 'questions.0.value');

    mappedSections = sections.map(s => {
      s.questions = s.questions || [];
      s.questions.forEach(q => {
        q.value =
          q.value === undefined
            ? _.get(storedDefaults, q.key, q.default)
            : q.value;
        q.type = q.type || 'input';
      });
      return s;
    });

    if (has_terms === 'liveTrucking') {
      mappedSections.push({
        title: 'Terms',
        class: 'TermsSection',
        questions: [
          {
            type: 'checkbox',
            key: 'terms',
            value: !!terms,
            label: `
I agree to the
<a href="/privacy-policy/#terms" class="Link" target="_blank">Terms of Use</a>
&amp;
<a href="/privacy-policy/#privacy" class="Link" target="_blank">Privacy Policy</a>*
        `,
          },
        ],
      });
    } else if (has_terms) {
      mappedSections.push({
        title: 'Terms',
        class: 'TermsSection',
        questions: [
          {
            type: 'checkbox',
            key: 'terms',
            value: !!terms,
            label:
              custom_terms_checkbox ||
              `
I agree to the
<a href="https://cdllife.com/privacy-policy-terms/#terms" class="Link" target="_blank">Terms of Use</a>
&amp;
<a href="https://cdllife.com/privacy-policy-terms/#privacy" class="Link" target="_blank">Privacy Policy</a>*
        `,
          },
        ],
      });
    }
  }

  $: {
    errors = {};
    mappedAnswers = {};
    mappedSections.forEach(s => {
      s.questions.forEach(q => {
        _.set(mappedAnswers, q.key, q.value);
      });
    });

    mappedSections.forEach(s => {
      s.questions.forEach(q => {
        // no validation if question isn't shown
        if (q.logic && !q.logic(mappedAnswers)) {
          return;
        }

        if (q.validator && hasAttemptedSubmit) {
          const fn = validators[q.validator];
          if (fn) {
            const error = fn(q.value);
            if (error) {
              errors[q.key] = error;
            }
          }
        }
      });
    });

    if (store_results_key) {
      try {
        const ma = _.clone(mappedAnswers);
        let current;
        try {
          current = JSON.parse(
            localStorage.getItem('LEADFORM|' + store_results_key)
          );
        } catch (e) {}
        dispatch('storeResults', {
          current: current || {},
          updated: ma,
        });
        localStorage.setItem(
          'LEADFORM|' + store_results_key,
          JSON.stringify(ma)
        );
      } catch (e) {}
    }
  }

  function hasPlugin(plugin) {
    return _.includes(plugins, plugin);
  }

  function getPluginSetting(plugin, key, def) {
    const k = `${plugin}.${key}`;
    return _.get(plugin_settings, k, _.get(defaultPluginSettings, k, def));
  }

  function handlePluginCPIX(mappedAnswers) {
    // only if cpix is installed
    try {
      const cpix = window['cpix'],
        p = new cpix.Pixel(),
        keys = p.paramKeys;

      _.each(keys, (v, k) => {
        if (v === undefined) {
          delete keys[k];
        }
      });

      keys.ed = JSON.stringify({});

      mappedAnswers['tr'] = keys;
    } catch (e) {}
  }

  async function handlePluginRecaptcha(mappedAnswers) {
    const gr = window['grecaptcha'];

    // we'll just skip
    if (!gr) {
      return;
    }

    const minScore = getPluginSetting('recaptcha', 'minScore'),
      server = getPluginSetting('recaptcha', 'server');

    if (!server) {
      return log('no server url provided for recaptcha', plugin_settings);
    }

    if (!minScore) {
      return log('no min score for recaptca');
    }

    log('checking recaptcha with score %s (url=%s)', minScore, server);

    return new Promise((resolve, reject) => {
      grecaptcha.ready(() => {
        grecaptcha
          .execute('6Lf7-asUAAAAALjDu7yPWNgrXC0gcGVhr7YOX9iA', {
            action: 'application',
          })
          .then(
            async token => {
              const res = await fetch(
                server +
                  `?recaptchaToken=${token}&minScore=${minScore}&product=${getPluginSetting(
                    'recaptcha',
                    'product',
                    'cdllife'
                  )}`
              ).then(d => d.json());
              const requestScore = parseFloat(
                  _.get(res, 'data.recaptchaResponse.score')
                ),
                minimumScore = parseFloat(minScore);
              let passedCheck = false;
              if (requestScore > minimumScore) {
                passedCheck = true;
              }

              resolve({
                passedAllChecks: passedCheck,
                minimumScore: minimumScore,
                remoteIPAddress: _.get(res, 'data.remoteIPAddress'),
                recaptchaV3Response: _.get(res, 'data.recaptchaResponse'),
              });
            },
            e => {
              resolve({
                passedAllChecks: false,
                grecaptchaError: true,
                minimumScore: parseFloat(minScore),
                recaptchaV3Response: String(e) || 'Unknown Error',
              });
            }
          )
          .catch(e => {
            console.error('Error coming from recaptca', e);
            resolve({
              passedAllChecks: false,
              grecaptchaError: true,
              minimumScore: parseFloat(minScore),
              recaptchaV3Response: String(e) || 'Unknown Error',
            });
          });
      });
    }).then(res => {
      mappedAnswers.security = res;
    });
  }

  function submit() {
    hasAttemptedSubmit = true;

    if (submitting || submitted) {
      return;
    }

    return setTimeout(async () => {
      if (_.size(errors)) {
        try {
          const el = leadForm.querySelector('.is-invalid');
          el.scrollIntoView({ behavior: 'smooth' });
        } catch (e) {}
        return;
      }

      const answers = _.cloneDeep(mappedAnswers);

      log('answers', answers);

      submitting = true;

      if (hasPlugin('cpix')) {
        handlePluginCPIX(mappedAnswers);
      }

      if (hasPlugin('recaptcha')) {
        await handlePluginRecaptcha(mappedAnswers);
      }

      dispatch('presubmit', mappedAnswers);
      log('after presubmit');

      let promise;

      if (!skip_lead_submit) {
        if (typeof request === 'function') {
          promise = request(mappedAnswers);
        } else {
          const fetchData = {
            method: 'POST',
            body: JSON.stringify(mappedAnswers),
            credentials: 'include',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              pragma: 'no-cache',
              'cache-control': 'no-cache',
            },
          };
          promise = fetch(_.get(request, 'url'), fetchData).then(res => {
            return res.json();
          });
        }

        promise.then(
          json => {
            submitting = false;
            submitted = true;
            dispatch('submit', {
              data: mappedAnswers,
              response: (json && json.data) || json,
            });
          },
          e => {
            submitting = false;
            alert('An unknown error occured');
          }
        );
      } else {
        submitting = false;

        dispatch('submit', {
          data: mappedAnswers,
        });
      }
    });
  }

  function progress(key) {
    setTimeout(() => {
      if (_.get(mappedAnswers, key)) {
        dispatch('progress', {
          key: key,
          value: _.get(mappedAnswers, key),
        });
      }
    });
  }
</script>

<div class="LeadForm" bind:this={leadForm}>
  <div class="LeadForm-content">
    <form action="javascript:;">
      {#each mappedSections as section (section.title)}
        <div class="LeadForm-section {section.class}">
          <div class="LeadForm-sectionTitle">{section.title}</div>
          {#if section.mobileSubTitle}
            <div class="LeadForm-LT-mobileSubTitle">
              {section.mobileSubTitle}
            </div>
          {/if}
          <div class="LeadForm-inputs {section.inputClass || ''}">
            {#each section.questions as question (question.key)}
              {#if !question.logic || question.logic(mappedAnswers)}
                {#if question.type === 'input'}
                  {#if question.inputType === 'tel'}
                    <label
                      class="Leadform-inputInput {question.class}"
                      class:is-invalid={errors[question.key]}
                    >
                      {#if question.topLabel}
                        {question.label}
                        {#if question.toolTipIcon}
                          <div class="tooltip">
                            <svg
                              width="10"
                              height="10"
                              viewBox="0 0 10 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5 1.03125C7.30469 1.03125 9.21875 2.92578
                                9.21875 5.25C9.21875 7.59375 7.32422 9.46875 5
                                9.46875C2.65625 9.46875 0.78125 7.59375 0.78125
                                5.25C0.78125 2.92578 2.65625 1.03125 5
                                1.03125ZM5 0.40625C2.32422 0.40625 0.15625
                                2.59375 0.15625 5.25C0.15625 7.92578 2.32422
                                10.0938 5 10.0938C7.65625 10.0938 9.84375
                                7.92578 9.84375 5.25C9.84375 2.59375 7.65625
                                0.40625 5 0.40625ZM4.29688 7.125C4.16016 7.125
                                4.0625 7.24219 4.0625 7.35938V7.51562C4.0625
                                7.65234 4.16016 7.75 4.29688
                                7.75H5.70312C5.82031 7.75 5.9375 7.65234 5.9375
                                7.51562V7.35938C5.9375 7.24219 5.82031 7.125
                                5.70312 7.125H5.46875V4.39062C5.46875 4.27344
                                5.35156 4.15625 5.23438 4.15625H4.29688C4.16016
                                4.15625 4.0625 4.27344 4.0625
                                4.39062V4.54688C4.0625 4.68359 4.16016 4.78125
                                4.29688 4.78125H4.53125V7.125H4.29688ZM5
                                2.4375C4.64844 2.4375 4.375 2.73047 4.375
                                3.0625C4.375 3.41406 4.64844 3.6875 5
                                3.6875C5.33203 3.6875 5.625 3.41406 5.625
                                3.0625C5.625 2.73047 5.33203 2.4375 5 2.4375Z"
                                fill="#767676"
                              />
                            </svg>

                            <span class="tooltiptext">
                              {question.toolTipText}
                            </span>
                          </div>
                        {/if}
                      {/if}
                      <input
                        name={question.name || question.key}
                        type="tel"
                        on:change={e => {
                          progress(question.key);
                        }}
                        placeholder={question.label}
                        bind:value={question.value}
                      />
                      {#if !question.topLabel}<span>{question.label}</span>{/if}
                    </label>
                  {:else if question.inputType === 'email'}
                    <label
                      class="Leadform-inputInput {question.class}"
                      class:is-invalid={errors[question.key]}
                    >
                      {#if question.topLabel}{question.label}{/if}
                      <input
                        name={question.name || question.key}
                        type="email"
                        bind:value={question.value}
                        on:change={e => {
                          progress(question.key);
                        }}
                        placeholder={question.label}
                      />
                      {#if !question.topLabel}<span>{question.label}</span>{/if}
                    </label>
                  {:else if question.inputType === 'date'}
                    <div class="LeadForm-calendar {question.class}">
                      <cdlc-calendar
                        bind:this={question.calendar}
                        value={question.value}
                        dob={!!question.dob}
                        max_day={question.max_day}
                        on:change={e => {
                          question.value = e.detail.value;
                          progress(question.key);
                        }}
                      >
                        <label
                          class="Leadform-inputInput"
                          class:is-invalid={errors[question.key]}
                        >
                          {#if question.topLabel}{question.label}{/if}
                          <input
                            name={question.name || question.key}
                            on:focus={d => {
                              question.calendar.open();
                              d.target.blur();
                            }}
                            bind:value={question.value}
                            placeholder={question.label}
                          />
                          {#if !question.topLabel}
                            <span>{question.label}</span>
                          {/if}
                        </label>
                      </cdlc-calendar>
                    </div>
                  {:else if question.inputType === 'message'}
                    <label class:is-invalid={errors[question.key]}>
                      {#if question.topLabel}{question.label}{/if}
                      <textarea
                        rows="8"
                        name={question.name || question.key}
                        bind:value={question.value}
                        on:change={() => {
                          progress(question.key);
                        }}
                        placeholder={question.label}
                      />
                    </label>
                  {:else}
                    <label
                      class="Leadform-inputInput {question.class}"
                      class:is-invalid={errors[question.key]}
                    >
                      {#if question.topLabel}{question.label}{/if}
                      <input
                        name={question.name || question.key}
                        bind:value={question.value}
                        on:change={() => {
                          progress(question.key);
                        }}
                        placeholder={question.label}
                      />
                      {#if !question.topLabel}<span>{question.label}</span>{/if}
                    </label>
                  {/if}
                {:else if question.type === 'select'}
                  <label class:is-invalid={errors[question.key]}>
                    {#if question.options.length >= 12}
                      <div
                        class="Selector"
                        class:Selector--fullWidth={question.fullWidth}
                        class:Selector--grid={question.grid}
                      >
                        <span>{question.label}</span>
                        <select
                          bind:value={question.value}
                          on:change={() => {
                            progress(question.key);
                          }}
                        >
                          {#each question.options || [] as o}
                            <option value={o.value}>{o.title}</option>
                          {/each}
                        </select>
                      </div>
                    {:else}
                      <div
                        class="Selector"
                        class:Selector--fullWidth={question.fullWidth}
                        class:Selector--grid={question.grid}
                      >
                        {#if !question.topLabel}<span>{question.label}</span
                          >{/if}
                        {#each question.options || [] as o}
                          <a
                            class="Selector-value"
                            href="javascript:;"
                            on:click={() => {
                              question.value = o.value;
                              progress(question.key);
                            }}
                            class:is-active={question.value === o.value}
                          >
                            {o.title}
                          </a>
                        {/each}
                      </div>
                    {/if}
                  </label>
                {:else if question.type === 'multiSelect'}
                  <label class:is-invalid={errors[question.key]}>
                    <div
                      class="Selector"
                      class:Selector--fullWidth={question.fullWidth}
                      class:Selector--grid={question.grid}
                    >
                      <span>{question.label}</span>
                      <div class="Selector--item">
                        {#each question.options || [] as o}
                          <a
                            class="Selector-value"
                            href="javascript:;"
                            on:click={() => {
                              question.value = question.value || [];
                              let idx = question.value.indexOf(o.value);
                              if (idx >= 0) {
                                question.value = question.value.filter(
                                  v => v != o.value
                                );
                              } else {
                                question.value.push(o.value);
                              }
                              progress(question.key);
                            }}
                            class:is-active={(question.value || []).indexOf(
                              o.value
                            ) >= 0}
                          >
                            {o.title}
                          </a>
                        {/each}
                      </div>
                    </div>
                  </label>
                {:else if question.type === 'file'}
                  <label class:is-invalid={errors[question.key]}>
                    <div class="Selector">
                      <span>{question.label}</span>
                      <cdlc-file-upload
                        style="display:inline-block"
                        accept={question.accept}
                        bind:this={question.file}
                        value={question.value}
                        on:change={e => {
                          question.value = e.detail.value;
                          progress(question.key);
                        }}
                      >
                        {#if question.value}
                          <a class="Selector-value" href="javascript:;">
                            Change File
                          </a>
                        {:else}
                          <a class="Selector-value" href="javascript:;">
                            Upload
                          </a>
                        {/if}
                      </cdlc-file-upload>
                      {#if question.value}
                        <a
                          class="Selector-value"
                          href={question.value}
                          target="_blank"
                        >
                          View File
                          <svg
                            role="img"
                            style="margin-left: 5px; height: 12px;"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <g class="fa-group">
                              <path
                                class="fa-secondary"
                                fill="currentColor"
                                d="M400 320h32a16 16 0 0 1 16 16v128a48 48 0 0
                                1-48 48H48a48 48 0 0 1-48-48V112a48 48 0 0 1
                                48-48h160a16 16 0 0 1 16 16v32a16 16 0 0 1-16
                                16H64v320h320V336a16 16 0 0 1 16-16z"
                                opacity="0.4"
                              />
                              <path
                                class="fa-primary"
                                fill="currentColor"
                                d="M484 224h-17.88a28 28 0 0 1-28-28v-.78L440
                                128 192.91 376.91A24 24 0 0 1 159
                                377l-.06-.06L135 353.09a24 24 0 0 1
                                0-33.94l.06-.06L384 72l-67.21 1.9A28 28 0 0 1
                                288 46.68V28a28 28 0 0 1 28-28h158.67A37.33
                                37.33 0 0 1 512 37.33V196a28 28 0 0 1-28 28z"
                              />
                            </g>
                          </svg>
                        </a>
                      {/if}
                    </div>
                  </label>
                {:else if question.type === 'text'}
                  <label class:is-invalid={errors[question.key]}>
                    <span>
                      {@html question.label}
                    </span>
                  </label>
                {:else if question.type === 'checkbox'}
                  <label class="Checkbox">
                    <input type="checkbox" bind:checked={question.value} />
                    <span>
                      {@html question.label}
                    </span>
                  </label>
                {:else if question.type === 'image'}
                  <div class="vetransPartnerImage">
                    <img
                      alt=""
                      src="https://d2r0eic16r3uxv.cloudfront.net/1602079067522471.png"
                    />
                    <p><b>Thank you for your service.</b></p>
                    <p>
                      LiveTrucking is proudly partnering with
                      <b>Veterans in Trucking</b>
                      to help match CDL drivers with military experience with reputable
                      companies that are specifically hiring Veterans.
                    </p>
                    <p>
                      Please continue to complete this application to apply
                      through the Veterans in Trucking program.
                    </p>
                  </div>
                {/if}
              {/if}
            {/each}
          </div>
        </div>
      {/each}
    </form>
  </div>

  <div class="LeadForm-submit">
    {#if submitting}
      <button disabled>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <g class="fa-group">
            <path
              fill="currentColor"
              d="M478.71 364.58zm-22 6.11l-27.83-15.9a15.92 15.92 0 0
              1-6.94-19.2A184 184 0 1 1 256 72c5.89 0 11.71.29
              17.46.83-.74-.07-1.48-.15-2.23-.21-8.49-.69-15.23-7.31-15.23-15.83v-32a16
              16 0 0 1 15.34-16C266.24 8.46 261.18 8 256 8 119 8 8 119 8 256s111
              248 248 248c98 0 182.42-56.95 222.71-139.42-4.13 7.86-14.23
              10.55-22 6.11z"
              opacity="0.4"
            />
            <path
              class="fa-primary"
              fill="currentColor"
              d="M271.23 72.62c-8.49-.69-15.23-7.31-15.23-15.83V24.73c0-9.11
              7.67-16.78 16.77-16.17C401.92 17.18 504 124.67 504 256a246 246 0 0
              1-25 108.24c-4 8.17-14.37 11-22.26
              6.45l-27.84-15.9c-7.41-4.23-9.83-13.35-6.2-21.07A182.53 182.53 0 0
              0 440 256c0-96.49-74.27-175.63-168.77-183.38z"
            />
          </g>
        </svg>
      </button>
    {:else if !mappedAnswers.terms && has_terms}
      <button disabled>Please accept the terms above</button>
    {:else if hasAttemptedSubmit && !isValid}
      <button disabled>Please complete the form</button>
    {:else}<button on:click={submit}>{button}</button>{/if}
    {#if has_terms === 'liveTrucking'}
      <div class="LeadForm-termsAsterisk">
        *By submitting this application, you agree to the following
        <a class="Link" target="_blank" href="/privacy-policy/#terms">
          Terms of Service
        </a>
        and
        <a class="Link" target="_blank" href="/privacy-policy/#privacy">
          Privacy Policy.
        </a>
        The phone number provided may be used to contact you with relevant opportunities
        via text message.
      </div>
    {:else if has_terms && custom_terms}
      <div class="LeadForm-termsAsterisk">
        {@html custom_terms}
      </div>
    {:else if has_terms}
      <div class="LeadForm-termsAsterisk">
        *By submitting this application, you agree to the following
        <a
          class="Link"
          target="_blank"
          href="https://cdllife.com/privacy-policy-terms/#terms"
        >
          Terms of Service
        </a>
        and
        <a
          class="Link"
          target="_blank"
          href="https://cdllife.com/privacy-policy-terms/#privacy"
        >
          Privacy Policy.
        </a>
        The phone number provided may be used to contact you with relevant opportunities
        via text message or phone call.
      </div>
    {/if}
  </div>
</div>

<style src="./LeadForm.scss">
</style>
