<script>
  import moment from 'moment';
  import debug from 'debug';
  import { createEventDispatcher } from 'svelte';
  import { get_current_component } from 'svelte/internal';

  const log = debug('cdlc:Calendar'),
    svelteDispatch = createEventDispatcher(),
    component = get_current_component(),
    dispatch = (name, detail) => {
      svelteDispatch(name, detail);
      component.dispatchEvent &&
        component.dispatchEvent(new CustomEvent(name, { detail }));
    };

  export let max_day;
  export let min_day;

  export let dob = false;

  export let value = '';
  export const open = () => {
    showPicker = true;
  };

  let month = moment().month() + 1,
    day = moment().date(),
    year = moment().year();

  $: {
    if (value) {
      const parsedDate = moment(value, 'YYYY-MM-DD');
      month = parsedDate.month() + 1;
      day = parsedDate.date();
      year = parsedDate.year();
    } else if (dob) {
      year = 1975;
    }
  }

  let daysOfWeek = [
    ['Sunday', 'Sun'],
    ['Monday', 'Mon'],
    ['Tuesday', 'Tue'],
    ['Wednesday', 'Wed'],
    ['Thursday', 'Thu'],
    ['Friday', 'Fri'],
    ['Saturday', 'Sat'],
  ];

  let monthsOfYear = [
    ['January', 'Jan'],
    ['February', 'Feb'],
    ['March', 'Mar'],
    ['April', 'Apr'],
    ['May', 'May'],
    ['June', 'Jun'],
    ['July', 'Jul'],
    ['August', 'Aug'],
    ['September', 'Sep'],
    ['October', 'Oct'],
    ['November', 'Nov'],
    ['December', 'Dec'],
  ];

  let showPicker = false,
    dobStage = 'year',
    availableMonths,
    availableYears,
    weeks;

  $: currentDate = moment(month + '/' + day + '/' + year, 'M/D/YYYY').startOf(
    'day'
  );
  $: maxDayMoment = moment(
    max_day || moment().format('YYYY-MM-DD'),
    'YYYY-MM-DD'
  );
  $: minDayMoment = moment(
    min_day ||
      moment()
        .subtract(100, 'years')
        .format('YYYY-MM-DD'),
    'YYYY-MM-DD'
  );
  $: minMonths =
    currentDate
      .clone()
      .startOf('month')
      .subtract(1, 'day')
      .endOf('day') < minDayMoment;
  $: maxMonths =
    currentDate
      .clone()
      .endOf('month')
      .add(1, 'day')
      .startOf('day') > maxDayMoment;

  $: {
    const date = currentDate,
      stepThrough = date.clone().startOf('month');

    let currentWeek = [];
    weeks = [currentWeek];

    while (stepThrough < date.endOf('month')) {
      if (!stepThrough.day() && currentWeek.length) {
        while (currentWeek.length < 7) {
          currentWeek.unshift(null);
        }
        currentWeek = [];
        weeks.push(currentWeek);
      }

      currentWeek.push(stepThrough.date());
      stepThrough.add(1, 'day');
    }

    while (currentWeek.length < 7) {
      currentWeek.push(null);
    }

    availableMonths = monthsOfYear;

    availableYears = [];

    let y = dob ? moment().year() - 16 : moment().year();

    for (let i = 0; i < 100; i++) {
      availableYears.push(y - i);
    }
  }

  function dayIsActive(d) {
    if (!value) {
      return false;
    } else {
      const parsedDate = moment(value, 'YYYY-MM-DD');

      return (
        month === parsedDate.month() + 1 &&
        d === parsedDate.date() &&
        year === parsedDate.year()
      );
    }
  }

  function subtractMonth() {
    const date = currentDate;

    date.subtract(1, 'month').startOf('month');

    day = date.date();
    year = date.year();
    month = date.month() + 1;
  }

  function addMonth() {
    const date = currentDate;

    date.add(1, 'month').startOf('month');

    day = date.date();
    year = date.year();
    month = date.month() + 1;
  }

  function selectDay(d) {
    day = d;
    showPicker = false;
    dispatch('change', {
      value: moment(month + '/' + day + '/' + year, 'M/D/YYYY')
        .startOf('day')
        .format('YYYY-MM-DD'),
    });
    dispatch('changed', {
      value: moment(month + '/' + day + '/' + year, 'M/D/YYYY')
        .startOf('day')
        .format('YYYY-MM-DD'),
    });
  }

  function monthEnabled(i, year) {
    const month = moment(i + 1 + '/1/' + year, 'M/D/YYYY');

    if (month.endOf('month').endOf('day') < minDayMoment) {
      return false;
    }

    if (month.startOf('month').startOf('day') > maxDayMoment) {
      return false;
    }

    return true;
  }

  function openPicker(event) {
    showPicker = true;
  }
</script>

<style src="./Calendar.scss">

</style>

<svelte:options tag="cdlc-calendar" />

<div class="Calendar">

  <div class="Calendar-preview" on:click={openPicker}>
    <div class="Calendar-previewMask" />
    <slot />
  </div>

  {#if showPicker}
    <div
      class="Calendar-pickerOverlay"
      on:click={() => {
        showPicker = false;
      }} />

    <div class="Calendar-picker">
      {#if dob && dobStage !== 'day'}
        {#if dobStage === 'year'}
          <div class="Calendar-pickerTop" style="text-align:center">
            <i class="Calendar-pickerLeft disabled" />
            <span>Select Year</span>
            <i class="Calendar-pickerRight disabled" />
          </div>

          <div class="Calendar-dobOption">
            <br />
            <select
              bind:value={year}
              on:change={() => {
                dobStage = 'month';
              }}>
              {#each availableYears as year}
                <option value={year}>{year}</option>
              {/each}
            </select>
            <p>
              <a
                on:click={() => {
                  dobStage = 'month';
                }}>
                Next
              </a>
            </p>

          </div>
        {:else if dobStage === 'month'}
          <div class="Calendar-pickerTop" style="text-align:center">
            <i
              class="Calendar-pickerLeft "
              on:click={() => {
                dobStage = 'year';
              }} />
            <span>Select Month</span>
            <i class="Calendar-pickerRight disabled" />
          </div>

          <div class="Calendar-dobOption">
            <br />
            <select
              bind:value={month}
              on:change={() => {
                dobStage = 'day';
              }}>
              {#each availableMonths as month, i}
                {#if monthEnabled(i, year)}
                  <option value={i + 1}>{month[0]}</option>
                {/if}
              {/each}
            </select>
            <p>
              <a
                on:click={() => {
                  dobStage = 'day';
                }}>
                Next
              </a>
            </p>

          </div>
        {/if}
      {:else}
        <div class="Calendar-pickerTop">
          <i
            class="Calendar-pickerLeft"
            on:click={subtractMonth}
            class:disabled={minMonths} />

          <select bind:value={month}>
            {#each availableMonths as month, i}
              {#if monthEnabled(i, year)}
                <option value={i + 1}>{month[0]}</option>
              {/if}
            {/each}
          </select>

          <select bind:value={year}>
            {#each availableYears as year}
              <option value={year}>{year}</option>
            {/each}
          </select>

          <i
            class="Calendar-pickerRight"
            on:click={addMonth}
            class:disabled={maxMonths} />
        </div>
      {/if}

      {#if !dob || dobStage === 'day'}
        <div class="Calendar-pickerWeekDays">
          {#each daysOfWeek as day}
            <div class="Calendar-pickerWeekDaysHeader">{day[1]}</div>
          {/each}
        </div>
        {#each weeks as week}
          <div class="Calendar-pickerWeekDays">
            {#each week as thisDay}
              {#if thisDay}
                <div class="Calendar-pickerWeekDaysDay">
                  <div
                    class="Calendar-pickerWeekDaysDaySelectable"
                    class:is-active={dayIsActive(thisDay)}
                    on:click={() => {
                      selectDay(thisDay);
                    }}>
                    {thisDay}
                  </div>
                </div>
              {:else}
                <div class="Calendar-pickerWeekDaysDay">&nbsp;</div>
              {/if}
            {/each}
          </div>
        {/each}
      {/if}
    </div>
  {/if}
</div>
