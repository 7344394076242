import _ from 'lodash';

export const questions = {
  firstName: {
    key: 'firstName',
    label: 'First Name  *',
    type: 'input',
    inputType: 'text',
    validator: 'required',
    class: 'Leadform-inputInput--left',
    topLabel: true,
  },
  lastName: {
    key: 'lastName',
    label: 'Last Name *',
    type: 'input',
    inputType: 'text',
    validator: 'required',
    class: 'Leadform-inputInput--right',
    topLabel: true,
  },
  dob: {
    key: 'dob',
    label: 'Date of Birth *',
    type: 'input',
    inputType: 'date',
    validator: 'required',
    class: 'Leadform-inputInput--full',
    topLabel: true,
    dob: true,
  },
  email: {
    key: 'email',
    label: 'Email Address *',
    type: 'input',
    inputType: 'email',
    validator: 'email',
    class: 'Leadform-inputInput--full',
    topLabel: true,
  },
  phone: {
    key: 'phone',
    label: 'Phone Number *',
    type: 'input',
    inputType: 'tel',
    validator: 'required',
    class: 'Leadform-inputInput--left',
    topLabel: true,
    toolTipIcon: true,
    toolTipText: 'Number provided will be given to recruiters',
  },
  zip: {
    key: 'zip',
    label: 'Zip Code *',
    type: 'input',
    inputType: 'tel',
    validator: 'zipcode',
    class: 'Leadform-inputInput--right',
    topLabel: true,
    toolTipIcon: true,
    toolTipText: 'Must be a 5 digit zip code.',
  },
  cdlLicense: {
    label: 'Do you have a Class A CDL? *',
    type: 'select',
    key: 'cdlLicense',
    validator: 'required',
    options: [
      {
        value: true,
        title: 'Yes',
      },
      {
        value: false,
        title: 'No',
      },
    ],
  },
  interestCdlTraining: {
    label: 'Are you interested in hearing about CDL Training opportunities?',
    type: 'select',
    key: 'interestCdlTraining',
    logic: d => {
      return d.cdlLicense === false;
    },
    options: [
      {
        value: true,
        title: 'Yes',
      },
      {
        value: false,
        title: 'No',
      },
    ],
  },
  endorsements: {
    label: 'Do you have any endorsements?',
    type: 'multiSelect',
    key: 'endorsements',
    logic: d => {
      return d.cdlLicense === true;
    },
    options: [
      {
        value: 'hEndorsement',
        title: 'H - Hazardous Materials',
      },
      {
        value: 'nEndorsement',
        title: 'N - Tanker Vehicles',
      },
      {
        value: 'tEndorsement',
        title: 'T - Doubles or Triples',
      },
    ],
  },
  experience: {
    label: 'How much verifiable experience do you have using your CDL? *',
    type: 'select',
    key: 'experience',
    validator: 'required',
    options: [
      {
        value: 'needtraining',
        title: 'Need CDL Training',
      },
      {
        value: 'intraining',
        title: 'In CDL Training',
      },
      {
        value: 'graduatedtraining',
        title: 'Graduated Training, No Experience Yet',
      },
      {
        value: '1-5_months',
        title: '1-5 Months',
      },
      {
        value: '6-11_months',
        title: '6-11 Months',
      },
      {
        value: '12-23_months',
        title: '12-23 Months',
      },
      {
        value: '2_years',
        title: '2 Years',
      },
      {
        value: '3_years',
        title: '3 Years',
      },
      {
        value: '4_years',
        title: '4 Years',
      },
      {
        value: '5+_years',
        title: '5+ Years',
      },
    ],
  },
  dieselTraining: {
    label: 'Would you also be interested in Diesel Mechanic Training?',
    type: 'select',
    key: 'dieselTraining',
    logic: d => {
      return d.experience === 'needtraining';
    },
    options: [
      {
        value: true,
        title: 'Yes',
      },
      {
        value: false,
        title: 'No',
      },
    ],
  },
  violations: {
    label: 'Number of moving violations in the last 3 years: *',
    type: 'select',
    key: 'violations',
    validator: 'required',
    options: [
      {
        value: '0',
        title: '0',
      },
      {
        value: '1',
        title: '1',
      },
      {
        value: '2',
        title: '2',
      },
      {
        value: '3',
        title: '3',
      },
      {
        value: '4',
        title: '4',
      },
      {
        value: '5+',
        title: '5+',
      },
    ],
  },
  accidents: {
    label: 'Number of preventable accidents in the last 3 years: *',
    type: 'select',
    key: 'accidents',
    validator: 'required',
    options: [
      {
        value: '0',
        title: '0',
      },
      {
        value: '1',
        title: '1',
      },
      {
        value: '2',
        title: '2',
      },
      {
        value: '3',
        title: '3',
      },
      {
        value: '4',
        title: '4',
      },
      {
        value: '5+',
        title: '5+',
      },
    ],
  },
  companyOrOwner: {
    label:
      'Are you a Company Driver, Lease Purchase Driver, or an Owner Operator? *',
    type: 'select',
    key: 'companyOrOwner',
    validator: 'required',
    options: [
      {
        value: 'company',
        title: 'Company Driver',
      },
      {
        value: 'leasePurchase',
        title: 'Lease Purchase Driver',
      },
      {
        value: 'owner',
        title: 'Owner Operator',
      },
    ],
  },
  interestOOorLP: {
    label:
      'Are you interested in becoming an Owner Operator or hearing about Lease Purchase opportunities?',
    type: 'select',
    key: 'interestOOorLP',
    logic: d => {
      return d.companyOrOwner === 'company';
    },
    options: [
      {
        value: 'yes',
        title: 'Yes',
      },
      {
        value: 'no',
        title: 'No',
      },
    ],
  },
  powerUnitOrOwnAuthority: {
    label:
      'Are you a Power Unit Only Owner Operator or do you have your Own Authority? *',
    type: 'select',
    key: 'powerUnitOrOwnAuthority',
    logic: d => {
      return d.companyOrOwner === 'owner';
    },
    options: [
      {
        value: 'power-unit',
        title: 'Power Unit Only',
      },
      {
        value: 'own-authority',
        title: 'Own Authority',
      },
    ],
  },
  truckYear: {
    label: 'Year of Truck',
    type: 'input',
    topLabel: true,
    class: 'Leadform-inputInput--left',
    inputType: 'tel',
    key: 'truckYear',
    logic: d => {
      return d.powerUnitOrOwnAuthority && d.companyOrOwner === 'owner';
    },
  },
  truckMake: {
    label: 'Make of Truck',
    type: 'input',
    topLabel: true,
    class: 'Leadform-inputInput--right',
    inputType: 'text',
    key: 'truckMake',
    logic: d => {
      return d.powerUnitOrOwnAuthority && d.companyOrOwner === 'owner';
    },
  },
  dotNumber: {
    label: 'What is your DOT #?',
    type: 'input',
    topLabel: true,
    class: 'Leadform-inputInput--left',
    inputType: 'text',
    key: 'dotNumber',
    logic: d => {
      return (
        d.powerUnitOrOwnAuthority === 'own-authority' &&
        d.companyOrOwner === 'owner'
      );
    },
  },
  mcNumber: {
    label: 'What is your MC #?',
    type: 'input',
    topLabel: true,
    inputType: 'text',
    class: 'Leadform-inputInput--right',
    key: 'mcNumber',
    logic: d => {
      return (
        d.powerUnitOrOwnAuthority === 'own-authority' &&
        d.companyOrOwner === 'owner'
      );
    },
  },
  soloOrTeam: {
    label: 'Are you a Solo Driver or a Team Driver? *',
    type: 'select',
    key: 'soloOrTeam',
    validator: 'required',
    options: [
      {
        value: 'solo',
        title: 'Solo Driver',
      },
      {
        value: 'team',
        title: 'Team Driver',
      },
    ],
  },
  interestTeamDriver: {
    label:
      'Would you be interested in becoming a team driver or matching up with another solo driver if it meant you would make more money and run in nicer equipment?',
    type: 'select',
    key: 'interestTeamDriver',
    logic: d => {
      return d.soloOrTeam === 'solo';
    },
    options: [
      {
        value: 'yes',
        title: 'Yes',
      },
      {
        value: 'no',
        title: 'No',
      },
    ],
  },
  mostCommonFreight: {
    label: 'What type of freight do you most commonly haul? *',
    validator: 'required',
    type: 'select',
    key: 'mostCommonFreight',
    options: _.map(
      {
        dryVan: 'Dry Van',
        reefer: 'Refrigerated (Reefer)',
        flatbed: 'Flatbed',
        tanker: 'Tanker',
        doubTrip: 'Doubles / Triples',
        intermodal: 'Intermodal',
        hauler: 'Auto Hauler',
        hhg: 'Household Goods',
        dryBulk: 'Dry Bulk & Pneumatic',
        specialized: 'Specialized/Heavy Haul',
        doublepups: 'Double Pups',
        hazmat: 'Hazmat',
      },
      (title, value) => {
        return { value, title };
      }
    ),
  },
  trailerTypes: {
    label:
      'What other freight types would you be interested in hauling? (select all that apply) *',
    type: 'multiSelect',
    key: 'trailerTypes',
    logic: d => {
      return d.mostCommonFreight;
    },
    options: _.map(
      {
        dryVan: 'Dry Van',
        reefer: 'Refrigerated (Reefer)',
        flatbed: 'Flatbed',
        tanker: 'Tanker',
        doubTrip: 'Doubles / Triples',
        intermodal: 'Intermodal',
        hauler: 'Auto Hauler',
        hhg: 'Household Goods',
        dryBulk: 'Dry Bulk & Pneumatic',
        specialized: 'Specialized/Heavy Haul',
        doublepups: 'Double Pups',
        hazmat: 'Hazmat',
      },
      (title, value) => {
        return { value, title };
      }
    ),
  },
  militaryExperience: {
    label: 'Do you have any military experience?',
    type: 'select',
    key: 'militaryExperience',
    options: [
      {
        value: true,
        title: 'Yes',
      },
      {
        value: false,
        title: 'No',
      },
    ],
  },
  vetransPartner: {
    class: 'vetransPartnerImage',
    key: 'vetransPartnerImage',
    type: 'image',
    logic: d => {
      return d.militaryExperience === true;
    },
  },
  contactTime: {
    label: 'What is the best time to contact you?',
    type: 'select',
    key: 'bestContactTime',
    options: [
      {
        value: 'anytime',
        title: 'Anytime',
      },
      {
        value: 'morning',
        title: 'Morning',
      },
      {
        value: 'afternoon',
        title: 'Afternoon',
      },
      {
        value: 'evening',
        title: 'Evening',
      },
    ],
  },
};

export const liveTruckingLong = [
  {
    title: 'Tell Us About Yourself and We’ll Match You With The Right Job',
    class: 'LeadForm-LT-singleForm',
    questions: [
      {
        key: 'firstName',
        label: 'First Name *',
        type: 'input',
        inputType: 'text',
        validator: 'required',
        class: 'Leadform-inputInput--left',
        topLabel: true,
      },
      {
        key: 'lastName',
        label: 'Last Name *',
        type: 'input',
        inputType: 'text',
        validator: 'required',
        class: 'Leadform-inputInput--right',
        topLabel: true,
      },
      {
        key: 'email',
        label: 'Email Address *',
        type: 'input',
        inputType: 'email',
        validator: 'email',
        class: 'Leadform-inputInput--left',
        topLabel: true,
      },
      {
        key: 'phone',
        label: 'Phone Number *',
        type: 'input',
        inputType: 'tel',
        validator: 'required',
        class: 'Leadform-inputInput--right',
        toolTipIcon: true,
        toolTipText: 'Number provided will be given to recruiters',
        topLabel: true,
      },
      {
        key: 'zip',
        label: 'Zip Code *',
        type: 'input',
        inputType: 'tel',
        validator: 'zipcode',
        topLabel: true,
        hint: false,
        topLabel: true,
        toolTipIcon: true,
        toolTipText: 'Must be a 5 digit zip code.',
      },
      {
        label: 'Do you have a Class A CDL? *',
        type: 'select',
        key: 'cdlLicense',
        validator: 'required',
        options: [
          {
            value: true,
            title: 'Yes',
          },
          {
            value: false,
            title: 'No',
          },
        ],
      },
      {
        label:
          'Are you interested in hearing about CDL Training opportunities?',
        type: 'select',
        key: 'interestCdlTraining',
        logic: d => {
          return d.cdlLicense === false;
        },
        options: [
          {
            value: true,
            title: 'Yes',
          },
          {
            value: false,
            title: 'No',
          },
        ],
      },
      {
        label: 'Do you have any endorsements?',
        type: 'multiSelect',
        key: 'endorsements',
        logic: d => {
          return d.cdlLicense === true;
        },
        options: _.map(
          {
            hEndorsement: 'H - Hazardous Materials',
            nEndorsement: 'N - Tanker Vehicles',
            tEndorsement: 'T - Doubles or Triples',
          },
          (title, value) => {
            return { value, title };
          }
        ),
      },
      {
        label: 'How much verifiable experience do you have using your CDL? *',
        type: 'select',
        key: 'experience',
        validator: 'required',
        options: [
          {
            value: 'needtraining',
            title: 'Need CDL Training',
          },
          {
            value: 'intraining',
            title: 'In CDL Training',
          },
          {
            value: 'graduatedtraining',
            title: 'Graduated Training, No Experience Yet',
          },
          {
            value: '1-5_months',
            title: '1-5 Months',
          },
          {
            value: '6-11_months',
            title: '6-11 Months',
          },
          {
            value: '12-23_months',
            title: '12-23 Months',
          },
          {
            value: '2_years',
            title: '2 Years',
          },
          {
            value: '3_years',
            title: '3 Years',
          },
          {
            value: '4_years',
            title: '4 Years',
          },
          {
            value: '5+_years',
            title: '5+ Years',
          },
        ],
      },
      {
        label: 'Would you also be interested in Diesel Mechanic Training?',
        type: 'select',
        key: 'dieselTraining',
        logic: d => {
          return d.experience === 'needtraining';
        },
        options: [
          {
            value: true,
            title: 'Yes',
          },
          {
            value: false,
            title: 'No',
          },
        ],
      },
      {
        label: 'Number of moving violations in the last 3 years: *',
        type: 'select',
        key: 'violations',
        validator: 'required',
        options: [
          {
            value: '0',
            title: '0',
          },
          {
            value: '1',
            title: '1',
          },
          {
            value: '2',
            title: '2',
          },
          {
            value: '3',
            title: '3',
          },
          {
            value: '4',
            title: '4',
          },
          {
            value: '5+',
            title: '5+',
          },
        ],
      },
      {
        label: 'Number of preventable accidents in the last 3 years: *',
        type: 'select',
        key: 'accidents',
        validator: 'required',
        options: [
          {
            value: '0',
            title: '0',
          },
          {
            value: '1',
            title: '1',
          },
          {
            value: '2',
            title: '2',
          },
          {
            value: '3',
            title: '3',
          },
          {
            value: '4',
            title: '4',
          },
          {
            value: '5+',
            title: '5+',
          },
        ],
      },
      {
        label:
          'Are you a Company Driver, Lease Purchase Driver, or an Owner Operator? *',
        type: 'select',
        key: 'companyOrOwner',
        validator: 'required',
        options: [
          {
            value: 'company',
            title: 'Company Driver',
          },
          {
            value: 'leasePurchase',
            title: 'Lease Purchase Driver',
          },
          {
            value: 'owner',
            title: 'Owner Operator',
          },
        ],
      },
      {
        label:
          'Are you interested in becoming an Owner Operator or hearing about Lease Purchase opportunities?',
        type: 'select',
        key: 'interestOOorLP',
        logic: d => {
          return d.companyOrOwner === 'company';
        },
        options: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
      },
      {
        label:
          'Are you a Power Unit Only Owner Operator or do you have your Own Authority? *',
        type: 'select',
        key: 'powerUnitOrOwnAuthority',
        logic: d => {
          return d.companyOrOwner === 'owner';
        },
        options: [
          {
            value: 'power-unit',
            title: 'Power Unit Only',
          },
          {
            value: 'own-authority',
            title: 'Own Authority',
          },
        ],
      },
      {
        label: 'Year of Truck',
        type: 'input',
        topLabel: true,
        class: 'Leadform-inputInput--left',
        inputType: 'tel',
        key: 'truckYear',
        logic: d => {
          return d.powerUnitOrOwnAuthority && d.companyOrOwner === 'owner';
        },
      },
      {
        label: 'Make of Truck',
        type: 'input',
        topLabel: true,
        class: 'Leadform-inputInput--right',
        inputType: 'text',
        key: 'truckMake',
        logic: d => {
          return d.powerUnitOrOwnAuthority && d.companyOrOwner === 'owner';
        },
      },
      {
        label: 'What is your DOT #?',
        type: 'input',
        topLabel: true,
        class: 'Leadform-inputInput--left',
        inputType: 'text',
        key: 'dotNumber',
        logic: d => {
          return (
            d.powerUnitOrOwnAuthority === 'own-authority' &&
            d.companyOrOwner === 'owner'
          );
        },
      },
      {
        label: 'What is your MC #?',
        type: 'input',
        topLabel: true,
        inputType: 'text',
        class: 'Leadform-inputInput--right',
        key: 'mcNumber',
        logic: d => {
          return (
            d.powerUnitOrOwnAuthority === 'own-authority' &&
            d.companyOrOwner === 'owner'
          );
        },
      },
      {
        label: 'Are you a Solo Driver or a Team Driver? *',
        type: 'select',
        key: 'soloOrTeam',
        validator: 'required',
        options: [
          {
            value: 'solo',
            title: 'Solo Driver',
          },
          {
            value: 'team',
            title: 'Team Driver',
          },
        ],
      },
      {
        label:
          'Would you be interested in becoming a team driver or matching up with another solo driver if it meant you would make more money and run in nicer equipment?',
        type: 'select',
        key: 'interestTeamDriver',
        logic: d => {
          return d.soloOrTeam === 'solo';
        },
        options: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
      },
      {
        label: 'What type of freight do you most commonly haul? *',
        validator: 'required',
        type: 'select',
        key: 'mostCommonFreight',
        options: _.map(
          {
            dryVan: 'Dry Van',
            reefer: 'Refrigerated (Reefer)',
            flatbed: 'Flatbed',
            tanker: 'Tanker',
            doubTrip: 'Doubles / Triples',
            intermodal: 'Intermodal',
            hauler: 'Auto Hauler',
            hhg: 'Household Goods',
            dryBulk: 'Dry Bulk & Pneumatic',
            specialized: 'Specialized/Heavy Haul',
            doublepups: 'Double Pups',
            hazmat: 'Hazmat',
          },
          (title, value) => {
            return { value, title };
          }
        ),
      },
      {
        label:
          'What other freight types would you be interested in hauling? (select all that apply) *',
        type: 'multiSelect',
        key: 'trailerTypes',
        logic: d => {
          return d.mostCommonFreight;
        },
        options: _.map(
          {
            dryVan: 'Dry Van',
            reefer: 'Refrigerated (Reefer)',
            flatbed: 'Flatbed',
            tanker: 'Tanker',
            doubTrip: 'Doubles / Triples',
            intermodal: 'Intermodal',
            hauler: 'Auto Hauler',
            hhg: 'Household Goods',
            dryBulk: 'Dry Bulk & Pneumatic',
            specialized: 'Specialized/Heavy Haul',
            doublepups: 'Double Pups',
            hazmat: 'Hazmat',
          },
          (title, value) => {
            return { value, title };
          }
        ),
      },
      {
        label: 'Do you have any military experience?',
        type: 'select',
        key: 'militaryExperience',
        options: [
          {
            value: true,
            title: 'Yes',
          },
          {
            value: false,
            title: 'No',
          },
        ],
      },
      {
        class: 'vetransPartnerImage',
        key: 'vetransPartnerImage',
        type: 'image',
        logic: d => {
          return d.militaryExperience === true;
        },
      },
      {
        label: 'What is the best time to contact you?',
        type: 'select',
        key: 'bestContactTime',
        options: [
          {
            value: 'anytime',
            title: 'Anytime',
          },
          {
            value: 'morning',
            title: 'Morning',
          },
          {
            value: 'afternoon',
            title: 'Afternoon',
          },
          {
            value: 'evening',
            title: 'Evening',
          },
        ],
      },
    ],
  },
];

export const liveTruckingMobile = [
  {
    title: 'Find The Right Trucking Job in 3 Steps',
    class: 'LeadForm-LT-mobileSectionOne',
    inputClass: 'multiPageOne-inputs',
    mobileSubTitle:
      'Tell Us About Yourself and We’ll Match You With The Right Job',
    questions: [
      {
        key: 'firstName',
        label: 'First Name *',
        type: 'input',
        inputType: 'text',
        validator: 'required',
        class: 'Leadform-inputInput--left',
        topLabel: true,
      },
      {
        key: 'lastName',
        label: 'Last Name *',
        type: 'input',
        inputType: 'text',
        validator: 'required',
        class: 'Leadform-inputInput--right',
        topLabel: true,
      },
      {
        key: 'dob',
        label: 'Date of Birth *',
        type: 'input',
        inputType: 'date',
        validator: 'required',
        logic: d => {
          return d.lastName;
        },
        class: 'Leadform-inputInput--left',
        topLabel: true,
        dob: true,
      },
      {
        key: 'email',
        label: 'Email Address *',
        type: 'input',
        inputType: 'email',
        validator: 'email',
        class: 'Leadform-inputInput--right',
        topLabel: true,
        logic: d => {
          return d.lastName;
        },
      },
      {
        key: 'phone',
        label: 'Phone Number *',
        type: 'input',
        inputType: 'tel',
        validator: 'required',
        class: 'Leadform-inputInput--left',
        toolTipIcon: true,
        toolTipText: 'Number provided will be given to recruiters',
        topLabel: true,
        logic: d => {
          return d.lastName;
        },
      },
      {
        key: 'zip',
        label: 'Zip Code *',
        type: 'input',
        inputType: 'tel',
        validator: 'zipcode',
        class: 'Leadform-inputInput--right',
        topLabel: true,
        hint: false,
        logic: d => {
          return d.lastName;
        },
        topLabel: true,
        toolTipIcon: true,
        toolTipText: 'Must be a 5 digit zip code.',
      },
    ],
  },
  {
    title: 'Find The Right Trucking Job in 3 Steps ',
    class: 'LeadForm-LT-mobileSectionTwo',
    inputClass: 'multiPageTwo-inputs',
    questions: [
      questions.cdlLicense,
      questions.interestCdlTraining,
      questions.endorsements,
      questions.experience,
      questions.dieselTraining,
      questions.violations,
      questions.accidents,
    ],
  },
  {
    title: 'Find The Right Trucking Job in 3 Steps ',
    class: 'LeadForm-LT-mobileSectionThree',
    inputClass: 'multiPageThree-inputs',
    questions: [
      questions.companyOrOwner,
      questions.interestOOorLP,
      questions.powerUnitOrOwnAuthority,
      questions.truckYear,
      questions.truckMake,
      questions.dotNumber,
      questions.mcNumber,
      questions.soloOrTeam,
      questions.interestTeamDriver,
      questions.mostCommonFreight,
      questions.trailerTypes,
      questions.militaryExperience,
      questions.vetransPartner,
      questions.contactTime,
    ],
  },
];

export const liveTruckingMulti = [
  {
    title: `Tell Us About Yourself and We’ll Match You With The Right Job in Three Easy Steps`,
    class: 'LeadForm-LT-multiSectionOne',
    inputClass: 'multiPageOne-inputs',
    questions: [
      questions.firstName,
      questions.lastName,
      questions.dob,
      questions.email,
      questions.phone,
      questions.zip,
    ],
  },
  {
    title: 'Let’s Get An Idea of Your Training',
    class: 'LeadForm-LT-multiSectionTwo',
    inputClass: 'multiPageTwo-inputs',
    questions: [
      questions.cdlLicense,
      questions.interestCdlTraining,
      questions.endorsements,
      questions.experience,
      questions.dieselTraining,
      questions.violations,
      questions.accidents,
    ],
  },
  {
    title: 'You’re Almost There, Just A Few More Questions',
    class: 'LeadForm-LT-multiSectionThree',
    inputClass: 'multiPageThree-inputs',
    questions: [
      questions.companyOrOwner,
      questions.interestOOorLP,
      questions.powerUnitOrOwnAuthority,
      questions.truckYear,
      questions.truckMake,
      questions.dotNumber,
      questions.mcNumber,
      questions.soloOrTeam,
      questions.interestTeamDriver,
      questions.mostCommonFreight,
      questions.trailerTypes,
      questions.militaryExperience,
      questions.vetransPartner,
      questions.contactTime,
    ],
  },
];
