<svelte:options tag="cdlc-job-card-v2-hints" />

<script>
  export let hint;
</script>

<div class="JobCardV2Hints">
  <div class="JobCardV2Hints-value">{@html hint}</div>
</div>

<style src="./JobCardV2Hints.scss">
</style>
