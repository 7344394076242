<svelte:options tag="cdlc-ad-reputation-ad" />

<script>
  import _ from 'lodash';

  const API =
    localStorage.getItem('JOBBOARD_API') || 'https://dedicatedjobs.cdllife.com';

  export let site = '1120901';
  export let ad;

  let width = 0;
  let step = 0;
  let error = false;
  let totalStars = 0;
  let stars = [false, false, false, false, false];

  $: companyLogo = _.get(ad, 'creative.data.customData.companyLogo', '');
  $: clientName = _.get(ad, 'creative.data.customData.companyName', '');
  $: adId = _.get(ad, 'adId', '');
  $: adOrientation = adOrientation =
    width > 500 ? 'Ad--landscape' : 'Ad--portrait';
  $: steps = {
    0: {
      img: companyLogo,
      header: `Have you ever heard of <span class="highlight">${clientName}</span>?`,
      buttons: [
        {
          text: 'No',
          action: selectNo,
        },
        {
          text: 'Yes',
          action: selectYes,
        },
      ],
      disclaimer: 'This survery is completely anonymous.',
    },
    1: {
      img: companyLogo,
      header: `How would you rate ${clientName}?`,
      error: false,
      errorMsg: 'Rate your experience to continue.',
      displayRating: true,
      buttons: [
        {
          text: 'Submit',
          action: handleSubmit,
        },
      ],
      disclaimer: 'This survery is completely anonymous.',
    },
    2: {
      icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>`,
      header: 'Thank you for your feedback.',
      body: `We appreciate your honest feedback about ${clientName}. Click on the link below to learn more about ${_.get(ad, 'companyName', '')}`,
      buttons: [
        {
          text: 'Learn More',
          action: learnMore,
          styleSecondary: true,
        },
      ],
    },
  };
  $: currentData = steps[step];

  function learnMore() {
    window.open(ad.clickUrl);
  }

  function toggleStar(index) {
    if (stars[index]) {
      for (let i = index; i < stars.length; i++) {
        stars[i] = false;
      }
      totalStars = index;
    } else {
      for (let i = 0; i <= index; i++) {
        stars[i] = true;
      }
      totalStars = index + 1;
    }
    error = false;
  }

  async function selectNo() {
    try {
      logClick();
      await fetch(`${API}/api/adn/${adId}/reputation`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          noCount: 1,
        }),
      });

      currentData = {
        img: companyLogo,
        header: `Don't know ${clientName} yet?`,
        body: `Interested in learning more about driving for ${clientName}? Press the link below 👇`,
        buttons: [
          {
            text: 'Learn More',
            action: learnMore,
          },
        ],
      };
    } catch (e) {
      console.error(e);
    }
  }

  async function selectYes() {
    try {
      logClick();
      await fetch(`${API}/api/adn/${adId}/reputation`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          yesCount: 1,
        }),
      });
      step += 1;
    } catch (e) {
      console.error(e);
    }
  }

  function logClick() {
    try {
      let it = document.createElement('img');
      it.src = ad.clickUrl;
      it.border = 0;
      it.style.width = '0px';
      it.style.height = '0px';
      document.body.appendChild(it);
      setTimeout(() => {
        document.body.removeChild(it);
      }, 1000);
    } catch (e) {
      // console.error('error logging click', ad.clickUrl);
    }
  }

  async function handleSubmit() {
    if (totalStars > 0) {
      try {
        await fetch(`${API}/api/adn/${adId}/reputation`, {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            starRatings: totalStars,
          }),
        });

        step += 1;
      } catch (e) {
        console.error(e);
      }
    } else {
      return (error = true);
    }
  }
</script>

<svelte:window bind:innerWidth={width} />

<div
  class={`ReputationAd ${site === '1120900' ? 'Ad--mobileapp' : `${adOrientation}`}`}
>
  <div class="ReputationAd-container">
    <div class="iconWrap">
      <div class="iconBackground">
        {#if _.get(currentData, 'img')}
          <img
            class="img"
            alt={`${_.get(ad, 'companyName', '')} Logo`}
            src={_.get(currentData, 'img')}
          />
        {:else if _.get(currentData, 'icon')}
          <div class="icon">
            {@html currentData.icon}
          </div>
        {/if}
      </div>
    </div>
    <div class:hasError={error} class="repAdContent">
      <div class="repAdContent-body">
        <!-- header -->
        {#if _.get(currentData, 'header')}
          <h3 class:hasError={error}>{@html currentData.header}</h3>
        {/if}

        {#if error && _.get(currentData, 'errorMsg')}
          <small class="error">{currentData.errorMsg}</small>
        {/if}
        <!-- body -->
        {#if _.get(currentData, 'body')}
          <p>{currentData.body}</p>
        {/if}

        {#if _.get(currentData, 'displayRating')}
          <div class="starWrapper">
            {#each Array.from({ length: 5 }) as _, i}
              <span
                class={stars[i] ? 'fa-solid' : 'fa-regular'}
                on:click={() => toggleStar(i)}
              >
                {#if stars[i]}
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.0859 8.75781L27.9062 9.90625C28.5625 10.0156 29.1094 10.4531 29.3281 11.1094C29.5469 11.7109 29.3828 12.4219 28.8906 12.8594L23.2031 18.4922L24.5703 26.4766C24.6797 27.1328 24.4062 27.7891 23.8594 28.1719C23.3125 28.6094 22.6016 28.6094 22 28.3359L15 24.5625L7.94531 28.3359C7.39844 28.6094 6.63281 28.6094 6.14062 28.1719C5.59375 27.7891 5.32031 27.1328 5.42969 26.4766L6.74219 18.4922L1.05469 12.8594C0.5625 12.4219 0.398438 11.7109 0.617188 11.1094C0.835938 10.4531 1.38281 10.0156 2.03906 9.90625L9.91406 8.75781L13.4141 1.48438C13.6875 0.882812 14.2891 0.5 15 0.5C15.6562 0.5 16.2578 0.882812 16.5312 1.48438L20.0859 8.75781Z"
                      fill="#F3C736"
                    />
                  </svg>
                {:else}
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.9453 24.3438L7.45312 28.3906C7.01562 28.6094 6.52344 28.5547 6.08594 28.2812C5.70312 28.0078 5.48438 27.4609 5.53906 26.9688L7.01562 18.4922L0.945312 12.4766C0.5625 12.0938 0.453125 11.6016 0.617188 11.1094C0.78125 10.6719 1.16406 10.2891 1.65625 10.2344L10.0234 8.97656L13.7969 1.26562C14.0156 0.828125 14.4531 0.5 14.9453 0.5C15.4922 0.5 15.9297 0.828125 16.1484 1.26562L19.9219 8.97656L28.2891 10.2344C28.7812 10.2891 29.1641 10.6719 29.3281 11.1094C29.4922 11.6016 29.3828 12.0938 29 12.4766L22.9297 18.4922L24.3516 26.9688C24.4609 27.4609 24.2422 28.0078 23.8594 28.2812C23.4219 28.5547 22.875 28.6094 22.4375 28.3906L14.9453 24.3438ZM10.8438 9.35938C10.6797 9.63281 10.4609 9.79688 10.1875 9.85156L1.76562 11.1094C1.60156 11.1094 1.49219 11.2188 1.4375 11.3828C1.38281 11.5469 1.4375 11.7109 1.54688 11.8203L7.61719 17.8359C7.83594 18.0547 7.89062 18.3281 7.89062 18.6016L6.41406 27.1328C6.41406 27.2969 6.46875 27.4609 6.57812 27.5703C6.74219 27.6797 6.90625 27.6797 7.07031 27.5703L14.5625 23.5781C14.8359 23.4688 15.1094 23.4688 15.3828 23.5781L22.875 27.5703C23.0391 27.6797 23.2031 27.6797 23.3125 27.5703C23.4766 27.4609 23.5312 27.2969 23.5312 27.1328L22.0547 18.6016C22 18.3281 22.1094 18.0547 22.3281 17.8359L28.3984 11.8203C28.5078 11.7109 28.5625 11.5469 28.5078 11.3828C28.4531 11.2188 28.3438 11.1094 28.1797 11.1094L19.7578 9.85156C19.4844 9.79688 19.2656 9.63281 19.1016 9.35938L15.3828 1.64844C15.2734 1.48438 15.1094 1.375 14.9453 1.375C14.7812 1.375 14.6719 1.48438 14.5625 1.64844L10.8438 9.35938ZM0.945312 12.4766L1.54688 11.8203L0.945312 12.4766Z"
                      fill="#666F7E"
                    />
                  </svg>
                {/if}
              </span>
            {/each}
          </div>
        {/if}

        <!-- buttons -->
        {#if _.get(currentData, 'buttons')}
          <div class:hasError={error} class="actions">
            {#each currentData.buttons as button}
              <button
                on:click={() => button.action()}
                class:large={currentData.buttons.length === 1 &&
                  button.text !== 'Submit'}
                class={button.styleSecondary ? 'secondary' : 'primary'}
                >{button.text}</button
              >
            {/each}
          </div>
        {/if}
      </div>

      {#if _.get(currentData, 'disclaimer')}
        <div class="repAdContent-disclaimer">
          <small>{currentData.disclaimer}</small>
        </div>
      {/if}
    </div>
  </div>
</div>

<style type="text/scss">
  @import './ReputationAd.scss';
</style>
