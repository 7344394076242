import _ from 'lodash';
import moment from 'moment';

let presets = {};

presets.dieselShort = [
  {
    title: 'Contact Us',
    questions: [
      {
        label: `Complete the form below to receive additional information about advertising with us. – We'll be in touch shortly!`,
        type: 'text',
        key: 'uploadHiringText',
      },
      {
        key: 'fullName',
        label: 'Full Name',
        type: 'input',
        inputType: 'text',
        validator: 'required',
      },
      {
        key: 'email',
        label: 'Email',
        type: 'input',
        inputType: 'email',
        validator: 'required',
      },
      {
        key: 'telephone',
        label: 'Phone Number',
        type: 'input',
        inputType: 'tel',
        validator: 'required',
      },
      {
        key: 'companyName',
        label: 'Company or Agency Name',
        type: 'input',
        validator: 'required',
      },
      {
        key: 'message',
        label: 'Message',
        type: 'input',
        inputType: 'message',
      },
    ],
  },
];

presets.diesel = [
  {
    title: 'Account Setup',
    questions: [
      {
        label: 'Are you an Advertising Agency?',
        type: 'select',
        key: 'advertisingAgency',
        options: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
      },
      {
        key: 'agencyName',
        label: 'Agency Name',
        logic: d => {
          return d.advertisingAgency === 'yes';
        },
        type: 'input',
        inputType: 'text',
      },
      {
        key: 'agencyPhone',
        label: 'Agency Phone Number',
        logic: d => {
          return d.advertisingAgency === 'yes';
        },
        type: 'input',
        inputType: 'tel',
      },
      {
        key: 'accountRepresentativeName',
        label: 'Account Representative Name',
        logic: d => {
          return d.advertisingAgency === 'yes';
        },
        type: 'input',
        inputType: 'text',
      },
      {
        key: 'accountRepresentativeEmail',
        label: 'Account Representative Email',
        logic: d => {
          return d.advertisingAgency === 'yes';
        },
        type: 'input',
        inputType: 'email',
      },
      {
        key: 'accountRepresentativePhone',
        label: 'Account Representative Phone Number',
        logic: d => {
          return d.advertisingAgency === 'yes';
        },
        type: 'input',
        inputType: 'tel',
      },
      {
        key: 'companyName',
        label: 'Company Name',
        logic: d => {
          return d.advertisingAgency === 'no';
        },
        type: 'input',
        inputType: 'text',
      },
      {
        key: 'companyPhone',
        label: 'Company Phone Number',
        logic: d => {
          return d.advertisingAgency === 'no';
        },
        type: 'input',
        inputType: 'text',
      },
      {
        key: 'contactName',
        label: 'Contact Name',
        logic: d => {
          return d.advertisingAgency === 'no';
        },
        type: 'input',
        inputType: 'text',
      },
      {
        key: 'contactEmail',
        label: 'Contact Email',
        logic: d => {
          return d.advertisingAgency === 'no';
        },
        type: 'input',
        inputType: 'text',
      },
      {
        key: 'contactPhone',
        label: 'Contact Phone',
        logic: d => {
          return d.advertisingAgency === 'no';
        },
        type: 'input',
        inputType: 'text',
      },
    ],
  },

  {
    title: 'Company Profile',
    questions: [
      {
        label: 'Upload your logo',
        type: 'file',
        accept: 'image/*',
        key: 'companyLogo',
        // validator: 'required'
      },
      {
        label: 'Company Bio',
        type: 'input',
        key: 'companyBio',
        validator: 'required',
      },
      {
        label: 'Job Copy',
        type: 'input',
        inputType: 'message',
        key: 'jobCopy',
      },
    ],
  },

  {
    title: 'Lead Destination',
    questions: [
      {
        label: 'Select your preferred lead destination:',
        type: 'select',
        key: 'leadDestination',
        options: [
          {
            value: 'tenstreet',
            title: 'Tenstreet',
          },
          {
            value: 'email',
            title: 'Email',
          },
        ],
      },
      {
        key: 'tenStreetID',
        label: 'Tenstreet ID',
        logic: d => {
          return d.leadDestination === 'tenstreet';
        },
        type: 'input',
        inputType: 'text',
      },
      {
        key: 'leadDestinationEmail',
        label: 'Email',
        logic: d => {
          return d.leadDestination === 'email';
        },
        type: 'input',
        inputType: 'email',
        validator: 'required',
      },
      {
        label: 'Email Format',
        type: 'select',
        key: 'emailFormat',
        logic: d => {
          return d.leadDestination === 'email';
        },
        options: [
          {
            value: 'html',
            title: 'HTML (default, recommended)',
          },
          {
            value: 'plaintext',
            title: 'Plaintext',
          },
          {
            value: 'json',
            title: 'JSON',
          },
        ],
      },
      {
        key: 'callTrackingNumber',
        label: 'Call Tracking Number',

        type: 'input',
      },
    ],
  },

  {
    title: 'Hiring Requirements',
    questions: [
      {
        label:
          'Is a certification of completion for diesel mechanic training required from any of the following sources: community colleges, technical institutes, automotive schools or on-the-job training which has led to a successful certification of completion?',
        type: 'select',
        key: 'dieselCertification',
        options: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
      },
      {
        label: 'How much verifiable Diesel Technician experience is required?',
        type: 'select',
        key: 'experience',
        options: [
          {
            value: 'needtraining',
            title: 'Need Diesel Training',
          },
          {
            value: 'intraining',
            title: 'In Training Now',
          },
          {
            value: 'graduatedtraining',
            title: 'Graduated Training, No Experience Yet',
          },
          {
            value: '1-5_months',
            title: '1-5 Months',
          },
          {
            value: '6-11_months',
            title: '6-11 Months',
          },
          {
            value: '12-23_months',
            title: '12-23 Months',
          },
          {
            value: '2_years',
            title: '2 Years',
          },
          {
            value: '3_years',
            title: '3 Years',
          },
          {
            value: '4_years',
            title: '4 Years',
          },
          {
            value: '5+_years',
            title: '5+ Years',
          },
        ],
      },
      {
        label:
          'Is professional ASE (Automotive Service Excellence) certification required?',
        type: 'select',
        key: 'dieselASECertification',
        options: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
      },
      {
        label:
          'Which of the following ASE certifications for Medium/Heavy Duty Trucks do you require?',
        type: 'multiSelect',
        key: 'dieselASECertificationHeavy',
        //#toggle
        logic: d => {
          return d.dieselASECertification === 'yes';
        },
        options: _.map(
          [
            'T1 – Gasoline Engines',
            'T2 – Diesel Engines',
            'T3 – Drive Train',
            'T4 – Brakes',
            'T5 – Suspension & Steering',
            'T6 – Electrical/ Electronic Systems',
            'T7 – Heating, Ventilation & Air Conditioning (HVAC)',
            'T8 – Preventive Maintenance Inspection',
            'N/A',
          ],
          d => {
            return {
              title: d,
              value: d.slice(0, 2).toLowerCase(),
            };
          }
        ),
        fullWidth: true,
      },
      {
        label:
          'Which of the following ASE certifications for Automobile & Light Trucks do you require?',
        type: 'multiSelect',
        key: 'dieselASECertificationLight',
        logic: d => {
          return d.dieselASECertification === 'yes';
        },
        options: _.map(
          [
            'A1 – Engine Repair',
            'A2 – Automatic Transmission/Transaxle',
            'A3 – Manual Drive Train & Axles',
            'A4 – Suspension & Steering',
            'A5 – Brakes',
            'A6 – Electrical/Electronic Systems',
            'A7 – Heating & Air Conditioning',
            'A8 – Engine Performance',
            'A9 – Light Vehicle Diesel Engines',
            'N/A',
          ],
          d => {
            return {
              title: d,
              value: d.slice(0, 2).toLowerCase(),
            };
          }
        ),
        fullWidth: true,
      },
      {
        label:
          'Which of the following ASE certifications for Transit Busses do you require?',
        type: 'multiSelect',
        key: 'dieselASECertificationSchoolBus',
        logic: d => {
          return d.dieselASECertification === 'yes';
        },
        options: _.map(
          [
            'S1 – Body Systems & Special Equipment',
            'S2 – Diesel Engines',
            'S3 – Drive Train',
            'S4 – Brakes',
            'S5 – Suspension & Steering',
            'S6 – Electrical/Electronic Systems ',
            'S7 – Air Conditioning Systems & Controls',
            'N/A',
          ],
          d => {
            return {
              title: d,
              value: d.slice(0, 2).toLowerCase(),
            };
          }
        ),
        fullWidth: true,
      },
      {
        label:
          'Have you obtained any of the following ASE certifications for Transit Bus',
        type: 'multiSelect',
        key: 'dieselASECertificationTransitBus',
        logic: d => {
          return d.dieselASECertification === 'yes';
        },
        options: _.map(
          [
            'H1 – Compressed Natural Gas (CNG) Engines',
            'H2 – Diesel Engines',
            'H3 – Drive Train',
            'H4 – Brakes',
            'H5 – Suspension & Steering',
            'H6 – Electrical/Electronic Systems',
            'H7 – Heating Ventilation & Air Conditioning (HVAC)',
            'H8 – Preventive Maintenance & Inspection (PMI)',
            'N/A',
          ],
          d => {
            return {
              title: d,
              value: d.slice(0, 2).toLowerCase(),
            };
          }
        ),
        fullWidth: true,
      },
      {
        label:
          'Are certifications for any of the following manufacturers required?',
        type: 'multiSelect',
        key: 'dieselOEMCertificationsManufacturers',
        options: _.map(
          [
            'Kenworth',
            'Peterbilt',
            'Volvo',
            'International',
            'Mack',
            'Freightliner',
            'Western Star',
          ],
          d => {
            return {
              title: d,
              value: d.toLowerCase(),
            };
          }
        ),
      },
      {
        label: 'Will applicants need to supply their own tools?',
        type: 'select',
        key: 'dieselOwnTools',
        options: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
      },
      {
        label: 'Are applicants required to have welding experience?',
        type: 'select',
        key: 'dieselWelding',
        required: true,
        options: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
      },

      {
        label: 'Are applicants required to have a valid driver’s license?',
        type: 'select',
        key: 'validDriversLicense',
        options: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
      },
      {
        label: 'Are applicants required to have a Class-A CDL?',
        type: 'select',
        key: 'cdlLicense',
        options: [
          {
            value: true,
            title: 'Yes',
          },
          {
            value: false,
            title: 'No',
          },
        ],
      },
    ],
  },

  {
    title: 'Hiring Areas',
    questions: [
      {
        label: 'What states are you hiring in?',
        type: 'multiSelect',
        key: 'statesHiring',
        grid: true,
        options: _.map(
          [
            'Alabama',
            'Arizona',
            'Arkansas',
            'California',
            'Colorado',
            'Connecticut',
            'Delaware',
            'Florida',
            'Georgia',
            'Idaho',
            'Illinois',
            'Indiana',
            'Iowa',
            'Kansas',
            'Kentucky',
            'Louisiana',
            'Maine',
            'Maryland',
            'Massachusetts',
            'Michigan',
            'Minnesota',
            'Mississippi',
            'Missouri',
            'Montana',
            'Nebraska',
            'Nevada',
            'New Hampshire',
            'New Jersey',
            'New Mexico',
            'New York',
            'North Carolina',
            'North Dakota',
            'Ohio',
            'Oklahoma',
            'Oregon',
            'Pennsylvania',
            'Rhode Island',
            'South Carolina',
            'South Dakota',
            'Tennessee',
            'Texas',
            'Utah',
            'Vermont',
            'Virginia',
            'Washington',
            'West Virginia',
            'Wisconsin',
            'Wyoming',
          ],
          d => {
            return {
              title: d,
              value: d.toLowerCase(),
            };
          }
        ),
      },
      {
        label:
          'Not hiring in full states? Upload an .xls, .csv, or a list of all applicable hiring areas here:',
        type: 'text',
        key: 'uploadHiringText',
      },
      {
        label: 'Upload Hiring Areas',
        type: 'file',
        accept: '.csv,.xls,.xlsx,.pdf',
        key: 'statesHiringFile',
      },
      {
        label: 'Or input your applicable hiring areas here: ',
        type: 'text',
        key: 'inputHiringText',
      },
      {
        label: 'Hiring Areas',
        type: 'input',
        key: 'statesHiringList',
      },
    ],
  },

  {
    title: 'Budget:',
    questions: [
      {
        label: 'Monthly Budget:',
        type: 'input',
        key: 'monthlyBudget',
      },
      {
        label: 'Campaign Start Date',
        type: 'input',
        inputType: 'date',
        key: 'campaignStart',
        max_day: moment().add(6, 'months').format('YYYY-MM-DD'),
      },
      {
        label: 'How would you like us to handle your budget this month?',
        type: 'select',
        key: 'handleBudget',
        logic: d => {
          // Get day from campaignStart and check if not 1st of the month
          if (d.campaignStart) {
            return d.campaignStart.slice(-2) !== '01';
          } else {
            return;
          }
        },
        options: _.map(
          ['Use the entire budget allocation', 'Pro-rate my budget'],
          d => {
            return {
              title: d,
              value: d.toLowerCase(),
            };
          }
        ),
      },
    ],
  },

  {
    title: 'Additional Information:',
    questions: [
      {
        label: 'What is the name of your Diesel Hire Account Rep?',
        type: 'select',
        key: 'accountRep',
        options: _.map(
          [
            'Roger Fair',
            'Matt Roland',
            'Sean Hayes',
            'Mitzi Wright',
            'Randy Dye',
            'April Dunston',
            'Sadie Church',
            'Tommy Church',
            'Logan Long',
          ],
          d => {
            return {
              title: d,
              value: d.toLowerCase(),
            };
          }
        ),
      },
      {
        label: 'How did you hear about us?',
        type: 'select',
        key: 'referred',
        options: [
          {
            value: 'accountRep',
            title: 'My Diesel Account Representative',
          },
          {
            value: 'newsletter',
            title: 'Newsletter',
          },
          {
            value: 'facebook',
            title: 'Facebook',
          },
          {
            value: 'instagram',
            title: 'Instagram',
          },
          {
            value: 'event',
            title: 'Trade Show or Industry Event',
          },
          {
            value: 'other',
            title: 'Other',
          },
        ],
      },
      {
        label: 'Name of Trade Show or Industry Event',
        type: 'input',
        key: 'referralEventName',
        validator: 'required',
        logic: d => {
          return d.referred === 'event';
        },
      },
      {
        label: 'Other (please specify): ',
        type: 'input',
        key: 'otherReferral',
        validator: 'required',
        logic: d => {
          return d.referred === 'other';
        },
      },
    ],
  },
];

presets.quoteWizardBudget = [
  {
    title: 'Budget',
    questions: [
      {
        label: `Let us know how much you're planning to allocate to this campaign.`,
        type: 'text',
        key: 'instructions',
      },
      {
        label: 'Select your preferred lead destination:',
        type: 'select',
        key: 'leadDestination',
        options: [
          {
            value: 'tenstreet',
            title: 'Tenstreet',
          },
          {
            value: 'email',
            title: 'Email',
          },
        ],
      },
    ],
  },
];

presets.quoteWizardContact = [
  {
    title: 'Contact Us',
    questions: [
      {
        label: `Enter your contact information below:`,
        type: 'text',
        key: 'instructions',
      },
      {
        key: 'fullName',
        label: 'Full Name',
        type: 'input',
        inputType: 'text',
        validator: 'required',
      },
      {
        key: 'company',
        label: 'Company Name',
        type: 'input',
        inputType: 'text',
        validator: 'required',
      },
      {
        key: 'email',
        label: 'Email Address',
        type: 'input',
        inputType: 'email',
        validator: 'required',
      },
      {
        key: 'telephone',
        label: 'Phone Number',
        type: 'input',
        inputType: 'tel',
        validator: 'required',
      },
      {
        label: 'Are you with an advertising agency?',
        type: 'select',
        key: 'advertisingAgency',
        options: [
          {
            value: 'yes',
            title: 'Yes',
          },
          {
            value: 'no',
            title: 'No',
          },
        ],
      },
      {
        key: 'agencyName',
        label: 'Agency Name',
        logic: d => {
          return d.advertisingAgency === 'yes';
        },
        type: 'input',
        inputType: 'text',
      },
      {
        key: 'generateQuote',
        label: 'How would you like to generate this quote?',
        logic: d => {
          return d.advertisingAgency === 'yes';
        },
        type: 'select',
        options: [
          {
            value: 'gross',
            title: 'Gross',
          },
          {
            value: 'net',
            title: 'Net',
          },
        ],
      },
    ],
  },
];

export default presets;
