<svelte:options tag="cdlc-mobile-post-thread" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import { onMount, tick } from 'svelte';
  import moment from 'moment';
  import '../MobileReply/MobileReply.svelte';

  const log = debug('cdlc:MobilePostThread');

  export let dispatch = () => {};
  export let post = {};
  export let options = {
    showEllipses: true,
    dots: [1, 2, 3],
  };

  export let user_engagements = {
    liked: false,
  };

  $: likes = _.get(post, 'stats.likes', 0);
  $: comments = _.get(post, 'stats.comments', 0);
  $: shares = _.get(post, 'stats.shares', 0);

  let thread = true;
  let postUser;
  let user;
  let replies = [];
  let link;
  let formattedDate = '';
  let showReplies = false;
  let loading = false;
  let visibleReplies;
  let defaultAvatar = `https://d2r0eic16r3uxv.cloudfront.net/1601471704432269.png`;
  let verificationIcon = `<svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 8C16 6.84375 15.25 5.84375 14.1875 5.4375C14.6562 4.4375 14.4688 3.1875 13.6562 2.34375C12.8125 1.53125 11.5625 1.34375 10.5625 1.8125C10.1562 0.75 9.15625 0 8 0C6.8125 0 5.8125 0.75 5.40625 1.8125C4.40625 1.34375 3.15625 1.53125 2.34375 2.34375C1.5 3.1875 1.3125 4.4375 1.78125 5.4375C0.71875 5.84375 0 6.84375 0 8C0 9.1875 0.71875 10.1875 1.78125 10.5938C1.3125 11.5938 1.5 12.8438 2.34375 13.6562C3.15625 14.5 4.40625 14.6875 5.40625 14.2188C5.8125 15.2812 6.8125 16 8 16C9.15625 16 10.1562 15.2812 10.5625 14.2188C11.5938 14.6875 12.8125 14.5 13.6562 13.6562C14.4688 12.8438 14.6562 11.5938 14.1875 10.5938C15.25 10.1875 16 9.1875 16 8ZM11.4688 6.625L7.375 10.6875C7.21875 10.8438 7 10.8125 6.875 10.6875L4.5 8.3125C4.375 8.1875 4.375 7.96875 4.5 7.8125L5.3125 7C5.46875 6.875 5.6875 6.875 5.8125 7.03125L7.125 8.34375L10.1562 5.34375C10.3125 5.1875 10.5312 5.1875 10.6562 5.34375L11.4688 6.15625C11.5938 6.28125 11.5938 6.5 11.4688 6.625Z"
                    fill="#2E5FF6"
                  />
                </svg>`;

  onMount(async () => {
    replies = post.replies;
    postUser = post.user;
    user = post.requestUserId;
    link = checkIfLink(post.commentInformation.text);
    formatDate();
    if (_.size(replies) > 0) {
      showReplies = true;
      visibleReplies = replies.map(
        (r, index) =>
          ((index === 0 && index < 3) || (_.get(r, `friendship`) && index < 3)) ? r : null
      );
    }
    loading = true;
  });

  function checkIfLink(input) {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)' +
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' +
        '((\\d{1,3}\\.){3}\\d{1,3}))' +
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
        '(\\?[;&a-z\\d%_.~+=-]*)?' +
        '(\\#[-a-z\\d_]*)?$',
      'i'
    );
    const is_link = !!urlPattern.test(input);
    return { is_link };
  }

  function formatDate() {
    const date = _.get(post, 'dates.created');
    formattedDate = moment(date).fromNow();
  }

  const showReply = index => {
    dispatch('show-hidden-thread');
    visibleReplies = visibleReplies.map((visible, i) =>
      i <= index ? true : visible
    );
  };

  function sendDispatch(Like, Header, Reply, event) {
    event.stopPropagation();
    if (Like) {
      dispatch('footer-like', { thread: true, post: post });
    } else if (Header) {
      dispatch('header-ellipses', { thread: true, post: post });
    } else if (Reply && !Like && !Header) {
      dispatch('thread-reply', { thread: true });
    }
  }
</script>

<!-- -----------  Only Displays Text, Videos, Images, & Links in a thread form -->
<div
  class="main-thread-wrapper"
  on:click={event => {
    sendDispatch(false, false, true, event);
  }}
>
  {#if post && loading && postUser}
    <div class="cm-Comments thread-wrap">
      <div class="flex">
        <div class="cm-Comments-left thread-avatar">
          <img
            height="38"
            width="38"
            class="avatar-img"
            src={postUser.userImage || defaultAvatar}
          />
        </div>

        <div class="thread-content-wrap">
          <div class="flex thread-user space-between">
            <div class="flex">
              {@html postUser.firstName}
              {@html postUser.lastName}

              {#if postUser.states.verifiedStatus === 'verified'}
                <span class="MobilePostHeader-verifiedBadge">
                  {@html verificationIcon}
                </span>
              {/if}
            </div>

            {#if options.showEllipses && !(post && post.states && post.states.isArchived)}
              <div
                class="MobilePostEdit-dots flex"
                on:click={event => {
                  sendDispatch(false, true, false, event);
                }}
              >
                {#each options.dots as dot}
                  <div class="thread-options">•</div>
                {/each}
              </div>
            {/if}
          </div>
          {#if post.commentInformation.imageUrl || post.commentInformation.giphyUrl}
            <cdlc-mobile-post-photo {post} {dispatch} {thread} />
          {/if}
          <div class="cm-Comments-text thread-text">
            {#if post.commentInformation.videoUrl}
              <cdlc-mobile-post-video {post} {dispatch} {thread} />
            {/if}
            {#if post.commentInformation.text != null && !link.is_link}
              {@html post.commentInformation.text}
            {/if}
            {#if link.is_link}
              <cdlc-mobile-post-link {post} {dispatch} {thread} />
            {/if}
          </div>
          <div
            class="thread-like-icon flex"
            on:click={event => {
              sendDispatch(true, false, false, event);
            }}
          >
            {#if user_engagements.liked}
              <div class="thread-blue-thumb">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="#2E5FF6"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_4909_1527)">
                    <path
                      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                      fill="url(#paint0_linear_4909_1527)"
                    />
                    <path
                      d="M5.34002 6.63997H3.94002C3.69503 6.63997 3.52002 6.83248 3.52002 7.05997V11.26C3.52002 11.505 3.69503 11.68 3.94002 11.68H5.34002C5.56751 11.68 5.76002 11.505 5.76002 11.26V7.05997C5.76002 6.83248 5.56751 6.63997 5.34002 6.63997ZM4.64002 10.98C4.39503 10.98 4.22002 10.805 4.22002 10.56C4.22002 10.3325 4.39503 10.14 4.64002 10.14C4.86751 10.14 5.06002 10.3325 5.06002 10.56C5.06002 10.805 4.86751 10.98 4.64002 10.98ZM10.24 4.15498C10.24 2.85997 9.40002 2.71997 8.98002 2.71997C8.61253 2.71997 8.45503 3.41997 8.38501 3.73498C8.28002 4.11997 8.19253 4.50496 7.93 4.76746C7.37 5.34496 7.07253 6.06247 6.37253 6.74496C6.33752 6.79747 6.32002 6.84995 6.32002 6.90247V10.6475C6.32002 10.7525 6.40751 10.84 6.51253 10.8575C6.79253 10.8575 7.16002 11.015 7.44002 11.1375C8.00002 11.3825 8.68252 11.68 9.52252 11.68H9.57503C10.3275 11.68 11.22 11.68 11.57 11.1725C11.7275 10.9625 11.7625 10.7 11.675 10.385C11.9725 10.0875 12.1125 9.52746 11.9725 9.07248C12.27 8.66999 12.305 8.09248 12.13 7.68995C12.34 7.47997 12.48 7.14746 12.4625 6.83248C12.4625 6.28995 12.0075 5.79997 11.43 5.79997H9.64501C9.78501 5.30999 10.24 4.88995 10.24 4.15498Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_4909_1527"
                      x1="8"
                      y1="0"
                      x2="8"
                      y2="16"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#3880FF" />
                      <stop offset="1" stop-color="#2E5FF6" />
                    </linearGradient>
                    <clipPath id="clip0_4909_1527">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            {:else}
              <div class="thread-gray-thumb">
                <svg
                  fill="#73767F"
                  stroke="none"
                  width="16"
                  height="16"
                  viewBox="0 0 15 17"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5625 9.46875C14.6875 10.1875 14.5938 10.875 14.2812 11.4375C14.375 12.1875 14.1562 12.9688 13.7188 13.5312C13.6875 15.2812 12.625 16.5 10.2188 16.5C10 16.5 9.75 16.5 9.5 16.5C6.3125 16.5 5.34375 15.3125 3.9375 15.2812C3.84375 15.6875 3.4375 16 3 16H1C0.4375 16 0 15.5625 0 15V7.5C0 6.96875 0.4375 6.5 1 6.5H4.0625C4.65625 6 5.5 4.625 6.21875 3.90625C6.65625 3.46875 6.53125 0.5 8.46875 0.5C10.25 0.5 11.4375 1.5 11.4375 3.78125C11.4375 4.375 11.3125 4.84375 11.1562 5.25H12.3125C13.8125 5.25 15 6.53125 15 7.90625C15 8.5 14.8438 9 14.5625 9.46875ZM12.625 11.1562C13.3125 10.5312 13.2188 9.5625 12.7812 9.09375C13.0938 9.09375 13.5 8.5 13.5 7.9375C13.4688 7.34375 12.9688 6.75 12.3125 6.75H9.0625C9.0625 5.5625 9.9375 5 9.9375 3.78125C9.9375 3.03125 9.9375 2 8.46875 2C7.875 2.59375 8.15625 4.09375 7.28125 4.96875C6.4375 5.8125 5.21875 8 4.3125 8H4V13.8438C5.65625 13.8438 7.125 15 9.34375 15H10.5312C11.625 15 12.4375 14.4688 12.1875 12.9688C12.6562 12.6875 13.0312 11.8125 12.625 11.1562ZM2.75 14C2.75 13.5938 2.40625 13.25 2 13.25C1.5625 13.25 1.25 13.5938 1.25 14C1.25 14.4375 1.5625 14.75 2 14.75C2.40625 14.75 2.75 14.4375 2.75 14Z"
                    fill="#73767F"
                    stroke="none"
                  />
                </svg>
              </div>
            {/if}
            <div class="thread-likes">
              {@html likes}
            </div>
          </div>
        </div>
      </div>
      <div class="thread-like-comment-wrap flex">
        <div class="thread-date">
          {formattedDate}
        </div>

        <div
          class="thread-bottom-like-btn"
          on:click={event => {
            sendDispatch(true, false, false, event);
          }}
        >
          {#if user_engagements.liked}
            Unlike
          {:else}
            Like
          {/if}
        </div>
        <div
          on:click={event => {
            sendDispatch(false, false, true, event);
          }}
        >
          Reply
        </div>
      </div>

      {#if showReplies}
        <div class="thread-comments-line"></div>
      {/if}
    </div>
  {/if}

  {#if showReplies}
    {#each replies as reply, index}
      <div class="main_thread_reply_wrapper">
        {#if visibleReplies[index]}
          {#if _.get(replies, index + 1)}
            {#if _.size(replies) > 0 && visibleReplies[index]}
              <div class="reply-thread-line"></div>
            {:else if index > 0 && !visibleReplies[index + 1]}
              <div class="reply-thread-line"></div>
              #2 line
            {/if}
          {/if}
          <cdlc-mobile-reply
            {reply}
            {index}
            {replies}
            {dispatch}
            {post}
            {user}
          />
        {:else if !visibleReplies[index]}
          {#if index > 0 && visibleReplies[index - 1]}
            <div class="hidden_replies_dots">
              {#each options.dots as dot}
                <div class="thread-options">•</div>
              {/each}
            </div>

            <div class="showMoreReplies" on:click={(e) => {
              e.stopPropagation();
              showReply(index + 2);
            }}>
              Show More Replies
            </div>
          {/if}
        {/if}
      </div>
    {/each}
  {/if}
</div>

<style src="./MobilePostThread.scss">
</style>
