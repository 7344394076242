<svelte:options tag="cdlc-text-blast-job-ad" />

<script>
  import _ from 'lodash';
  import moment from 'moment';
  export let ad_data;

  function formatText(data) {
    if (data) {
      return _.get(data, 'text')
        .replace('%LINK%', 'https://jb.cdllife.com/api/blt/12345')
        .replace('%PHONE%', _.get(data, 'callbackPhone'));
    } else {
      return;
    }
  }
</script>

{#if ad_data}
  <div
    class="TextBlastJobAd"
    on:click={() => {
      if (_.get(ad_data, 'messageInfo.sms.link')) {
        window.open(_.get(ad_data, 'messageInfo.sms.link'), '_blank');
      }
    }}
  >
    {#if ad_data.show_blast_status}
      <p class="TextBlastJobAd-blastStatus">
        {_.startCase(_.get(ad_data, 'status'))}
      </p>
      <p class="TextBlastJobAd-blastStatus">
        {moment(_.get(ad_data, 'dates.start')).format('MM/DD/YYYY')} - {moment(
          _.get(ad_data, 'dates.end')
        ).format('MM/DD/YYYY')}
      </p>
    {/if}
    <div class="TextBlastJobAd-messageBubble">
      <p class="TextBlastJobAd-messageText">
        {formatText(_.get(ad_data, 'messageInfo.sms'))}
      </p>
    </div>
  </div>
{/if}

<style src="./TextBlastJobAd.scss">
</style>
