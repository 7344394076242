import debug from 'debug';

const log = debug('cdlc:aid');

try {
  runBooter();
} catch (e) {
  log('error', e);
}

function runBooter() {
  try {
    if (
      location.origin !== 'http://localhost:6006' &&
      location.origin !== 'https://cdllife.com' &&
      location.origin !== 'http://cdllife.com' &&
      location.origin !== localStorage.getItem('AID_TEST_ORIGIN')
    ) {
      return;
    }
  } catch (e) {
    return;
  }

  let search = lsOrFallback('AID_DEBUG_SEARCH', location.search);
  const url = lsOrFallback('AID_URL', 'https://dedicatedjobs.cdllife.com'),
    parsedParams = new URLSearchParams(search),
    azUser = lsOrFallback('CDLC|AZ|USER'),
    existing = findExisting(),
    existingId = existing && existing.id,
    specialKeys = ['mc_eid'];

  let found = false;

  log('runBooter', { search, url, existing });

  if (existingId) {
    logEvent('aid-found');
  }

  for (let i = 0; i < specialKeys.length; i++) {
    if (parsedParams.get(specialKeys[i])) {
      found = true;
      break;
    }
  }

  log('  -> found', found);

  if (found || existingId) {
    search = search || '?';
    if (azUser) {
      search += `&azu=${encodeURIComponent(azUser)}`;
    }
    if (existingId) {
      search += `&e_id=${encodeURIComponent(existingId)}`;
    }
    fetch(`${url}/api/utilities/aid${search}`, { method: 'GET' })
      .then(res => res.json())
      .then(d => {
        log('result', d);
        if (d && d.data && d.data.id) {
          try {
            localStorage.setItem(
              'AID',
              JSON.stringify(Object.assign(existing || {}, d.data))
            );
            if (existing && existing.id) {
              logEvent('aid-updated');
            } else {
              logEvent('aid-added');
            }
          } catch (e) {
            console.error('e2', e);
          }
        }
      });
  } else {
    // We do not know this user
    fetch('https://m.cdllife.com/api/utilities/geolocate', { method: 'GET' })
      .then(res => res.json())
      .then(d => {
        try {
          if (d && d.data && d.data.postal) {
            // fallback
            set('zf', d.data.postal);
            let search = `?zf=${d.data.postal}`;
            if (azUser) {
              search += `&azu=${encodeURIComponent(azUser)}`;
            }
            fetch(`${url}/api/utilities/aid${search}`, {
              method: 'GET',
            })
              .then(res => res.json())
              .then(d => {
                try {
                  log('fb result', d);
                  if (d && d.data) {
                    setMerge(d.data);
                    console.error('e2', e);
                  }
                } catch (e) {}
              });
          }
        } catch (e) {}
      });
  }
}

function set(k, v) {
  setMerge({ [k]: v });
}

function setMerge(obj) {
  const existing = findExisting() || {};
  localStorage.setItem(
    'AID',
    JSON.stringify(Object.assign(existing || {}, obj))
  );
}

function findExisting() {
  try {
    return JSON.parse(localStorage.getItem('AID'));
  } catch (e) {
    return null;
  }
}

function logEvent(e, m) {
  try {
    loadCPIX();
    log('event', e);
    cpix('event', e, m || {});
  } catch (err) {
    log('error with event', err);
  }
}

function loadCPIX() {
  if (window.cpix) return;

  !(function (e, t, c, n, i, p, s, a, o) {
    e[i] ||
      (((s = e[i] =
        function () {
          s.process ? s.process.apply(s, arguments) : s.queue.push(arguments);
        }).queue = []),
      (s.t = 1 * new Date()),
      ((a = t.createElement(c)).async = 1),
      (a.src =
        'https://cdllife.com/tr/pixel.js?t=' +
        864e5 * Math.ceil(new Date() / 864e5)),
      (o = t.getElementsByTagName(c)[0]).parentNode.insertBefore(a, o));
  })(window, document, 'script', 0, 'cpix');
  cpix('init', 'ID-876628911');
  // not doing page load
}

function lsOrFallback(key, fb) {
  try {
    return localStorage.getItem(key) || fb;
  } catch (e) {
    return fb;
  }
}
