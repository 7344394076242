<svelte:options tag="cdlc-job-card-v2-detail-list" />

<script>
  import '../JobCardV2Hints/JobCardV2Hints.svelte';
  import _ from 'lodash';
  import { t } from '../../../../tools/translate/index.js';
  import stateNames from '../../../../tools/stateFullNames.json';
  import { icons } from '../../JobCardV2Tools/index';
  import { createEventDispatcher } from 'svelte';
  import { get_current_component } from 'svelte/internal';

  const svelteDispatch = createEventDispatcher(),
    component = get_current_component(),
    dispatch = (name, detail) => {
      svelteDispatch(name, detail);
      component.dispatchEvent &&
        component.dispatchEvent(new CustomEvent(name, { detail }));
    };

  export let job;
  export let clickAction;
  export let settings;

  let overviewDetails = [];
  let remainingHiringAreas = 0;
  let remainingHighlights = 0;
  let showEndorsmentTooltip = {};
  $: highlightDetails =
    _.get(job, 'computedAds.structuredHighlights') ||
    _.get(job, 'structuredHighlights') ||
    [];
  $: trueEndorsements = job && getTrueKeys(job.endorsements);
  $: sortedExperience = job && sortExperience(job.experience);
  $: {
    if (job) {
      overviewDetails = [
        {
          label: 'Driver Type',
          value: t(job.driverType) || '-',
        },
        {
          label: 'Job Type',
          value: t(job.jobTypeSelect) || '-',
        },
        {
          label: 'Trailer Type',
          value: translateArray(job.trailerType),
        },
        {
          label: 'Experience',
          value: translateRange(sortedExperience),
        },
        {
          label: 'Hiring Area',
          value:
            (job && job.hiringAreas && job.hiringAreas.length) ||
            (job && job.hiringStates && job.hiringStates.length)
              ? translateHiringAreas(job.hiringAreas, job.hiringStates)
              : '-',
          button: remainingHiringAreas
            ? {
                title: `+${remainingHiringAreas} more`,
                action: 'viewMap',
              }
            : null,
        },
        {
          label: 'Benefits',
          value: translateBenefits(highlightDetails).length
            ? translateBenefits(highlightDetails)
            : '-',
          button:
            remainingHighlights && highlightDetails.length > 1
              ? {
                  title: `+${remainingHighlights} more`,
                  action: 'viewBenefits',
                }
              : null,
        },
        {
          label: 'Endorsements',
          endorsements:
            trueEndorsements && trueEndorsements.length
              ? translateEndorsments(trueEndorsements)
              : [{ icon: '-' }],
        },
      ];
    }
  }

  function translateArray(arr) {
    let newValue = '';
    if (arr && arr.length) {
      for (let i = 0; i < arr.length; i++) {
        newValue = newValue.concat(t(arr[i]));
        if (arr.length > 1 && i !== arr.length - 1) {
          newValue = newValue.concat(', ');
        }
      }
    }
    return newValue;
  }

  function translateBenefits(arr) {
    remainingHiringAreas = 0;
    if (!arr.length) {
      return '-';
    }

    arr = _.map(arr, a => {
      return a.text;
    });

    let newValue = '';
    if (arr && arr.length === 1) {
      newValue = translateArray(arr);
    } else {
      newValue = translateArray(_.pullAt(arr, 0));

      if (arr.length) {
        remainingHighlights = arr.length;
      }
    }

    return newValue;
  }

  function translateEndorsments(arr) {
    let newValue = [];
    if (arr && arr.length) {
      for (let i = 0; i < arr.length; i++) {
        newValue.push({
          value: `<b>Required:</b><br/><span style='color:#73767F;'>${t(
            arr[i]
          )} endorsement</span>`,
          icon: icons[arr[i]],
        });
      }
    }
    return newValue;
  }

  function translateRange(arr) {
    let newValue = '';

    if (arr && arr.length) {
      let start = arr.splice(0, 1);
      newValue = newValue.concat(t(start));

      if (arr.length > 1) {
        let end = arr.splice(arr.length - 1, 1);
        newValue = newValue.concat(' - ');
        newValue = newValue.concat(t(end));
      }
      return newValue;
    }
    return '-';
  }
  function sortExperience(arr) {
    let sortedList = [
      'needtraining',
      'intraining',
      'graduatedtraining',
      '1-5_months',
      '6-11_months',
      '12-23_months',
      '2_years',
      '3_years',
      '4_years',
      '5+_years',
    ];
    let sortedArr = [];

    for (let i = 0; i < sortedList.length; i++) {
      if (_.includes(arr, sortedList[i])) {
        sortedArr.push(sortedList[i]);
      }
    }
    return sortedArr;
  }

  function translateHiringAreas(areas = [], states = []) {
    remainingHiringAreas = 0;
    let newValue = '';
    if (areas && areas.length) {
      let hiringAreas = _.map(areas, 'cityState');

      if (hiringAreas && hiringAreas.length === 1) {
        newValue = translateArray(hiringAreas);
      } else {
        newValue = translateArray(_.pullAt(hiringAreas, 0));

        if (hiringAreas.length) {
          remainingHiringAreas = hiringAreas.length;
        }
      }
    }

    if (states.length) {
      let translate = areas.length < 2;
      if (newValue.length) {
        newValue += ', ';
      }
      newValue += translateStates(states, translate);
    }

    return newValue;
  }

  function translateStates(statesArr, translate = true) {
    let translated = '';

    for (let i = 0; i < statesArr.length; i++) {
      const abbreviation = statesArr[i];
      const stateName = stateNames[abbreviation];
      if (stateName) {
        if (i === 0 && translate) {
          translated += stateName;
        } else if (i === 1 && translate) {
          translated += ', ' + stateName;
        } else {
          remainingHiringAreas += 1;
        }
      }
    }
    return translated;
  }

  function getTrueKeys(obj) {
    let trueKeys = [];
    for (const key in obj) {
      if (obj[key] === true) {
        trueKeys.push(key);
      }
    }

    return trueKeys;
  }
</script>

<div class="JobCardV2DetailList">
  {#each overviewDetails as detail}
    <div class="JobCardV2DetailList-item">
      <p class="JobCardV2DetailList-label">{detail.label}:</p>
      <p
        class="JobCardV2DetailList-value"
        class:shortenText={detail && detail.value && detail.value.length > 28}
        class:plusMoreText={detail.button &&
          (remainingHighlights > 0 || remainingHiringAreas > 0) &&
          detail.value.length > 16}
      >
        {#if detail.label === 'Endorsements'}
          {#each detail.endorsements as endorsement, i}
            <div
              class="JobCardV2DetailList-endorsementWrapper"
              on:mouseenter={() => {
                showEndorsmentTooltip[i] = !showEndorsmentTooltip[i];
              }}
              on:mouseleave={() => {
                showEndorsmentTooltip[i] = !showEndorsmentTooltip[i];
              }}
            >
              <span class="JobCardV2DetailList-endorsement">
                {@html endorsement.icon}
                {#if showEndorsmentTooltip[i] && endorsement.icon !== '-'}
                  <cdlc-job-card-v2-hints hint={endorsement.value} />
                {/if}
              </span>
            </div>
          {/each}
        {:else}
          {detail.value}
        {/if}
      </p>
      {#if detail.button}
        <button
          on:click={() => {
            dispatch(detail.button.action);
          }}
          style={detail.button.style}
          class="JobCardV2DetailList-itemButton">{detail.button.title}</button
        >
      {/if}
    </div>
  {/each}
</div>

<style src="./JobCardV2DetailList.scss">
</style>
