import _ from 'lodash';

const jobTools = {
  formatLocations: (locations, meta) => {
    let str;
    let locs = (locations || []).map(d => d.cityState).sort(),
      last = locs.pop() || '',
      s = '';

    if (!_.size(locations)) {
      return '';
    }
    if (!locs.length) str = last;
    if (locs.length > 1) s = ', and ';
    else if (locs.length === 1) s = ' and ';
    str = locs.join(', ') + s + last;

    if (_.size(str) > 65) {
      return (_.get(meta, 'count') || locs.length + 1) + ' locations';
    }
    return str;
  },

  formatCityState: function(cityState) {
    if (!cityState) return '';

    var city = cityState.split(', ')[0],
      state = cityState.split(', ')[1];

    if (city.toLowerCase() == 'undefined') return state;
    return cityState;
  },

  formatOtrLocations: function(states, radiuses) {
    var sortedStates = (states || []).slice().sort(),
      sortedRadius = _.sortBy((radiuses || []).slice(), d => {
        return String(d.cityState || '').toLowerCase();
      })
        .map(_.property('cityState'))
        .filter(_.identity);

    if (_.size(sortedRadius) > 10) {
      sortedRadius = [sortedRadius.length + ' cities'];
    }

    if (!_.size(sortedStates) && !_.size(sortedRadius)) {
      return 'No locations specified';
    }

    return getText(getList());

    function getText(list) {
      return list.join(' • ');
    }

    function getList() {
      var list,
        DC = _.includes(states, 'DC');

      if (sortedStates.length > 10) {
        if (DC) {
          sortedStates.pop(); // just remove a random one
          list = _.union([sortedStates.length + ' states', 'DC'], sortedRadius);
        } else {
          list = _.union([sortedStates.length + ' states'], sortedRadius);
        }
      } else {
        list = _.union(sortedStates, sortedRadius);
      }

      return list;
    }
  },
  formatXMLCardTitle: function(title) {
    return title.length > 100 ? title.substring(0, 100) + '...' : title;
  },
  formatTrailerTypes: function(types, list) {
    let s = '';
    _.each(list, l => {
      if (s) {
        s += ', ';
      }
      s += types[l] || '';
    });
    return s;
  },
  payTimeRange: function(d) {
    if (/month/i.test(d)) {
      return 'month';
    } else if (/annual/i.test(d)) {
      return 'year';
    } else return 'week';
  },
  formatPrice: function(d) {
    var str = String(d).replace(/[^0-9.]/g, ''),
      d = Number(str) || 0,
      b = d < 0 ? '-$' : '$';

    return (
      '$' +
      d
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        .replace(/\.00$/, '')
    );
  },

  isNumber: function(type, rate) {
    if (type == 'paypermile' || type == 'payperhour') {
      if (rate && /^([0-9]+)?(\.\d+)?$/.test(rate)) return true;
      else return false;
    } else return true;
  },

  admin: function() {
    var role = _locals.user && _locals.user && _locals.user.role;
    return role && role == 'admin';
  },

  userPosted: function() {
    var jobId = this.get('job.user'),
      userId = _locals.user._id;
    return jobId == userId;
  },

  superAdmin: function() {
    return tools.hasSubRole('superAdmin') && this.get('admin');
  },

  salesRep: function() {
    var role = _locals.user && _locals.user && _locals.user.role;
    return role && role == 'sales_rep';
  },

  jobid: function() {
    var id = this.get('job._id');
    if (!id || !id.length) {
      return 'NO ID';
    } else {
      return id.slice(id.length - 6).toUpperCase();
    }
  },

  hasXMLMetadata: function() {
    if (this.get('isXMLJob')) {
      return _.size(_.keys(_.get(this.get('job'), 'metadata')));
    } else {
      return false;
    }
  },

  isXMLJob: function() {
    return _.get(this.get('job'), 'cardType') === 'xml';
  },

  owner: function() {
    var userid = _.get(_locals, 'user._id'),
      owner = userid && this.get('job.user') == userid;

    return owner;
  },
};

export default jobTools;
