<script>
  import debug from 'debug';
  import _ from 'lodash';
  import { onMount, tick } from 'svelte';
  import { get_current_component } from 'svelte/internal';
  import { placeTypeIcon } from './../svg.js';

  const log = debug('cdlc:MobilePostReview');

  export let dispatch = () => {};
  export let post;
  let placeImageUrl;

  let svg;
  let address = '';
  let message;

  onMount(async () => {
    await tick();

    message = _.get(post, 'link_data.review.review', '');

    placeImageUrl = updatePlaceImage();

    svg = placeTypeIcon(_.get(post, 'link_data.place', ''));
    let addressLine1 = _.get(post, 'link_data.place.address');
    let addressLine2 = _.get(post, 'link_data.place.addressLine2');
    let city = _.get(post, 'link_data.place.city');
    let state = _.get(post, 'link_data.place.state');
    let zipcode = _.get(post, 'link_data.place.zipcode');

    address = addressLine1;
    if (addressLine2) {
      address += ' ' + addressLine2;
    }
    address += city + ', ' + state + ' ' + zipcode;
  });

  function updatePlaceImage() {
    const lat = _.get(post, 'link_data.place.location.coordinates[1]'),
      lon = _.get(post, 'link_data.place.location.coordinates[0]'),
      token = `pk.eyJ1IjoiY2RsbGlmZSIsImEiOiJjajBqdDRjMmUwMXVzMzJycWR5M2g0bDViIn0.u6LWT9oaeco7Uw6KnjvG6g`;
    return (
      'https://api.mapbox.com/styles/v1/mapbox/traffic-day-v2/static/' +
      `pin-s+f00(${lon},${lat})/${lon},${lat},15,0,10/600x600` +
      `?access_token=${token}`
    );
  }
</script>

<style src="./MobilePostReview.scss">
</style>

<svelte:options tag="cdlc-mobile-post-review" />

{#if post}
  <div class="MobilePostReview">
    {#if message}
      <cdlc-mobile-post-text {dispatch} {post} text={message} max_length="275" />
    {/if}
    <div class="MobilePostReview-reviewCard">
      <div
        class="PostReview"
        on:click={() => {
          dispatch('body-review', {
            place: _.get(post, 'link_data.place._id'),
          });
        }}>
        <div class="PostReview-Map"><img src={placeImageUrl} alt="" /></div>
        <div class="PostReview-Footer">
          {#if svg}
            <span class="PostReview-icon">{@html svg}</span>
          {/if}
          <div style="display: inline-block; width: calc(100% - 9px - 2em);">
            <div class="PostReview-FooterLocationName">
              {_.get(post, 'link_data.place.name', '')}
            </div>
            <div class="PostReview-FooterLocationAddress">{address}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
{/if}
