const validators = {};

validators.required = d => {
  if (d === undefined || d === null) {
    d = '';
  }
  return String(d).length ? null : 'Value is required.';
};

validators.zipcode = d => {
  if (d === undefined || d === null) {
    d = '';
  }
  return String(d).length === 5 ? null : 'Must be a 5 digit zip code.';
};

validators.email = val => {
  var str = String(val),
    re = /^([\w\-\.\+]+)@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([\w\-]+\.)+)([a-zA-Z]{2,15}))$/;
  return re.test(str) ? null : 'Not a valid email.';
};

export default validators;
