<svelte:options tag="cdlc-job-card-title" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import { createEventDispatcher, onMount, tick } from 'svelte';
  import { get_current_component } from 'svelte/internal';
  import jobTools from '../../../../tools/jobTools/tools.js';

  const log = debug('cdlc:JobCardTitle'),
    svelteDispatch = createEventDispatcher(),
    component = get_current_component(),
    dispatch = (name, detail) => {
      svelteDispatch(name, detail);
      component.dispatchEvent &&
        component.dispatchEvent(new CustomEvent(name, { detail }));
    };

  export let job;
  let fullNote = false;

  onMount(async () => {
    await tick();
    log('job on mount: ', job);
  });
</script>

{#if job}
  <div class="JobCard-title">
    <div class="JobCard-titleWrapper">
      {#if job.cardType === 'hybrid'}
        {#if /custom/i.test(job.payType) && !fullNote}
          <h2>Click for Details</h2>
        {:else if /custom/i.test(job.payType)}
          <div style="height: 4px;" />
        {:else if job.payType == 'clickForDetails'}
          <div>
            {#if !fullNote}
              <h2>Click for Details</h2>
            {:else}
              <div style="height: 6px;" />
            {/if}
          </div>
        {:else if /range/i.test(job.payType)}
          <h2>
            <span class="JobCard-price">
              {jobTools.formatPrice(job.payMin)} - {jobTools.formatPrice(
                job.payMax
              )}
              {#if job.payMaxPlus}+{/if}
            </span>
            per {jobTools.payTimeRange(job.payType)}
          </h2>
        {:else}
          <div>
            <h2>
              {#if job.payUpTo}Up to{/if}
              <span class="JobCard-price">
                {jobTools.formatPrice(job.pay)}
                {#if job.payMaxPlus}+{/if}
              </span>
              per {jobTools.payTimeRange(job.payType)}
            </h2>
          </div>
        {/if}
        <h3>
          {#if job.type == 'otr'}
            {jobTools.formatOtrLocations(job.operatingStates, job.stops)}
          {:else if job.stops}
            {#if job.stops.length > 1}
              {jobTools.formatCityState(job.stops[0].cityState)} &rarr; {jobTools.formatCityState(
                job.stops[job.stops.length - 1].cityState
              )}
            {:else if (job.stops.length = 1 && job.stops.length != [])}
              {jobTools.formatCityState(job.stops[0].cityState)}
            {:else}
              No Stops Specified
              {#if job.type == 'radius'}
                ({job.stops[0].routeRadius || 50} mile radius)
              {/if}
            {/if}
          {:else}No Stops Specified{/if}
        </h3>
      {:else if job.cardType === 'xml'}
        {#if (job.descriptionCardTitle || job.title).length > 29}
          <h2>
            {jobTools.formatXMLCardTitle(job.title || job.descriptionCardTitle)}
          </h2>
        {:else}
          <h2>
            {jobTools.formatXMLCardTitle(job.title || job.descriptionCardTitle)}
          </h2>
        {/if}
        <h3>
          {jobTools.formatLocations(job.hiringAreas, job.hiringAreasMetadata)}
        </h3>
      {:else}
        {#if (job.descriptionCardTitle || job.title).length > 29}
          <h2>{job.title || job.descriptionCardTitle}</h2>
        {:else}
          <h2>{job.title || job.descriptionCardTitle}</h2>
        {/if}
        <h3>
          {#if job.type == 'otr' && (_.size(job.operatingStates) || _.size(job.stops))}
            {jobTools.formatOtrLocations(job.operatingStates, job.stops)}
          {:else if job.type != 'otr-freeform' && _.size(job.locations)}
            {jobTools.formatLocations(job.locations)}
          {:else}No Stops Specified{/if}
        </h3>
      {/if}
    </div>
  </div>
{/if}

<style src="./JobCardTitle.scss">
</style>
