const datatypes = {};

function comma(d) {
  var str = String(d).replace(/[^0-9]/g, ''),
    d = Number(str) || 0;
  return d.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function formatPrice(d) {
  var str = String(d).replace(/[^0-9.]/g, ''),
    d = Number(str) || 0,
    b = d < 0 ? '-$' : '$';

  return (
    '$' +
    d
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      .replace(/\.00$/, '')
  );
}

datatypes.driverType = {
  studentRecentGrad: 'Student / Recent Graduate',
  soloCompany: 'Solo Company Driver',
  soloLease: 'Solo Lease Purchase',
  soloOwner: 'Solo Owner Operator',
  teamCompany: 'Team Company Driver',
  teamLease: 'Team Lease Purchase',
  teamOwner: 'Team Owner Operator',
};

datatypes.driverTypeArr = Object.keys(datatypes.driverType).map(function (k) {
  return { key: k, value: datatypes.driverType[k] };
});

datatypes.jobType = {
  route: 'Dedicated Route',
  radius: 'Regional / Radius',
  otr: 'Over the Road',
};

datatypes.jobTypeArr = Object.keys(datatypes.jobType).map(function (k) {
  return { key: k, value: datatypes.jobType[k] };
});

datatypes.jobTypeSelect = {
  otr: 'Over the Road',
  regional: 'Regional',
  dedicatedlane: 'Dedicated Lane',
  dedicatedaccount: 'Dedicated Account',
  local: 'Local',
  'non-driving': 'Non-Driving',
  'diesel-mechanic': 'Diesel Mechanic',
};

datatypes.jobTypeSelectArr = Object.keys(datatypes.jobTypeSelect).map(function (
  k
) {
  return { key: k, value: datatypes.jobTypeSelect[k] };
});

datatypes.trailerType = {
  dryVan: 'Dry Van',
  reefer: 'Refrigerated (Reefer)',
  flatbed: 'Flatbed',
  tanker: 'Tanker',
  doubtrip: 'Doubles / Triples',
  intermodal: 'Intermodal',
  hhg: 'Household Goods',
  hazmat: 'Hazmat', // direct leads only
  specialized: 'Specialized',
  hauler: 'Auto Hauler',
  doublepups: 'Double Pups', // direct leads only
  dryBulk: 'Dry Bulk & Pneumatic',
  // other:        'Other'  -- deprecated
};

datatypes.trailerTypeArr = Object.keys(datatypes.trailerType).map(function (k) {
  return { key: k, value: datatypes.trailerType[k] };
});

datatypes.milesTypeFns = {
  describe: function (j) {
    var upTo = j.milesUpTo ? 'Up to ' : '',
      plus = j.milesPerPlus ? '+' : '';

    if (j.milesType == 'milesperweek') {
      if (j.milesPerWeekRange) {
        return (
          comma(j.milesPerWeekMin) +
          ' - ' +
          comma(j.milesPerWeekMax) +
          plus +
          ' per week'
        );
      } else return upTo + comma(j.milesPerWeek) + plus + ' per week';
    } else {
      if (j.milesPerMonthRange) {
        return (
          comma(j.milesPerMonthMin) +
          ' - ' +
          comma(j.milesPerMonthMax) +
          plus +
          ' per month'
        );
      } else return upTo + comma(j.milesPerMonth) + plus + ' per month';
    }
  },
};

datatypes.paymentTypeFns = {
  getRateText: function (n) {
    if (n == 'paypermile') return 'mile';
    if (n == 'payperhour') return 'hour';
  },
  describe: function (j) {
    var upTo = j.payRateUpTo ? 'Up to ' : '';

    if (j.paymentType == 'salary') return 'Salary';
    else if (j.paymentType == 'varies') return 'Varies';
    else if (j.paymentType == 'calltodiscuss') return 'Call to Discuss';
    else if (j.paymentType == 'custom') return 'Click for Details';
    else if (j.paymentType == 'percentLoad') {
      return (j.payRate || '').replace('%', '') + '% of line haul';
    } else
      return (
        upTo +
        formatPrice(j.payRate) +
        ' per ' +
        datatypes.paymentTypeFns.getRateText(j.paymentType)
      );
  },
  range: function (n, min, max) {
    var desc;

    if (n == 'paypermile') desc = ' per mile';
    if (n == 'payperhour') desc = ' per hour';

    return formatPrice(min) + ' - ' + formatPrice(max) + desc;
  },
};

datatypes.payTypeFns = {
  describe: function (j) {
    let label;

    if (j.payType !== 'clickForDetails') {
      if (j.payType == 'fixed') label = ' Weekly';
      if (j.payType == 'monthlyFixed') label = ' Monthly';
      if (j.payType == 'annuallyFixed') label = ' Annually';

      if (j.payUpTo) {
        return 'Up to ' + formatPrice(j.pay) + label;
      } else if (j.payMaxPlus) {
        return formatPrice(j.pay) + '+' + label;
      } else {
        return formatPrice(j.pay) + label;
      }
    } else {
      return 'Click For Details';
    }
  },

  range: function (n, min, max) {
    var desc;

    if (n === 'monthlyRange') desc = ' Monthly';
    if (n === 'range') desc = ' Weekly';
    if (n === 'annuallyRange') desc = ' Annually';
    return formatPrice(min) + ' - ' + formatPrice(max) + desc;
  },
};

datatypes.homeTime = {
  daily: 'Daily',
  eotherday: 'Every Other Day',
  onedayweek: '1 Day/Wk',
  twodayweek: '2 Days/Wk',
  weekly: 'Weekly',
  weekends: 'Weekends',
  eother2weeks: 'Every 2 Wks',
  eother3weeks: 'Every 3 Wks',
  monthly: 'Monthly',
  xDays: 'Custom',
  calltodiscuss: 'Call to Discuss',
  varies: 'Varies',
};

datatypes.homeTimeArr = Object.keys(datatypes.homeTime).map(function (k) {
  return { key: k, value: datatypes.homeTime[k] };
});

datatypes.bonusTypeFns = {
  describe: function (j) {
    var b = j.bonusOffer,
      d = j.bonusDetails;

    if (b == 'calltodiscuss') return 'Call to Discuss';
    else if (b == 'yes') {
      if (d) return d;
      else return 'Yes';
    } else return 'No';
  },
};

export default datatypes;
