<svelte:options tag="cdlc-mobile-post-video" />

<script>
  import debug from 'debug';
  import _ from 'lodash';
  import '../MobilePostText/MobilePostText.svelte';
  import { onMount } from 'svelte';

  const log = debug('cdlc:MobilePostVideo');

  export let dispatch = () => {};
  export let thread = false;
  export let post;

  let video;
  let message;
  let videoUrl;
  let poster;

  $: message =
    _.get(post, 'videoInformation.caption') ||
    _.get(post, 'commentInformation.text');

  $: {
    if (post && !thread) {
      videoUrl = post.videoInformation.videoUrl;
      poster = post.videoInformation.poster || post.videoInformation.videoUrl;
    } else if (thread) {
      videoUrl = post.commentInformation.videoUrl;
      poster = post.commentInformation.videoUrl;
    }
  }

  function handleVideoClick(e) {
    dispatch('body-video-click', { video });
  }

  function handleLoadedMetadata(e) {
    dispatch('body-video-ready', { video });
  }

  function handlePause(e) {
    dispatch('body-video-pause', { video });
  }

  function handlePlay(e) {
    dispatch('body-video-play', { video });
  }

  onMount(() => {
    const regexTimeframe = /#t=/gm;
    if (!thread) {
      if (
        !regexTimeframe.test(post.videoInformation.videoUrl) &&
        !post.videoInformation.poster &&
        !thread
      ) {
        post.videoInformation.videoUrl += '#t=0.1';
      }
    }
  });
</script>

{#if post}
  <div class="MobilePostVideo">
    <div class="MobilePost-postSection">
      {#if message && !thread && !post.videoInformation.youtubeId}
        <cdlc-mobile-post-text
          {dispatch}
          {post}
          text={message}
          max_length="275"
        />
      {/if}

      {#if videoUrl}
        <div class="MobilePost-postSection-video">
          <video
            controls
            preload="metadata"
            bind:this={video}
            playsinline
            webkit-playsinline
            {poster}
            on:click={handleVideoClick}
            on:loadedmetadata={handleLoadedMetadata}
            on:pause={handlePause}
            on:play={handlePlay}
          >
            <source
              style="object-fit: contain;"
              type="video/mp4"
              src={videoUrl}
            />
          </video>
        </div>
      {:else if post.videoInformation.youtubeId}
        <div class="MobilePost-postSection-video">
          <iframe 
            width="100%" 
            height="250vw" 
            src="https://www.youtube.com/embed/{post.videoInformation.youtubeId}" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          ></iframe>
        </div>
      {/if}
    </div>
  </div>
{/if}

<style src="./MobilePostVideo.scss">
</style>
