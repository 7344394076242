
const exp = {
  fetch: (url, opts) => {
    url = String(url).replace(/^\//, '');
    url = String('https://cdllife-web-components.herokuapp.com/api/components/FileUpload/' + url);
    return fetch(url, opts || {
      method: 'GET'
    }).then(res => {
      return res.json();
    }).then(res => {
      if (res && res.error) {
        return Promise.reject(res.error);
      }
      return res && res.data;
    });
  }
}
export default exp;
