<svelte:options tag="cdlc-job-card-v2-map" />

<script>
  export let job;
  let mapAPI, lastSrc;
  $: {
    if (job && job.imgSrc && job.imgSrc !== lastSrc) {
      mapAPI = `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyCp4aj43tx9151AKGc2ZV8GNKfSbCgq3NE&size=323x246&scale=2&${job.imgSrc}`;
      lastSrc = job.imgSrc;
    }
  }
</script>

{#if job}
  <div class="JobCard-imgWrap">
    <div class="JobCard-map">
      {#if mapAPI}
        <img alt="map" src={mapAPI} />
      {/if}
    </div>
  </div>
{/if}

<style src="./JobCardV2Map.scss">
</style>
